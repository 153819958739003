// React
import React, { useState } from "react";

// Styles
import "../../components/pbTheme.css";
import useStyles from "./styles";
import "./styles/style.css";

// MUI
import Grid from "@material-ui/core/Grid";

// Router
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import { useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";

// Component
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Modal,
  Typography,
} from "@material-ui/core";
import {
  ChevronRightRounded,
  Close,
  Notifications,
  Person,
} from "@material-ui/icons";
import { Helmet } from "react-helmet";
import slugify from "slugify";
import BottomNav from "../../components/BottomNav";
import { useCoin } from "../../components/CoinProvider";
import { useNotifikasi } from "../../components/NotifikasiProvider";
import useRest from "../../hooks/useRest";
import beritaImg from "../../images/berita.png";
import cekPeluangImg from "../../images/cekPeluang.png";
import gabungImg from "../../images/gabung.png";
import coinImage from "../../images/icon-coin.png";
import konsultasiImg from "../../images/konsul.png";
import lainnyaImg from "../../images/lainnya.png";
import latihanImg from "../../images/latihan.png";
import plusImg from "../../images/plus.png";
import { baseURL } from "../../images/s3";
import testMinatBakatImg from "../../images/tesMinat.png";
import { delimiters } from "../../utils/formatter-rupiah";
import LengkapiData from "./DialogLengkapiData";
import HomeItem from "./HomeItem";
import ModalItem from "./ModalItem";

function Home({ history }) {
  // UseStyles
  const classes = useStyles();

  // Profie
  const { profile } = useSiswa();
  const { user, firestore } = useFirebase();
  const [banner] = useDocumentData(firestore.doc(`events/banner`));
  // Dialog
  const [dialog, setDialog] = useState({
    open: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [anounceDialog, setAnounceDialog] = useState(true);

  const [newsFetcher] = useRest({
    url: "news",
    method: "get",
    params: {
      type: "list",
    },
  });

  const { notifItems } = useNotifikasi();
  let unreadCount = null;
  if (notifItems) {
    const findunreadCount = notifItems.filter((item) => !item.read);
    if (findunreadCount) {
      unreadCount = findunreadCount.length;
    }
    if (unreadCount <= 0) {
      unreadCount = null;
    } else if (unreadCount > 10) {
      unreadCount = "10+";
    }
  }

  const { Coin } = useCoin();
  const koin = Coin?.coin ? Coin.coin : 0;

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let newsList = newsFetcher?.data.Items;

  if (newsList) {
    newsList.map((news) => {
      if (news.pinNews === false) {
        const timestamp = news.publishedAt;
        const date = new Date(timestamp);
        const dayOfWeek = new Intl.DateTimeFormat("en-US", {
          weekday: "long",
        }).format(date);
        const dayOfMonth = date.getDate();
        const monthName = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        news.publishDate = `${dayOfWeek}, ${dayOfMonth} ${monthName} ${year} | ${hours}:${minutes}`;
        return news;
      }
      return null;
    });

    newsList = newsList.slice(0, 5);
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>PakBudi | Home</title>
      </Helmet>
      {/* <TopNav /> */}
      <div className="pb-home-top-container">
        <div
          className="pb-name-wrapper"
          onClick={() => history.push("/settings")}
        >
          <div className="pb-icon-wrapper">
            <Person color="primary" fontSize="medium" />
          </div>
          <Typography variant="body1">
            {profile?.username ? profile.username : "anak Pak Budi"}
          </Typography>
        </div>
        <div className="pb-notif-container">
          <Link to="/notifikasi">
            {unreadCount && (
              <span className="pb-navbar-notification-counter">
                {unreadCount}
              </span>
            )}
            <Notifications color="primary" fontSize="large" />
          </Link>
        </div>
      </div>
      <div className="pb-coin-card-container">
        <div className="pb-coin-card-info">
          <Typography variant="subtitle2">Coin Pakbudi</Typography>
          <Typography
            color="primary"
            variant="h5"
            style={{ fontWeight: "bold" }}
          >
            {delimiters(koin)} Coin
          </Typography>
          <Typography variant="caption">
            <span style={{ fontWeight: "bold" }}>Kerjakan Misi</span> untuk
            dapat koin gratis
          </Typography>
        </div>
        <Divider orientation="vertical" />
        <div className="pb-coin-action" onClick={() => history.push("/myCoin")}>
          <img
            src={coinImage}
            style={{ width: "30px", height: "30px", marginBottom: "5px" }}
            alt="Pakbudi Coin"
          />
          <Typography variant="subtitle2" color="secondary">
            Tambah Coin
          </Typography>
        </div>
      </div>
      <Grid container spacing={3} style={{ width: "95%", margin: "0 auto" }}>
        <HomeItem
          image={testMinatBakatImg}
          label="Tes Minat Bakat"
          action={() => history.push("/tesminatbakat")}
        />
        <HomeItem
          image={plusImg}
          label="Tes Minat Bakat Plus"
          action={() => history.push("/tesminatbakat")}
        />
        <HomeItem
          image={cekPeluangImg}
          label="Cek peluang"
          action={() => history.push("/cekPeluang")}
        />
        <HomeItem
          image={beritaImg}
          label="Berita"
          action={() => {
            const newspath = user ? "/berita" : "/warta";
            history.push(newspath);
          }}
        />
        <HomeItem
          image={latihanImg}
          label="Latihan & Ujian"
          action={() => history.push("/listTryout")}
        />
        <HomeItem
          image={konsultasiImg}
          label="Konsultasi Online"
          action={() => history.push("/konsultasi/semua")}
        />
        <HomeItem
          image={gabungImg}
          label="Gabung Mitra"
          action={() => window.open("https://mitra.pakbudi.id", "_blank")}
        />
        <HomeItem
          image={lainnyaImg}
          label="Lainnya"
          action={() => setOpenModal(true)}
        />
      </Grid>
      <Grid container spacing={2} className={classes.page2}>
        {/* BANNER */}
        <Grid item xs={12}>
          {banner?.photoUrl && banner.target_event.tipe === "offline" && (
            <Link to={`/acara/u/${banner?.target_event?.id}`}>
              <img
                style={{ marginTop: 10 }}
                src={banner?.photoUrl}
                alt=""
                crossOrigin="anonymous"
                width="100%"
              />
            </Link>
          )}
          {banner?.photoUrl && banner.target_event.tipe === "online" && (
            <Link
              to={{ pathname: banner?.target_event?.lokasi_online }}
              target="_blank"
            >
              <img
                style={{ marginTop: 15 }}
                src={banner?.photoUrl}
                alt=""
                crossOrigin="anonymous"
                width="100%"
              />
            </Link>
          )}
        </Grid>
      </Grid>
      {/* BERITA */}
      <div className="pb-news-container">
        {newsList && (
          <>
            <span className="pb-news-intro">Berita Update Untukmu!</span>
            {newsList.map((news) => (
              <div
                key={news.newsId}
                onClick={() => {
                  const newspath = user ? "berita" : "warta";
                  history.push(
                    `/${newspath}/${news.newsId}/${slugify(news.title)}`
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="pb-news-item">
                  <div
                    className="pb-news-item-image-container"
                    style={{ backgroundImage: `url(${news?.thumbnailURL})` }}
                  ></div>
                  <div className="pb-news-item-info-container">
                    <h1>{news.title}</h1>
                    <span>{news.publishDate}</span>
                  </div>
                  <div className="pb-news-arrow">
                    <ChevronRightRounded />
                  </div>
                </div>
                <Divider style={{ marginTop: 10 }} />
              </div>
            ))}
            <p
              className="more-text"
              onClick={() => {
                const newspath = user ? "berita" : "warta";
                history.push(`/${newspath}`);
              }}
            >
              View More <ChevronRightRounded color="primary" />
            </p>
          </>
        )}
      </div>
      <LengkapiData
        {...dialog}
        handleClose={() => {
          setDialog((dialog) => {
            return {
              ...dialog,
              open: false,
            };
          });
        }}
      />
      <BottomNav />
      <Dialog open={anounceDialog} onClose={() => setAnounceDialog(false)}>
        <DialogTitle id="alert-dialog-title">
          <div className={classes.logo}>
            <img
              className={classes.logoImg}
              src={`${baseURL}new-logo-c.png`}
              alt=""
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h5"
              style={{ fontWeight: 800, color: "black", textAlign: "center" }}
            >
              PEMBERITAHUAN
            </Typography>
            <div className={classes.announceBox}>
              <Typography
                style={{ fontWeight: 600, textAlign: "center", color: "white" }}
              >
                Kami informasikan bahwa akun instagram @pakbudiapp sedang
                terkena serangan oleh pihak yang tidak bertanggung jawab.
              </Typography>
            </div>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 600,
                color: "black",
                textAlign: "center",
                lineHeight: 1.2,
              }}
            >
              Jika ada pertanyaan <br /> hubungi 0851-7313-3644
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAnounceDialog(false)} color="primary">
            Oke
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="pb-home-modal">
          <Typography
            style={{ fontWeight: "bold", color: "gray", marginBottom: 10 }}
          >
            LAYANAN PAKBUDI
          </Typography>
          <ModalItem
            image={testMinatBakatImg}
            label="Tes Minat Bakat"
            action={() => history.push("/tesminatbakat")}
          />
          <ModalItem
            image={plusImg}
            label="Tes Minat Bakat Plus"
            action={() => history.push("/tesminatbakat")}
          />
          <ModalItem
            image={cekPeluangImg}
            label="Cek peluang"
            action={() => history.push("/cekPeluang")}
          />
          <ModalItem
            image={beritaImg}
            label="Berita"
            action={() => {
              const newspath = user ? "/berita" : "/warta";
              history.push(newspath);
            }}
          />
          <ModalItem
            image={latihanImg}
            label="Latihan & Ujian"
            action={() => history.push("/listTryout")}
          />
          <ModalItem
            image={konsultasiImg}
            label="Konsultasi Online"
            action={() => history.push("/konsultasi/semua")}
          />
          <ModalItem
            image={gabungImg}
            label="Gabung Mitra"
            action={() => window.open("https://mitra.pakbudi.id", "_blank")}
            showDivider={false}
          />
          <div className="pb-modal-close" onClick={() => setOpenModal(false)}>
            <Close style={{ color: "white" }} />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Home;
