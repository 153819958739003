import React, { useContext } from 'react';

import { useFirebase } from './FirebaseProvider';
import { useDocumentData, useCollectionData } from 'react-firebase-hooks/firestore';
import AppLoading from './AppLoading';
const NotifikasiContext = React.createContext();

export function useNotifikasi() {
    return useContext(NotifikasiContext);
}

function NotifikasiProvider(props) {
    const { user, firestore } = useFirebase();
    const notifdoc = firestore.doc(`notifikasi/${user.uid}`);
    const notifItem = firestore.doc(`notifikasi/${user.uid}`).collection('items');
    const [notif, loadingnotif] = useDocumentData(notifdoc);
    const [notifItems, loadingItems] = useCollectionData(notifItem.orderBy("createdAt", "desc").limit(15), { idField: 'id' });

    if (loadingnotif || loadingItems) {
        return <AppLoading />
    }
    return (
        <NotifikasiContext.Provider value={{
            notif, loadingnotif,
            notifItems, loadingItems
        }}>
            {props.children}
        </NotifikasiContext.Provider>
    )
}

export default NotifikasiProvider;

