let firebaseConfig = {
    apiKey: "AIzaSyB37fbQz3fll2SLIUXEcTuLaH8MqvWJ6PE",
    authDomain: "pakbudi-dev.firebaseapp.com",
    databaseURL: "https://pakbudi-dev.firebaseio.com",
    projectId: "pakbudi-dev",
    storageBucket: "pakbudi-dev.appspot.com",
    messagingSenderId: "538827530705",
    appId: "1:538827530705:web:805a39d73c6432e4a4c0f0",
    measurementId: "G-BV488CPEXZ"
};

let vapidKey = 'BK01zH_rfIvoMzHipoT3dmXPIcH6ux24B7G1WB0du7YltAwG1gSyRD6wTZjICwZqxzL6SA-71VCQDYCetkJc6s8';


if (process.env.REACT_APP_FIREBASE_PROD === "YES") {
    firebaseConfig = {
        apiKey: "AIzaSyDeG_Iy4bq5QeF2Ap4gUeq2vWvQZl6cuKE",
        authDomain: "pakbudi-prod.firebaseapp.com",
        databaseURL: "https://pakbudi-prod.firebaseio.com",
        projectId: "pakbudi-prod",
        storageBucket: "pakbudi-prod.appspot.com",
        messagingSenderId: "139974693653",
        appId: "1:139974693653:web:91b1d58d19b5782a36cf1c",
        measurementId: "G-YXSS9D1MSN"
    };
    vapidKey = 'BD82F4kqxAqIqHNEhJv7BiR7CyNDm6RChMVKF_PV5biHtwX2So0OpnSGXeDPjKACU5OzN59ZuEiQyV4PWXidpZg'
}

export const publicVapidKey = vapidKey;

export default firebaseConfig;