// React
import React from "react";

// Router
import { Route, Switch } from "react-router-dom";

// Styles
import useStyles from "./styles";

// Components
import BottomNav from "../../components/BottomNav";
import FormPembayaran from "../acara/formPembayaran";
import KonfirmasiPembayaran from "../acara/konfirmasiPembayaran";
import LengkapiPendaftaran from "../acara/lengkapiPendaftaran";
import UserEticket from "../acara/userEticket";
import UserEventIntro from "../acara/userIntro";
import PBLeaderboard from "../leaderboard/";
import Misi from "../misi";
import Setelan from "../settings/";
import ChangeEmailPassword from "../settings/changeEmailPassword";
import EditProfile from "../settings/editProfile";
import SetelanNotifikasi from "../settings/notifikasi";
import Vouchers from "../settings/vouchers";
import TmbPlusCheckout from "../tmbPlus";

function LayoutC() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.appBody}>
        <Switch>
          <Route path="/settings" component={Setelan} />
          <Route path="/editProfile" component={EditProfile} />
          <Route path="/editProfile" component={EditProfile} />
          <Route path="/notifikasisettings" component={SetelanNotifikasi} />
          <Route path="/ubahemaildanpassword" component={ChangeEmailPassword} />
          <Route path="/vouchers" component={Vouchers} />
          <Route path="/misi" component={Misi} />
          <Route path="/leaderboard/:to_id" component={PBLeaderboard} />
          <Route path="/checkout" component={TmbPlusCheckout} />
        </Switch>
      </div>
      <div
        className={classes.appBody}
        style={{ width: "100%", paddingBottom: 40 }}
      >
        <Switch>
          <Route exact path="/acara/u/:eventId" component={UserEventIntro} />
          <Route
            path="/acara/u/:eventId/daftar"
            render={() => <LengkapiPendaftaran register={true} />}
          />
          <Route
            path="/acara/u/:eventId/pembayaran/:transaksiId"
            render={() => <FormPembayaran auth={true} />}
          />
          <Route
            path="/acara/u/:eventId/konfirmasi/:transaksiId"
            component={KonfirmasiPembayaran}
          />
          <Route
            path="/acara/u/:eventId/lengkapi-data/:pesertaId"
            component={LengkapiPendaftaran}
          />
          <Route
            path="/acara/ticket/:eventId/:pesertaId"
            component={UserEticket}
          />
        </Switch>
      </div>
      <BottomNav />
    </div>
  );
}

export default LayoutC;
