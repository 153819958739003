import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "20px 0",
  },
  btnBackHome: {
    width: 180,
    height: 50,
    "&:hover": {
      background: "none",
    },
  },
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 50,
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 560px)": {
      width: "80%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
    "@media(min-width: 1100px)": {
      width: "50%",
    },
    margin: "auto",
  },
  toggleBack: {
    width: 27,
  },
  logo: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "baseline",
    margin: "0 auto",
    position: "relative",
    left: 0,
  },
  logoImg: {
    width: 180,
    // height: 50,
  },
  pageName: {
    position: "absolute",
    color: "#fff",
    fontSize: 12,
    top: -10,
    fontStyle: "italic",
    textTransform: "capitalize",
    fontWeight: 500,
    left: 50,
  },
  historyBack: {
    color: "#aaa",
  },
  historyBackWhite: {
    color: "#fff",
  },
  historyBackColor: {
    color: "#fff !important",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },  
  historyBackWhite2: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#eee !important",
    },
  },
  shareWhite: {
    color: "#fff !important",
  },  
  spanWrap:{
      position: 'absolute',
      top: -10,
      width: 125,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      left: 42,
  },
  pageNameTwo:{
      color: '#fff',
      fontSize: 12,
      textTransform: 'capitalize',
      fontStyle: "italic",
  },
  betaTag:{
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
      fontSize: 7,
      borderRadius: 6,
      padding: '3px 5px',
      display: 'block',
      marginTop: -2,
      height: 16,
  }
  // logotype:{
  //     fontSize: 24,
  //     fontWeight: 700,
  // },
  // logotypeA:{
  //     color: theme.palette.primary.main,
  //     fontFamily: '"Exo 2" !important',
  // },
  // logotypeB:{
  //     color: theme.palette.secondary.main,
  //     fontFamily: 'Exo 2 !important',
  // },
}));

export default useStyles;
