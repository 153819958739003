import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFirebase } from '../../components/FirebaseProvider';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import useStyles from './style';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IcoExit from '../../images/ico-exit.svg';
import Divider from '@material-ui/core/Divider';

export default function AlertDialogSlide() {
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();

    const { auth } = useFirebase();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSignOut = () => {
        auth.signOut();
    };

    return (
        <div>
            <ListItem
                button
                onClick={handleClickOpen}
            >
                <ListItemIcon classes={{root:classes.listIcon}}>
                    <img src={IcoExit} alt="" />
                </ListItemIcon>
                <ListItemText primary="Keluar" classes={{primary: classes.listItemText}} />
                <ListItemSecondaryAction>
                    <IconButton className={classes.iconBtn} edge="end" disabled aria-label="Keluar">
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <Dialog
                open={open}
            >
                <DialogTitle className={classes.DialogTitle}>{"Keluar Aplikasi ?"}</DialogTitle>
                <p className={classes.txt}>Kamu yakin ingin keluar dari akun Kamu?</p>
                <Button
                    onClick={handleSignOut}
                    fullWidth
                    className={classes.btnOke}
                >
                    Keluar
                    </Button>
                <Button
                    onClick={handleClose}
                    fullWidth
                    className={classes.btnNo}
                >
                    Tidak
                    </Button>
            </Dialog>
        </div>
    );
}