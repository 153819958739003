import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFirebase } from '../../components/FirebaseProvider';
import Typography from '@material-ui/core/Typography';
import useStyles from './style/DialogVerifikasi';

export default function DialogVerifikasi() {
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();

    const { user } = useFirebase();

    const handleClickOpen = async (e) => {
        setOpen(true);

        const actionCodeSettings = {
            url: `${window.location.origin}/login`
        };

        await user.sendEmailVerification(actionCodeSettings);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {
                user.emailVerified ?
                    <Typography
                        color="primary"
                        variant="subtitle1"
                    >
                        Email Kamu Sudah Terverifikasi
                </Typography>
                    :
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        className={classes.btnVerify}
                        onClick={handleClickOpen}
                    >
                        Verifikasi Email Kamu
                </Button>
            }
            <div>
                <Dialog
                    open={open}
                >
                    <DialogTitle className={classes.DialogTitle}>{"Periksa Email Anda"}</DialogTitle>
                    <p className={classes.txt}>Ikuti tautan di email yang telah kami kirimkan ke <span className={classes.sub}>{user.email}</span>, untuk mengonfirmasi alamat email dan membantu mengamankan akun Anda.</p>
                    <Button
                        fullWidth
                        onClick={handleClose}
                        className={classes.btnOke}
                    >
                        Ok
                    </Button>
                </Dialog>
            </div>
        </div>
    );
}