import React from "react";

// Style
import useStyles from "./styles/";

// Components
import Ratings from "@material-ui/lab/Rating";

export function Rate({ rating }) {
  //
  const classes = useStyles();
  const rate = parseFloat(rating);
  //
  return (
    <React.Fragment>
      {rating !== undefined ? (
        <Ratings
          value={rate}
          precision={0.5}
          readOnly
          size="small"
          className={classes.mitraRating}
        />
      ) : (
        <Ratings
          value={0}
          precision={0.5}
          readOnly
          size="small"
          className={classes.mitraRating}
        />
      )}
    </React.Fragment>
  );
}

export function RateChat({ rating }) {
  //
  const classes = useStyles();
  const rate = parseFloat(rating);
  //
  return (
    <div className={classes.ratingBlock}>
      <div className={classes.ratingBlockInner}>
        {rating !== undefined ? (
          <Ratings value={rate} precision={0.5} readOnly size="small" />
        ) : (
          <div className={classes.priceTag1}>
            <Ratings value={0} precision={0.5} readOnly size="small" />
          </div>
        )}
      </div>
    </div>
  );
}

function Rating({ rating }) {
  //
  const classes = useStyles();
  const rate = parseFloat(rating);
  //
  return (
    <div className={classes.ratingBlock}>
      <div className={classes.ratingBlockInner}>
        {rating !== undefined ? (
          <Ratings
            value={rate}
            precision={0.5}
            readOnly
            size="small"
            classes={{ sizeSmall: classes.sizeSmall }}
          />
        ) : (
          <div className={classes.priceTag1}>
            <Ratings
              value={0}
              precision={0.5}
              readOnly
              size="small"
              classes={{ sizeSmall: classes.sizeSmall }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Rating;
