// React
import React, { useState } from "react";

// Styles
import useStyles from "./styles";

// List
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

// Divider
import Divider from "@material-ui/core/Divider";

// Icon
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

// Component
import Typography from "@material-ui/core/Typography";
import { useNotifikasi } from "../../components/NotifikasiProvider";
import TopNav from "../../components/TopNav";
import Switch from "./InAppSwitch";
import Ringtone, { Ring } from "./Ringtone";

function Notifikasi(props) {
  // UseStyles
  const classes = useStyles();

  const { notif } = useNotifikasi();

  // State
  const [Dialog, setDialog] = useState({
    open: false,
  });
  const [dialog, setdialog] = useState({
    open: false,
  });

  // Condition
  const handleRingtone = () => {
    setDialog({
      open: true,
    });
  };
  //
  const handleRing = () => {
    setdialog({
      open: true,
    });
  };
  //
  return (
    <div className={classes.root}>
      <TopNav />
      {/* <AppHeader /> */}
      {/* <div className={classes.pageHeading}>
          <Button className={classes.historyBack} onClick={handleClick}>
            <ChevronLeftIcon />
          </Button>
        </div> */}
      <div className={classes.settingBody}>
        <h2 className={classes.pageTitle1}>Setelan Notifikasi</h2>
        <List
          className={classes.settingList}
          component="nav"
          aria-label="main mailbox folders"
        >
          <ListSubheader className={classes.subHeaderAlt}>
            Notifikasi In-App
          </ListSubheader>
          <ListItem>
            <ListItemText primary="Tampilkan Notifikasi" />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Tampilkan Notifikasi">
                <Switch />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem button onClick={handleRingtone}>
            <ListItemText primary="Bunyi" />
            <ListItemSecondaryAction>
              <IconButton edge="end" disabled aria-label="Bunyi">
                <Typography>{notif?.ringApp?.nama}</Typography>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListSubheader className={classes.subHeaderAlt}>
            Notifikasi Pesan
          </ListSubheader>
          <ListItem button onClick={handleRing}>
            <ListItemText primary="Bunyi" />
            <ListItemSecondaryAction>
              <IconButton edge="end" disabled aria-label="Bunyi">
                <Typography>{notif?.ringmessage?.nama}</Typography>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </List>
      </div>
      <Ringtone {...Dialog} set={setDialog} />
      <Ring {...dialog} set={setdialog} />
    </div>
  );
}

export default Notifikasi;
