import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: 30,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0px 0px 20px 20px",
  },
  tcBody: {
    margin: "0 auto 50px",
    paddingTop: "10px",
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  pageHeading: {
    padding: "10px 20px",
  },
  pageTitle: {
    margin: 0,
  },
  pageTitle2: {
    padding: 1,
    fontSize: "1.2em",
    color: "#4F4F4F",
  },
  pageSubtitle1: {
    margin: 0,
    padding: 1,
    color: "#777",
  },
  pageSubtitle2: {
    margin: 0,
    color: "#777",
  },
  hr: {
    height: 1.5,
  },
  pageContent: {
    padding: "10px 20px",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ul: {
    fontSize: "10px",
    paddingInlineStart: 0,
  },
  itemTf: {
    marginBottom: 20,
    width: "100%",
  },
  label: {
    marginBottom: 10,
    fontWeight: "bold",
    color: "#777",
    textAlign: "left",
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: "11px",
    margin: 0,
    marginTop: 10,
  },
  btn: {
    marginTop: 20,
    textTransform: "Capitalize",
    fontWeight: "bold",
    width: "120px",
  },
  note: {
    color: "#777",
    textAlign: "center",
    fontSize: 12,
  },

  root2: {
    flexGrow: 1,
    paddingBottom: 60,
    userSelect: "none",
    msUserSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
  },
  blueBg: {
    width: "100%",
    height: 400,
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -100,
  },
  historyBack: {
    position: "absolute",
    left: 0,
    top: 25,
    color: "#fff",
  },
  coinBody: {
    backgroundColor: "#fff",
    margin: "20px auto 50px",
    padding: 20,
    borderRadius: 8,
    marginBottom: 10,
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  sub1: {
    color: theme.palette.textColor.gray,
    fontSize: 16,
    width: "100%",
    margin: "0 auto",
  },
  textRp: {
    color: "#1461A8",
    marginTop: 0,
    margin: "auto",
    fontSize: 20,
    fontWeight: "bold",
  },
  checkoutForm: {
    margin: "20px 0px",
  },
  choicesWrap: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    margin: "auto",
    "@media(max-width: 1400px)": {
      width: "40%",
    },
    "@media(max-width: 700px)": {
      width: "40%",
    },
    "@media(max-width: 600px)": {
      width: "50%",
    },
    "@media(max-width: 500px)": {
      width: "80%",
    },
  },
  choiceBlock: {
    width: "35%",
    marginBottom: 10,
    display: "grid",
    placeContent: "center",
  },
  labelle: {
    fontSize: 11,
  },
  choiceImg: {
    border: "solid 1.5px rgb(221, 221, 221)",
    boxShadow: "0px 2px 15px -3px rgba(221, 221, 221, 0.8)",
    padding: 10,
    borderRadius: 6,
    width: 100,
    height: "auto",
  },
  choiceImgChecked: {
    border: "solid 1.5px rgb(48, 188, 236)",
    boxShadow: "0px 2px 15px -3px rgba(48, 188, 236, 0.5)",
    padding: 10,
    borderRadius: 6,
    width: 100,
    height: "auto",
  },
  account: {
    fontSize: 11,
    margin: 0,
  },

  thumbPLayout: {
    width: 240,
    height: 200,
    borderRadius: 5,
    borderStyle: "dashed",
    border: "1.4px solid" + theme.palette.primary.main,
    margin: "auto",
    marginTop: 20,
    position: "relative",
  },
  gridCaption: {
    width: 160,
    height: 16,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  header2: {
    paddingTop: 30,
    backgroundColor: "#fff",
  },
  sub2: {
    color: "#4F4F4F",
    fontSize: 15,
    fontWeight: 500,
    width: "100%",
    margin: "0 auto",
  },
  infoList: {
    listStyleType: "none",
    paddingInlineStart: 0,
    color: "#1461a8",
    lineHeight: 1.5,
  },
  thumbPreview: {
    width: 220,
    height: 180,
    borderRadius: 5,
    margin: 8,
  },
  btnroot: {
    minWidth: 0,
  },
  btnClose: {
    position: "absolute",
    zIndex: 2,
    top: 15,
    right: 15,
    width: 20,
    height: 20,
  },
  iconClose: {
    fontSize: 35,
  },

  inputKode: {
    display: "grid",
    placeContent: "center",
    justifyItems: "center",
    margin: "10px 0px",
  },

  pageContent2: {
    width: '100%',
    padding: "20px 0px",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  btn2: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    height: 40,
    width: 100,
  },
}));

export default useStyles;
