// React
import React from 'react';

// Styles
import useStyles from './style';

// Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { baseURL } from '../../images/s3';

function TopUp({ open, handleNoTopUp, handleTopUp }) {

    // UseStyles
    const classes = useStyles();

    return (
        <Dialog
            open={open}
        >
            <DialogTitle className={classes.DialogTitle1} >
                <img className={classes.logoImg} src={`${baseURL}logo-pakbudi-full.png`} alt="" />
                <br />
                Yah.. Coin Kamu Gak Cukup 😭
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.txt1}>
                    Top Up Dulu Yuk, Supaya Kamu Bisa Chatting Lagi !!!
                </DialogContentText>
                <DialogContentText className={classes.imgCoinwrap}>
                    <img src={`${baseURL}img-coin-double.png`} className={classes.imgCoin} alt="" />
                </DialogContentText>
            </DialogContent>
            <Button
                onClick={handleTopUp}
                fullWidth
                className={classes.btnedit}
            >
                Top Up
            </Button>
            <Button
                onClick={handleNoTopUp}
                fullWidth
                className={classes.btnCancel}
            >
                Nanti
            </Button>
        </Dialog>
    )
}

export default TopUp;