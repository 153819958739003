export default theme => ({

loadingBody:{
    margin: '10%',
    paddingBottom: '10%'
},

loadingText:{
    margin: '5px 0 0',
    fontSize: 13,
    fontStyle: 'italic',
},
});