// React
import React from 'react';
// Styles
import useStyles from './styles';
// Components
import AppHeader from '../../components/AppHeader';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDate } from '../../utils/formatter';
import Notransaction from '../../images/ico-notransaksi.png';
import Button from '@material-ui/core/Button';
// Firebase
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useFirebase, firestore } from '../../components/FirebaseProvider';

function ListTransaksi(props) {
    // Styles
    const classes = useStyles();
    // Firebase
    const { user } = useFirebase();
    // Query
    const [list, loading] = useCollectionData(firestore.collection('transaksi').where('user_id', '==', user.uid));
    // 
    return (
        <div className={classes.root} style={{ marginBottom: 100 }}>
            <AppHeader />
            <div className={classes.listBody}>
                <div className={classes.pageHeading}>
                    <h2 style={{ fontSize: 18 }} className={classes.pageTitle} >Daftar Transaksi</h2>
                    <p className={classes.pageSubtitle}>Segera lakukan transfer dan konfirmasi dengan mengupload bukti transfer</p>
                </div>
                <div className={classes.tableWrap}>
                    <div className={classes.tableTitleWrap}>
                        <h3 className={classes.tableTitle}>Menunggu Konfirmasi</h3>
                    </div>
                    <div className={classes.TableHead}>
                        <p className={classes.tableCellTopID}>Nomor Transaksi</p>
                        <p className={classes.tableCellTopDate}>Tanggal</p>
                        <p className={`${classes.tableCell} ${classes.tableCellTopCoin}`}>Coin</p>
                    </div>
                    {
                        loading ?
                            <Grid container justify="center" alignItems="center" style={{ height: 300 }} >
                                <CircularProgress />
                            </Grid>
                            :
                            <div className={classes.TableBody}>
                                <div className={classes.TableRowWait}>
                                    {
                                        list?.sort?.((a, b) => { return b.tanggal - a.tanggal }).map((item) => {
                                            if (item.status !== 'review') {
                                                return null;
                                            }
                                            return (<div key={item.no_transaksi} className={classes.TableRow}>
                                                <p className={`${classes.TRow} ${classes.TRowID}`}>{item.no_transaksi}</p>
                                                <p className={`${classes.TRow} ${classes.TRowDate}`}>{formatDate(item.tanggal)}</p>
                                                <p className={`${classes.TRow} ${classes.TRowCoin}`}><strong>{item.jumlah_coin}</strong></p>
                                            </div>)
                                        })
                                    }

                                </div>
                            </div>
                    }
                </div>
                <div className={classes.tableWrap} >
                    <div className={classes.tableTitleWrap}>
                        <h3 className={classes.tableTitle}>Riwayat Transaksi</h3>
                    </div>
                    <div className={classes.TableHead}>
                        <p className={classes.tableCell1}>Nomor Transaksi</p>
                        <p className={classes.tableCell}>Tanggal</p>
                        <p className={`${classes.tableCell} ${classes.tableCellCoin}`}>Coin</p>
                        <p className={classes.tableCell}>Status</p>
                    </div>
                    {
                        loading ?
                            <Grid container justify="center" alignItems="center" style={{ height: 300 }} >
                                <CircularProgress />
                            </Grid>
                            :
                            <div className={classes.TableBody}>
                                {
                                    list ?
                                        list.sort((a, b) => { return b.tanggal - a.tanggal }).map(item => {
                                            const handleClick = () => {
                                                return props.history.push(`/konfirmasitransfer/${item.doc_id}`)
                                            }

                                            if (item.status === 'review') {
                                                return null;
                                            }
                                            return (
                                                <div key={item.no_transaksi} className={classes.TableRow}>
                                                    <p className={`${classes.TRow} ${classes.TRowID}`}>{item.no_transaksi}</p>
                                                    <p className={`${classes.TRow} ${classes.TRowDate}`}>{formatDate(item.tanggal)}</p>
                                                    <p className={`${classes.TRow} ${classes.TRowCoin}`}><strong>{item.jumlah_coin}</strong></p>
                                                    <div className={classes.itemStatus}>
                                                        {
                                                            item.status === 'konfirmasi' ?
                                                                <Button onClick={handleClick} className={classes.statusFalse} color="primary">
                                                                    Kirim Bukti
                                                                </Button>
                                                                :
                                                                null

                                                        }
                                                        {
                                                            item.status === 'sukses' ?
                                                                <Typography className={classes.statusTrue} component="p">
                                                                    Berhasil
                                                                </Typography>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.status === 'review' ?
                                                                <Typography className={classes.statusTrue} component="p">
                                                                    Dalam Proses Admin
                                                                </Typography>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            item.status === 'rejected' ?
                                                                <Typography style={{ color: 'red' }} className={classes.statusTrue} component="p">
                                                                    Ditolak
                                                                </Typography>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        null
                                }
                                {
                                    list.length === 0 ?
                                        <>
                                            <img alt="" src={Notransaction} style={{ width: 220, marginTop: 30 }} />
                                            <p style={{ color: 'gray' }} >Kamu Belum Melakukan Transaksi Apapun</p>
                                        </>
                                        :
                                        null
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ListTransaksi;