// React
import React, { useState } from "react";
import AppHeader from "../../components/AppHeader";

// Styles
import useStyles from "./styles";

// MUI
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/styles";

// Firebase
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { firestore } from "../../components/FirebaseProvider";

// React Router Dom
import { useParams } from "react-router-dom";

import AppLoading from "../../components/AppLoading";

import Barcode from "react-barcode";

import { useSnackbar } from "notistack";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { customAlphabet } from "nanoid";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: 4,
    fontWeight: "bold",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
      "&:hover fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
      "& placeholder": {
        fontWeight: "bold",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: 0,
    },
  },
})(TextField);

function UserEticket() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { eventId, pesertaId } = useParams();

  const eventDoc = firestore.doc(`events/${eventId}`);

  const [event, loadingEvent] = useDocumentData(eventDoc);

  const [code, setCode] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [dialog, setDialog] = useState(false);

  const pesertaColl = eventDoc.collection("peserta_event").doc(pesertaId);

  const [peserta, pesertaLoading] = useDocumentData(pesertaColl);

  const randomString = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ", 4);

  const randomNumber = customAlphabet("0123456789", 4);

  const kodeColl = firestore
    .collection("kode_rahasia")
    .where("peserta_id", "==", pesertaId);

  const [kode, kodeLoading] = useCollectionData(kodeColl);

  const handleClick = async () => {
    setIsSubmitting(true);
    try {
      if (code === "LOLOSPTNYESYESYES") {
        if (kode?.length === 0) {
          const kode_rahasia = randomNumber() + randomString();

          const pesertaEvent = firestore
            .collection("kode_rahasia")
            .doc(kode_rahasia);

          if (peserta?.email_user !== undefined) {
            await pesertaEvent.set(
              {
                email_user: peserta?.email_user ?? "",
                event_id: eventId,
                kode_rahasia,
                peserta_id: peserta?.pesertaId ?? "",
                used: false,
              },
              { merge: true }
            );
          } else {
            const transaksiEvent = await firestore
              .collection("transaksi_event")
              .where("pesertaId", "==", pesertaId)
              .get();

            if (!transaksiEvent.empty) {
              const trxData = transaksiEvent?.docs?.[0]?.data();

              await pesertaEvent.set(
                {
                  email_user: trxData?.email_user ?? "",
                  event_id: eventId,
                  kode_rahasia,
                  peserta_id: trxData?.pesertaId ?? "",
                  used: false,
                },
                { merge: true }
              );
            }
          }

          setDialog(true);

          setCode("");
        } else {
          setDialog(true);

          setCode("");
        }
      } else if (!code) {
        enqueueSnackbar("Kode Ujian Wajib Diisi !!!", { variant: "error" });
      } else {
        enqueueSnackbar("Kode Ujian Tidak Valid !!!", { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
    setIsSubmitting(false);
  };

  if (loadingEvent || pesertaLoading || kodeLoading) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AppHeader />
      </div>
      <div className={classes.tcBody}>
        <div className={classes.pageHeading}>
          <h2 className={classes.pageTitle}>E-Ticket {event?.nama ?? ""}</h2>
          <h3 className={classes.pageTitle}>{event?.nama ?? ""}</h3>
          <p className={classes.note} style={{ marginBottom: 0 }}>
            Selamat kamu telah mengamankan kursimu di acara {event?.nama ?? ""}
          </p>
        </div>
        <div className={classes.pageContent}>
          <p
            className={classes.note}
            style={{ color: "#000", margin: "0px 0px 10px" }}
          >
            Berikut adalah detail tiket kamu
          </p>
          <table style={{ color: "#1461A8" }}>
            <tbody>
              <tr>
                <td width="110px" valign="top">
                  Nama Lengkap
                </td>
                <td valign="top">:</td>
                <td>
                  <strong>{peserta?.nama_lengkap ?? ""}</strong>
                </td>
              </tr>
              <tr>
                <td width="110px" valign="top">
                  Kode Peserta
                </td>
                <td valign="top">:</td>
                <td>
                  <strong>{peserta?.pesertaId ?? ""}</strong>
                </td>
              </tr>
              <tr>
                <td width="110px" valign="top">
                  Asal Sekolah
                </td>
                <td valign="top">:</td>
                <td>
                  <strong>{peserta?.asal_sekolah ?? ""}</strong>
                </td>
              </tr>
              <tr>
                <td width="110px" valign="top">
                  Jenis Tryout
                </td>
                <td valign="top">:</td>
                <td>
                  <strong>{peserta?.jenis_tryout ?? ""}</strong>
                </td>
              </tr>
              <tr>
                <td width="110px" valign="top">
                  Lokasi Ujian
                </td>
                <td valign="top">:</td>
                <td>
                  <strong>{peserta?.lokasi_event?.name ?? ""}</strong>
                </td>
              </tr>
              <tr>
                <td width="110px" valign="top">
                  Alamat
                </td>
                <td valign="top">:</td>
                <td>
                  <strong>Info menyusul</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className={classes.note}>
          Pantau terus social media kami di Instagram{" "}
          <span style={{ color: "#30bcec" }}>@masukkeptn</span> dan Twitter{" "}
          <span style={{ color: "#30bcec" }}>@masukkeptn</span> Untuk tau info
          tentang giveaway, hadiah, promo, persiapan sebelum acara, dan modul
          belajar gratis!
        </p>
        <div className={classes.itemTf} style={{ margin: "30px 0px" }}>
          <CustomTextField
            placeholder="Masukkan Kode Ujian"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <Button
                  className={classes.btn2}
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleClick}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              ),
            }}
            onChange={(e) => setCode(e.target.value)}
            value={code}
            disabled={isSubmitting}
          />
        </div>
        <p className={classes.note} style={{ color: "#000" }}>
          Bagi peserta offline, tunjukan halaman ini ketika daftar ulang pada
          hari-H acara untuk mendapatkan kode ujian.
        </p>
        <p className={classes.note} style={{ color: "#000" }}>
          Bagi peserta online , pada hari H acara akan mendapatkan kode ujian
          dari tim kami via Whatsapp.
        </p>
        <Barcode
          value={peserta?.pesertaId ?? ""}
          displayValue={false}
          height={50}
          background="#ffffff00"
        />
      </div>

      {/* Dialog */}
      <Dialog open={dialog}>
        <DialogTitle>Kode Rahasia</DialogTitle>
        <DialogContent>
          <p>Salin Kode Rahasia Dibawah</p>
          <b>{kode?.[0]?.kode_rahasia ?? ""}</b>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              setDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UserEticket;
