import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';

import isStandAlone from '../utils/isStandAlone';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '100%',
        height: 60,
        zIndex: 998,
        borderBottom: 'dashed 1px #30bcec',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        "& .MuiAlert-icon": {
            display: "none"
        },
        "& .MuiAlert-action": {
            paddingLeft: 0
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1.2rem"
        }
    },
    padding: 0,
    margin: 0,
    fontSize: 12,
    fontWeight: "bold",
    dlText: {
        fontSize: 10,
        margin: 0,
        display: "inline-block"
    },
    btnDownload: {
        fontSize: 10,
        padding: 5
    }
}));


const DownloadAppContext = React.createContext();

export function useDownloadApp() {

    return React.useContext(DownloadAppContext)
}

export default function DownloadApp(props) {
    const [hide, setHide] = React.useState(isStandAlone());
    const [isPublicNews] = React.useState(window.location.pathname.includes('public-berita') || window.location.pathname.includes('home') || window.location.pathname.includes('seminar') || window.location.pathname.includes('bagikan'))
    const classes = useStyles();

    const hideDownloadApp = React.useCallback(() => {
        setHide(true)
    }, [])

    const showDownloadApp = React.useCallback(() => {
        setHide(false)
    }, [])


    return <DownloadAppContext.Provider value={{
        hideDownloadApp, showDownloadApp, hide
    }}>{(hide || isPublicNews) ? null : <Alert
        className={classes.root}
        onClose={() => { setHide(false) }}
        severity="info"
        data-html2canvas-ignore
        action=
        {<Button
            color="primary"
            variant="outlined"
            className={classes.btnDownload}
            href="https://play.google.com/store/apps/details?id=xyz.appmaker.gvqags">
            <DownloadIcon /> Download
        </Button>}
    >
        <span className={classes.dlText}>Masih pake versi web? Download Aplikasi PakBudi di Google Play</span>
    </Alert>}
        {props.children}
    </DownloadAppContext.Provider>
}