import React, { useContext } from "react";
import { useFirebase, firestore } from "./FirebaseProvider";
import { useCollection } from "react-firebase-hooks/firestore";
// import { withRouter } from 'react-router-dom';
import AppLoading from "./AppLoading";

const ChatContext = React.createContext();

export function useChat() {
  return useContext(ChatContext);
}

function ChatProvider(props) {
  const { user } = useFirebase();
  const chatCol = firestore
    .collection("chat")
    .where("siswa_id", "==", user.uid)
    .where("status", "!=", "canceled");
  const [chat, loadingchat] = useCollection(chatCol);
  const mitraRating = firestore.collection("count_rating");
  const [rate, loadingrating] = useCollection(mitraRating);
  const mitraHarga = firestore.collection("harga_mitra");
  const [harga, loadingHarga] = useCollection(mitraHarga);
  const [unread, setUnread] = React.useState(null);

  React.useEffect(() => {
    if (chat && !chat.empty) {
      setUnread(chat.docs.filter((doc) => doc.data().siswa_unread_count > 0));
    }
  }, [chat]);

  if (loadingchat || loadingrating || loadingHarga) {
    return <AppLoading />;
  }

  return (
    <ChatContext.Provider
      value={{
        chat,
        loadingchat,
        rate,
        loadingrating,
        harga,
        loadingHarga,
        unread,
        setUnread,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
}

export default ChatProvider;
