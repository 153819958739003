// React
import React from 'react';

// Image
import mainImage from '../../images/error.png';

// Material UI
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

// Styles
import useStyles from './styles';

// Firebase
import { useFirebase } from '../../components/FirebaseProvider';

// React Router Dom
import { Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';


function Restricted() {
    const classes = useStyles();

        // Firebase
        const {auth, user} = useFirebase();
        if (!user){
            return <Redirect to="/login"/>
        }

    return(
        <Container maxWidth="xs">
                <div className={classes.content}>
                    <img
                        src={mainImage}
                        alt=""
                        width="100vw"
                    />
                    <Typography className={classes.text1}>Yah,</Typography>
                    <Typography className={classes.text2}>Ada Masalah !</Typography>
                    <Typography className={classes.text3}>Mungkin...</Typography>
                    <ul>
                        <li>
                            <Typography className={classes.text4}>Koneksi Internet Anda Bermasalah</Typography>
                        </li>
                        <li>
                        <Typography className={classes.text4}>Atau Akun Anda Bukan Akun Siswa</Typography>
                        </li>
                    </ul>
                    <Typography className={classes.text3}>Coba Untuk...</Typography>
                    <ul>
                        <li>
                            <Typography className={classes.text4}>Periksa Koneksi Internet Anda</Typography>
                        </li>
                        <li>
                            <Typography className={classes.text4}>Memuat Ulang Halaman</Typography>
                        </li>
                        <li>
                        <Typography className={classes.text4}>Masuk Dengan Akun Siswa</Typography>
                        </li>
                    </ul>
                    <Typography className={classes.text5}>Tekan Keluar Untuk Beralih Akun, Tekan Muat Ulang Untuk Memuat Halaman</Typography>
                    <Grid container alignItems="center" justify="center">
                    <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.refreshBtn}
                        fullWidth
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        Muat Ulang
                    </Button>
                    </Grid>
                    <Grid item xs={6}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        className={classes.logoutBtn}
                        onClick={()=>{
                            auth.signOut();
                        }}
                    >
                        Keluar
                    </Button>
                    </Grid>
                    </Grid>
                </div>
        </Container>
    )
}

export default Restricted;