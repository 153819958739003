// React
import React from 'react';

// Styles
import useStyles from './styles';

// Input
import Button from '@material-ui/core/Button';

// Roter
import { Link } from 'react-router-dom';


function NotFound() {
    // UseStyles
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.appBody}>
                {/* <img className="img-responsive" src={bg} alt="" /> */}
                <h1>Halaman Tidak Ditemukan.</h1>
                <Button variant="contained" color="primary" component={Link} to="/">Beranda</Button>
            </div>
        </div>
    );
}

export default NotFound;