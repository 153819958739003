import { makeStyles } from '@material-ui/core/styles';

const bigAvatar = {
    margin: 10,
    width: 60,
    height: 60,
};
const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.primary.main,
        height: '100vh',
        overflow: 'hidden',
        overflowY: 'auto',
        userSelect: 'none',
        msUserSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none'
    },
    pageHeader:{
        height: 100,
        margin: '0 auto 10px',
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media(min-width: 320px)': {
            width: '90%',
        },
        '@media(min-width: 960px)': {
            width: '50%',
        },
    },
    avatarText: {
        ...bigAvatar,
        color: '#fff',
        backgroundColor: theme.palette.secondary.main
    },
    avatarPhoto: {
        ...bigAvatar
    },
    whiteBox:{
        backgroundColor: '#fff',
        borderRadius: 8,
        marginBottom: 5,
    },
    whiteBox1:{
        backgroundColor: '#fff',
        borderRadius: 8,
        marginBottom: 5,
        textAlign: 'left'
    },
    textWhite: {
        color: '#fff'
    },
    textBlack:{
        color: '#222',
        fontSize: 13,
    },
    logo: {
        width: 150
    },
    fab: {
        color: '#fff',
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fab1: {
        color: '#fff',
        backgroundColor: theme.palette.textColor.green,
        position: 'absolute',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
    },
    iconRight: {
        marginLeft: theme.spacing(1)
    },
    historyBack: {
        width: 50,
        minWidth: 35,
        height: 50,
        color: '#fff',
    },
    headerWrap:{
        width: '75%',
        '@media(min-width: 960px)': {
            width: 182,
        },
    },
    emptySlot:{
        width: 50,
        height: 50,
    },
    toggleBack:{
        display: 'none',
        width: 0,
        visibility: 'hidden',
    },
    otherNav:{
        display: 'none',
        width: 0,
        visibility: 'hidden',
    },
    backHome: {
        marginTop: 20,
        textTransform: 'capitalize'
    }
}))

export default useStyles;   