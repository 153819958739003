// React
import React from 'react';
import { useState, useCallback, useEffect } from 'react';
// DropZone
import { useDropzone } from 'react-dropzone';
// Axios
import axios from 'axios';
// Firebase
import { functions, firestore, Arr } from '../../components/FirebaseProvider';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
// Notification
import { useSnackbar } from 'notistack';
// Styles
import useStyles from './styles';
// Component
import AppHeader from '../../components/AppHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Formater
import { formatDate } from '../../utils/formatter';
import ImageIcon from '@material-ui/icons/Image';
import { baseURL } from '../../images/s3';
// 
function KonfirmasiTransfer(props) {
    // Styles
    const classes = useStyles();
    // Router
    const params = useParams();
    // State
    const [error, setError] = useState('');
    // 
    const [Loading, setLoading] = useState(false);
    // 
    const [Data, setData] = useState();
    // State File
    const accept = ['image/png', 'image/jpeg'];
    // MaxSize
    const maxSize = '20971520';
    // Query
    const [snapshot, loading] = useDocument(firestore.doc(`transaksi/${params.id}`));
    // UseEffect
    useEffect(() => {
        if (snapshot) {
            setData(snapshot.data())
        }
    }, [snapshot])
    // Data
    let no = Data && Data.no_transaksi ? Data.no_transaksi : '';
    let jt = Data && Data.jumlah_transfer ? Data.jumlah_transfer : '';
    let jc = Data && Data.jumlah_coin ? Data.jumlah_coin : '';
    let tgl = Data && Data.tanggal ? Data.tanggal : '';
    // Snackbar
    const { enqueueSnackbar } = useSnackbar();
    // On Accepted
    const onDropAccepted = useCallback(acceptedFiles => {
        setError('');
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onabort = () => {
            setError('Pembacaan file dibatalkan')
        }
        reader.onerror = () => {
            setError('Pembacaan file gagal')
        }
        reader.onload = async () => {
            setLoading(true);
            try {
                const generateUploadUrl = functions.httpsCallable('generateUploadUrl');

                const result = await generateUploadUrl({
                    filename: file.name,
                    directory: `images/buktiTransfer/`,
                    contentType: file.type,
                    id: params.id
                })

                await axios.put(result.data.uploadUrl, file, {
                    headers: {
                        'Content-Type': file.type
                    }
                })

                await firestore.doc(`transaksi/${params.id}`).set({
                    photoURL: result.data.photoUrl,
                }, { merge: true });

                // Show Notification
                enqueueSnackbar('Photo Transaksi Berhasil Diupload.', { variant: 'success' });
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        }

        reader.readAsDataURL(file);

    }, [enqueueSnackbar, params.id])
    // On Rejected
    const onDropRejected = useCallback(rejected => {
        if (!accept.includes(rejected[0].type)) {
            setError(`Tipe file tidak didukung : ${rejected[0].type} `)
        } else if (rejected[0].size >= maxSize) {
            setError(`Ukuran file terlalu besar > 20Mb`)
        }
    }, [accept, maxSize])
    // UseDropzone
    const { getRootProps, getInputProps } = useDropzone({ onDropAccepted, onDropRejected, accept, maxSize, disabled: Loading })
    // State
    const [open, setOpen] = React.useState(false);
    // FUnctions
    const handleClickOpen = async () => {
        try {
            await firestore.doc(`transaksi/${params.id}`).set({
                status: 'review'
            }, { merge: true })

        } catch (err) {
            console.log(err.message);
        }
        setOpen(true);
    };
    // 
    const handleClose = () => {
        setOpen(false);
        props.history.push('/listtransaksi');
    };
    // 
    const handleDelete = async () => {
        await firestore.doc(`transaksi/${params.id}`).set({
            photoURL: Arr.delete()
        }, { merge: true });
    }
    // Condition Loading
    const loadd = Data && Data.photoURL === undefined;
    // 
    if (loading) {
        return (
            <Container maxWidth="xs" className={classes.wrapper}>
                <CircularProgress />
            </Container>
        )
    }
    // 
    return (
        <div className={classes.root}>
            <AppHeader />
            <div className={classes.coinBody}>
                <div className={classes.pageHeading}>
                    <h2 className={classes.pageTitle} >Konfirmasi Transfer</h2>
                    <div className={classes.konfirmasiWrap}>
                        <p className={classes.pageSubtitle7}><span className={classes.konfirmasiTxt}>Nomor Transaksi   </span><span className={classes.konfirmasiTxtValue}>: {no}</span></p>
                        <p className={classes.pageSubtitle7}><span className={classes.konfirmasiTxt}>Tanggal Transaksi </span><span className={classes.konfirmasiTxtValue}>: {formatDate(tgl)}</span></p>
                        <p className={classes.pageSubtitle7}><span className={classes.konfirmasiTxt}>Jumlah Coin       </span><span className={classes.konfirmasiTxtValue}>: {jc}</span> </p>
                        <p className={classes.pageSubtitle7}><span className={classes.konfirmasiTxt}>Jumlah Transfer   </span><span className={classes.konfirmasiTxtValue}>: {jt}</span> </p>
                    </div>
                </div>
                <div className={classes.uploadForm}>
                {Data && Data.photoURL ?
                    <Grid className={classes.thumbPLayout}>
                        <Button onClick={handleDelete} classes={{root: classes.btnroot}} className={classes.btnClose}><CloseIcon className={classes.iconClose} color="error" /></Button>
                        <div className={classes.layer} />
                        <Avatar variant="square" className={classes.thumbPreview} src={Data.photoURL} />
                    </Grid>
                    :
                    <Grid {...getRootProps()} className={classes.thumbPLayout}>
                        <input {...getInputProps()} />
                        {Loading && <CircularProgress className={classes.gridCaption} />}
                        {!Loading && <Typography variant="caption" color="primary" className={classes.gridCaption}><ImageIcon className={classes.imageIcon} /> Upload Bukti Transfer Kamu</Typography>}
                    </Grid>
                }
                {error &&
                    <Typography
                        color="error"
                    >
                        {error}
                    </Typography>
                }
                <img src={`${baseURL}klik.png`} className={classes.imgKlik} alt="" />
                </div>
                <Button className={classes.btnOke} variant="contained" onClick={handleClickOpen} color="primary" disabled={loadd} size="large">Kirim Bukti</Button>

                <div className={classes.dialogWrap}>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle className={classes.DialogTitle} >
                            <span className={classes.pageName}>Coin</span>
                            <img className={classes.logoImg} src={`${baseURL}new-logo-w.png`} alt="" />
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText className={classes.dialogContent}>
                                Terima kasih konfirmasi pembayaran sudah kami terima, dan akan dikonfirmasi dalam waktu <strong>2 x 24 Jam</strong>.
                            </DialogContentText>
                        </DialogContent>
                        <Button className={classes.btnDialog} onClick={handleClose} color="primary">
                            Ok
                        </Button>
                    </Dialog>
                </div>
            
            </div>
        </div>
    )
}

export default KonfirmasiTransfer;