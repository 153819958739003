import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function RankIcon(props) {
  return (
    <SvgIcon viewBox="-10 -10 120 120" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50Zm-6.622-25.143c1.908-.053 3.815-.105 5.722-.119.8 0 1.6-.01 2.395-.02.785-.01 1.565-.02 2.336-.02.966.034 1.931.075 2.895.116 2.485.105 4.963.21 7.44.181 2.246-.02 4.492-.138 6.699-.652 1.172-.276 1.848-.889 2.246-2.055 1.67-5.057 2.941-10.234 4.154-15.43 1.093-4.662 2.147-9.345 2.703-14.126.239-1.936-.874-2.904-2.803-2.49-.556.119-1.073.336-1.57.593-1.57.77-2.961 1.857-4.313 2.964l-1.248 1.02a293.02 293.02 0 0 0-3.502 2.892c-.676.592-1.193.513-1.71-.198-.205-.29-.426-.572-.647-.852-.334-.426-.667-.85-.943-1.302-1.724-2.868-3.442-5.75-5.161-8.633-1.253-2.102-2.506-4.204-3.763-6.303-.756-1.264-1.57-2.509-2.644-3.556-1.212-1.166-2.166-1.146-3.359 0-.636.632-1.173 1.343-1.65 2.094-2.105 3.255-4.041 6.622-5.975 9.983-.367.637-.733 1.275-1.1 1.91l-.25.436c-1.266 2.205-2.523 4.396-4.183 6.361-.477.554-.875.633-1.51.257-1.81-1.067-3.4-2.39-4.91-3.833a105.42 105.42 0 0 1-.575-.547c-.956-.914-1.913-1.83-3.023-2.554-1.411-.93-2.822-.988-4.293-.12-.616.376-.855.811-.835 1.522.073 2.304.548 4.542 1.026 6.79l.127.6c.409 1.828.872 3.646 1.336 5.464.618 2.425 1.237 4.849 1.725 7.299l.211 1.095c.439 2.285.878 4.573 1.598 6.788 1.113 3.398 2.325 4.247 5.883 4.445 3.83.21 7.652.105 11.47 0Z"
      />
    </SvgIcon>
  );
}
