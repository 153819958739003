import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";

const useDebounce = (callback) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debounceCallback = useMemo(() => {
    const func = () => {
      // eslint-disable-next-line no-unused-expressions
      ref.current?.();
    };
    return debounce(func, 1000);
  }, []);
  return debounceCallback;
};

export default useDebounce;
