import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    // paddingBottom: 120,
  },
  headBg: {
    backgroundColor: theme.palette.lightBlue.main,
    height: 210,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  // start page goes here
  startPage: {
    // height: 'calc(100vh - 100px)',
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: 400,
    },
    height: "fit-content",
    margin: "auto",
    // padding: 20,
    borderRadius: 6,
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    "& > p, & > ol li": {
      color: "#fff",
      fontSize: 13,
      fontWeight: "bold",
    },
    "& > ol": {
      paddingLeft: 13,
    },
    "& > ol li": {
      textAlign: "left",
      marginBottom: 7,
    },
  },
  logoWrap: {
    position: "relative",
    width: 180,
    height: 56,
    margin: "auto",
    zIndex: 2,
  },
  logoWrap2: {
    position: "relative",
    margin: "auto",
    zIndex: 2,
  },
  mainLogo: {
    marginTop: 5,
    width: 150,
  },
  pageName: {
    position: "absolute",
    color: "#fff",
    fontSize: 12,
    top: -5,
    left: 56,
    textTransform: "capitalize",
    fontStyle: "italic",
  },
  startBtnWrap: {
    textAlign: "center",
    position: "relative",
  },
  backtoHomeBtnWrap: {
    textAlign: "center",
    position: "fixed",
    padding: "10px 0 10px",
    margin: "auto",
    width: "100%",
    bottom: 0,
    backgroundColor: "#FFF",
    boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.3)",
  },
  backtoHomeBtn: {
    width: 140,
    padding: "9px 16px",
    color: "#fff",
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 3,
  },
  buttonProgress: {
    position: "absolute",
    // top: '50%',
    // left: '50%',
    // marginTop: -12,
    // marginLeft: -12,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
  },

  contentWrapIn: {
    backgroundColor: "white",
    padding: "30px 20px",
    position: "relative",
    zIndex: 2,
    borderRadius: 10,
    marginBottom: 10,
  },
  welcomeMsg: {
    color: theme.palette.textColor.darkGray,
    fontSize: "1.250rem",
    textAlign: "center",
    marginTop: 5,
    marginBottom: 3,
    fontWeight: 700,
  },
  ToTitle: {
    color: theme.palette.textColor.gray,
    fontSize: "1rem",
    fontWeight: 500,
    textAlign: "center",
    margin: "0 0 10px",
  },
  contentText: {
    "& > img": {
      display: "block",
      width: "100%",
      height: "auto",
      marginBottom: 20,
    },
    "& > p": {
      marginBottom: 9,
    },
    "& > ol, & > ul": {
      paddingLeft: 20,
      margin: 0,
    },
    "& > ol li, & > ul li": {
      marginBottom: 9,
    },
    "& > p, & > ol li, & > ul li": {
      wordBreak: "break-word",
      color: theme.palette.textColor.gray,
      fontWeight: 500,
      fontSize: 11,
      lineHeight: "18px",
    },
  },
  endMsg: {
    color: theme.palette.darkBlue.main,
    fontSize: "1.250rem",
    fontWeight: 600,
    textAlign: "center",
    margin: 0,
  },
  resultTitle: {
    color: theme.palette.textColor.gray8,
    fontSize: "1.063em",
    fontWeight: 500,
    textAlign: "center",
    margin: 0,
  },
  pageHeader: {
    backgroundColor: theme.palette.lightBlue.main,
    // height: 160,
    display: "flex",
    flexFlow: "column nowrap",
    position: "relative",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  fab: {
    position: "absolute",
    top: 15,
    right: 15,
    backgroundColor: "#2590B5",
    boxShadow: "none",
    "&:hover, &:active": {
      backgroundColor: "#2EA9D4",
    },
  },
  fabIcon: {
    color: "#fff",
    width: 17,
    height: 17,
  },
  // index goes here
  headerContent: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    // padding: '7px 15px',
  },
  headerContentTop: {
    width: "100%",
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "30px auto 0",
  },
  headerContentBot: {
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "baseline",
    justifyContent: "space-between",
    padding: "0 20px 10px",
    margin: "15px auto 0",
  },
  TOName: {
    width: "100%",
    color: "#fff",
    textAlign: "center",
    fontSize: "1.063rem",
    fontWeight: 600,
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  endBtnWrap: {
    width: "35%",
    textAlign: "right",
  },
  endIcon: {
    width: 16,
    height: 16,
  },
  endBtn: {
    padding: "0 9px",
    backgroundColor: theme.palette.danger.main,
    color: "#fff",
    fontWeight: "bold",
    marginBottom: 3,
    "&:hover, &:active": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  TOType: {
    width: "100%",
    padding: "0 10px",
    "& > p": {
      color: "#fff",
      margin: 0,
      textAlign: "center",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  TOtime: {
    width: "35%",
    color: theme.palette.darkBlue.main,
    "& > p": {
      textAlign: "left",
      margin: 0,
      fontSize: "1.063rem",
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
    },
  },
  TOBox: {
    width: "94%",
    margin: "5px auto 25px",
  },
  TOBoxInner: {
    backgroundColor: "#fff",
    border: "solid 1px #ddd",
  },
  boxHeader: {
    borderBottom: "solid 1px #ddd",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    width: "100%",
  },
  navIcon: {
    color: "#ababab",
  },
  iconAnswered: {
    color: "#35e995",
  },
  // navLeft:{
  //     width: '40%',
  // },
  // navRight:{
  //     width: '40%',
  // },
  emptyBtn: {
    width: 125,
  },
  navBtn: {},
  prevNextBtn: {
    padding: "3px 14px",
    fontSize: "0.813rem",
    fontWeight: 600,
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    backgroundColor: "#fff",
    border: "solid 1px " + theme.palette.textColor.gray,
    color: theme.palette.textColor.gray,
  },
  // prevBtn:{
  //     backgroundColor: '#fff',
  //     border: 'solid 1px '+ theme.palette.textColor.gray,
  //     color: theme.palette.textColor.gray,
  // },
  // nextBtn:{
  //     backgroundColor: theme.palette.lightBlue.main,
  //     border: 'solid 1px '+ theme.palette.lightBlue.main,
  //     color: '#fff',
  // },
  theNum: {
    width: 46,
    height: 46,
    borderRadius: "100%",
    border: "solid 2px #ddd",
    color: "#ababab",
    minWidth: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  numAnswered: {
    backgroundColor: theme.palette.lightBlue.main,
    border: "solid 1px " + theme.palette.lightBlue.main,
    color: "#fff",
  },
  fabMid: {
    width: 38,
    height: 33,
    backgroundColor: theme.palette.textColor.gray10,
  },
  // numAnswered: {
  //     backgroundColor: '#35e995',
  //     color: '#fff',
  // },
  currentQuestionWrap: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "baseline",
    width: "100%",
    color: theme.palette.lightBlue.main,
    justifyContent: "center",
    margin: "20px auto 10px",
  },
  currentQuestion: {
    fontSize: "1.063rem",
    fontWeight: 700,
    marginRight: 5,
  },
  totalQuestion: {
    fontSize: "0.813rem",
    fontStyle: "italic",
  },
  boxContentWrap: {
    height: "calc(100vh - 350px)",
    overflowY: "auto",
  },
  boxContent: {
    padding: "10px 20px 0",
    textAlign: "left",
    color: theme.palette.textColor.darkGray,
    fontSize: 15,
    "& > img": {
      display: "block",
      width: "100%",
      height: "auto",
      marginBottom: 20,
    },
    "& > p": {
      marginBottom: 20,
    },
    "& > ol, & > ul": {
      paddingLeft: 20,
    },
    "& > ol li, & > ul li": {
      marginBottom: 15,
    },
    "& > p, & > ol li, & > ul li": {
      wordBreak: "break-word",
    },
  },
  answerOptionWrap: {
    width: "100%",
    padding: "0 20px 20px",
    textAlign: "left",
  },
  answerOptionBox: {
    marginBottom: 10,
  },
  answerOptions: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
  },
  answerOptionImg: {
    display: "flex",
    flexFlow: "row nowrap",
    marginBottom: 30,
    "& > img": {
      display: "block",
      width: "calc(100% - 35px)",
      height: "auto",
    },
  },
  bulletList: {
    width: 30,
    textTransform: "uppercase",
  },
  theAnswer: {
    textAlign: "left",
    display: "block",
    width: "calc(100% - 35px)",
    marginLeft: 25,
  },
  answerBox: {
    // borderTop: 'solid 1px #ddd',
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    // padding: 10,
  },
  answerBtn: {
    border: "solid 1px" + theme.palette.textColor.gray,
    borderRadius: "100%",
    backgroundColor: "#fff",
    fontWeight: 600,
    minWidth: 0,
    width: 40,
    height: 40,
    padding: 0,
    color: theme.palette.textColor.gray,
    fontSize: 17,
    boxShadow: "0px 1.33333px 1.33333px rgba(0, 0, 0, 0.25)",

    // '&:hover': {
    //     backgroundColor: '#35e995',
    //     color: '#fff',
    //     border: 'solid 1px #29BB77',
    // },
  },
  btnChecked: {
    backgroundColor: "#2FBBEC !important",
    color: "#fff",
    border: "solid 1px #2FBBEC",
    borderRadius: "100%",
    minWidth: 0,
    width: 40,
    height: 40,
    padding: 0,
    boxShadow: "0px 1.33333px 1.33333px rgba(0, 0, 0, 0.25)",
  },
  answerChecked: {
    color: "#2FBBEC",
  },
  navBox: {
    width: 27,
    height: 27,
    minWidth: 0,
    color: "#777",
    // fontSize: 17,
    fontWeight: 600,
    // border: 'solid 1px #777',
    // backgroundColor: theme.palette.textColor.gray10,
    textAlign: "center",
    marginBottom: 6,
    padding: 0,
    borderRadius: "50%",
    "&:hover, &:active": {
      backgroundColor: theme.palette.orange.main,
      "& > span": {
        color: "#fff",
      },
    },
  },
  navBoxLabel: {
    display: "flex",
    flexFlow: "column nowrap",
    width: 33,
    height: 43,
    "& > span": {
      borderBottom: "solid 1px" + theme.palette.textColor.gray7,
      display: "block",
      width: "100%",
      fontSize: "1.063rem",
      height: 27,
    },
    "& > span:last-child": {
      borderBottom: "none",
      fontSize: "0.625rem",
      height: 16,
    },
  },
  navBoxChecked: {
    border: "solid 1px" + theme.palette.textColor.gray7,
    backgroundColor: theme.palette.orange.main,
    "& > span": {
      color: "#fff",
      fontWeight: "bold",
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  mapTitle: {
    backgroundColor: theme.palette.lightBlue.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    fontSize: "1.438rem",
    fontWeight: 700,
    position: "relative",
    overflow: "hidden",
  },
  numWrap: {
    width: "98%",
    height: 250,
    overflowY: "auto",
    margin: "10px auto",
    display: "flex",
    flexFlow: "row wrap",
    // justifyContent: 'space-between',
    gap: 9,
    alignContent: "flex-start",
  },
  numBox: {
    width: 33,
    height: 43,
    minWidth: 0,
    color: "#777",
    // fontSize: 17,
    fontWeight: 600,
    // border: 'solid 1px #777',
    backgroundColor: theme.palette.textColor.gray10,
    textAlign: "center",
    marginBottom: 6,
    padding: 0,
    // borderRadius: '50%',
    "&:hover, &:active": {
      backgroundColor: theme.palette.lightBlue.main,
      "& > span": {
        color: "#fff",
      },
    },
  },
  numBoxLabel: {
    display: "flex",
    flexFlow: "column nowrap",
    width: 33,
    height: 43,
    "& > span": {
      borderBottom: "solid 1px" + theme.palette.textColor.gray7,
      display: "block",
      width: "100%",
      fontSize: "1.063rem",
      height: 27,
    },
    "& > span:last-child": {
      borderBottom: "none",
      fontSize: "0.625rem",
      height: 16,
    },
  },
  userNum: {
    display: "block",
  },
  numBoxChecked: {
    border: "solid 1px" + theme.palette.textColor.gray7,
    backgroundColor: theme.palette.textColor.green3,
    "& > span": {
      color: "#fff",
      fontWeight: "bold",
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  numLegend: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    padding: "0",
  },
  indicatorWrap: {
    width: "48%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  indicatorChecked: {
    width: 10,
    height: 10,
    border: "solid 1px " + theme.palette.textColor.green3,
    backgroundColor: theme.palette.textColor.green3,
    display: "inline-block",
    marginRight: 5,
  },
  indicatorUnchecked: {
    width: 10,
    height: 10,
    border: "solid 1px " + theme.palette.textColor.gray10,
    backgroundColor: theme.palette.textColor.gray10,
    display: "inline-block",
    marginRight: 5,
  },
  indicatorLabel: {
    fontSize: 12,
  },
  contentWrap: {
    borderRadius: 10,
    backgroundColor: "#fff",
    "@media(min-width: 320px)": {
      width: "90%",
      padding: "10px 0 0",
      margin: "10px auto 0",
    },
    "@media(min-width: 960px)": {
      width: 400,
      padding: "10px 20px",
    },
    textAlign: "left",
  },
  contentWrapStart: {
    borderRadius: 10,
    "@media(min-width: 650px)": {
      width: "80%",
    },
    "@media(min-width: 800px)": {
      width: "60%",
    },
    "@media(min-width: 960px)": {
      width: 400,
    },
    textAlign: "left",
    margin: "auto",
  },
  contentWrapInner: {
    padding: "5px 20px",
    // height: 360,
    // overflowY: 'auto',

    "& > img": {
      display: "block",
      width: "100%",
      height: "auto",
      marginBottom: 20,
    },
    "& > p": {
      marginBottom: 9,
    },
    "& > ol, & > ul": {
      paddingLeft: 20,
      margin: 0,
    },
    "& > ol li, & > ul li": {
      marginBottom: 9,
    },
    "& > p, & > ol li, & > ul li": {
      wordBreak: "break-word",
      color: "#777",
      fontWeight: "bold",
      fontSize: 11,
    },
    marginBottom: 10,
  },

  readyMsg: {
    color: theme.palette.textColor.darkGray,
    fontSize: 11,
    fontWeight: 500,
    textAlign: "center",
    margin: "15px 0",
  },
  btnGroup: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    margin: "25px auto 10px",
    "@media(min-width: 320px)": {
      width: "100%",
    },
    "@media(min-width: 400px)": {
      width: "80%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  goBack: {
    width: 94,
    height: 28,
    backgroundColor: "white",
    border: "solid 1px " + theme.palette.textColor.gray,
    color: theme.palette.textColor.gray,
    fontWeight: 600,
    fontSize: "0.833rem",
  },
  startBtn: {
    color: "#fff",
    width: 94,
    height: 28,
    // backgroundColor: theme.palette.lightBlue.main,
    // border: 'solid 1px ' + theme.palette.lightBlue.main,
    fontWeight: 600,
    fontSize: "0.833rem",
  },
  endTitle: {
    backgroundColor: theme.palette.lightBlue.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    fontSize: "1.438rem",
    fontWeight: 700,
    position: "relative",
    overflow: "hidden",
    marginBottom: 20,
  },
  contentRow: {
    marginBottom: 10,
    display: "flex",
    flexFlow: "row wrap",
  },
  contentLabel: {
    color: theme.palette.textColor.gray,
    fontWeight: 500,
    minWidth: 115,
  },
  contentValue: {
    fontSize: "0.813rem",
    color: theme.palette.darkBlue.main,
    fontWeight: 600,
  },
  totalQ: {
    textAlign: "center",
  },
  endNotice: {
    borderTop: "solid 1px #000",
    paddingTop: 15,
    marginTop: 15,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    textAlign: "center",
  },
  endNoticeImg: {
    width: 34,
    height: 34,
    display: "block",
    margin: "auto",
  },
  endDialogAction: {
    justifyContent: "space-between",
    paddingBottom: 30,
  },
  btnCancel: {
    backgroundColor: theme.palette.textColor.gray6,
    color: "#fff",
    border: "solid 1px " + theme.palette.textColor.gray6,
    padding: "3px 32px",
    fontWeight: 600,
    fontSize: "0.750rem",
    textTransform: "capitalize",
  },
  btnEnd: {
    backgroundColor: "#fff",
    color: theme.palette.lightBlue.main,
    border: "solid 1px " + theme.palette.lightBlue.main,
    padding: "3px 32px",
    fontWeight: 600,
    fontSize: "0.750rem",
    textTransform: "capitalize",
  },
  resultHead: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingTop: 10,
    position: "fixed",
    top: 0,
  },
  shareBtn: {
    position: "absolute",
    top: 0,
    right: 15,
    bottom: 0,
    margin: "auto",
    color: "#fff",
    width: 24,
    height: 24,
    zIndex: 2,
  },
  resultTableHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px " + theme.palette.textColor.gray6,
    marginTop: 25,
    paddingBottom: 5,
    marginBottom: 5,
  },
  toTypeCell: {
    width: "40%",
    color: theme.palette.textColor.gray8,
    fontSize: "0.625rem",
    fontWeight: 500,
  },
  toTrueCell: {
    width: "20%",
    color: theme.palette.lightBlue.main,
    fontSize: "0.625rem",
    fontWeight: 500,
    textAlign: "center",
  },
  toFalseCell: {
    width: "20%",
    color: theme.palette.red.main,
    fontSize: "0.625rem",
    fontWeight: 500,
    textAlign: "center",
  },
  toEmptyCell: {
    width: "20%",
    color: theme.palette.textColor.gray8,
    fontSize: "0.625rem",
    fontWeight: 500,
    textAlign: "center",
  },
  resultRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  resultBox: {
    marginBottom: 20,
  },
  resultCat: {
    textAlign: "center",
  },
  resultCount: {
    width: "96%",
    margin: "10px auto",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  resultCountBox: {
    width: 75,
    "& > span": {
      fontWeight: "bold",
    },
  },
  resultCountBoxTrue: {
    "& > span": {
      color: "#5b9bd5",
    },
  },
  resultCountBoxFalse: {
    "& > span": {
      color: "#ff0000",
    },
  },
  resultCountBoxEmpty: {
    "& > span": {
      color: theme.palette.secondary.main,
    },
  },
  theCount: {
    display: "block",
    textAlign: "center",
  },
  countLabel: {
    display: "block",
    textAlign: "center",
    textTransform: "uppercase",
  },
  testResult: {
    borderRadius: 10,
    backgroundColor: "#fff",
    "@media(min-width: 320px)": {
      width: "90%",
      padding: "10px 0 0",
      margin: "10px auto",
    },
    "@media(min-width: 960px)": {
      width: 400,
      // padding: '10px 20px',
    },
    textAlign: "left",
    marginBottom: 10,
  },
  title: {
    borderRadius: 10,
    backgroundColor: "#fff",
    "@media(min-width: 320px)": {
      width: "90%",
      padding: "10px 0 10px",
      margin: "10px auto",
    },
    "@media(min-width: 960px)": {
      width: 400,
      padding: "10px 20px",
    },
    textAlign: "left",
  },
  trHead: {
    margin: "5px auto 10px",
    "& > h2": {
      color: theme.palette.darkBlue.main,
      fontSize: "1.250rem",
      fontWeight: 600,
      textAlign: "center",
      margin: 0,
    },
    "& > p": {
      color: theme.palette.textColor.gray8,
      fontSize: "1.200em",
      fontWeight: 600,
      textAlign: "center",
      margin: 0,
    },
  },
  trDesc: {
    display: "flex",
    justifyContent: "space-around",
    padding: "0 15px",
    "& > span": {
      color: theme.palette.textColor.gray8,
      fontSize: "0.550rem",
      fontWeight: 500,
    },
    "& > span:last-child": {
      width: "25%",
    },
  },
  soalNum: {
    backgroundColor: theme.palette.orange.main,
    width: "100%",
    padding: "8px 20px",
    color: "#fff",
    fontSize: "1.063rem",
    fontWeight: 600,
    marginTop: 10,
  },
  soalText: {
    color: theme.palette.textColor.darkGray,
    fontSize: "0.938rem",
    fontWeight: 400,
    margin: "13px 20px",
  },
  trSoalReview: {
    borderTop: "solid 1px #000",
    margin: "13px 0",
    padding: "13px 20px",
  },
  answerTrue: {
    color: theme.palette.lightBlue.main,
    fontWeight: 600,
    fontSize: "0.938rem",
  },
  myAnswer: {
    fontWeight: 600,
    fontSize: "0.938rem",
    margin: "13px 20px",
  },
  myAnswer0: {
    color: theme.palette.red.main,
  },
  myAnswer1: {
    color: theme.palette.textColor.green3,
  },
  answerEmpty: {
    color: theme.palette.textColor.gray8,
    fontWeight: 600,
    fontSize: "0.938rem",
  },
  explanation: {
    margin: "0 0 20px",
  },
  trueAnswer: {
    marginBottom: 10,
  },
  DialogTitleTimeout: {
    fontSize: 18,
    textAlign: "center",
    backgroundColor: theme.palette.lightBlue.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: "relative",
    paddingTop: 24,
  },
  pageNameAlt: {
    position: "absolute",
    top: 13,
    left: 112,
    color: "#fff",
    fontSize: 12,
    fontStyle: "italic",
  },
  logoImg: {
    width: 160,
    height: "auto",
  },
  timeoutBox: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexFlow: "column nowrap",
  },
  gonnaCry: {
    width: 98,
    height: "auto",
    display: "block",
    margin: "20px auto 0",
  },
  timeoutMsg: {
    marginTop: 10,
  },
  timeoutTxt: {
    color: theme.palette.textColor.gray8,
    fontSize: 20,
    fontWeight: 600,
    display: "block",
  },
  hideRank: {
    "& > h2": {
      color: "#fff",
      margin: "5px 0 0",
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: "1.250rem",
      fontWeight: 700,
    },
    "& > p": {
      color: theme.palette.darkBlue.main,
      margin: "200px auto",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: 600,
      maxWidth: "500px",
      padding: "10px",
    },
  },
}));

export default useStyles;
