//
import React, { useEffect } from "react";

// Styles
import useStyles from "./styles";

// MUI
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import RepeatIcon from "@material-ui/icons/Repeat";

import { useHistory } from "react-router-dom";

// Components
import coin from "../../images/icon-coin.png";
import RankIcon from "../../images/Rank.js";
import Timer from "../home/Timer";

//

export function ListTryout({ item, handleTryout, openTO }) {
  // UseStyles
  const classes = useStyles();
  //
  const history = useHistory();
  //

  // open handleTryout automatically if openTO === item.it

  useEffect(() => {
    if (openTO === item.id) {
      if (!item.hasiltryout || item?.hasiltryout?.status !== "done") {
        if (
          item?.hasiltryout?.status === "purchased" ||
          item?.hasiltryout?.status === "on-progress"
        ) {
          history.push(`/tryout/${item.hasiltryout.id}`);
        } else if (item.status === "published") {
          handleTryout({
            judul: item.judul,
            tryout_id: item.id,
            coin: item.coin,
            waktu: item.waktu,
            isExpired: false,
            jumlah: item.jumlah_soal,
            kategori: item?.kategori ? item.kategori : "none",
          });
        }
      }
    }
  }, [openTO, item, handleTryout, history]);
  return (
    <React.Fragment>
      {!item.hasiltryout || item?.hasiltryout?.status !== "done" ? (
        <>
          <>
            {item?.hasiltryout?.status === "on-progress" && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.box2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Typography align="left" className={classes.boxTitle2}>
                        {item.judul}
                      </Typography>
                      <Typography align="left" className={classes.boxTxt8}>
                        Belum ada hasil
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        className={classes.cta4}
                        onClick={() => {
                          history.push(`/tryout/${item.hasiltryout.id}`);
                        }}
                      >
                        Lanjutkan
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
            {item?.hasiltryout?.status === "purchased" && (
              <>
                <Divider
                  className={classes.divider}
                  style={{ margin: "10px 0px" }}
                />
                <div className={classes.box2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Typography align="left" className={classes.boxTitle2}>
                        {item.judul}
                      </Typography>
                      <Typography align="left" className={classes.boxTxt8}>
                        Belum ada hasil
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        className={classes.cta2}
                        onClick={() => {
                          history.push(`/tryout/${item.hasiltryout.id}`);
                        }}
                      >
                        Mulai
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
            {!item.hasiltryout && item.status === "published" && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.box2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Typography align="left" className={classes.boxTitle2}>
                        {item.judul}
                      </Typography>
                      <Typography align="left" className={classes.boxTxt8}>
                        Belum ada hasil
                      </Typography>
                    </Grid>
                    <Grid item>
                      {item.coin === 0 ? (
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          className={classes.cta2}
                          onClick={() =>
                            handleTryout({
                              judul: item.judul,
                              tryout_id: item.id,
                              coin: item.coin,
                              waktu: item.waktu,
                              isExpired: false,
                              jumlah: item.jumlah_soal,
                              kategori: item?.kategori ? item.kategori : "none",
                            })
                          }
                        >
                          Gratis
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          className={classes.cta2}
                          endIcon={<img src={coin} width="15px" alt="" />}
                          onClick={() =>
                            handleTryout({
                              judul: item.judul,
                              tryout_id: item.id,
                              coin: item.coin,
                              waktu: item.waktu,
                              isExpired: false,
                              jumlah: item.jumlah_soal,
                              kategori: item?.kategori ? item.kategori : "none",
                            })
                          }
                        >
                          + {item?.coin}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
            {!item.hasiltryout && item.status === "coming soon" && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.box2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Typography align="left" className={classes.boxTitle2}>
                        {item.judul}
                      </Typography>
                      <Timer startDate={item.release_date.toMillis()} />
                    </Grid>
                    {/* <Grid item>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        className={classes.cta3}
                        disabled
                      >
                        Belum Dibuka
                      </Button>
                    </Grid> */}
                  </Grid>
                </div>
              </>
            )}
          </>
        </>
      ) : (
        <>
          <Divider className={classes.divider} />
          <Grid
            container
            spacing={2}
            style={{ padding: "10px 0px !important" }}
          >
            <Grid item xs>
              <Typography
                align="left"
                color="primary"
                className={classes.boxTxt5}
              >
                {item.judul}
              </Typography>
              <Typography align="left" className={classes.boxTxt6}>
                Hasil Jawaban Benar
              </Typography>
              {Object.entries(item.hasiltryout.hasil).map(([key, item]) => {
                return (
                  <Typography
                    key={key}
                    align="left"
                    className={classes.boxTxt7}
                  >
                    {item.nama} : {item.benar}
                  </Typography>
                );
              })}
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <IconButton
                    color="secondary"
                    size="small"
                    // disabled
                    onClick={() => history.push(`/leaderboard/${item.id}`)}
                  >
                    <RankIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() =>
                      history.push(`/tryout/${item.hasiltryout.id}`)
                    }
                  >
                    <InfoIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() =>
                      handleTryout({
                        judul: item.judul,
                        tryout_id: item.id,
                        coin: item.coin,
                        waktu: item.waktu,
                        isExpired: false,
                        jumlah: item.jumlah_soal,
                        kategori: item?.kategori ? item.kategori : "none",
                      })
                    }
                  >
                    <RepeatIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item>
              <img src={ujian} alt="" height="40px" />
            </Grid> */}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default ListTryout;
