import Button from "@material-ui/core/Button";
import CircularProgres from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import TypoGraphy from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ShareIcon from "@material-ui/icons/Share";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useRest from "../../hooks/useRest";
import { date } from "../../utils/formatter";
import styles from "./styles/index";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import slugify from "slugify";
import { functions } from "../../components/FirebaseProvider";
import { baseURL } from "../../images/s3";
import { shareURL } from "../../utils/share";
function BeritaDetail(props) {
  const { classes } = props;

  const params = useParams();
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [openConfirmPay, setOpenConfirmPay] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingPaidNews, setIsLoadingPaidNews] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    fetchNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const [snapshot, loadsnap] = useRest({
    url: "news/banner",
    method: "get",
    params: {
      type: "banner",
    },
  });

  const linkBanner = snapshot?.data?.Item?.targetUrl;
  const PhotoBanner = snapshot?.data?.Item?.photoUrl;
  const handleShare = (data) => async (e) => {
    try {
      await shareURL(data);
    } catch (e) {
      enqueueSnackbar(e.message);
    }
  };

  async function fetchNews() {
    try {
      setIsLoadingPaidNews(true);
      const getNews = functions.httpsCallable("getNews");
      const result = await getNews({
        news_id: params.id,
      });
      setResponse(result);
      if (result.data.Item.unlocked) {
        setIsUnlocked(true);
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setIsLoadingPaidNews(false);
    }
  }

  async function handlePayNews() {
    try {
      setIsSubmitting(true);
      const payNews = functions.httpsCallable("payNews");
      await payNews({
        news_id: params.id,
      });
      await fetchNews();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setIsSubmitting(false);
      setOpenConfirmPay(false);
    }
  }
  if (loadsnap || isLoadingPaidNews || isSubmitting) {
    return (
      <Container maxWidth="xs">
        <CircularProgres style={{ marginTop: "90%" }} />
      </Container>
    );
  }
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Baca berita selengkapnya di PakBudi.id!"
        />
        <meta
          property="og:title"
          content={`${
            response?.data?.Item?.title
              ? response.data.Item.title
              : "Judul Berita"
          }`}
        />
        <meta
          property="og:description"
          content="Baca berita selengkapnya di PakBudi.id!"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`${window.location.origin}/warta/${
            response?.data?.Item && params.id
          }/${
            response?.data?.Item &&
            slugify(response.data.Item.title.toLowerCase())
          }`}
        />
        {/* <meta property="og:image" content={getFirstImage(response && response.data.Item && response.data.Item.content) || logo} /> */}
        <meta
          property="og:image"
          content={
            response?.data?.Item?.thumbnailURL &&
            response?.data?.Item?.thumbnailURL !== ""
              ? response?.data?.Item?.thumbnailURL
              : "https://pakbudi.s3-ap-southeast-1.amazonaws.com/images/pakbudi-logo.png"
          }
        />
        <meta property="og:site_name" content="Pak Budi" />
        <title>PakBudi | Berita</title>
      </Helmet>
      <div className={classes.newsWrap}>
        <div className={classes.newsBody}>
          <div className={`${classes.newsFeed} ${classes.newsFeedDetail}`}>
            <div className={classes.detailHead}>
              <div className={classes.postDesc}>
                {response?.data?.Item ? (
                  <>
                    <h2
                      className={classes.newsTitle}
                      dangerouslySetInnerHTML={{
                        __html: response.data.Item.title,
                      }}
                    />
                    <span className={classes.dateTime}>
                      {date(response.data.Item.updatedAt)}
                    </span>
                  </>
                ) : null}
              </div>
              {response?.data?.Item ? (
                <IconButton
                  onClick={handleShare({
                    title: response.data.Item.title,
                    url: `${window.location.origin}/warta/${params.id}/${
                      response?.data?.Item &&
                      slugify(response.data.Item.title.toLowerCase())
                    }`,
                    text: `${response.data.Item.title}\n\nDownload aplikasi Pak Budi, untuk info lebih lanjut\n`,
                    image: response.data.Item.thumbnailURL,
                  })}
                  className={classes.shareBtn}
                  aria-label="share"
                >
                  <ShareIcon />
                </IconButton>
              ) : null}
            </div>
            <div className={classes.newsContent}>
              {response?.data?.Item ? (
                <>
                  <TypoGraphy
                    style={{ wordWrap: "break-word" }}
                    dangerouslySetInnerHTML={{
                      __html: response.data.Item.preview,
                    }}
                  />
                  {isUnlocked ||
                  response.data.Item?.fee === undefined ||
                  (response.data.Item?.fee && response.data.Item.fee <= 0) ? (
                    <TypoGraphy
                      style={{ wordWrap: "break-word" }}
                      dangerouslySetInnerHTML={{
                        __html: response.data.Item.content,
                      }}
                    />
                  ) : (
                    <>
                      <Box
                        style={{
                          position: "relative",
                        }}
                      >
                        <Box
                          style={{
                            backgroundColor: "white",
                            filter: "blur(4px)",
                          }}
                        >
                          <TypoGraphy>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Phasellus et magna eget nulla suscipit
                            hendrerit. Donec mollis, sapien quis consequat
                            aliquam, diam lacus pharetra ex, at laoreet eros
                            nibh eget urna. In luctus enim vel ex scelerisque,
                            id venenatis elit congue.
                          </TypoGraphy>
                          <TypoGraphy style={{ marginTop: 10 }}>
                            Nullam nec libero vel tellus luctus commodo. Aenean
                            at fringilla metus. Sed euismod mi vel tellus
                            placerat bibendum. Pellentesque habitant morbi
                            tristique senectus et netus et malesuada fames ac
                            turpis egestas. Sed sollicitudin finibus aliquam.
                          </TypoGraphy>
                        </Box>
                        <Box className={classes.payNewsContainer}>
                          <TypoGraphy
                            variant="subtitle2"
                            style={{ textAlign: "center" }}
                          >
                            Buka lanjutan artikel dengan{" "}
                            {response?.data?.Item?.fee}{" "}
                            <span className={classes.icoCoin}>
                              <img
                                className={classes.imgCoin}
                                src={`${baseURL}icon-coin.png`}
                                alt=""
                              />
                            </span>
                          </TypoGraphy>
                          <Button
                            variant="contained"
                            size="small"
                            className={classes.payNewsButton}
                            onClick={() => setOpenConfirmPay(true)}
                          >
                            Beli
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
        {linkBanner !== undefined && PhotoBanner !== undefined ? (
          <div className={classes.banner}>
            <Button className={classes.btnBanner}>
              <img
                onClick={() => window.open(linkBanner, "_blank")}
                className={classes.bannerImg}
                alt=""
                src={PhotoBanner}
              />
            </Button>
          </div>
        ) : (
          <div style={{ marginBottom: 100 }}></div>
        )}
      </div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openConfirmPay}
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
        onClose={(_event, reason) => {
          if (reason !== "backdropClick" || !isSubmitting) {
            setOpenConfirmPay(false);
          }
        }}
      >
        <DialogTitle
          className={classes.DialogTitle}
          style={{ backgroundColor: "#4bbcec" }}
        >
          <div className={classes.logoWrap}>
            <img
              className={classes.mainLogo}
              src={`${baseURL}new-logo-w.png`}
              alt=""
            />
            <span className={classes.pageName}>Artikel Berbayar</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <TypoGraphy variant="subtitle2">
            Apakah kamu yakin ingin membayar artikel ini dengan{" "}
            {response?.data?.Item?.fee} koin? Bayar sekarang dan baca artikel
            eksklusif ini sekarang juga!
          </TypoGraphy>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.actionButton}
            style={{ color: "red" }}
            variant="outlined"
            fullWidth
            onClick={() => setOpenConfirmPay(false)}
          >
            Kembali
          </Button>
          <Button
            className={classes.actionButton}
            style={{ color: "white" }}
            variant="contained"
            fullWidth
            color="primary"
            onClick={handlePayNews}
          >
            Beli
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(BeritaDetail);
