// React
import React, { useEffect, useState } from "react";

// Styles
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";

// MUI
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import FormatListNumberedRtlIcon from "@material-ui/icons/FormatListNumberedRtl";
import ListAltIcon from "@material-ui/icons/ListAlt";
import TimerIcon from "@material-ui/icons/Timer";

//
import LoadingOverlay from "../../components/LoadingOverlay";
import { baseURL } from "../../images/s3";

export default function DialogTransaksi({
  open,
  handleClose,
  hadleBuyTo,
  judul,
  coin,
  waktu,
  kategori,
  isload,
  isExpired,
  jumlah,
  kode_rahasia,
  setBuy,
  check,
  handleChecked,
}) {
  // Yes
  const Btn1 = withStyles({
    label: {
      textTransform: "capitalize",
      fontSize: 10,
      fontWeight: 600,
    },
    root: {
      height: 35,
      padding: "6px 9px",
    },
  })(Button);

  // No
  const Btn0 = withStyles({
    label: {
      textTransform: "capitalize",
      fontSize: 10,
      fontWeight: 600,
      color: "#777",
    },
    root: {
      border: "1.5px solid #777",
      height: 35,
      padding: "6px 9px",
    },
  })(Button);

  // UseStyles
  const classes = useStyles();
  const [bgCategoryColor, setBgCategoryColor] = useState("#4bbcec");

  //
  let txt = `Kerjakan (${coin} Coin)`;
  if (coin === 0) {
    txt = `Kerjakan (Gratis)`;
  }
  //
  let caption = "Kamu yakin ingin mengerjakan paket ini?";
  if (isload === true) {
    txt = "Loading...";
  }
  //
  if (isExpired === true) {
    caption = "Maaf Paket Tryout Yang Kamu Pilih Sudah Tidak Berlaku.";
  }
  //

  useEffect(() => {
    switch (kategori) {
      case "none":
        setBgCategoryColor("#4bbcec");
        break;
      case "snbt":
        setBgCategoryColor("#f6b343");
        break;
      default:
        setBgCategoryColor("#4bbcec");
        break;
    }
  }, [kategori]);

  // Function
  const closeDialog = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
      >
        {isload && (
          <LoadingOverlay height="100%" position="absolute" top="0px" />
        )}
        <DialogTitle
          className={classes.DialogTitle}
          style={{ backgroundColor: bgCategoryColor }}
        >
          <div className={classes.logoWrap}>
            <img
              className={classes.mainLogo}
              src={`${baseURL}new-logo-w.png`}
              alt=""
            />
            <span className={classes.pageName}>Tryout</span>
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <List>
            <ListItem classes={{ root: classes.ListItem }}>
              <ListItemIcon>
                <Avatar style={{ backgroundColor: "#e0fcfa" }}>
                  <ListAltIcon color="primary" />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.dtxt1}>Nama Ujian</Typography>
                }
                secondary={
                  <Typography className={classes.dtxt2}>{judul}</Typography>
                }
              />
            </ListItem>
            <ListItem classes={{ root: classes.ListItem }}>
              <ListItemIcon>
                <Avatar style={{ backgroundColor: "#e0fcfa" }}>
                  <FormatListNumberedRtlIcon color="primary" />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.dtxt1}>Jumlah Soal</Typography>
                }
                secondary={
                  <Typography
                    className={classes.dtxt2}
                  >{`${jumlah} Soal`}</Typography>
                }
              />
            </ListItem>
            <ListItem classes={{ root: classes.ListItem }}>
              <ListItemIcon>
                <Avatar style={{ backgroundColor: "#e0fcfa" }}>
                  <TimerIcon color="primary" />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.dtxt1}>
                    Durasi Pengerjaan
                  </Typography>
                }
                secondary={
                  <Typography className={classes.dtxt2}>{`${
                    waktu / 60
                  } Menit`}</Typography>
                }
              />
            </ListItem>
          </List>
          {coin > 0 && (
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography align="center" variant="subtitle2">
                  <Box className={classes.dtxt2}>{caption}</Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Checkbox
                      style={{ width: 20 }}
                      size="small"
                      name="haveCode"
                      color="primary"
                      checked={check}
                      onChange={handleChecked}
                      disabled={isload}
                    />
                  </Grid>
                  <Grid item>
                    <Box className={classes.dtxt3}>
                      Saya sudah punya kode rahasia
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {check && (
            <ListItem>
              <TextField
                placeholder="Masukkan Kode Rahasia"
                name="kode"
                variant="outlined"
                fullWidth
                size="small"
                helperText="kode rahasia hanya dapat digunakan 1x"
                value={kode_rahasia}
                onChange={(e) => {
                  setBuy(e.target.value);
                }}
                disabled={isload}
              />
            </ListItem>
          )}
        </DialogContent>
        {isExpired === false ? (
          <DialogActions style={{ margin: 8 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Btn0
                  onClick={closeDialog}
                  variant="outlined"
                  fullWidth
                  disabled={isload === true}
                >
                  {check ? "Kembali" : "Tidak"}
                </Btn0>
              </Grid>
              {check ? (
                <Grid item xs={6}>
                  <Btn1
                    onClick={() => hadleBuyTo(true)}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isload || !kode_rahasia}
                  >
                    Kerjakan
                  </Btn1>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Btn1
                    onClick={() => hadleBuyTo(false)}
                    style={{
                      fontsize: "3px !important",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isload}
                  >
                    {txt}
                  </Btn1>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        ) : (
          <DialogActions style={{ margin: 10 }}>
            <Btn0 onClick={closeDialog} variant="outlined" fullWidth>
              Kembali
            </Btn0>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}
