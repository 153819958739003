import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    wrap: {
        display: 'flex', 
        flexDirection: 'row', 
        textTransform: 'capitalize' 
    },
    wrapImg: {
        width: 35, 
        height: 35
    },
    wrapContent: {
        marginLeft: 25
    },
    title: {
        textAlign: 'left',
        marginTop: 0, 
        fontWeight: '400', 
        color: '#FFF',
        fontSize: 14 
    },
    body: {
        textAlign: 'left', 
        marginTop: -15, 
        fontWeight: '200', 
        color: '#FFF', 
        fontSize: 12
    }
}))

export default useStyles;