import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";
import React from "react";
import { functions, useFirebase } from "../../components/FirebaseProvider";
import useStyles from "./styles";
import TopNav from "../../components/TopNav";

// Icons

// function VoucherItem({ item }) {
//   const classes = useStyles();
//   console.log("item", item);
//   return (
//     <div className={classes.voucherRow}>
//       <div className={classes.voucherIcon}>
//         <img src={voucherIcon} alt="" />
//       </div>
//       <div className={classes.voucherDesc}>
//         <div className={classes.voucherName}>Voucher Gratis A</div>
//         <div className={classes.voucherExpire}>Berlaku hingga 10.01.2022</div>
//       </div>
//       <div className={classes.seeDetail}>
//         <div className={classes.radioWrap}>
//           <FormControlLabel
//             classes={{ label: classes.labelle }}
//             control={
//               <Radio
//                 color="primary"
//                 value="cek"
//                 icon={
//                   <RadioButtonUncheckedIcon
//                     className={classes.uncheckedRadio}
//                   />
//                 }
//                 checkedIcon={
//                   <CheckCircleIcon className={classes.checkedRadio} />
//                 }
//               />
//             }
//             label={""}
//             labelPlacement="bottom"
//           />
//         </div>
//         <div className={classes.detailLink}>Lihat Detail</div>
//       </div>
//     </div>
//   );
// }
function Vouchers(props) {
  const classes = useStyles();

  const { user } = useFirebase();

  // const { pageEvent } = useSiswa();

  // const history = useHistory();

  const [voucher, setVoucher] = React.useState({
    voucher: "",
  });
  const [open, setOpen] = React.useState(false);

  const [load, setLoad] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const [Coin, setCoin] = React.useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value === "") {
      setLoading(true);
    } else {
      setLoading(false);
    }

    setVoucher({
      ...voucher,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const Voucher = functions.httpsCallable("cekVoucher");
    try {
      setLoad(true);
      const result = await Voucher({
        siswa_id: user.uid,
        voucher: voucher.voucher,
      });
      setCoin(result.data.coin);
      setOpen(true);
      setVoucher({ voucher: "" });
      setLoad(false);
    } catch (e) {
      if (e.message === "Cannot read property 'data' of undefined") {
        enqueueSnackbar("Kode Voucher Kamu Tidak Valid", { variant: "error" });
      } else {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      setOpen(false);
      setLoad(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleClick = () => {
  //   if (pageEvent === "settings") {
  //     history.push("/settings");
  //   } else {
  //     history.goBack();
  //   }
  // };

  return (
    <div className={classes.root}>
      <TopNav />
      {/* <AppHeader /> */}
      {/* <div className={classes.voucherHead}>
        <Button className={classes.historyBack1} onClick={handleClick}>
          <ChevronLeftIcon />
        </Button>
      </div> */}

      <div className={classes.voucherBox}>
        <h4 className={classes.voucherTitle}>Pilih Voucher</h4>
        {/* <h3>Silakan Input Kode Voucher</h3> */}
        <div className={classes.voucherBoxInner}>
          <form className={classes.formBox} onSubmit={handleSubmit}>
            {/* <TextField className={classes.kodeVoucher} 
              id="kode-voucher"
              label="Masukan Kode Voucher"
              name="voucher"
              value={voucher.voucher}
              autoComplete="off"
              onChange={handleChange}
              variant="outlined"
              classes={{notchedOutline:classes.notchedOut}}
              inputProps={{
                style: {
                  padding: 10,
                }
            }}
            InputLabelProps={{
              style:{
                transform: 'translate(14px, 11px) scale(1)',
              }
            }}
            /> */}
            <TextField
              className={classes.kodeVoucher}
              id="kode-voucher"
              label="Masukan Kode Voucher"
              name="voucher"
              value={voucher.voucher}
              variant="outlined"
              autoComplete="off"
              onChange={handleChange}
              size="small"
              classes={{
                root: classes.tfRoot,
                inputMarginDense: classes.marginDense,
              }}
              inputProps={{
                style: {
                  backgroundColor: "#e5e5e5",
                  height: 21,
                  padding: "3px 7px",
                  fontSize: 10,
                  borderRadius: 3,
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 10,
                },
              }}
            />
            <div className={classes.btnKirimWrap}>
              {load && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.load}
                />
              )}
              <Button
                type="submit"
                disabled={loading || load}
                variant="contained"
                color="primary"
                size="small"
                className={classes.btnKirim}
              >
                Gunakan
              </Button>
            </div>
          </form>
        </div>
        {/* <div className={classes.voucherList}>
          <div className={classes.listHead}>
            <div className={classes.listTitle}>Nama Kategori Voucher</div>
            <div className={classes.listTitleRight}>Pilih</div>
          </div>
          <div className={classes.listBody}>
            {
              vouchers.map((vouch) => {
                return <VoucherItem key={vouch.id} item={vouch} />
              })
            }
          </div>
          <div className={classes.listFoot}>
            <Button variant='contained' className={classes.okBtn}>Ok</Button>
          </div>
        </div> */}
      </div>
      <Dialog
        className={classes.voucherModal}
        open={open}
        onClose={handleClose}
        aria-labelledby="voucher-dialog-title"
        aria-describedby="voucher-dialog-description"
      >
        <DialogTitle
          className={classes.voucherDialogTitle}
          id="voucher-dialog-title"
        >
          {"Selamat!!!"}
        </DialogTitle>
        <DialogContent>
          <div className={classes.voucherPaper}>
            <p id="voucher-modal-description">Kamu mendapatkan {Coin} coin</p>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogAct}>
          <Button onClick={handleClose} className={classes.btnVoucher}>
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Vouchers;
