import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chatBody: {
    position: "relative",
    paddingBottom: 50,
  },
  noChat: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    width: 200,
    height: 45,
  },
  wrapNoChat: {
    margin: "30px auto",
  },
  NoChat: {
    width: 200,
    height: "auto",
  },
  txtTitle: {
    marginTop: 15,
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.textColor.gray,
  },
  txtSub: {
    fontSize: 12,
    color: theme.palette.textColor.gray,
    width: "80%",
    margin: "10px auto",
  },
  pageHeader: {
    margin: "0 auto 20px",
    position: "relative",
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  pageTitle: {
    color: "#fff",
    fontSize: 24,
    margin: "10px 0",
  },
  chatListWrap: {
    backgroundColor: "#fff",
    padding: 5,
    borderRadius: 8,
    minHeight: 380,
    margin: "auto",
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  sender: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
  },
  profileName: {
    fontSize: 14,
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "@media(min-width: 320px)": {
      width: 120,
    },
    "@media(min-width: 360px)": {
      width: 150,
    },
    "@media(min-width: 400px)": {
      width: 185,
    },
    "@media(min-width: 440px)": {
      width: 220,
    },
    "@media(min-width: 480px)": {
      width: 250,
    },
    "@media(min-width: 540px)": {
      width: 300,
    },
    "@media(min-width: 600px)": {
      width: 330,
    },
    "@media(min-width: 660px)": {
      width: 380,
    },
    "@media(min-width: 768px)": {
      width: 480,
    },
  },
  time: {
    color: theme.palette.textColor.gray,
    fontSize: 11,
  },
  lastMsg: {
    fontSize: 13,
  },
  chatHeading: {
    width: "100%",
    minHeight: 72,
    position: "fixed",
    zIndex: 10,
    top: 0,
    left: 0,
  },
  chatHeading2: {
    width: "100%",
    minHeight: 72,
    position: "absolute",
    top: 0,
    left: 0,
  },
  detailPanel: {
    margin: "0 auto 0 !important",
    "@media(min-width: 320px)": {
      width: "100%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
    zIndex: 99,
    borderBottom: "solid 2px #ddd",
    boxShadow: "none",
    backgroundColor: theme.palette.lightBlue.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  topDetail: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    paddingTop: 5,
    paddingBottom: 5,
  },
  back: {
    position: "absolute",
    left: 0,
    color: "#fff",
  },
  siswaId: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginLeft: 25,
  },
  siswaAvatar: {
    width: 32,
    height: 32,
    marginTop: 5,
  },
  coreHeading: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
  },
  siswaDesc: {
    marginLeft: 15,
    width: "94%",
  },
  siswaName: {
    color: "#fff",
    fontSize: 13,
    textAlign: "left",
    fontWeight: 700,

    wordBreak: "break-word",
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    // '@media(min-width: 320px)': {
    //     width: 90,
    //     fontSize: 13,
    // },
    // '@media(min-width: 360px)': {
    //     width: 115,
    // },
    // '@media(min-width: 400px)': {
    //     width: 155,
    // },
    // '@media(min-width: 440px)': {
    //     width: 185,
    //     fontSize: 14,
    // },
    // '@media(min-width: 480px)': {
    //     width: 220,
    // },
    // '@media(min-width: 540px)': {
    //     width: 265,
    // },
    // '@media(min-width: 600px)': {
    //     width: 300,
    // },
    // '@media(min-width: 660px)': {
    //     width: 350,
    // },
    // '@media(min-width: 768px)': {
    //     width: 400,
    // },
  },
  durationBlock: {},
  chatDuration: {
    textAlign: "left",
    marginLeft: 0,
    fontSize: 12,
    color: theme.palette.textColor.yellow,
  },
  detailWrap: {
    width: "100%",
  },
  detailItem: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    width: "100%",
  },
  siswaDetail: {
    color: "#fff",
    textAlign: "left",
  },
  siswaDetailItem: {
    fontSize: 12,
  },
  pauseBtn: {
    backgroundColor: "red",
    color: "#fff",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
  chatWindow: {
    width: "100%",
    margin: "0 auto",
    padding: "82px 15px 15px",
    position: "relative",
    overflowY: "auto",
    height: "calc(100vh - 72px)",
    backgroundColor: "#efefef",
    "@media(min-width: 320px)": {
      width: "100%",
      paddingBottom: 30,
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  chatWindow1: {
    width: "100%",
    margin: "0 auto",
    padding: "82px 15px 15px",
    position: "relative",
    height: "calc(100vh - 72px)",
    overflowY: "auto",
    backgroundColor: theme.palette.textColor.gray7,
    "@media(min-width: 320px)": {
      width: "100%",
      paddingBottom: 30,
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  SesiWrap: {
    margin: "10px auto 15px",
  },
  chatDayWrap: {
    margin: "10px auto 15px",
    width: 140,
    backgroundColor: "#FFF",
    borderRadius: 3,
    textAlign: "center",
    padding: "3px 0",
  },
  chatDay: {
    fontSize: 10,
    color: theme.palette.textColor.gray,
    fontWeight: "bold",
  },
  unreadCount: {
    backgroundColor: "#06d755",
    borderRadius: 12,
    padding: "0 5px",
    textAlign: "center",
    color: "#fff",
    height: 19,
    lineHeight: "19px",
    minWidth: 9,
    fontWeight: 500,
    verticalAlign: "top",
    fontSize: 12,
    position: "absolute",
    right: 7,
    bottom: 7,
  },
  clientChatBubble: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    margin: "0 auto 10px",
  },
  clientTextBody: {
    width: "fit-content",
    backgroundColor: "#ffffff",
    textAlign: "left",
    padding: 10,
    borderRadius: 8,
    position: "relative",
    marginRight: 17,
    display: "flex",
    flexFlow: "column nowrap",
    "&::before": {
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      left: -10,
      right: "auto",
      top: 0,
      bottom: "auto",
      border: "12px solid",
      borderColor: "#ffffff transparent transparent transparent",
    },
  },
  clientText: {
    color: theme.palette.textColor.darkGray,
    fontSize: 13,
    wordBreak: "break-word",
  },
  clientTimeStamp: {
    alignSelf: "flex-end",
    color: theme.palette.textColor.gray,
    fontSize: 10,
  },
  tmbBubble: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    margin: "0 auto 10px",
    paddingLeft: 25,
  },
  myTmbTextBody: {
    backgroundColor: theme.palette.lightBlue.secondary,
    width: "fit-content",
    maxWidth: "100%",
    textAlign: "left",
    padding: 10,
    borderRadius: 8,
    position: "relative",
    alignSelf: "flex-end",
    "&::before": {
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      right: -8,
      top: 0,
      bottom: "auto",
      border: "12px solid",
      borderColor: "#A5E8FF transparent transparent transparent ",
    },
  },
  myTmbText: {
    backgroundColor: theme.palette.textColor.otherBlue,
    borderRadius: 5,
  },
  tmbBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    width: 250,
    padding: "10px 5px 20px",
  },
  tmbResultcaption: {
    margin: 0,
    color: "#fff",
    fontSize: 11,
  },
  tmbResultNameWrap: {
    backgroundColor: "#fff",
    borderRadius: 7,
    width: "90%",
    padding: "10px 5px",
    margin: "5px auto 10px",
  },
  tmbResultName: {
    color: theme.palette.textColor.gray8,
    fontSize: 12,
    textAlign: "center",
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tmbResultImg: {
    width: 120,
    height: "auto",
    display: "block",
    margin: "5px auto",
  },
  tmbResultTitle: {
    margin: "0 0 5px",
    color: "#fff",
    fontSize: 35,
    textDecoration: "underline",
  },
  tmbResultSplit: {
    color: theme.palette.textColor.darkGray,
    fontSize: 9,
    fontWeight: 700,
    margin: "0px 0px 10px",
  },
  tmbResultSubtitle: {
    color: theme.palette.textColor.darkGray,
    fontSize: 13,
    fontWeight: 600,
    margin: 0,
  },
  toBubble: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    margin: "0 auto 10px",
    paddingLeft: 25,
  },
  myToTextBody: {
    backgroundColor: theme.palette.lightBlue.secondary,
    width: "fit-content",
    maxWidth: "100%",
    textAlign: "left",
    padding: "10px 25px",
    borderRadius: 8,
    position: "relative",
    alignSelf: "flex-end",
    "&::before": {
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      right: -8,
      top: 0,
      bottom: "auto",
      border: "12px solid",
      borderColor: "#A5E8FF transparent transparent transparent ",
    },
  },
  toBox: {
    minWidth: 250,
  },
  toBoxTitle: {
    color: theme.palette.textColor.gray12,
    fontSize: 20,
    fontWeight: 600,
    margin: "0 0 5px",
  },
  toBoxSubtitle: {
    color: theme.palette.textColor.darkGray,
    fontSize: 13,
    fontWeight: 700,
    margin: "0 0 5px",
  },
  toBoxDetailRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
  },
  detailLabel: {
    width: "calc(90% - 30px)",
  },
  detailValue: {
    width: 30,
  },

  myLink: {
    backgroundColor: "#F2F4F3",
    borderRadius: 8,
    marginBottom: 5,
    textAlign: "left",
    fontFamily: "Poppins",
  },
  imgLink: {
    display: "flex",
    height: "100%",
    padding: 10,
    backgroundColor: "#FFF",
    border: "4px solid #F2F4F3",
    borderRadius: 9,
  },
  txtLink: {
    padding: "10px",
  },
  titleLink: {
    fontSize: 10,
    fontWeight: 600,
    margin: 0,
    marginBottom: 3,
    maxWidth: 200,
  },
  descLink: {
    fontSize: 6,
    fontWeight: 500,
    margin: 0,
    marginBottom: 3,
    color: "#777",
    maxWidth: 200,
  },
  mainLink: {
    color: "#1461A8",
    fontWeight: 500,
    fontSize: 14,
    textDecoration: "underline",
  },

  myChatBubble: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    margin: "0 auto 10px",
    paddingLeft: 25,
  },
  myTextBody: {
    width: "fit-content",
    maxWidth: "100%",
    backgroundColor: theme.palette.lightBlue.secondary,
    textAlign: "left",
    padding: 10,
    borderRadius: 8,
    position: "relative",
    alignSelf: "flex-end",
    "&::before": {
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      right: -8,
      top: 0,
      bottom: "auto",
      border: "12px solid",
      borderColor: "#A5E8FF transparent transparent transparent ",
    },
  },
  myText: {
    color: theme.palette.textColor.darkGray,
    fontSize: 13,
    wordBreak: "break-word",
  },
  myTimeStamp: {
    color: theme.palette.textColor.gray,
    alignSelf: "flex-end",
    fontSize: 10,
  },
  deliveryDetail: {
    position: "relative",
    textAlign: "right",
    paddingRight: 5,
  },
  sentIcon: {
    color: theme.palette.textColor.gray7,
    width: 12,
    height: 12,
  },
  readIcon: {
    color: theme.palette.textColor.green,
    width: 12,
    height: 12,
  },
  isTyping: {
    color: theme.palette.textColor.gray7,
    position: "absolute",
    top: "auto",
    left: 0,
    right: 0,
    bottom: 10,
    margin: "auto",
    width: 110,
  },
  chatInput: {
    position: "fixed",
    top: "auto",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    width: "100%",
    zIndex: 99,
    height: 77,
    backgroundColor: theme.palette.textColor.gray7,
    // borderTop: 'solid 1px #ddd',
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    padding: "10px 5px",
    "@media(min-width: 320px)": {
      width: "100%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  chatText: {
    width: "calc(100% - 50px)",
    backgroundColor: "#fff",
    // border: '1px solid' + theme.palette.textColor.gray2,
    padding: 5,
    borderRadius: 16,
    marginLeft: 10,
    marginRight: 5,
  },
  sendBtn: {
    backgroundColor: theme.palette.lightBlue.main,
    width: 47,
    height: 47,
    color: theme.palette.primary.main,
    "&:focus": {
      backgroundColor: theme.palette.darkBlue.main,
    },
  },
  sendIcon: {
    width: 25,
    height: "auto",
  },
  disabledSendBtn: {
    backgroundColor: theme.palette.textColor.gray10 + "!important",
  },
  online: {
    color: theme.palette.textColor.green,
    backgroundColor: "#44b700",
    padding: 5,
  },
  offline: {
    color: theme.palette.textColor.gray2,
    backgroundColor: theme.palette.textColor.gray,
    padding: 5,
  },
  rating1: {
    width: "100%",
    textAlign: "center",
    marginTop: 10,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: 6,
  },
  paper: {
    backgroundColor: "#fff",
  },
  modalTitle: {
    backgroundColor: theme.palette.lightBlue.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    fontSize: 20,
    fontWeight: 700,
    color: "#fff",
    textAlign: "center",
  },
  mitraSub: {
    display: "block",
    textAlign: "center",
    color: theme.palette.textColor.gray6,
    fontSize: 12,
    fontWeight: 500,
  },
  ulasanBox: {
    textAlign: "center",
  },
  ulasanText: {
    width: "98%",
  },
  kirimRating: {
    width: 90,
    height: 26,
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    textTransform: "capitalize",
  },
  btnWrap: {
    justifyContent: "center",
  },
  profileImg: {
    width: 90,
    height: 90,
    borderRadius: 10,
  },
  boxWelcome: {
    width: "100%",
    minHeight: 150,
    backgroundColor: "white",
    borderRadius: ".5rem",
    marginBottom: 15,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 4px 0px",
  },
  boxHeading: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid" + theme.palette.textColor.gray2,
    padding: 15,
    position: "relative",
  },
  textHeadingWrap: {
    textAlign: "left",
    marginLeft: 10,
  },
  textHeading: {
    fontWeight: "600",
    color: theme.palette.textColor.darkGray,
    fontSize: 11,
  },
  textSubHeading: {
    display: "block",
    fontSize: 10,
    lineHeight: "14px",
    color: theme.palette.textColor.gray8,
    fontWeight: "500",
  },
  closeBtn: {
    position: "absolute",
    top: 0,
    right: -5,
  },
  boxDetail: {
    padding: 15,
    color: theme.palette.textColor.darkGray,
    fontSize: 12,
  },
  attachFile: {
    position: "absolute",
    bottom: 25,
    right: 70,
    width: 23,
    height: 23,
    zIndex: 2,
  },
  floatingMenu: {
    position: "fixed",
    bottom: 80,
    left: 0,
    right: 0,
    width: "fit-content",
    margin: "auto",
    // minHeight: 86,
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  attachBtn: {
    width: 86,
    height: "fit-content",
    textAlign: "center",
    textTransform: "capitalize",
    padding: "10px 0",
  },
  btnLabel: {
    display: "flex",
    flexDirection: "column",
  },
  attachBtnLabel: {
    color: theme.palette.textColor.gray,
    fontSize: 9,
    fontWeight: 400,
    marginTop: 5,
    display: "block",
  },
  myAttachment: {
    width: 200,
    height: "fit-content",
    padding: 2,
    "& > img": {
      width: "100%",
      height: "auto",
    },
  },
  closeAttach: {
    position: "absolute",
    right: -10,
    top: -40,
  },
}));

export default useStyles;
