import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AppHeader from "../../components/AppHeader";
import { baseUrl } from "../../images/s3";
import useStyles from "./styles";

const PendingPayment = () => {
  const classes = useStyles();
  const history = useHistory();
  const [time, setTime] = useState(15);
  const timerRef = useRef(null);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      clearInterval(timerRef.current);
      history.replace("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.headerAfterPayment}>
          <AppHeader />
          <img
            src={`${baseUrl}tmbLogo.png`}
            alt="tmbLogo"
            className={classes.logoInPayment}
          />
        </div>
        <div className={classes.messageBody}>
          <img
            src={`${baseUrl}pending.png`}
            alt="tmbLogo"
            className={classes.statusImage}
          />
          <Typography variant="body2" style={{ fontSize: "0.75rem" }}>
            Hi! Kami ingin memberitahumu bahwa pembayaranmu sedang dalam proses
            verifikasi. Jangan khawatir, kami akan segera memprosesnya dan
            menghubungimu jika ada informasi terbaru terkait pembayaranmu.
            Terima kasih atas kesabarannya ya! Jika kamu membutuhkan bantuan
            dari kami, jangan ragu untuk menghubungi kami di Instagram{" "}
            <Typography
              variant="body2"
              style={{
                fontSize: "0.75rem",
                color: "#4bbcec",
                display: "inline",
              }}
            >
              @pakbudiapp
            </Typography>{" "}
            ya! Terima kasih.
          </Typography>
          <Typography
            variant="body2"
            style={{ fontSize: "0.75rem", marginTop: 16 }}
          >
            Kamu akan diarahkan ke halaman tujuan dalam {time} detik.
          </Typography>
        </div>
        <Button
          className={classes.startBtn}
          variant="contained"
          color="secondary"
          component={Link}
          to="/home"
        >
          Kembali Ke Home
        </Button>
      </div>
    </div>
  );
};

export default PendingPayment;
