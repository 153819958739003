import React from "react";
import useStyles from "../pages/home/styles";

function PbNumberInput({ label, inputName, value, setValue, max }) {
  const classes = useStyles();
  const handleDecrement = (name) => {
    setValue((prevState) => {
      let newCount = prevState[name] - 1;
      if (newCount > max) {
        newCount = max;
      }
      if (newCount < 0) {
        newCount = 0;
      }
      return ({ ...prevState, [name]: newCount });
    });
  };

  const handleIncrement = (name) => {
    setValue((prevState) => {
      let newCount = prevState[name] + 1;
      if (newCount > max) {
        newCount = max;
      }
      if (newCount < 0) {
        newCount = 0;
      }
      return ({ ...prevState, [name]: newCount });
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let parsedValue = parseInt(value);
    if (parsedValue > max) {
      parsedValue = max;
    }
    if (parsedValue < 0) {
      parsedValue = 0;
    }
    setValue((prevState) => ({ ...prevState, [name]: parsedValue }));
  };

  return (
    <div className={classes.pbInputGroup}>
      <label>{label}</label>
      <div className={classes.pbNumberInput}>
        <span onClick={() => handleDecrement(inputName)}>-</span>
        <input
          type="number"
          name={inputName}
          value={value}
          onChange={handleChange}
        ></input>
        <span onClick={() => handleIncrement(inputName)}>+</span>
      </div>
    </div>
  );
}

export default PbNumberInput;
