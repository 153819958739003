import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 60,
        userSelect: 'none',
        msUserSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none'
    },
    missionHead: {
        backgroundColor: theme.palette.lightBlue.main,
        borderBottomLeftRadius: 13,
        borderBottomRightRadius: 13,
        padding: '0 20px 20px',
        position: 'relative',
        margin: '0 auto',
        '@media(min-width: 960px)': {
            width: '63%',
        },
    },
    missionTitle: {
        color: '#fff',
        fontSize: '1rem',
        display: 'inline-block',
        lineHeight: '30px',
        margin: '10px auto',
        fontWeight: 500,
        textAlign: 'center',
        width: 'fit-content',
        '@media(min-width: 397px)': {
            fontSize: 26,
        },
    },
    Body: {

        margin: '15px auto 50px',
        minHeight: 400,
        borderRadius: 8,
        '@media(min-width: 320px)': {
            width: '100%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    listBody1: {
        background: '#fff',
        position: 'relative',
        zIndex: 2,
        margin: '-150px auto 50px',
        minHeight: 400,
        padding: 10,
        borderRadius: 8,
        '@media(min-width: 320px)': {
            width: '90%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    listBody: {
        backgroundColor: '#fff',
        margin: '20px auto 50px',
        minHeight: 400,
        padding: 10,
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media(min-width: 320px)': {
            width: '90%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    listTitle: {
        color: theme.palette.textColor.gray8,
        fontSize: '1.563rem',
        margin: '10px auto',
    },
    listSubtitle: {
        color: theme.palette.textColor.gray8,
        fontSize: '0.938rem'
    },
    promoBtn: {
        textTransform: 'capitalize',
        color: '#FFFF',
        backgroundColor: '#fff',
        width: '94%',
        borderRadius: 10,
        // '&:active': {
        //     backgroundColor: 'mediumseagreen',
        // },
        // '&:hover': {
        //     backgroundColor: 'mediumseagreen',
        // },

    },
    missionBox: {
        width: '90%',
        margin: '0 auto 20px',
        borderRadius: 10,
        backgroundColor: 'white',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        position: 'relative',
        '@media(min-width: 320px)': {
            marginBottom: 10,
            width: '90%'
        },
    },
    missionBoxDone: {
        width: '100%',
        margin: '0 auto 20px',
        borderRadius: 10,
        backgroundColor: 'white',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        position: 'relative',
        '@media(min-width: 320px)': {
            marginBottom: 10,
            width: '100%'
        },
    },
    missionBoxVerify: {
        width: '94%',
        marginTop: -5,
    },
    misiThumb: {
        width: 60,
        margin: '0 10px',
    },
    misiDetail: {
        width: 'calc(100% - 70px)',
        padding: '10px 0 35px',
        position: 'relative',
    },
    misiDetailVerify: {
        width: 'calc(100% - 70px)',
        padding: '20px 0',
        position: 'relative',
        display: 'flex',
        flexFlow: 'row nowrap',
    },
    misiTitle: {
        color: theme.palette.darkBlue.main,
        fontSize: '1rem',
        lineHeight: '20px',
        fontWeight: 600,
        textAlign: 'left',
    },
    misiSubtitle: {
        color: theme.palette.textColor.gray,
        fontSize: '0.75rem',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        marginTop: 7,
        textTransform: 'capitalize',
    },
    goBtn: {
        color: 'white',
        backgroundColor: theme.palette.lightBlue.main,
        borderRadius: 10,
        padding: 0,
        width: 80.33,
        textTransform: 'capitalize',
        position: 'absolute',
        right: 25,
        bottom: 9,
        fontWeight: 600,
        fontSize: 12,
        height: 20,
        '&:hover': {
            backgroundColor: "#14A7DC",
        },
    },
    claimBtn: {
        color: 'white',
        backgroundColor: theme.palette.orange.main,
        borderRadius: 10,
        padding: 0,
        width: 80.33,
        textTransform: 'capitalize',
        position: 'absolute',
        right: 25,
        bottom: 9,
        fontWeight: 600,
        fontSize: 12,
        height: 20,
        '&:hover': {
            backgroundColor: "#FFAA0C",
        },
    },
    retryBtn: {
        color: 'white',
        backgroundColor: theme.palette.red.main,
        borderRadius: 10,
        padding: 0,
        width: 80.33,
        textTransform: 'capitalize',
        position: 'absolute',
        right: 25,
        bottom: 9,
        fontWeight: 600,
        fontSize: 12,
        height: 20,
        '&:hover': {
            backgroundColor: "#A71919",
        },
    },
    imgCoin: {
        width: 11,
        height: 'auto',
        margin: '0 3px 0 5px',
    },
    rejectBtn: {
        textTransform: 'capitalize',
        color: '#FFFF',
        backgroundColor: '#c0392b',
        '&:active': {
            backgroundColor: '#e74c3c',
        },
        '&:hover': {
            backgroundColor: '#e74c3c',
        },
        '@media(min-width: 320px)': {
            marginBottom: 10,
            width: '100%'
        },
        '@media(min-width: 960px)': {
            width: 400,
        },
    },
    // labelBtn: {
    //     display: 'flex',
    //     justifyContent: 'flex-start',
    //     '@media(min-width: 320px)': {
    //         flexFlow: 'row wrap',
    //     },
    //     alignItems: 'flex-start'
    // },
    imgHg: {
        width: 16,
        height: 'auto',
        display: 'block',
    },
    missionDone: {
        position: 'relative',
        marginBottom: 10,
        '@media(min-width: 960px)': {
            width: '90% !important',
        },
    },
    doneMsg: {
        backgroundColor: 'rgba(34,34,34,.75)',
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'capitalize',
        top: 0,
        left: 0,
        zIndex: 3,
        '& > span': {
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '1.25rem',
        }
    },
    misiDetailLeft: {
        width: '65%',
        marginRight: 7,
    },
    missionStatus: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '27%',
    },
    statusVerify: {
        color: theme.palette.lightBlue.main,
        fontSize: 12,
        lineHeight: '14px',
        textTransform: 'capitalize',
        display: 'block',
        marginTop: 5,
        textAlign: 'center',
    },
    boldText: {
        fontWeight: 'bold',
    },
    title: {
        marginBottom: 15,
        fontWeight: '600',
        fontSize: 18,
        color: theme.palette.textColor.gray8,
    },
    caption: {
        marginTop: 15,
        color: theme.palette.textColor.gray8,
        fontWeight: '500',
        fontSize: 14,
    },
    btnMisi: {
        marginTop: 25,
        textTransform: 'capitalize'
    },
    DialogTitle: {
        fontSize: 18,
        textAlign: 'center',
        backgroundColor: theme.palette.lightBlue.main,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        position: 'relative',
        paddingTop: 24,
    },
    DialogTitleConfirm: {
        color: theme.palette.textColor.gray8 + '!important',
        fontSize: '.85rem',
        textAlign: 'center',
        backgroundColor: theme.palette.lightBlue.main,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        position: 'relative',
        paddingTop: 24,
    },
    pageName: {
        position: 'absolute',
        top: 15,
        left: 109,
        color: '#fff',
        fontSize: 12,
        fontStyle: 'italic',
    },
    pageNameAlt: {
        position: 'absolute',
        top: 15,
        left: 76,
        color: '#fff',
        fontSize: 12,
        fontStyle: 'italic',
    },
    logoImg: {
        width: 160,
        height: 'auto',
    },
    dialogContent: {
        padding: 0
    },
    ListItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    btnedit1: {
        padding: '10px',
        textTransform: 'capitalize',
        width: '70%',
        margin: '0 auto',
        borderRadius: 25
    },
    btnedit2: {
        padding: '10px',
        textTransform: 'capitalize',
        width: '70%',
        margin: '0 auto',
        borderRadius: 25,
        marginBottom: 25,
    },
    btnCancel: {
        padding: '10px',
        textTransform: 'capitalize',
        width: '70%',
        marginTop: 15,
        marginBottom: 25,
        margin: 'auto',
        borderRadius: 25
    },
    content: {
        padding: '0px 24px'
    },
    desc: {
        padding: 15,
        textAlign: 'center',
        maxWidth: 250
    },
    uploadForm: {
        position: 'relative',
        width: '100%',
    },
    imgKlik: {
        position: 'absolute',
        right: 25,
        bottom: 0,
        width: 34,
        height: 'auto',
    },
    thumbPLayout: {
        width: 240,
        height: 240,
        borderRadius: 10,
        borderStyle: 'dashed',
        border: '1.4px solid' + theme.palette.lightBlue.main,
        margin: 'auto',
        position: 'relative',
    },
    btnOke: {
        marginTop: 30,
        marginBottom: 50,
        width: 195,
        textTransform: 'capitalize',
    },
    pageTitle: {
        color: theme.palette.textColor.gray,
        fontSize: 24,
        marginBottom: 10,
    },
    gridCaption: {
        width: '100%',
        height: 50,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '1rem',
    },
    imageIcon: {
        width: 'auto',
        fontSize: '4.75rem',
    },
    thumbPreview: {
        width: 237,
        height: 237,
        borderRadius: 5,
        margin: 'auto',
    },
    layer: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.4)',
        position: 'absolute',
        zIndex: 1,
        borderRadius: 5,
    },
    btnroot: {
        minWidth: 0
    },
    btnClose: {
        position: 'absolute',
        zIndex: 2,
        top: 5,
        right: 15,
        width: 20,
        height: 20
    },
    iconClose: {
        fontSize: 35,
    },
    sub1: {
        color: theme.palette.textColor.gray,
        fontSize: 12,
        width: '75%',
        margin: '0 auto 20px'
    },
    konfirmasiWrap: {
        width: 250,
        margin: '20px auto',
        textAlign: 'left'
    },
    pageSubtitle: {
        color: theme.palette.textColor.gray,
        fontSize: 14,
        width: '70%',
        margin: '15px auto 20px',
    },
    pageSubtitle2: {
        color: theme.palette.textColor.gray,
        fontSize: 14,
        margin: '10px',
        textAlign: 'center'
    },
    btnDialog: {
        padding: 10,
        borderTop: '1px solid #dedede'
    },
    btnDialogConfirm: {
        width: 112,
        height: 26,
        padding: 0,
        color: '#fff',
        fontSize: 13,
        fontWeight: 600,
        backgroundColor: theme.palette.lightBlue.main,
        margin: '0 auto 30px',
    },
    dialogTitle: {
        textAlign: 'center'
    },
    tableWrap: {
        marginBottom: 30,
    },
    tableTitle: {
        fontWeight: 700,
        color: theme.palette.textColor.gray8,
        textAlign: 'left',
        paddingLeft: 10,
        '@media(min-width: 960px)': {
            width: 400,
            margin: '0 auto 10px',
        },
    },
    tableCell: {
        color: theme.palette.primary.main,
        fontSize: 14,
        width: '25%',
        // margin: '10px auto',
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
    },
    tableCellTopID: {
        color: theme.palette.primary.main,
        fontSize: 14,
        width: '30%',
        // margin: '10px auto',
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
    },
    tableCellTopDate: {
        color: theme.palette.primary.main,
        fontSize: 14,
        width: '30%',
        // margin: '10px auto',
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
    },
    tableCellTopCoin: {
        color: theme.palette.primary.main,
        fontSize: 14,
        width: '30%',
        // margin: '10px auto',
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
    },
    tableCellCoin: {
        color: theme.palette.primary.main,
        fontSize: 14,
        width: 40,
        margin: '10px auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    },
    tableCell1: {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 600,
        width: '25%',
        // margin: '10px auto',
        margin: '10px 0',
    },
    TableHead: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'space-around',
        '@media(min-width: 320px)': {
            width: '100%',
        },
        '@media(min-width: 960px)': {
            width: 400,
        },
    },
    TableBody: {
        margin: 'auto',
        '@media(min-width: 320px)': {
            width: '100%',
        },
        '@media(min-width: 960px)': {
            width: 400,
        },
    },
    TableRow: {
        display: 'flex',
        margin: 'auto',
        '@media(min-width: 320px)': {
            width: '100%',
            justifyContent: 'flex-start',
        },
        '@media(min-width: 360px)': {
            justifyContent: 'space-between',
        },
        '@media(min-width: 412px)': {
            width: '100%',
        },
        '@media(min-width: 480px)': {
            width: '94%',
        },
        padding: '5px 0',
        borderBottom: 'solid 1px' + theme.palette.textColor.gray6,
    },
    TRow: {
        color: theme.palette.textColor.gray,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media(min-width: 360px)': {
            fontSize: 12,
            marginLeft: 10,
        },
    },
    TRowID: {
        width: '23%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    TRowCoin: {
        '@media(min-width: 320px)': {
            fontSize: 10,
            width: '25%',
        },
        '@media(min-width: 360px)': {
            fontSize: 12,
        },
    },
    TRowDate: {

    },
    statusFalse: {
        color: theme.palette.orange.main,
        fontSize: 12,
        width: 75,
        padding: 0,
    },
    statusTrue: {
        color: theme.palette.textColor.gray,
        fontSize: 12,
        width: 75,
        padding: 0,
    },
    itemStatus: {
        marginTop: 10,
    },
    wrapper: {
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
    },
    listMissionHead: {
        backgroundColor: theme.palette.lightBlue.main,
        padding: '20px 0 40px',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        position: 'relative',
        zIndex: 1,
        height: 250,
    },
    btnWrap: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        margin: '0 10px 30px',
    },
    btnOk: {
        height: 25,
        fontSize: 12,
        padding: '0 10px',
        width: '40%',
        margin: 'auto',
        borderRadius: 6,
        textTransform: 'capitalize',
    },
    btnOkAlt: {
        height: 30,
        padding: '0 10px',
        width: 100,
        margin: '0 auto 30px',
        borderRadius: 6,
        textTransform: 'capitalize',
    },
    btnNo: {
        height: 25,
        fontSize: 12,
        padding: '0 10px',
        width: '40%',
        margin: 'auto',
        borderRadius: 6,
        borderColor: theme.palette.textColor.gray,
        color: theme.palette.textColor.gray,
        textTransform: 'capitalize',
    },
    confirmBody: {
        background: '#fff',
        position: 'relative',
        zIndex: 2,
        margin: '-150px auto 50px',
        minHeight: 400,
        padding: '10px 0',
        borderRadius: 8,
        '@media(min-width: 320px)': {
            width: '90%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    pageTop: {
        borderBottom: 'solid 1px' + theme.palette.textColor.gray6,
        paddingBottom: 20,
        marginBottom: 20,
    },
    confirmTitle: {
        fontSize: 24,
        color: theme.palette.textColor.darkGray,
        marginBottom: 5,
    },
    confirmSubtitle: {
        fontSize: 16,
        color: theme.palette.textColor.gray6,
        fontWeight: 700,
    },
    btnGogo: {
        color: 'white',
        fontWeight: 700,
        backgroundColor: theme.palette.orange.main,
        borderRadius: 6,
        width: 145,
        padding: 5,
        fontSize: 18,
        marginTop: 0,
        '&:hover': {
            backgroundColor: "#FFAA0C",
        },
    },
    primaryStyle: {
        fontSize: 12,
        color: theme.palette.textColor.gray,
    },
    secondaryStyle: {
        fontWeight: 700,
        color: theme.palette.textColor.gray8,
        position: 'relative',
    },
    secondaryStyleAlt: {
        fontWeight: 700,
        color: theme.palette.textColor.gray8,
        position: 'relative',
        paddingLeft: 18,
        '&::before': {
            content: '""',
            backgroundImage: 'url(https://pakbudi.s3-ap-southeast-1.amazonaws.com/images/icon-coin.png)',
            backgroundRepeat: 'no-repeat',
            width: 13,
            height: 13,
            position: 'absolute',
            top: 2,
            left: 0,
            backgroundSize: 'contain'
        }
    },
    blueBold: {
        fontWeight: 700,
        color: theme.palette.darkBlue.main,
    },
    btnListMisiCon: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 10,
    },
    btnListMisi: {
        color: '#fff',
        fontSize: 10,
        fontWeight: 500,
        textTransform: 'capitalize',
        backgroundColor: theme.palette.orange.main,
        width: 69,
        border: 'none',
        borderRadius: 3.33,
        padding: '3px 0',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: "#FFAA0C",
            border: 'none',
        },
    },
    btnDisabled: {
        color: '#fff !important',
    }
}))

export default useStyles;