export default (theme) => ({
  root: {
    flexGrow: 1,
    "@media(min-width: 500px)": {
      paddingBottom: 60,
    },
    // paddingBottom: 120,
  },
  newsWrap: {
    margin: "10px auto 30px",
    "@media(min-width: 320px)": {
      width: "95%",
    },
    "@media(min-width: 400px)": {
      width: "100%",
    },
    "@media(min-width: 500px)": {
      width: "94%",
    },
    "@media(min-width: 600px)": {
      width: "85%",
    },
    "@media(min-width: 800px)": {
      width: "70%",
    },
  },
  newsBody: {
    position: "relative",
    width: "100%",
  },
  newsInner: {
    backgroundColor: "#fff",
    borderRadius: 6,
    padding: "13px 13px 0",
  },
  newsCategory: {
    width: "100%",
    overflow: "hidden",
    marginBottom: 10,
  },
  categorySlide: {
    display: "flex",
    flexFlow: "row nowrap",
    overflow: "auto",
  },
  categoryLink: {
    width: 80,
    height: 50,
    margin: "0 10px",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  categoryBtn: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontSize: 14,
    lineHeight: "18px",
  },
  newsFeedDetail: {
    padding: 10,
    textAlign: "left",
    backgroundColor: "#fff",
    position: "relative"
  },
  publicHeaderLogo: {
    position: "relative",
    zIndex: 5,
    textAlign: "center",
    display: "inline-block",
    margin: "20px 0 0",
    cursor: "pointer",
    "& > img": {
      maxWidth: 200
    }
  },
  detailHead: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
  },
  shareBtn: {
    width: 40,
    height: 40,
    "@media(max-width: 425px)": {
      position: "absolute",
      top: 10,
      right: 10,
    },
  },
  newsCaption: {
    width: "100%",
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 2,
    textAlign: "left",
    backgroundImage:
      "linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1))",
    padding: 10,
  },
  newsHeadline: {
    color: "#fff",
    margin: "5px 0",
    textTransform: "capitalize",
  },
  headlineDate: {
    color: theme.palette.textColor.gray2,
    fontSize: 11,
  },
  newsThumb: {
    borderRadius: 4,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    flex: "0 0 90px",
    height: 0,
    paddingBottom: 90,
    "@media(max-width: 600px)": {
      flex: "0 0 120px",
      paddingBottom: 120,
    },
  },
  newsTitle: {
    color: theme.palette.primary.main,
    margin: "5px 0",
    textTransform: "capitalize",
  },
  dateTime: {
    color: theme.palette.textColor.gray,
    fontSize: 11,
  },
  banner: {
    width: "100%",
    marginTop: 15,
    marginBottom: 100,
  },
  btnBanner: {
    padding: 0,
  },
  bannerImg: {
    width: "100%",
    height: "auto",
  },
  disabledBtn: {
    color: theme.palette.primary.main + "!important",
    backgroundColor: "#fff !important",
  },
  listItemNews: {
    padding: 0,
    display: "flex",
    gap: 5
  },
  rootMain: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: theme.palette.textColor.gray4,
    overflow: "auto",
    position: "relative",
  },
  appBody: {
    position: "relative",
    zIndex: 1,
    width: "100%",
    margin: "auto",
  },
  blueBg: {
    width: "100%",
    height: 250,
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  payNewsContainer: {
    position: "absolute",
    width: "100%",
    top: 50,
    borderRadius: 10,
    padding: 8,
    backgroundColor: "rgba(75, 188, 236, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  icoCoin: {
    width: 20,
    height: 20,
    position: "relative",
    display: "inline-block",
  },
  imgCoin: {
    width: 20,
    height: 20,
    position: "absolute",
    left: 0,
    top: 5,
  },
  payNewsButton: {
    backgroundColor: "orange",
    textTransform: "capitalize",
    margin: "10px 0",
  },
  logoWrap: {
    position: "relative",
    width: 180,
    margin: "auto",
  },
  mainLogo: {
    marginTop: 5,
    width: 150,
  },
  pageName: {
    position: "absolute",
    color: theme.palette.textColor.white,
    fontSize: 12,
    top: -5,
    left: 56,
    textTransform: "capitalize",
    fontStyle: "italic",
  },
  actionButton: {
    textTransform: "capitalize",
    fontSize: 10,
    fontWeight: 600,
    color: "#777",
  },
});
