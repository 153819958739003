// React
import React from "react";

// Styles
import useStyles from "./styles";

// Input
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

//
import { baseURL } from "../../images/s3";

function DialogStatus({ open, handleOk, type, coin, message }) {
  // UseStyles
  const classes = useStyles();
  //
  let Txt;
  //
  switch (type) {
    case "success":
      Txt = `<p style='color:#1461A8;margin-bottom:5px;'><strong style='letter-spacing:1px;'>SELAMAT!</strong></p> Misi Kamu Berhasil, <br/> <strong> <img style='width:13px;height:auto;' src='https://pakbudi.s3-ap-southeast-1.amazonaws.com/images/icon-coin.png' alt='' /> ${coin} Coin </strong> <br/> Telah Kami Top Up`;
      break;
    case "rejected":
      Txt = `<b>MAAF!</b> <br/> Misi Kami Tolak Karena <br/> ${message}`;
      break;
    default:
      Txt = `Terimakasih, konfirmasi misi telah kami <br/>
            terima, dan akan dikonfirmasi dalam waktu <strong style='color: #1461A8;letter-spacing:1px;'>2x24 Jam</strong>`;
      break;
  }
  //
  return (
    <Dialog open={open}>
      <DialogTitle className={classes.DialogTitle}>
        <span className={classes.pageName}>Misi</span>
        <img
          className={classes.logoImg}
          src={`${baseURL}new-logo-w.png`}
          alt=""
        />
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Typography
          className={classes.desc}
          dangerouslySetInnerHTML={{ __html: Txt }}
        />
      </DialogContent>
      <Button
        onClick={handleOk}
        variant="contained"
        color="primary"
        className={classes.btnOkAlt}
      >
        Ok
      </Button>
    </Dialog>
  );
}

export default DialogStatus;
