// React
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//Custom Components
import * as serviceWorker from "../../serviceWorker";

// Styles
import "./styles/style.css";

import logo from "../../images/new-logo-w.png";
import poster from "../../images/Seminar-nasional-new.jpeg";
import iconFB from "../../images/sosmed-icon-fb.png";
import iconIG from "../../images/sosmed-icon-ig.png";
import iconTK from "../../images/sosmed-icon-tk.png";
import iconTW from "../../images/sosmed-icon-tw.png";
import iconYT from "../../images/sosmed-icon-yt.png";

function Seminar() {
  React.useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        // Add the following line to force the update
        registration.waiting.skipWaiting();
      },
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>PakBudi | Seminar</title>
        <meta
          name="description"
          content="Ikuti Seminar Nasional PakBudi Road to UTBK SNBT 2023! Berhadiah Beasiswa dan banyak hadiah lainnya!"
        />
        <meta
          property="og:title"
          content="Seminar Nasional Pakbudi Road to UTBK SNBT 2023"
        />
        <meta
          property="og:description"
          content="Ikuti Seminar Nasional PakBudi Road to UTBK SNBT 2023! Berhadiah Beasiswa dan banyak hadiah lainnya!"
        />
        <meta property="og:image" content={poster} />
        <meta property="og:type" content="event" />
        <meta property="og:url" content="https://pakbudi.id/seminar" />
        <meta property="og:start_time" content="2023-05-07T08:00:00" />
        <meta property="og:end_time" content="2023-06-27T08:00:00" />
        <meta property="og:location" content="Online" />
        <meta property="og:site_name" content="Pak Budi" />
      </Helmet>
      <div className="pb-seminar-wrapper">
        <div className="pb-page-container">
          <img
            src={poster}
            className="pb-seminar-poster"
            alt="Poster Seminar Nasional PakBudi Road to UTBK SNBT 2023"
          ></img>
          <h1>Pilih Seminar mu:</h1>
          <a
            target="_blank"
            href="https://wa.me/6285173133644?text=Mau%20daftar%20seminar%20nasional%20Day%201"
            className="pb-seminar-button"
            rel="noopener noreferrer"
          >
            Daftar Day One
          </a>
          <a
            target="_blank"
            href="https://wa.me/6285173133644?text=Mau%20daftar%20seminar%20nasional%20Day%202"
            className="pb-seminar-button"
            rel="noopener noreferrer"
          >
            Daftar Day Two
          </a>
          <a
            target="_blank"
            href="https://wa.me/6285173133644?text=Mau%20daftar%20seminar%20nasional%20Day%203"
            className="pb-seminar-button"
            rel="noopener noreferrer"
          >
            Daftar Day Three
          </a>
          <a
            target="_blank"
            href="https://wa.me/6285173133644?text=Mau%20daftar%20seminar%20nasional%20Day%204"
            className="pb-seminar-button"
            rel="noopener noreferrer"
          >
            Daftar Day Four
          </a>
          <a
            target="_blank"
            href="https://wa.me/6285173133644?text=Mau%20daftar%20seminar%20nasional%20SEMUANYA"
            className="pb-seminar-button"
            rel="noopener noreferrer"
          >
            Daftar Semua
          </a>
        </div>
        <div className="pb-seminar-footer-wrapper">
          <div className="pb-page-container">
            <div className="pb-sosmed-icon-container">
              <a
                target="_blank"
                href="https://facebook.com/people/Pakbudiapp/100085959276563/"
                rel="noopener noreferrer"
              >
                <img src={iconFB} alt="Facebook logo"></img>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/pakbudiapp/"
                rel="noopener noreferrer"
              >
                <img src={iconIG} alt="Instagram logo"></img>
              </a>
              <a
                target="_blank"
                href="https://www.tiktok.com/@pakbudiapp"
                rel="noopener noreferrer"
              >
                <img src={iconTK} alt="Tiktok logo"></img>
              </a>
              <a
                target="_blank"
                href="https://twitter.com/halo_pakbudi"
                rel="noopener noreferrer"
              >
                <img src={iconTW} alt="Twitter logo"></img>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/@pakbudiapp"
                rel="noopener noreferrer"
              >
                <img src={iconYT} alt="Youtube logo"></img>
              </a>
            </div>
            <h3>&copy; PakBudiApp 2023</h3>
            <img
              src={logo}
              className="pb-seminar-footer-image"
              alt="PakBudi logo"
            ></img>
            <h4>Tentang Kami</h4>
            <p>
              PakBudi Merupakan Aplikasi Konsultasi Pendidikan dan Karir Pertama
              di Indonesia yg dikelola oleh PT. Aku Orang Indonesia
            </p>
            <h4>PT. Aku Orang Indonesia</h4>
            <p>
              Perumahan Bale Prompong B3 RT.007 RW 04 Kutasari, Baturraden. Kab.
              Banyumas, Jawa Tengah, Indonesia
            </p>
            <a
              style={{ marginTop: 20 }}
              className="pb-footer-link"
              href="mailto:halo@pakbudi.id"
            >
              Email: <span>halo@pakbudi.id</span>
            </a>
            <p>
              Website:{" "}
              <Link to="/home" className="pb-footer-link">
                pakbudi.id
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Seminar;
