import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useCoin } from "../../components/CoinProvider";
import { useFirebase } from "../../components/FirebaseProvider";
import { useNotifikasi } from "../../components/NotifikasiProvider";
import { useSiswa } from "../../components/SiswaProvider";
import { delimiters } from "../../utils/formatter-rupiah";

// Stylesheet and images
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import coinImage from "../../images/icon-coin.png";
import headerLogo from "../../images/new-logo-c.png";
import placeholderProfile from "../../images/no-pic.svg";
import "../pbTheme.css";
import "./style.css";

function TopNav(props) {
  //user
  const { user } = useFirebase();
  const { profile } = useSiswa();
  const { Coin } = useCoin();
  const koin = Coin?.coin ? Coin.coin : 0;
  //Notification
  const { notifItems } = useNotifikasi();
  let unreadCount = null;
  if (notifItems) {
    const findunreadCount = notifItems.filter((item) => !item.read);
    if (findunreadCount) {
      unreadCount = findunreadCount.length;
    }
    if (unreadCount <= 0) {
      unreadCount = null;
    } else if (unreadCount > 10) {
      unreadCount = "10+";
    }
  }

  const handleBack = () => {
    props.history.goBack();
  };
  const handlePurchase = () => {
    props.history.push(`/myCoin`);
  };
  return (
    <>
      <div className="pb-navbar-wrapper">
        <div className="pb-navbar-container">
          <button
            className="pb-navbar-back-button hidden"
            onClick={handleBack}
          ></button>
          <div className="pb-navbar-logo-container">
            <img src={headerLogo} alt="header-logo"></img>
          </div>
          <div className="pb-navbar-button-container">
            <Link to="/notifikasi" className="pb-navbar-button">
              {unreadCount && (
                <span className="pb-navbar-notification-counter">
                  {unreadCount}
                </span>
              )}
              <NotificationsNoneOutlinedIcon />
            </Link>
            <Link to="/bagikan" className="pb-navbar-button">
              <div className="pb-navbar-profile-picture-container">
                <img
                  src={user?.photoURL ? user?.photoURL : placeholderProfile}
                  alt="user-avatar-container"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <span className="pb-navbar-statbar">
        <div className="pb-navbar-container">
          <h3 className="pb-navbar-greetings">
            Halo, {profile?.username ? profile?.username : "anak Pak Budi"}!
          </h3>
          <span className="pb-navbar-coin-container" onClick={handlePurchase}>
            {delimiters(koin)}
            <img src={coinImage} alt="Pakbudi Coin" />
          </span>
        </div>
      </span>
    </>
  );
}

export default withRouter(TopNav);
