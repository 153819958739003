import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppHeader from "../../components/AppHeader";
import { baseUrl } from "../../images/s3";
import useStyles from "./styles";
const FailedPayment = () => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("order_id");
  const orderArray = orderId.split("-");
  const orderIdentifier = orderArray[0];
  const history = useHistory();
  const [time, setTime] = useState(15);
  const timerRef = useRef(null);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      clearInterval(timerRef.current);
      history.replace("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <div className={classes.root}>
      <div className={classes.headerAfterPayment}>
        <AppHeader />
        {orderIdentifier === "PakBudi" ? (
          <img
            src={`${baseUrl}tmbLogo.png`}
            alt="tmbLogo"
            className={classes.logoInPayment}
          />
        ) : (
          <img
            src={`${baseUrl}logo_pakbudi_vertical.png`}
            alt="tmbLogo"
            className={classes.logoInPayment}
            style={{ top: 8 }}
          />
        )}
      </div>
      <div className={classes.messageBody}>
        <img
          src={`${baseUrl}failed.png`}
          alt="statusImage"
          className={classes.statusImage}
        />
        <Typography variant="body2" style={{ fontSize: "0.75rem" }}>
          Oops, maaf sekali pembayaranmu belum berhasil diproses. Kami mohon
          maaf atas ketidaknyamanannya. Silakan cek kembali informasi
          pembayaranmu atau hubungi pihak terkait untuk mendapatkan bantuan
          lebih lanjut. Jika kamu membutuhkan bantuan dari kami, jangan sungkan
          untuk menghubungi kami di Instagram{" "}
          <Typography
            variant="body2"
            style={{ fontSize: "0.75rem", color: "#4bbcec", display: "inline" }}
          >
            @pakbudiapp
          </Typography>{" "}
          ya! Terima kasih.
        </Typography>
        <Typography
          variant="body2"
          style={{ fontSize: "0.75rem", marginTop: 16 }}
        >
          Kamu akan diarahkan ke halaman tujuan dalam {time} detik.
        </Typography>
      </div>
      <Button
        className={classes.startBtn}
        variant="contained"
        color="secondary"
        component={Link}
        to="/home"
      >
        Kembali Ke Home
      </Button>
    </div>
  );
};

export default FailedPayment;
