import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    Out: {
        color: '#c0392b',
        fontWeight: 700,
    },
    listItemText:{
        color: '#c0392b',
        fontWeight: 700,
        fontSize: 14,
    },
    iconBtn:{
        color: theme.palette.textColor.gray8 + ' !important',
    },
    DialogTitle: {
        textAlign: 'center',
    },
    txt: {
        textAlign: 'center', 
        width: '70%', 
        margin: '0 auto'
    },
    btnOke: {
        padding: '10px', 
        marginTop: '20px', 
        borderTop: '1px solid #dedede', 
        color: '#38b1db', 
        borderBottom: '1px solid #dedede',
        textTransform: 'capitalize',
    },
    btnNo: {
        padding: '10px',
        textTransform: 'capitalize',
    },
    DialogTitle1: {
        fontSize: 18,
        textAlign: 'center'
    },
    txt1: {
        textAlign: 'center'
    },
    imgCoinwrap: {
        textAlign: 'center'
    },
    btnedit: {
        padding: '10px', 
        borderTop: '1px solid #dedede', 
        color: '#38b1db', 
        borderBottom: '1px solid #dedede',
        textTransform: 'capitalize',
    },
    btnCancel: {
        padding: '10px',
        textTransform: 'capitalize',
    },
    listIcon:{
        minWidth: 30,
    },
}));

export default useStyles;