import React from "react";
import { Grid, Typography } from "@material-ui/core";
import "./styles/style.css";

function HomeItem(props) {
  return (
    <Grid item xs={3} onClick={props.action} style={{ cursor: "pointer" }}>
      <div className="pb-home-item-wrapper">
        <img src={props.image} alt="home-action-icon" />
        <Typography
          variant="caption"
          style={{ marginTop: 8, fontWeight: 500, lineHeight: 1.3 }}
        >
          {props.label}
        </Typography>
      </div>
    </Grid>
  );
}

export default HomeItem;
