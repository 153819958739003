import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    btnVerify: {
        textTransform: 'capitalize'
    },
    waitDialogTitle:{
        color: '#fff',
        fontSize: 20,
        fontWeight: 700,
        backgroundColor: theme.palette.lightBlue.main,
        textAlign: 'center',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginBottom: 20,
    },
    timeoutTxt:{
        textAlign: 'center',
        fontSize: '17px',
        width: '85%',
        margin: '0 auto',
        color: theme.palette.textColor.gray8,
    },
    txt: {
        textAlign: 'center',
        fontSize: '14px',
        width: '80%',
        margin: '0 auto',
        color: '#777',
    },
    sub: {
        color: '#f39c12'
    },
    btnOke: {
        padding: '10px',
        marginTop: '20px',
        borderTop: '1px solid #dedede',
        borderBottom: '1px solid #dedede',
        textTransform: 'capitalize',
        color: theme.palette.secondary.main,
    },
    noticeImg:{
        display: 'block',
        margin: '10px auto 20px',
    },
    noticeImgAlt:{
        width: 50,
        height: 50,
        display: 'block',
        margin: '10px auto 20px',
    },
    btnNo: {
        width: 140,
        height: 30,
        padding: 0,
        textTransform: 'capitalize',
        color: theme.palette.red.main,
        fontSize: 10,
        fontWeight: 600,
        border: 'solid 1px ' + theme.palette.red.main,
        margin: '20px auto 40px',
        textAlign: 'center',
        borderRadius: 3,
    },
    waitingTime:{
        textAlign: 'center',
        borderTop: 'solid 1px ' + theme.palette.textColor.gray6,
        paddingTop: 20,
        width: '90%',
        margin: '15px auto',
        color: theme.palette.orange.main,
        fontWeight: 500,
    },
    endTxt:{
        color: theme.palette.textColor.gray8,
        fontSize: 20,
        fontWeight: 500,
        textAlign: 'center',
    },
    dialogActionEnd:{
        justifyContent: 'space-between',
        width: '100%',
        padding: '25px 25px',
    },
    btnDontEnd:{
        backgroundColor: theme.palette.textColor.gray6,
        color: '#fff',
        width: 112,
        height: 26,
        border: 'solid 1px ' + theme.palette.textColor.gray,
        textTransform: 'capitalize',
        fontSize: 10,
    },
    btnEndChat:{
        backgroundColor: '#fff',
        color:  theme.palette.lightBlue.main,
        width: 112,
        height: 26,
        border: 'solid 1px ' + theme.palette.lightBlue.main,
        textTransform: 'capitalize',
        fontSize: 10,
    },
    warningTimer:{
        width: 50,
        height: 50,
        display: 'block',
        margin: '25px auto 20px',
        color: theme.palette.orange.main,
    },
    dangerTimer:{
        width: 50,
        height: 50,
        display: 'block',
        margin: '25px auto 20px',
        color: theme.palette.red.main,
    },
    btnStop:{
        backgroundColor: '#fff',
        color: theme.palette.textColor.gray,
        width: 90,
        height: 26,
        border: 'solid 1px ' + theme.palette.textColor.gray,
        textTransform: 'capitalize',
        fontSize: 10,
    },
    btnExtend:{
        backgroundColor: theme.palette.lightBlue.main,
        color: '#fff',
        width: 135,
        height: 26,
        border: 'solid 1px ' + theme.palette.lightBlue.main,
        textTransform: 'capitalize',
        fontSize: 10,
    },
    toListDialogTitle:{
        color: '#fff',
        fontSize: 20,
        fontWeight: 700,
        backgroundColor: theme.palette.lightBlue.main,
        textAlign: 'center',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginBottom: 20,
    },
    toList:{
        '& > div:last-child':{
            borderBottom: 'none',
        }
    },
    toListRow:{
        marginBottom: 20,
        paddingBottom: 15,
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray6,
        minWidth: 250,
    },
    toBoxDesc:{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    toBoxDescCol:{
        width: '70%',
    },
    toName:{
        margin: '0 0 10px',
        color: theme.palette.lightBlue.main,
        fontSize: 16,
        fontWeight: 600,
    },
    toNameAlt:{
        margin: '0 0 10px',
        color: theme.palette.textColor.gray,
        fontSize: 16,
        fontWeight: 600,
    },
    toListCaption:{
        margin: 0,
        color: theme.palette.textColor.darkGray,
        fontSize: 10,
        fontWeight:700,
    },
    descLabel:{
        color: theme.palette.textColor.darkGray,
        fontSize: 10,
        fontWeight:500,
    },
    descValue:{
        color: theme.palette.textColor.darkGray,
        fontSize: 10,
        fontWeight:500,
    },
    shareToBtn:{
        textTransform: "capitalize",
        color: theme.palette.textColor.white,
        fontSize: 10,
        fontWeight: 600,
        backgroundColor: theme.palette.orange.main,
        width: 80,
        height: 26,
        padding: 0,
        "&:hover": {
        backgroundColor: theme.palette.lightBlue.main,
        },
        
    },
    goToBtn:{
        textTransform: "capitalize",
        color: theme.palette.textColor.white,
        fontSize: 10,
        fontWeight: 600,
        backgroundColor: theme.palette.lightBlue.main,
        width: 80,
        height: 26,
        padding: 0,
        "&:hover": {
        backgroundColor: theme.palette.darkBlue.main,
        },
        
    },
    btnBack:{
        backgroundColor: theme.palette.textColor.white,
        color: theme.palette.textColor.gray,
        width: 112,
        height: 26,
        border: 'solid 1px ' + theme.palette.textColor.gray,
        textTransform: 'capitalize',
        fontSize: 10,
        margin: 'auto',
    }
}));

export default useStyles;