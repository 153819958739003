import Button from "@material-ui/core/Button";
import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

// Styles
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import AppHeader from "../../components/AppHeader";
import { functions } from "../../components/FirebaseProvider";
import useStyles from "./styles/index";

export default function StartTO({ tryout }) {
  const classes = useStyles();
  const params = useParams();

  const history = useHistory();

  const [isStarting, setStarting] = React.useState(false);

  const handleStartTO = async (e) => {
    setStarting(true);
    try {
      const startTryout = functions.httpsCallable("startTryout");
      await startTryout({
        hasil_tryout_id: params.hasil_tryout_id,
        waktu: Date.now(),
      });
    } catch (e) {
      setStarting(false);
    }
  };

  return (
    <>
      {" "}
      <Helmet>
        <title>PakBudi | Tryout</title>
      </Helmet>
      <div className={classes.logoWrap2}>
        <AppHeader />
      </div>
      <div className={classes.startPage}>
        <div className={classes.contentWrapStart}>
          <div className={classes.headBg}></div>
          <div className={classes.contentWrapIn}>
            <h1 className={classes.welcomeMsg}>Instruksi Pengerjaan </h1>
            <p className={classes.ToTitle}>({tryout.judul}) </p>
            <div className={classes.contentText}>
              <p>
                Silahkan baca keterangan dibawah ini sebelum mulai mengerjakan :
              </p>
              <ol>
                <li>Pastikan baterai HP terisi penuh &amp; internet stabil</li>
                <li>
                  Siapkan kertas buram &amp; alat tulis untuk melakukan hitungan
                </li>
                <li>Tryout hanya dapat dikerjakan selama durasi berlangsung</li>
                <li>
                  Timer durasi pengerjaan akan tetap berjalan meskipun Tryout
                  di-minimize / di-close
                </li>
                <li>
                  Jawaban tidak dapat diubah setelah kamu menekan tombol
                  “Selesai” atau “Durasi Pengerjaan Habis
                </li>
                <li>Jawaban benar/salah akan muncul ketika Tryout berakhir</li>
              </ol>
            </div>
            <div className={classes.startBtnWrap}>
              <p className={classes.readyMsg}>Sudah paham dan siap memulai?</p>
              <div className={classes.btnGroup}>
                <Button
                  onClick={() => history.goBack()}
                  className={classes.goBack}
                  variant="contained"
                >
                  Kembali
                </Button>
                <Button
                  onClick={handleStartTO}
                  disabled={isStarting}
                  className={classes.startBtn}
                  color="primary"
                  variant="contained"
                >
                  Mulai
                </Button>
              </div>
              {isStarting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
