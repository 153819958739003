import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: 60,
    userSelect: "none",
    msUserSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
  },
  pageTitle: {
    color: theme.palette.textColor.darkGray,
    fontSize: 24,
    marginBottom: 10,
  },
  pageTitleAlt: {
    color: theme.palette.textColor.darkGray,
    fontSize: 24,
    marginBottom: 0,
  },
  coinBody: {
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 8,
    margin: "0 auto 50px",
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: 400,
    },
  },
  coinBody2: {
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 8,
  },
  coinBodyAlt: {
    backgroundColor: "#fff",
    borderRadius: 8,
    margin: "0 auto 50px",
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: 400,
    },
  },
  pageHeading: {
    padding: "5px 0",
  },
  coinContainer: {
    margin: "0px auto 50px",
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  btnListTransaksiCon: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  btnListTransaksi: {
    color: "#fff",
    fontSize: 12,
    textTransform: "capitalize",
    backgroundColor: theme.palette.darkBlue.main,
    border: "none",
    paddingTop: 3,
    paddingBottom: 3,
    "&:hover": {
      backgroundColor: "#115391",
    },
  },
  pageSubtitle: {
    color: theme.palette.textColor.gray,
    fontSize: 14,
    width: "94%",
    margin: "10px auto 20px",
  },
  pageSubtitle1: {
    color: theme.palette.textColor.gray,
    fontSize: 14,
    width: "65%",
    margin: "10px auto",
  },
  pageSubtitle2: {
    color: theme.palette.textColor.gray,
    fontSize: 14,
    marginTop: 0,
    margin: "10px",
  },
  pageSubtitle3: {
    color: theme.palette.textColor.gray,
    fontSize: 10,
    fontWeight: 400,
  },
  pageSubtitle4: {
    color: theme.palette.textColor.darkGray,
    fontSize: 10,
    fontWeight: 500,
  },
  pageSubtitle5: {
    color: theme.palette.textColor.gray,
    fontSize: 10,
    fontWeight: 400,
    margin: "0",
  },
  pageSubtitle6: {
    color: theme.palette.textColor.darkGray,
    fontSize: 10,
    fontWeight: 500,
  },
  pageSubtitle7: {
    color: theme.palette.textColor.gray8,
    fontSize: 12,
    fontWeight: 500,
    margin: "0 0 7px",
    display: "flex",
    justifyContent: "space-between",
  },
  pageSubtitle8: {
    color: theme.palette.textColor.gray,
    fontSize: 14,
    fontWeight: 500,
    margin: "10px 0",
  },
  konfirmasiTxt: {
    width: 140,
  },
  konfirmasiTxtValue: {
    width: "calc(100% - 140px)",
    textAlign: "left",
  },
  sub1: {
    color: theme.palette.textColor.gray,
    fontSize: 16,
    width: "100%",
    margin: "0 auto",
  },
  sub2: {
    color: theme.palette.textColor.gray,
    fontSize: 14,
    width: "70%",
    margin: "15px auto",
  },
  sub3: {
    color: theme.palette.textColor.gray,
    fontSize: 16,
    width: "70%",
    margin: "0 auto",
    textDecoration: "underline",
  },
  sub4: {
    fontSize: 10,
    width: "70%",
    margin: "10px auto",
    color: theme.palette.textColor.gray,
  },
  sub5: {
    color: theme.palette.textColor.gray,
    fontSize: 14,
    width: "80%",
    margin: "0 auto",
  },
  sub6: {
    width: "60%",
    margin: "15px auto 20px",
    color: theme.palette.textColor.gray,
    fontSize: 14,
  },
  total: {
    width: "70%",
    margin: "10px auto 15px",
    color: theme.palette.lightBlue.main,
    fontSize: 17,
    fontWeight: 700,
  },
  confirmMsg: {
    color: theme.palette.textColor.darkGray,
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 20,
    borderBottom: "solid 1px " + theme.palette.textColor.gray6,
    margin: "10px 0 20px",
  },
  sub: {
    width: "75%",
    margin: "20px auto",
    marginBottom: -10,
    color: "#30bcec",
    fontSize: 14,
    fontWeight: "bold",
  },
  Link: {
    textTransform: "capitalize",
    color: theme.palette.lightBlue.main,
    marginLeft: -10,
  },
  border: {
    border: "1px solid gray",
    width: 200,
    margin: "10px auto",
  },
  textRp: {
    color: theme.palette.darkBlue.main,
    marginTop: 0,
    width: "65%",
    margin: "auto",
    fontSize: 17,
    fontWeight: 700,
  },
  trfMethod: {
    borderTop: "solid 1px " + theme.palette.textColor.gray6,
    marginTop: 15,
    padding: "20px 0",
  },
  trfMethodTitle: {
    margin: "0 0 15px",
  },
  statsTitle: {
    color: theme.palette.darkBlue.main,
    fontSize: 21,
    margin: 0,
  },
  coinCountWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 60,
  },
  coinCount: {
    color: theme.palette.darkBlue.main,
    fontWeight: 700,
    fontSize: 32,
  },
  imgCoin: {
    width: 45,
    height: "auto",
    marginRight: 7,
  },
  valueCounter: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "baseline",
    width: "170px",
    margin: "-10px auto",
  },
  InputProps: {
    textAlign: "center",
    height: 38,
  },
  inputProps: {
    textAlign: "center",
    color: "#7f8c8d",
    fontSize: 16,
    fontWeight: "bold",
  },
  txtField: {
    backgroundColor: "#dfe4ea",
    borderRadius: 8,
    height: 38,
  },
  counterBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",

    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  counterBtnLeft: {
    borderRadius: "8px 0 0 8px",
  },
  counterBtnRight: {
    borderRadius: " 0 8px 8px 0",
  },
  counterItem: {
    width: 15,
    height: 15,
  },
  coinValue: {
    width: 70,
    margin: "0 10px",
  },
  addCoin: {
    marginTop: -10,
  },
  addCoinLabel: {
    color: theme.palette.lightBlue.main,
    fontSize: 16,
  },
  moneyValue: {
    color: theme.palette.textColor.gray6,
    marginTop: 20,
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 20,
  },
  noCoinMsg: {
    fontWeight: "normal",
  },
  actionBtnWrap: {
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
    margin: "30px auto 0",
    "@media(max-width: 400px)": {
      width: "94%",
    },
  },
  isiBtn: {
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  contBtn: {
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  pageHeader: {
    borderBottom: "dashed 1px #ddd",
    paddingBottom: 20,
    width: "90%",
    margin: "0 auto 30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  coinThumb: {
    display: "block",
    marginRight: 10,
  },
  pageBody: {
    marginTop: 30,
    position: "relative",
  },
  listBody: {
    background: "#fff",
    position: "relative",
    zIndex: 2,
    margin: "0 auto 50px",
    minHeight: 400,
    padding: 10,
    borderRadius: 8,
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  boxedLink: {
    border: "solid 1px blue",
    padding: 3,
    display: "inline-block",
    marginBottom: 5,
  },
  hyperlink: {
    color: "navy",
    textDecoration: "none",
    fontWeight: "bold",
  },
  list: {
    width: "72%",
    margin: "0 auto 30px",
    display: "block",
    padding: 0,
    counterReset: "count-me",
    listStyleType: "none",
    position: "relative",
    zIndex: 2,
  },
  listItem: {
    borderBottom: "solid 1px #ddd",
    padding: "10px 0",
    marginLeft: 10,
    textAlign: "left",
    fontSize: 13,
    counterIncrement: "count-me",
    position: "relative",
    zIndex: 3,
    "&:before": {
      content: "counter(count-me)",
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
      borderRadius: "100%",
      padding: "4px 8px",
      position: "absolute",
      left: "-35px",
      fontWeight: "bold",
    },
  },
  verticalLine: {
    width: 3,
    position: "absolute",
    left: "-14px",
    right: "auto",
    bottom: 0,
    margin: "auto",
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
    "@media(max-width: 3000px)": {
      height: "89%",
      top: "-12px",
    },
    "@media(max-width: 600px)": {
      height: "85%",
      top: "-19px",
    },
  },
  kodeVoucher: {
    width: 210,
  },
  btnKirim: {
    margin: "20px auto",
    display: "block",
    padding: "5px 25px",
    borderRadius: 25,
  },
  actionBtn: {
    padding: "5px 25px",
    borderRadius: 25,
  },
  choicesWrap: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    margin: "auto",
    "@media(max-width: 1400px)": {
      width: "50%",
    },
    "@media(max-width: 700px)": {
      width: "65%",
    },
    "@media(max-width: 600px)": {
      width: "60%",
    },
    "@media(max-width: 500px)": {
      width: "70%",
    },
    "@media(max-width: 400px)": {
      width: "80%",
    },
  },
  choiceBlock: {
    width: "35%",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
  },
  labelle: {
    fontSize: 11,
  },
  choiceImg: {
    border: "solid 3px" + theme.palette.textColor.gray2,
    padding: 10,
    borderRadius: 6,
    width: 100,
    height: "auto",
  },
  choiceImg1: {
    border: "solid 3px" + theme.palette.textColor.gray2,
    padding: 10,
    borderRadius: 6,
    width: 100,
    height: "auto",
    marginTop: 10,
  },
  choiceImgChecked: {
    border: "solid 3px" + theme.palette.primary.main,
    padding: 10,
    borderRadius: 6,
    width: 100,
    height: "auto",
  },

  anotherPayment: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    backgroundColor: "#ebebeb",
    margin: "20px auto 10px",
    padding: 10,
    "@media(min-width: 320px)": {
      flexDirection: "column",
      marginBottom: 10,
      width: "100%",
    },
    "@media(min-width: 768px)": {
      flexDirection: "row",
      width: 330,
    },
  },
  imgPayment: {
    width: "auto",
    height: 35,
  },
  img: {
    width: 90,
    height: 50,
  },
  nohp: {
    paddingLeft: 10,
    fontSize: 15,
    "@media(min-width: 320px)": {
      textAlign: "center",
    },
    "@media(min-width: 768px)": {
      textAlign: "left",
    },
  },
  pemilik: {
    marginTop: -15,
    fontSize: 15,
    paddingLeft: 10,
    "@media(min-width: 320px)": {
      textAlign: "center",
    },
    "@media(min-width: 768px)": {
      textAlign: "left",
    },
  },
  nama: {
    fontWeight: "bold",
  },
  tableWrap: {
    marginBottom: 30,
  },
  tableTitle: {
    fontWeight: 700,
    color: theme.palette.textColor.gray8,
    textAlign: "left",
    paddingLeft: 10,
    "@media(min-width: 960px)": {
      width: 400,
      margin: "0 auto 10px",
    },
  },
  tableCell: {
    color: theme.palette.primary.main,
    fontSize: 11,
    width: 65,
    margin: "10px auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCellCoin: {
    color: theme.palette.primary.main,
    fontSize: 11,
    width: 40,
    margin: "10px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCell1: {
    color: theme.palette.primary.main,
    fontSize: 11,
    width: 96,
    margin: "10px 0",
  },
  TableHead: {
    margin: "auto",
    display: "flex",
    justifyContent: "space-around",
    "@media(min-width: 320px)": {
      width: "100%",
    },
    "@media(min-width: 960px)": {
      width: 400,
    },
  },
  tableCellTopID: {
    color: theme.palette.primary.main,
    fontSize: 12,
    width: "40%",
    // margin: '10px auto',
    margin: "10px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
  },
  tableCellTopDate: {
    color: theme.palette.primary.main,
    fontSize: 14,
    width: "30%",
    // margin: '10px auto',
    margin: "10px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
  },
  tableCellTopCoin: {
    color: theme.palette.primary.main,
    fontSize: 14,
    width: "30%",
    // margin: '10px auto',
    margin: "10px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
  },
  TableBody: {
    margin: "auto",
    "@media(min-width: 320px)": {
      width: "100%",
    },
    "@media(min-width: 960px)": {
      width: 400,
    },
  },
  TableRow: {
    display: "flex",
    margin: "auto",
    "@media(min-width: 320px)": {
      width: "100%",
      justifyContent: "flex-start",
    },
    "@media(min-width: 360px)": {
      justifyContent: "space-between",
    },
    "@media(min-width: 412px)": {
      width: "100%",
    },
    "@media(min-width: 480px)": {
      width: "94%",
    },
    padding: "5px 0",
    borderBottom: "solid 1px" + theme.palette.textColor.gray6,
  },
  TRow: {
    color: theme.palette.textColor.gray,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(min-width: 360px)": {
      fontSize: 12,
      marginLeft: 10,
    },
  },
  TRowID: {
    width: "35%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  TRowCoin: {
    color: theme.palette.textColor.gray,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(min-width: 320px)": {
      fontSize: 13,
      width: 40,
    },
    "@media(min-width: 360px)": {
      fontSize: 12,
      width: 75,
      marginLeft: 10,
    },
  },
  TRowCoin2: {
    textAlign: "center",
    "@media(min-width: 320px)": {
      fontSize: 12,
      width: "25%",
    },
    "@media(min-width: 360px)": {
      fontSize: 13,
    },
  },
  tRowDate: {
    textAlign: "center",
  },
  statusFalse: {
    color: theme.palette.orange.main,
    fontSize: 12,
    width: 85,
  },
  statusTrue: {
    color: theme.palette.textColor.gray,
    fontSize: 12,
    width: 85,
  },
  wrapper: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadForm: {
    position: "relative",
    width: "100%",
  },
  imgKlik: {
    position: "absolute",
    right: 25,
    bottom: 0,
    width: 34,
    height: "auto",
  },
  thumbPLayout: {
    width: 240,
    height: 240,
    borderRadius: 10,
    borderStyle: "dashed",
    border: "1.4px solid" + theme.palette.lightBlue.main,
    margin: "auto",
    position: "relative",
  },
  btnroot: {
    minWidth: 0,
  },
  btnClose: {
    position: "absolute",
    zIndex: 2,
    top: 5,
    right: 15,
    width: 20,
    height: 20,
  },
  iconClose: {
    fontSize: 35,
  },
  layer: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.4)",
    position: "absolute",
    zIndex: 1,
    borderRadius: 5,
  },
  gridCaption: {
    width: "100%",
    height: 50,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "1rem",
  },
  thumbPreview: {
    width: 237,
    height: 237,
    borderRadius: 5,
    margin: "auto",
  },
  promoWrap: {
    maxWidth: 500,
    margin: "auto",
  },
  promoWrapTitle: {
    color: theme.palette.darkBlue.main,
    fontSize: 20,
    fontWeight: 700,
    textAlign: "center",
    textTransform: "uppercase",
  },
  dividerpromo: {
    borderTop: "solid 1px #ddd",
    marginTop: 20,
  },
  promoRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  promoTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > img": {
      width: 15,
      height: 15,
      marginRight: 5,
    },
    "& > span": {
      fontSize: 12,
      fontWeight: 600,
    },
  },
  promoBtn: {
    textTransform: "capitalize",
    color: "#FFFF",
    backgroundColor: theme.palette.lightBlue.main,
    width: 125,
    height: 20,
    fontSize: 12,
    "&:active": {
      backgroundColor: "mediumseagreen",
    },
    "&:hover": {
      backgroundColor: "mediumseagreen",
    },
  },
  voucherBtn: {
    color: theme.palette.textColor.darkGray,
    fontWeight: 600,
    fontSize: 16,
    textTransform: "capitalize",
    "@media(min-width: 320px)": {
      marginBottom: 10,
      width: "100%",
    },
    "@media(min-width: 960px)": {
      width: 235,
    },
  },
  blueText: {
    marginLeft: 5,
    color: theme.palette.lightBlue.main,
  },
  labelBtn: {
    display: "flex",
    justifyContent: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
  itemStatus: {
    marginTop: 10,
  },
  checkoutForm: {
    marginBottom: 20,
  },
  konfirmasiWrap: {
    width: 250,
    margin: "20px auto",
    textAlign: "left",
  },
  btnOke: {
    marginTop: 30,
    marginBottom: 50,
    width: 153,
    height: 32,
    textTransform: "capitalize",
    fontSize: 12,
    fontWeight: 700,
  },
  btnDialog: {
    padding: 0,
    width: 112,
    height: 26,
    backgroundColor: theme.palette.lightBlue.main,
    color: "#fff",
    fontSize: 13,
    fontWeight: 600,
    margin: "0 auto 30px",
  },
  DialogTitle: {
    fontSize: 18,
    textAlign: "center",
    backgroundColor: theme.palette.lightBlue.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: "relative",
    paddingTop: 24,
  },
  pageName: {
    position: "absolute",
    top: 15,
    left: 109,
    color: "#fff",
    fontSize: 12,
    fontStyle: "italic",
  },
  logoImg: {
    width: 160,
    height: "auto",
  },
  dialogContent: {
    margin: "20px auto 15px",
    fontSize: 12,
    textAlign: "center",
    "& > strong": {
      color: theme.palette.darkBlue.main,
      fontWeight: 700,
    },
  },
  historyBack: {
    position: "absolute",
    left: 0,
    top: 25,
    color: "#fff",
  },
  voucherWrap: {
    marginTop: 10,
  },
  priceBox: {
    margin: "15px auto",

    borderTop: "solid 1px " + theme.palette.textColor.gray6,
    paddingTop: 20,
    "@media(min-width: 320px)": {
      width: "80%",
    },
    "@media(min-width: 960px)": {
      width: "fit-content",
    },
  },
  priceRow: {
    margin: 0,
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
  },
  labelPrice: {
    width: 140,
  },
  CoinPrice: {
    display: "inline-block",
    marginLeft: 25,
    color: theme.palette.textColor.darkGray,
    fontWeight: 500,
  },
  convPrice: {
    display: "inline-block",
    marginLeft: 25,
    color: theme.palette.lightBlue.main,
    fontWeight: 500,
  },
  noRek: {
    backgroundColor: "#F2F4F3",
    margin: "20px auto",
    width: "80%",
    height: 33,
    borderRadius: 3,
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(min-width: 960px)": {
      width: 220,
    },
  },
  btnCopyLabel: {
    display: "block",
    padding: "3px 0 0",
    fontSize: 17,
    fontWeight: 700,
    color: theme.palette.darkBlue.main,
    textAlign: "center",
    width: "calc(100% - 79px)",
    borderRadius: 3,
  },
  btnCopy: {
    width: 79,
    height: 29,
    fontSize: 13,
    fontWeight: 600,
    padding: "6px 10px",
    display: "flex",
    borderRadius: 3,
    alignItems: "center",
  },
  iconCopy: {
    width: 13,
    height: 13,
    marginRight: 5,
  },
  noTrx: {
    color: theme.palette.lightBlue.main,
    fontSize: 10,
  },
  imageIcon: {
    width: "auto",
    fontSize: "4.75rem",
  },
  appliedVoucher: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "100%",
    padding: 0,
    textTransform: "capitalize",
  },
  voucherIconSmall: {
    width: 14,
    height: 14,
    "& > img": {
      width: "100%",
      height: "auto",
    },
  },
  appliedVoucherDesc: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "calc(100% - 25px)",
  },
  appliedVoucherLabel: {
    color: theme.palette.textColor.gray6,
    fontSize: 17,
    fontWeight: 600,
  },
  appliedVoucherName: {
    color: theme.palette.lightBlue.main,
    fontSize: 17,
    fontWeight: 600,
    textAlign: "right",
  },
  chevronRight: {
    color: theme.palette.textColor.gray6,
    fontSize: 20,
    paddingTop: 6,
    width: 20,
  },
}));

export default useStyles;
