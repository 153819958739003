import React, { useEffect, useState } from "react";

// material-ui components
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";

import html2canvas from "html2canvas";
import { useHistory } from "react-router-dom";

// Styles
import useStyles from "./styles/index";

import { useSnackbar } from "notistack";
import { useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";
import { useTo } from "../../components/TryoutProvider";
import results from "../../pages/tesMinatBakat/game/data/results.json";
import { dataURItoFile } from "../../utils/converter";
import share from "../../utils/share";

// Component
import noPic from "../../images/no-pic.svg";

function searchTMB(inp) {
  let out;
  if (inp === "E") {
    out = "Extrovert";
  }
  if (inp === "I") {
    out = "Introvert";
  }
  if (inp === "N") {
    out = "Intuition";
  }
  if (inp === "S") {
    out = "Sensing";
  }
  if (inp === "T") {
    out = "Thinking";
  }
  if (inp === "F") {
    out = "Feeling";
  }
  if (inp === "P") {
    out = "Perception";
  }
  if (inp === "J") {
    out = "Judgement";
  }

  return out;
}

function Bagikan() {
  //
  const history = useHistory();
  //
  const classes = useStyles();
  //
  const { profile, shareRaport } = useSiswa();
  const { user } = useFirebase();
  const { hasiltryout, tryout } = useTo();
  //
  const { enqueueSnackbar } = useSnackbar();
  //
  const tmbSplit = profile?.hasil_tesMinatBakat?.split("");
  //
  const resultTMB = profile?.hasil_tesMinatBakat
    ? results[profile.hasil_tesMinatBakat]
    : "";
  //
  const [listTo, setListTo] = useState([]);
  //
  useEffect(() => {
    if (tryout) {
      const toDoc =
        hasiltryout.sort((a, b) => {
          return b.created_at.toMillis() - a.created_at.toMillis();
        }) || [];
      setListTo(
        tryout.map((doc) => {
          const to = toDoc.find((to) => to.tryout.id === doc.id);
          return {
            hasiltryout: to,
            ...doc,
          };
        })
      );
    }
  }, [tryout, hasiltryout]);
  //
  const raport = {
    data: `
            ${profile?.nama}
            ${profile?.sekolah?.nama}
            ${profile?.kampus ? profile.kampus?.nama : ""}
            ${profile?.jurusan ? profile.jurusan?.nama : ""}
            \b
            ${
              resultTMB &&
              `
            Tes Minat Bakat
            Kepribadian: ${profile?.hasil_tesMinatBakat}
            Keahlian: ${resultTMB.short}
            Kemampuan: ${resultTMB.kemampuan}
            `
            }
            ${listTo.map((item) => {
              return (
                item.hasiltryout &&
                item.hasiltryout.status === "done" &&
                `
                    \b
                    ${item.hasiltryout.tryout.nama}
            Hasil Jawaban Benar
            ${Object.entries(item.hasiltryout.hasil).map(([key, values]) => {
              return `${values.nama}: ${values.benar}
            `;
            })}
            `
              );
            })}
        `,
  };
  //
  const shareBody = async (e) => {
    try {
      const canvas = await html2canvas(document.body, {
        allowTaint: false,
        useCORS: true,
        windowHeight: 900,
        windowWidth: 450,
      });
      const file = dataURItoFile(canvas.toDataURL());
      const shareData = {
        files: [file],
        title: "PakBudi.id",
        text: "Ini hasil Tes Minat Bakatku\nkamu mana?\nTes 5 menit aja gratis klik:\nhttps://tesminatbakat.pakbudi.id",
      };
      await share(shareData);
      //  console.log(response)
    } catch (e) {
      enqueueSnackbar(e.message);
    }
  };

  const handleSettings = () => {
    history.push("/settings");
  };
  return (
    <>
      <Grid
        className={classes.page}
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          className={classes.whiteBox}
        >
          <div>
            <div className={classes.btnListSettings}>
              <BottomNavigationAction
                showLabel
                classes={{ label: classes.label }}
                label="Setelan"
                icon={<SettingsOutlinedIcon />}
                onClick={handleSettings}
              />
            </div>
            <div className={classes.avatarCon}>
              <img
                alt=""
                src={user?.photoURL ? user?.photoURL : noPic}
                crossOrigin="anonymous"
                className={classes.avatarImg}
              />
            </div>
          </div>
          <Typography className={classes.name}>{profile?.nama}</Typography>
          <Typography className={classes.txt1}>
            {profile?.sekolah?.nama}
          </Typography>
          {profile.kampus && (
            <Typography className={classes.txt1}>
              {profile?.kampus?.nama}
            </Typography>
          )}
          {profile.jurusan && (
            <Typography className={classes.txt1}>
              {profile?.jurusan?.nama}
            </Typography>
          )}
        </Grid>
        {resultTMB && (
          <Grid
            item
            xs={12}
            container
            direction="column"
            className={classes.whiteBox}
          >
            <Typography
              className={`${classes.boxTitle} ${classes.centerTmb}`}
              color="primary"
            >
              Tes Minat Bakat
            </Typography>
            <Typography className={classes.tmbRes1}>
              {profile?.hasil_tesMinatBakat}
            </Typography>
            <Typography className={classes.tmbRes2}>
              {profile?.hasil_tesMinatBakat &&
                `${searchTMB(tmbSplit[0])}, ${searchTMB(
                  tmbSplit[1]
                )}, ${searchTMB(tmbSplit[2])}, & ${searchTMB(tmbSplit[3])}`}
            </Typography>
            <Typography className={classes.tmbRes3}>
              {resultTMB?.short}
            </Typography>
            <Typography className={classes.tmbRes3}>
              {resultTMB?.kemampuan}
            </Typography>
            <br />
            <Typography className={classes.tmbRes4}>
              Tokoh dengan kepribadian yang sama
            </Typography>
            <Typography className={classes.tmbRes3}>
              {resultTMB?.tokoh}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="flex-start"
          direction="column"
          className={classes.whiteBox}
        >
          <Typography className={classes.txt3}>
            Jangan sampai salah pilih jurusan, cari tahu bakatmu di{" "}
            <Link href="https://pakbudi.id/">www.pakbudi.id</Link>. <br />
            <br /> Tryout dan Chat konsultasi langsung sama kakak kelas
            mahasiswa, motivator pendidikan, guru BK, dosen hanya di PakBudi App
          </Typography>
        </Grid>
        {listTo.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              {item.hasiltryout && item.hasiltryout.status === "done" ? (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="flex-start"
                  direction="column"
                  className={classes.whiteBox}
                  data-html2canvas-ignore
                >
                  <Typography className={classes.boxTitle} color="primary">
                    {item.hasiltryout.tryout.nama}
                  </Typography>
                  <Typography className={classes.txt2}>
                    Hasil Jawaban Benar
                  </Typography>
                  {Object.entries(item.hasiltryout.hasil).map(
                    ([key, values]) => {
                      return (
                        <div className={classes.liquidRow} key={key}>
                          <Typography className={classes.liquidLabel2}>
                            {values.nama}
                          </Typography>
                          <Typography className={classes.liquidValue}>
                            <span className={classes.mr5}>:</span>{" "}
                            <span>{values.benar}</span>
                          </Typography>
                        </div>
                      );
                    }
                  )}
                </Grid>
              ) : null}
            </React.Fragment>
          );
        })}
        <Grid item xs={12} className={classes.clrBox} data-html2canvas-ignore>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            className={classes.clrBtn}
            onClick={() => {
              shareRaport(raport.data.replace(/,/g, ""));
              history.push("/konsultasi/semua");
            }}
          >
            Konsultasi
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.clrBtn}
            onClick={shareBody}
          >
            Bagikan
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default Bagikan;
