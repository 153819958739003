// React
import React, { useState } from "react";
// Styles
import useStyles from "./styles";
// Component
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import uniqid from "uniqid";
import AppHeader from "../../components/AppHeader";
import { baseURL } from "../../images/s3";
// Router
import { useParams } from "react-router-dom";
// Firebase
import { firestore, useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";

import { currency } from "../../utils/formatter-rupiah";

function IsiCoin(props) {
  // Styles
  const classes = useStyles();
  // Firebase
  const { user } = useFirebase();
  const { profile } = useSiswa();
  // Router
  const params = useParams();
  // State
  const [payment, setPayment] = useState("");
  const [submit, setSubmit] = useState(true);
  // Functions
  const handleChange = (e) => {
    if (e.target.value !== "") {
      setSubmit(false);
    } else {
      setSubmit(true);
    }

    setPayment(e.target.value);
  };
  //
  const handleClick = async () => {
    const noTransaksi = uniqid();
    const docId = uniqid();
    try {
      await firestore.doc(`transaksi/${docId}`).set(
        {
          user_id: user?.uid,
          nama: profile?.nama ?? "",
          username: profile?.username ?? "",
          no_hp: profile?.no_hp ?? "",
          email: profile?.email ?? "",
          no_transaksi: `${payment}${noTransaksi}`,
          jumlah_coin: params.coin,
          status: "konfirmasi",
          tanggal: Date.now(),
          jumlah_transfer: `${currency(params.total)}`,
          doc_id: docId,
        },
        { merge: true }
      );
    } catch (err) {
      console.log(err.message);
    }
    return props.history.push(
      `/rinciantransfer/${params.total}/${payment}/${docId}/${
        payment + noTransaksi
      }`
    );
  };

  console.log(profile);

  //
  return (
    <div className={classes.root}>
      <AppHeader />
      <div style={{ marginBottom: 100 }} className={classes.coinBodyAlt}>
        <div className={classes.pageHeading}>
          <h2 className={classes.pageTitle}>Pembayaran Coin</h2>
          <p className={classes.pageSubtitle3}>
            Pilih metode pembayaran yang kamu anggap mudah
          </p>
          <p className={classes.pageSubtitle4}>
            Total tagihan yang harus kamu bayar adalah senilai :
          </p>
          <p className={classes.textRp}>{currency(params.total)} </p>
        </div>
        <Container className={classes.trfMethod} maxWidth="sm">
          <h4 className={classes.trfMethodTitle}>Pilih Metode Transfer</h4>
          <form className={classes.checkoutForm}>
            <FormGroup aria-label="position" name="position" row>
              <RadioGroup aria-label="position" name="position" row>
                <div className={classes.choicesWrap}>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          onChange={handleChange}
                          icon={
                            <img
                              src={`${baseURL}img-payment-ovo.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-ovo.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          value="OVO"
                        />
                      }
                      label="Transfer Saldo OVO"
                      labelPlacement="bottom"
                    />
                  </div>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          onChange={handleChange}
                          icon={
                            <img
                              src={`${baseURL}img-payment-gopay.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-gopay.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          value="GPY"
                        />
                      }
                      label="Transfer Saldo GOPAY"
                      labelPlacement="bottom"
                    />
                  </div>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          onChange={handleChange}
                          icon={
                            <img
                              src={`${baseURL}img-payment-bsi.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-bsi.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          value="BSI"
                        />
                      }
                      label="Transfer Bank BSI"
                      labelPlacement="bottom"
                    />
                  </div>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          onChange={handleChange}
                          icon={
                            <img
                              src={`${baseURL}img-payment-mandiri.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-mandiri.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          value="MDR"
                        />
                      }
                      label="Transfer Bank Mandiri"
                      labelPlacement="bottom"
                    />
                  </div>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          onChange={handleChange}
                          icon={
                            <img
                              src={`${baseURL}img-payment-bri.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-bri.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          value="BRI"
                        />
                      }
                      label="Transfer Bank BRI"
                      labelPlacement="bottom"
                    />
                  </div>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          onChange={handleChange}
                          icon={
                            <img
                              src={`${baseURL}img-payment-bni.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-bni.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          value="BNI"
                        />
                      }
                      label="Transfer Bank BNI"
                      labelPlacement="bottom"
                    />
                  </div>
                </div>
              </RadioGroup>
            </FormGroup>
            <Button
              style={{
                marginTop: 20,
                width: 94,
                height: 28,
                padding: 0,
                fontSize: 12,
                fontWeight: 700,
                borderRadius: 3,
              }}
              variant="contained"
              color="primary"
              onClick={handleClick}
              disabled={submit}
              size="large"
            >
              Check Out
            </Button>
          </form>
        </Container>
      </div>
    </div>
  );
}

export default IsiCoin;
