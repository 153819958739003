import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import notice from "../../images/notice.svg";
import useStyles from "./styles/index";

import { useParams } from "react-router-dom";
import { functions } from "../../components/FirebaseProvider";
import useTimer from "../../components/useTimer";

export default function ConfirmEnd({
  open,
  handleClose,
  orderIds,
  jawaban,
  to,
  hasilTo,
}) {
  const params = useParams();
  const classes = useStyles();
  const [isEnding, setEnding] = useState(false);
  //
  const [milisecondstart] = useState(hasilTo.started_at);
  const [milisecondend] = useState(Date.now());
  const { countDownStr, countDownSeconds, stopTimer } = useTimer(
    milisecondstart,
    milisecondend,
    to.waktu
  );
  //
  useEffect(() => {
    if (countDownSeconds <= 0) {
      stopTimer();
    }
  }, [countDownSeconds, stopTimer]);
  //
  const handleEndTO = async (e) => {
    setEnding(true);
    try {
      const endTryout = functions.httpsCallable("endTryout");
      await endTryout({
        hasil_tryout_id: params.hasil_tryout_id,
        waktu: Date.now(),
      });
    } catch (e) {
      setEnding(false);
      console.log(e.message);
    }
  };

  return (
    <Dialog
      disableBackdropClick={isEnding}
      disableEscapeKeyDown={isEnding}
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="end-session"
      aria-describedby="end-session-dialog"
    >
      <DialogTitle className={classes.endTitle} id="end-session-dialog-title">
        {"Sudah selesai?"}
        <Button
          style={{
            position: "absolute",
            top: 10,
            right: -16,
            width: 15,
            height: 10,
          }}
          onClick={handleClose}
        >
          <CloseIcon style={{ color: "#fff" }} />
        </Button>
      </DialogTitle>
      <DialogContent>
        <div className={classes.contentBox}>
          <div className={classes.contentRow}>
            <span className={classes.contentLabel}>Nama Ujian: </span>
            <span className={classes.contentValue}>{to.judul}</span>
          </div>
          <div className={classes.contentRow}>
            <span className={classes.contentLabel}>Soal Terjawab: </span>
            <span className={classes.contentValue}>
              {jawaban.length - 1} / {orderIds.length}
            </span>
          </div>
          <div className={classes.contentRow}>
            <span className={classes.contentLabel}>Sisa Waktu: </span>
            <span className={classes.contentValue}>
              {countDownSeconds > 0 && <span>{countDownStr}</span>}
              {countDownSeconds <= 0 && <span>Waktu Habis</span>}
            </span>
          </div>
        </div>
        <div className={classes.endNotice}>
          <img src={notice} alt="" className={classes.endNoticeImg} />
          <p className={classes.endNoticeText}>
            Kamu tidak bisa mengulangi ujian ini jika memilih untuk selesai
          </p>
        </div>

        <DialogActions className={classes.endDialogAction}>
          <Button
            className={classes.btnCancel}
            onClick={handleClose}
            disabled={isEnding}
            autoFocus
          >
            Batal
          </Button>
          <Button
            className={classes.btnEnd}
            onClick={handleEndTO}
            disabled={isEnding}
          >
            {isEnding === true ? "Loading..." : "Selesai"}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
