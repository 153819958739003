// React
import React from "react";

// Styles
import useStyles from "./styles";

// Input
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

//
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { baseURL } from "../../images/s3";
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";

function DialogMisi({ open, data, handleClose, handleOk, mode }) {
  // UseStyles
  const classes = useStyles();
  //
  return (
    <Dialog open={open}>
      <DialogTitle className={classes.DialogTitle}>
        <span className={classes.pageName}>Misi</span>
        <img
          className={classes.logoImg}
          src={`${baseURL}new-logo-w.png`}
          alt=""
        />
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <List>
          <ListItem classes={{ root: classes.ListItem }}>
            <ListItemIcon>
              <Avatar style={{ backgroundColor: "#e0fcfa" }}>
                <ListAltIcon color="primary" />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.primaryStyle,
                secondary: classes.secondaryStyle,
              }}
              primary="Nama Misi"
              secondary={data?.judul}
            />
          </ListItem>
          <ListItem classes={{ root: classes.ListItem }}>
            <ListItemIcon>
              <Avatar style={{ backgroundColor: "#e0fcfa" }}>
                <EmojiEventsIcon color="primary" />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.primaryStyle,
                secondary: classes.secondaryStyleAlt,
              }}
              primary="Hadiah"
              secondary={`${data?.reward} Coin`}
            />
          </ListItem>
          <ListItem>
            <Typography
              style={{ fontSize: 12 }}
              align="center"
              variant="subtitle2"
            >
              <strong>
                Kamu yakin ingin mengerjakan misi ini
                {mode === "Coba Lagi" ? " lagi" : ""}?
              </strong>
            </Typography>
          </ListItem>
        </List>
      </DialogContent>
      <div className={classes.btnWrap}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
          className={classes.btnNo}
        >
          Tidak
        </Button>
        <Button
          onClick={handleOk}
          variant="contained"
          color="primary"
          className={classes.btnOk}
        >
          {mode}
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogMisi;
