// React
import React from "react";
// Component's
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";

import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import mainLogo from "../../images/img-logo.png";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useStyles from "./styles";
// Firebase
import StyledBadge from "../../components/Badge";
import { useFirebase } from "../../components/FirebaseProvider";
//
const labels = {
  1: "Apa Yang Kamu Tidak Sukai ?",
  2: "Apa Yang Kamu Tidak Sukai ?",
  4: "Apa Yang Kamu Sukai ?",
  5: "Apa Yang Kamu Sukai ?",
};
//
export default function HoverRating({ data, uid, url }) {
  const { firestore } = useFirebase();
  const [hover, setHover] = React.useState(-1);
  const [value, setValue] = React.useState(-1);
  const [coment, setComent] = React.useState({
    ulasan: "",
  });
  const [error, setError] = React.useState({
    ulasan: "",
    rating: "",
  });
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setComent({
      ...coment,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const validate = () => {
    const newError = { ...error };
    if (!coment.ulasan) {
      newError.ulasan = "Ketikkan Ulasan Kamu";
    }
    if (hover === -1) {
      newError.rating = "Rating Wajib Diisi";
    }
    return newError;
  };

  const handleClose = async (e) => {
    e.preventDefault();
    const findErrors = validate();
    //
    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      await firestore.collection("rating_mitra").add({
        siswa_id: data.siswa_id,
        mitra_id: data.mitra_id,
        rating: hover,
        comment: coment.ulasan,
        chatId: uid,
      });
      setOpen(false);
    }
  };

  const online = data?.online === false ? classes.offline : classes.online;

  return (
    <div className={classes.rateMitra}>
      <Dialog
        aria-labelledby="rate-modal-title"
        aria-describedby="rate-modal-description"
        className={classes.modal}
        open={open}
      >
        <DialogTitle
          disableTypography
          className={classes.modalTitle}
          id="rate-dialog-title"
        >
          {"Terima Kasih Sudah Konsultasi"}
        </DialogTitle>
        <DialogContent>
          <div className={classes.paper}>
            <div className={classes.ratingBox}>
              <Box component="fieldset" borderColor="transparent">
                <span className={classes.mitraSub}>
                  Berikan bintang 5 jika kamu puas dengan pelayanan mitra kami
                </span>
                <ListItemAvatar>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "10px 0px 10px 0px",
                    }}
                  >
                    <StyledBadge
                      overlap="rectangle"
                      classes={{ badge: online }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      variant="dot"
                    >
                      <Avatar
                        alt="mitra"
                        src={url || mainLogo}
                        className={classes.profileImg}
                        variant="square"
                      />
                    </StyledBadge>
                  </div>
                </ListItemAvatar>
                <div>
                  <Typography component="legend" align="center">
                    <strong>{data.nama_mitra}</strong>
                  </Typography>
                  <span className={classes.mitraSub}>
                    Guru Bk - Nama Organisasi
                  </span>
                </div>
                <div className={classes.rating1}>
                  <Rating
                    name="hover"
                    value={hover}
                    onChange={(event, newHover) => {
                      setHover(newHover);
                      setError({ rating: "" });
                    }}
                    onChangeActive={(event, newValue) => {
                      setValue(newValue);
                      setError({ rating: "" });
                    }}
                  />
                  {hover !== null && (
                    <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
                  )}
                </div>
                <Typography component="p" color="error" align="center">
                  {error.rating}
                </Typography>
              </Box>
            </div>
            <div className={classes.ulasanBox}>
              <TextField
                id="ulasan"
                label="Tulis Ulasan"
                name="ulasan"
                multiline
                rows="4"
                value={coment.ulasan}
                onChange={handleChange}
                error={!!error.ulasan}
                helperText={error.ulasan}
                variant="outlined"
                className={classes.ulasanText}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.btnWrap}>
          <Button
            className={classes.kirimRating}
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleClose}
          >
            Kirim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
