import React, { useState } from "react";

// Input
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";

// styles
import useStyles from "./styles";

// Router
import { Redirect } from "react-router-dom";

// Firebase & Profile Hooks
import { useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";

import { baseURL } from "../../images/s3";

function Harapan() {
  // Firebase Use
  const { user, firestore } = useFirebase();

  // Profile Use
  const { profile } = useSiswa();

  // useStyles
  const classes = useStyles();

  const [isSubmiting, setSubmiting] = useState(true);

  const [state, setState] = useState({
    checkedA: "",
    checkedB: "",
    checkedC: "",
    checkedD: "",
    checkedE: "",
    checkedF: "",
  });

  // OnChange
  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    if (event.target.checked === true) {
      setSubmiting(false);
    } else {
      setSubmiting(true);
    }
  };

  // OnSubmit
  const handleSubmit = (e) => {
    e.preventDefault();

    const arr = [];
    for (const key in state) {
      if (state[key] === true) {
        arr.push(key);
      }
    }
    const data = arr.toString();
    try {
      setSubmiting(true);
      const date = new Date();
      const ref = firestore.doc(`siswa/${user.uid}`);
      ref.set(
        {
          harapan: data,
          diperbarui_pada: date,
        },
        { merge: true }
      );
      setSubmiting(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Condition if the user has filled out the harapan form
  if (profile?.harapan) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.pageHeading}>
        <h2 className={classes.pageTitle}>Halo, {profile.nama}</h2>
        <p className={classes.pageSubtitle}>
          Apa harapanmu dengan menggunakan aplikasi ini?
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <FormGroup
          aria-label="position"
          name="position"
          onChange={handleChange}
          row
        >
          <div className={classes.choicesWrap}>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Lulus ke PTN impian")}
                    icon={
                      <img
                        src={`${baseURL}img-harapan1.png`}
                        className={classes.choiceImg}
                        alt=""
                      />
                    }
                    checkedIcon={
                      <img
                        src={`${baseURL}img-harapan1.png`}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Lulus ke PTN impian"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Lulus ke sekolah kedinasan impian")}
                    icon={
                      <img
                        src={`${baseURL}img-harapan2.png`}
                        className={classes.choiceImg}
                        alt=""
                      />
                    }
                    checkedIcon={
                      <img
                        src={`${baseURL}img-harapan2.png`}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Lulus ke sekolah kedinasan impian"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Ngobrol dengan senior mahasiswa")}
                    icon={
                      <img
                        src={`${baseURL}img-harapan3.png`}
                        className={classes.choiceImg}
                        alt=""
                      />
                    }
                    checkedIcon={
                      <img
                        src={`${baseURL}img-harapan3.png`}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Ngobrol dengan senior mahasiswa"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Mengukur kemampuan diri")}
                    icon={
                      <img
                        src={`${baseURL}img-harapan4.png`}
                        className={classes.choiceImg}
                        alt=""
                      />
                    }
                    checkedIcon={
                      <img
                        src={`${baseURL}img-harapan4.png`}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Mengukur kemampuan diri"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Dapat tempat belajar baru")}
                    icon={
                      <img
                        src={`${baseURL}img-harapan5.png`}
                        className={classes.choiceImg}
                        alt=""
                      />
                    }
                    checkedIcon={
                      <img
                        src={`${baseURL}img-harapan5.png`}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Dapat tempat belajar baru"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Tidak berharap apapun")}
                    icon={
                      <img
                        src={`${baseURL}img-harapan6.png`}
                        className={classes.choiceImg}
                        alt=""
                      />
                    }
                    checkedIcon={
                      <img
                        src={`${baseURL}img-harapan6.png`}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Tidak berharap apapun"
                labelPlacement="bottom"
              />
            </div>
          </div>
        </FormGroup>
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={isSubmiting}
        >
          Pilih
        </Button>
      </form>
    </div>
  );
}

export default Harapan;
