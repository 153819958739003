import parse from "date-fns/parse";
import formatISO from "date-fns/formatISO";
import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parseISO from "date-fns/parseISO";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import { id } from "date-fns/locale";
export const unixToIsoDate = (unix) => {
  try {
    return formatISO(parse(unix, "T", new Date()), { representation: "date" });
  } catch (e) {
    return "";
  }
};

export const unixToTime = (unix) => {
  try {
    return format(parse(unix, "T", new Date()), "HH:mm");
  } catch (e) {
    return "";
  }
};

export const DateToNow = (timestamp) => {
  try {
    if (!timestamp) return "";
    return formatDistanceToNow(new Date(timestamp), {
      locale: id,
      includeSeconds: true,
      addSuffix: true,
    });
  } catch (e) {
    return "";
  }
};

export const isoToRelative = (iso) => {
  try {
    const date = parseISO(iso);

    if (isToday(date)) {
      return "Hari ini";
    } else if (isYesterday(date)) {
      return "Kemarin";
    } else {
      return format(date, "EEEE, dd MMM Y", { locale: id });
    }
  } catch (e) {
    return "";
  }
};

export const formatDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "dd/MM/yy");
};

export const date = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "EEEE, dd LLL yyyy HH:mm 'WIB'", {
    locale: id,
  });
};

export const dateToCS = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "EEEE, dd LLLL yyyy HH:mm 'WIB'", {
    locale: id,
  });
};

export const formatDateLB = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "HH:mm dd/LL/yyyy", { locale: id });
};
