import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#fff",
    },
  },
  root: {
    flexGrow: 1,
  },
  container1: {
    color: "#FFF",
    width: "100%",
    backgroundColor: "#2FBBEC",
    minHeight: 570,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
  },
  container1a: {
    width: 270,
    margin: "auto",
    paddingTop: 70,
  },
  txt1: {
    fontSize: 30,
    fontWeight: 700,
    margin: "0px auto 10px",
  },
  txt2: {
    fontSize: 26.67,
    fontWeight: 600,
    margin: 0,
  },
  img: {
    maxWidth: 230,
    margin: "30px auto",
  },
  txt3: {
    fontSize: 16.67,
    margin: "0px auto 15px",
  },
  txt4: {
    fontSize: 11.67,
    margin: 0,
  },
  container2: {
    width: 270,
    margin: "35px auto 50px",
    display: "flex",
    justifyContent: "center",
    '@media(max-width: 1250px)': {
        justifyContent: "end",
    },
  },
  btn: {
    fontSize: 20,
    color: "#2FBBEC",
    fontWeight: 600,
    textTransform: "capitalize",
  },
}));

export default useStyles;
