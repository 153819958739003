import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/index';
import { Switch, Route } from 'react-router-dom';
import ListBerita from './list';
import BeritaDetail from './beritaDetail';
// 
function Berita(props) {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <Switch>
                <Route path="/berita/:id" component={BeritaDetail} />
                <Route component={ListBerita} />
            </Switch>
        </div>
    );
}

export default withStyles(styles)(Berita);