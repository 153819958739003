import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        // paddingBottom: 120,
    },
    appBody: {
        // backgroundColor: theme.palette.textColor.blue2,
        height: '100vh',
        overflow: 'auto',
        // paddingBottom: 120,
    },

    hasilTestBody: {
        width: '80%',
        margin: 'auto'
    },
    hasilTestHead: {
        padding: '10px 0',
    },
    resultTitle: {
        color: theme.palette.secondary.main,
        fontSize: 40,
        margin: 0,
        lineHeight: '40px',
        textDecoration: 'underline',
    },
    resultSubtitle: {
        color: '#000',
        fontSize: 18,
        margin: '10px 0',
    },
    whiteText: {
        color: '#fff',
        fontSize: 12,
        fontWeight: 700,
        margin: '0 0 5px',
    },
    blackText: {
        color: '#000',
        fontSize: 12,
        fontWeight: 700,
        margin: '0 0 5px',
    },
    announcePerson: {
        backgroundColor: '#fff',
        padding: 7,
        textAlign: 'center',
        width: '85%',
        margin: '10px auto 10px',
        borderRadius: 8,
    },
    detailWrap: {
        margin: '20px 0',
    },
    heading: {
        fontSize: 14,
        textAlign: 'left',
    },
    panelDetail: {
        fontSize: 12,
        textAlign: 'left',
        paddingLeft: 15,
    }
}))

export default useStyles;