// React
import React from 'react';

// Styles
import useStyles from './styles';

// Component
import AppHeader from '../../components/AppHeader';
import { baseURL } from '../../images/s3';
import { currency, delimiters } from '../../utils/formatter-rupiah';

// Input
import Button from '@material-ui/core/Button';

// Router
import { useParams } from 'react-router-dom';
// 
function PromoCoin(props) {
    // Styles
    const classes = useStyles();
    // Router
    const params = useParams();
    // Get Params
    const Total = params.total;
    const Coin = params.coin;
    // 
    return (
        <div className={classes.root}>
            <AppHeader />
            <div className={classes.coinBody}>
                <div className={classes.pageHeading}>
                    <p className={classes.sub6}><b>PROMO</b> COIN SPESIAL CUMA BUAT KAMU</p>
                </div>
                <div className={classes.coinImgWrap}>
                    <img src={`${baseURL}img-coin-double.png`} className={classes.imgCoin} alt="" />
                </div>
                <div className={classes.coinStats}>
                    <p><b>TAMBAH</b></p>
                    <p className={classes.coinCount}>{delimiters(Coin)} Coin</p>
                    <p><b>HANYA</b></p>
                    <p className={classes.coinCount}>{currency(Total)}</p>
                </div>
                <Button style={{ marginBottom: 100 }} color="primary" onClick={() => props.history.push(`/isiCoin/${Total}/${Coin}`)} variant="contained" size="large">Proses</Button>
            </div>
        </div>
    )
}

export default PromoCoin;