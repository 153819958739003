import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    pageHeading:{
        width: '75%',
        margin: 'auto',
    },
    formRow:{
        position: 'relative',
        margin: '0 auto 15px',
    },
    formControl:{
        width: '100%',
    },
    selecto: {
        width: '100%',
        textAlign: 'left',
        color: theme.palette.textColor.gray,
    },
    Select: {
        marginTop: '25px',
    },
    formRowBtn:{
        margin: '10px auto 15px',
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column nowrap',
    },
    pageTitle:{
        color: theme.palette.textColor.gray,
        fontSize: 24,
        marginBottom: 10,
    },
    pageSubtitle:{
        color: theme.palette.textColor.gray,
        fontSize: 14,
        marginTop: 0,
    },
    textField:{
        width: '100%',
    },
    textFieldLast:{
        fontSize: '13px !important',
        color: '#798790 !important',
    },
    btnInfo:{
        position: 'absolute',
        top: 15,
        right: -29,
        width: 20,
        height: 20,
    },
    poperContent:{
        width: 250,
        height: 60,
        backgroundColor: '#fff',
        padding: 10,
        border: 'solid 1px' + theme.palette.textColor.gray,
    },
    poperTxt:{
        fontStyle: 'italic',
        fontSize: 12,
        margin: 2,
    },
    formWrap:{
        margin: '0 auto 10px',
        width: '75%',
        '@media(min-width: 400px)': {
            width: '70%',
        },
        '@media(min-width: 500px)': {
            width: '65%',
        },
        '@media(min-width: 600px)': {
            width: '60%',
        },
        '@media(min-width: 700px)': {
            width: '50%',
        },
    },
    formRowPrize:{
        backgroundColor: '#ebeeec',
        padding: 20,
    },
    textFieldRef:{
        backgroundColor: '#aae0ff',
    },
    prizeInfo:{
        borderTop: 'solid 1px #b0b2b3',
        fontSize: 12,
        fontWeight: 'bold',
        paddingTop: 20,
    },
    textFieldUnderline:{
        borderBottom: 'solid 2px #798790 !important',
        '&::after':{
            borderBottom: 'solid 2px #798790 !important',
        }
    },
    DialogTitle: {
        fontSize: 18,
        textAlign: 'center',
        backgroundColor: '#e0fcfa'
    },
    txt: {
        textAlign: 'center'
    },
    imgCoinwrapp: {
        textAlign: 'center'
    },
    btneditt: {
        padding: '10px',
        borderTop: '1px solid #dedede',
        color: '#38b1db',
        borderBottom: '1px solid #dedede',
        textTransform: 'capitalize',
    }
}))

export default useStyles;