import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { dateToCS } from "../../utils/formatter";

class Timer extends Component {
  constructor(props) {
    super(props);
    this.countDownId = null;
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false,
    };
  }

  componentDidMount() {
    this.countDownId = setInterval(this.timerInit, 1000);
  }

  componentWillUnmount() {
    if (this.countDownId) {
      clearInterval(this.countDownId);
    }
  }

  timerInit = () => {
    const { startDate } = this.props;
    const now = Date.now();
    if (!startDate) {
      this.setState({ expired: true });
      return;
    }
    const countDownStartDate = startDate;
    const distance = countDownStartDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // For countdown is finished
    if (distance < 0) {
      clearInterval(this.countDownId);
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: true,
      });
      return;
    }
    this.setState({ days, hours, minutes, seconds, expired: false });
  };

  render() {
    const { days, hours, minutes, seconds, expired } = this.state;
    const { startDate } = this.props;
    if (expired) {
      return (
        <Typography
          align="left"
          style={{
            color: "#bbb",
            fontSize: 11,
          }}
        >
          Follow Instagram @pakbudiapp untuk infonya
        </Typography>
      );
    }
    return (
      <Typography
        align="left"
        color="secondary"
        style={{
          fontSize: 10,
        }}
      >
        {days > 1 ? (
          <>
            <span>Dibuka pada :</span>
            &nbsp;
            {dateToCS(startDate)}
          </>
        ) : (
          <>
            <span>Dibuka dalam :</span>
            {days > 0 && (
              <>
                &nbsp;
                {days}
                &nbsp;
                <span>Hari</span>
              </>
            )}
            {hours > 0 && (
              <>
                &nbsp;
                {hours}
                &nbsp;
                <span>Jam</span>
              </>
            )}
            {minutes > 0 && (
              <>
                &nbsp;
                {minutes}
                &nbsp;
                <span>Menit</span>
              </>
            )}
            {seconds > 0 && (
              <>
                &nbsp;
                {seconds}
                &nbsp;
                <span>Detik</span>
                &nbsp;
              </>
            )}
          </>
        )}
      </Typography>
    );
  }
}
export default Timer;
