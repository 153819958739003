import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PauseIcon from "@material-ui/icons/Pause";
import React, { useCallback, useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import StyledBadge from "../../components/Badge";
import { Arr, firestore, functions } from "../../components/FirebaseProvider";
import useTimer from "../../components/useTimer";
import mainLogo from "../../images/img-logo.png";
import useStyles from "./styles";

import { useSnackbar } from "notistack";
import { useCoin } from "../../components/CoinProvider";
import { useDownloadApp } from "../../components/DownloadApp";
import { useChat } from "../../components/useChatList";
import { RateChat as Rate } from "../../pages/konsultasi/Rating";
import ChatRating from "./chatRating";
import EndChatDialog from "./endChatDialog";
import ReminderDialog from "./reminderDialog";
import TimeoutDialog from "./timeoutDialog";
import WaitingDialog from "./waitingDialog";

export default function ChatHeading({ data }) {
  const classes = useStyles();
  const params = useParams();
  const { hide } = useDownloadApp();
  const [profileMitra, loading] = useDocumentData(
    firestore.doc(`mitra/${data?.mitra_id}`),
    {
      idField: "id",
    }
  );
  const [hargaMitra, loadingHargaMitra] = useDocumentData(
    firestore.doc(`harga_mitra/${data?.mitra_id}`)
  );
  const durasi = (profileMitra?.durasi ?? 5) * 60;
  const milisecondstart = data?.new_session_started_at
    ? data.new_session_started_at.toMillis()
    : Date.now();
  const milisecondend = data?.new_session_ended_at
    ? data.new_session_ended_at.toMillis()
    : Date.now();
  const { seconds, timerDownStr, stopTimer, resetTimer, startTimer } = useTimer(
    milisecondstart,
    milisecondend,
    0,
    durasi
  );
  const [expanded, setExpanded] = useState(false);
  const { Coin } = useCoin();
  const { rate } = useChat();
  const { enqueueSnackbar } = useSnackbar();
  const [shouldAutoExtend, setShouldAutoExtend] = useState(false);
  const [timeoutdialog, setTimeoutDialog] = useState({
    open: false,
    isLoading: false,
  });
  const [waitingdialog, setWaitingDialog] = useState({
    open: false,
    isLoading: false,
  });

  const [reminderdialog, setReminderDialog] = useState({
    open: false,
  });

  const [endDialog, setEndDialog] = useState({
    open: false,
  });

  const handleClickOk = useCallback(async () => {
    const extendChatRequest = functions.httpsCallable("extendChatRequest");
    setTimeoutDialog((timeoutdialog) => ({
      ...timeoutdialog,
      isLoading: true,
    }));
    try {
      await extendChatRequest({
        chat_id: params.chatId,
      });
      await firestore.doc(`chat/${params.chatId}`).set(
        {
          new_session_started_at: Arr.serverTimestamp(),
          new_session_ended_at: null,
          local_new_session_ended_at: null,
        },
        { merge: true }
      );
      setTimeoutDialog((timeoutdialog) => ({
        ...timeoutdialog,
        isLoading: false,
        open: false,
      }));
      resetTimer();
      startTimer();
    } catch (e) {
      console.log("e ", e.message);
      enqueueSnackbar(e.message);
      setTimeoutDialog((timeoutdialog) => ({
        ...timeoutdialog,
        isLoading: false,
      }));
    }
  }, [enqueueSnackbar, resetTimer, startTimer, params.chatId]);
  useEffect(() => {
    if (data?.status === "active") {
      const duration = 60 * (profileMitra?.durasi || 5);
      const remain = duration - seconds;
      if (parseInt(remain) === 1 && shouldAutoExtend) {
        handleClickOk().finally(() => {});
      }
    }
  }, [
    seconds,
    shouldAutoExtend,
    handleClickOk,
    data.status,
    durasi,
    profileMitra.durasi,
  ]);
  useEffect(() => {
    //if timer more than 5 minutes (300 seconds)
    if (data?.status === "active" && profileMitra?.id) {
      const duration = 60 * (profileMitra?.durasi || 5);

      if (seconds >= duration) {
        if (!data?.local_new_session_ended_at) {
          const endCurrentSession = async () => {
            try {
              await firestore.doc(`chat/${params.chatId}`).set(
                {
                  new_session_ended_at: Arr.serverTimestamp(),
                  local_new_session_ended_at: Date.now(),
                  sessions: {
                    ...data.sessions,
                    [`session_${data.total_session}`]: {
                      started_at: data.new_session_started_at,
                      ended_at: Arr.serverTimestamp(),
                    },
                  },
                },
                { merge: true }
              );
            } catch (e) {}
          };
          endCurrentSession();
        }
        stopTimer();
        if (!shouldAutoExtend) {
          setTimeoutDialog((timeoutdialog) => ({
            ...timeoutdialog,
            open: true,
          }));
        }
      }
    }
  }, [
    seconds,
    Coin,
    stopTimer,
    params.chatId,
    shouldAutoExtend,
    data.status,
    data.local_new_session_ended_at,
    data.sessions,
    data.total_session,
    data.new_session_started_at,
    data.mitra_id,
    durasi,
    profileMitra.id,
    profileMitra.durasi,
  ]);

  useEffect(() => {
    if (data?.status === "active") {
      const duration = 60 * (profileMitra?.durasi || 5);
      const remain = duration - seconds;
      // console.log("remain", duration, seconds, remain)
      // jika waktu kurang dari satu menit
      if (parseInt(remain) === 60) {
        setReminderDialog((reminderdialog) => ({
          ...reminderdialog,
          open: true,
        }));
      }
    }
  }, [data.status, durasi, profileMitra.durasi, seconds]);

  useEffect(() => {
    if (data && data.status !== "active") {
      stopTimer();
    }
  }, [data, stopTimer]);

  useEffect(() => {
    const cleanUp = () => {
      const docRef = firestore.doc(`chat/${params.chatId}`);

      docRef.onSnapshot((snapshot) => {
        const doc = snapshot?.data();

        if (doc?.status === "waiting-for-accept") {
          console.log("I'am here ");
          const cancelChatRequest =
            functions.httpsCallable("cancelChatRequest");
          cancelChatRequest({
            chat_id: params.chatId,
          }).finally(() => {
            enqueueSnackbar("Chat dibatalkan", { variant: "success" });
          });
        }
      });
    };

    return () => {
      cleanUp();
    };
  }, [enqueueSnackbar, params.chatId]);

  const handleClickNo = async () => {
    try {
      await firestore.doc(`chat/${params.chatId}`).set(
        {
          status: "done",
          siswa_typing: false,
          mitra_typing: false,
          siswa_inApp: false,
          mitra_inApp: false,
        },
        { merge: true }
      );
    } catch (e) {}
    setTimeoutDialog((timeoutdialog) => ({
      ...timeoutdialog,
      open: false,
    }));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const endChat = async () => {
    await firestore.doc(`chat/${params.chatId}`).set(
      {
        status: "done",
        siswa_typing: false,
        mitra_typing: false,
        siswa_inApp: false,
        mitra_inApp: false,
        new_session_ended_at: Arr.serverTimestamp(),
        local_new_session_ended_at: Date.now(),
        sessions: {
          ...data.sessions,
          [`session_${data.total_session}`]: {
            started_at: data.new_session_started_at,
            ended_at: Arr.serverTimestamp(),
          },
        },
      },
      { merge: true }
    );
    setEndDialog((endDialog) => {
      return {
        ...endDialog,
        open: false,
      };
    });
  };

  const profile = profileMitra || {};
  const online = !profile.online ? classes.offline : classes.online;
  const disabled = data && data.status !== "active";
  let type = null;
  if (profile.type === "gurubk") {
    type = "Guru Bk";
  }
  if (profile.type === "mahasiswa") {
    type = "Mahasiswa";
  }
  if (profile.type === "motivator") {
    type = "Motivator";
  }

  const rateDocs = rate?.docs;

  const rateDoc = rateDocs?.find((rd) => rd?.id === data?.mitra_id);

  const rateData = rateDoc?.data();
  // console.log("timeout dialog", timeoutdialog)
  return (
    <>
      <div className={hide ? classes.chatHeading : classes.chatHeading2}>
        <ExpansionPanel
          className={classes.detailPanel}
          square={true}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={classes.topDetail}>
              {/* <IconButton onClick={handleBack} className={classes.back}>
                <ChevronLeftIcon />
              </IconButton> */}
              <div className={classes.coreHeading}>
                <div className={classes.siswaId}>
                  {loading ? (
                    <CircularProgress color="primary" size={44} />
                  ) : (
                    <>
                      <StyledBadge
                        overlap="circle"
                        classes={{ badge: online }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          className={classes.siswaAvatar}
                          alt=""
                          src={profile.photoURL || mainLogo}
                        ></Avatar>
                      </StyledBadge>
                      <div className={classes.siswaDesc}>
                        <Typography className={classes.siswaName}>
                          {profile.nama || data.nama_mitra || "Nama Mitra"}
                        </Typography>
                        {!loading && (
                          <div className={classes.durationBlock}>
                            <Typography className={classes.chatDuration}>
                              Sisa waktu: {timerDownStr} (Sesi{" "}
                              {data ? data.total_session : ""})
                            </Typography>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.detailWrap}>
              <div className={classes.detailItem}>
                <div className={classes.siswaDetail}>
                  <Typography
                    className={classes.siswaDetailItem}
                    variant="body1"
                  >
                    {type} - {profile.title}
                  </Typography>
                  <div className={classes.mitraRating}>
                    <Rate rating={rateData?.average_rating} />
                  </div>
                </div>
                <Button
                  disabled={disabled || data === undefined}
                  onClick={() => {
                    setEndDialog((endDialog) => {
                      return {
                        ...endDialog,
                        open: true,
                      };
                    });
                  }}
                  size="small"
                  className={classes.pauseBtn}
                  variant="contained"
                >
                  <PauseIcon />
                  <Typography variant="body1">End Chat</Typography>
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>

      {data && data.status === "done" && data.rate !== true && (
        <ChatRating
          data={{ ...data, ...profile }}
          uid={params.chatId}
          url={profile.photoURL}
        />
      )}

      {!loadingHargaMitra && hargaMitra && (
        <>
          <TimeoutDialog
            {...timeoutdialog}
            {...hargaMitra}
            name={profile.nama || data.nama_mitra || "Nama Mitra"}
            durasi={profile.durasi || 5}
            handleClose={() => {
              setTimeoutDialog((timeoutdialog) => {
                return {
                  ...timeoutdialog,
                  open: false,
                };
              });
            }}
            handleClickNo={handleClickNo}
            handleClickOk={handleClickOk}
          />
          <ReminderDialog
            {...reminderdialog}
            {...hargaMitra}
            handleClose={() => {
              setReminderDialog((reminderdialog) => {
                return {
                  ...reminderdialog,
                  open: false,
                };
              });
            }}
            handleClickNo={() => {
              setShouldAutoExtend(false);
              setReminderDialog((reminderdialog) => {
                return {
                  ...reminderdialog,
                  open: false,
                };
              });
            }}
            handleClickOk={() => {
              setShouldAutoExtend(true);
              setReminderDialog((reminderdialog) => {
                return {
                  ...reminderdialog,
                  open: false,
                };
              });
            }}
          />
        </>
      )}

      {data && data.status === "waiting-for-accept" && (
        <WaitingDialog
          {...waitingdialog}
          handleClose={() => {
            setWaitingDialog((waitingdialog) => {
              return {
                ...waitingdialog,
                open: false,
              };
            });
          }}
          open={data.status === "waiting-for-accept"}
          data={data}
        />
      )}
      <EndChatDialog
        {...endDialog}
        handleClose={() => {
          setEndDialog((endDialog) => {
            return {
              ...endDialog,
              open: false,
            };
          });
        }}
        endChat={endChat}
      />
    </>
  );
}
