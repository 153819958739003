import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // index
  root: {
    flexGrow: 1,
    userSelect: "none",
    msUserSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    paddingBottom: 100,
    maxWidth: 500,
    margin: "auto",
  },
  pbCard: {
    backgroundColor: "#fff",
    borderRadius: 15,
    width: "95%",
    position: "relative",
    margin: "0 auto",
    zIndex: 997,
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 10,
    fontFamily: "poppins",
    "&.first": {
      marginTop: -25,
    },
    "&.medium": {
      maxWidth: 700,
    },
    "& h2": {
      color: "#0E4970",
      margin: 0,
      fontSize: "1.1rem",
      fontWeight: 800,
    },
    "& p": {
      margin: 10,
      fontWeight: 400,
      fontSize: "0.75rem",
    },
  },
  pbAccordionCard: {
    backgroundColor: "#fff",
    borderRadius: 15,
    width: "95%",
    position: "relative",
    margin: "0 auto",
    zIndex: 997,
    padding: 10,
    marginTop: 10,
    fontFamily: "poppins",
    maxWidth: 700,
    "& > h3": {
      margin: 0,
      borderBottom: "none",
      position: "relative",
      fontSize: "1rem",
      textAlign: "left",
      transition: "all .375s ease",
      cursor: "pointer",
      "&.expanded": {
        borderBottom: "solid 1px #aaa",
        paddingBottom: 5,
        paddingLeft: 5,
      },
      "&:after": {
        content: "''",
        backgroundImage: "url('../assets/chevron.png')",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: 18,
        height: 18,
        display: "block",
        position: "absolute",
        right: 5,
        top: 1,
        transition: "all .375s ease",
      },
      "&.expanded:after": {
        transform: "rotate3d(0, 0, 1, 180deg)",
      },
    },
  },
  pbAccordionBody: {
    transition: "all .375s ease",
    height: 0,
    overflow: "hidden",
    "& p": {
      textAlign: "left",
    },
    "&.expanded": {
      height: "auto",
    },
  },
  pbDisclaimerCard: {
    backgroundColor: "#FFBB38",
    borderRadius: 15,
    width: "95%",
    position: "relative",
    margin: "0 auto",
    zIndex: 997,
    padding: 10,
    marginTop: 10,
    fontFamily: "poppins",
    maxWidth: 700,
    "& h4": {
      margin: 0,
      fontSize: "0.6rem",
    },
    "& p": {
      margin: 0,
      fontSize: "0.6rem",
    },
  },
  pbHeroIntro: {
    fontWeight: "600 !important",
    margin: "0 !important",
  },
  pbHeroTitle: {
    color: "#0E4970",
    margin: 0,
    fontSize: "2.2rem",
  },
  pbHeroNumber: {
    fontSize: "1.5rem",
  },
  pbCardTitle: {
    backgroundColor: "#0E4970",
    color: "#fff",
    padding: 10,
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: "0.8rem",
  },
  pbInputGroup: {
    padding: 8,
    borderBottom: "solid 1px #ddd",
    margin: "0 10px",
    display: "flex",
    alignItems: "center",
    "& label": {
      flex: "3 1 auto",
      fontSize: "0.6rem",
      fontWeight: 600,
      textAlign: "left",
    },
  },
  pbNumberInput: {
    display: "flex",
    backgroundColor: "#eee",
    border: "solid 1px #aaa",
    color: "#30bcec",
    flex: "0 0 77px",
    "& span": {
      minWidth: 20,
      cursor: "pointer",
    },
    "& input": {
      backgroundColor: "#eee",
      width: 35,
      textAlign: "center",
      color: "#444",
      border: "none",
      borderLeft: "solid 1px #aaa",
      borderRight: "solid 1px #aaa",
    },
  },
  pbButton: {
    border: "none",
    borderRadius: 10,
    backgroundColor: "#30bcec",
    color: "#fff",
    display: "flex",
    width: 200,
    margin: "10px auto",
    fontWeight: 600,
    alignItems: "center",
    gap: 5,
    cursor: "pointer",
    justifyContent: "space-evenly",
    "& > p": {
      margin: 0,
      fontWeight: 600,
      flex: "1 1 auto",
    },
    "& span": {
      borderLeft: "solid 1px #fff",
      paddingLeft: 5,
      lineHeight: "30px",
    },
    "& img": {
      width: 15,
      height: 15,
    },
  },
  pbCoinGroup: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    flex: "0 0 33px",
  },
  pbBackLink: {
    color: "#444",
    cursor: "pointer",
    "&:hover": {
      color: "#222",
    },
  },
  profileBg: {
    width: "100%",
    top: 0,
    zIndex: 3,
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    paddingTop: 10,
    paddingBottom: 20,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  page: {
    margin: "0 auto 10px !important",
    position: "relative",
    "@media(min-width: 320px)": {
      width: "95% !important",
    },
    "@media(min-width: 560px)": {
      width: "80% !important",
    },
    "@media(min-width: 960px)": {
      width: "70% !important",
    },
    "@media(min-width: 1100px)": {
      width: "50% !important",
    },
  },
  page2: {
    margin: "0 auto",
    position: "relative",
    "@media(min-width: 320px)": {
      width: "95% !important",
    },
    "@media(min-width: 560px)": {
      width: "80% !important",
    },
    "@media(min-width: 960px)": {
      width: "70% !important",
    },
    "@media(min-width: 1100px)": {
      width: "100% !important",
    },
  },
  fab: {
    position: "absolute",
    top: 4,
    right: 10,
  },
  profileImg: {
    margin: "10px auto",
    width: "60px !important",
    height: "60px !important",
    pointerEvents: "none",
  },
  profileBar: {
    textAlign: "left",
    paddingRight: 75,
    position: "relative",
  },
  username: {
    margin: 0,
    fontSize: 20,
    letterSpacing: 2,
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.textColor.white,
  },
  profile: {
    margin: "0",
    fontSize: 10,
    fontWeight: 300,
    color: theme.palette.textColor.white,
  },
  editBtnCon: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  editBtn: {
    textTransform: "capitalize",
    fontSize: 10,
    fontWeight: 600,
    backgroundColor: "#0E4970",
    "&:hover": {
      backgroundColor: "#166DBD",
    },
    color: theme.palette.textColor.white,
  },
  progressTitle: {
    fontWeight: 600,
    color: theme.palette.darkBlue.main,
    fontSize: 16.67,
  },
  missionBtn: {
    fontWeight: 600,
    fontSize: 16.67,
    backgroundColor: "#FDED1F",
    "&:hover": {
      backgroundColor: "#FDF141",
    },
    color: theme.palette.darkBlue.main,
    padding: 0,
    borderBottom: "3px solid #1461A8",
  },
  box: {
    backgroundColor: theme.palette.textColor.white,
    padding: 10,
    borderRadius: 10,
  },
  boxTitle: {
    fontWeight: 600,
    color: theme.palette.darkBlue.main,
    fontSize: 16.67,
  },
  boxTxt: {
    fontStyle: "italic",
    fontWeight: 400,
    color: theme.palette.textColor.gray8,
    fontSize: 12,
  },
  boxTxt2: {
    color: theme.palette.textColor.gray8,
    fontWeight: 500,
    fontSize: 13.33,
  },
  boxTxt3: {
    fontStyle: "italic",
    fontWeight: 400,
    color: theme.palette.darkBlue.main,
    fontSize: 12,
  },
  boxTxt4: {
    color: theme.palette.textColor.gray6,
    fontWeight: 500,
    fontSize: 13.33,
  },
  boxTxt5: {
    fontWeight: "bold",
    fontSize: 13.33,
  },
  boxTxt6: {
    color: theme.palette.textColor.gray8,
    fontWeight: "bold",
    fontSize: 10,
  },
  boxTxt7: {
    fontStyle: "italic",
    color: theme.palette.textColor.gray8,
    fontSize: 10,
  },
  cta: {
    borderRadius: 100,
    padding: "2px 10px",
    fontWeight: 600,
  },
  linkTo: {
    width: "100%",
    borderRadius: 10,
  },
  divider: {
    margin: "10px 0px !important",
  },

  // listTryout
  box2: {
    backgroundColor: theme.palette.textColor.gray,
    padding: 10,
    borderRadius: 10,
  },
  boxTitle2: {
    fontWeight: 600,
    color: theme.palette.textColor.white,
    fontSize: 13.33,
  },
  boxTxt8: {
    color: theme.palette.textColor.gray9,
    fontSize: 10,
  },
  cta2: {
    fontWeight: 600,
    borderRadius: 100,
    padding: "2px 10px",
    color: "#FFF",
  },
  cta3: {
    fontWeight: 600,
    borderRadius: 100,
    padding: "2px 10px",
    color: theme.palette.textColor.gray9,
  },
  cta4: {
    fontWeight: 600,
    borderRadius: 100,
    padding: "2px 10px",
    color: "#FFF",
    backgroundColor: "#D72020",
    "&:hover": {
      backgroundColor: "#B71B1B",
    },
  },

  // DialogTransaksiTo & DialogTo
  DialogTitle: {
    fontSize: 18,
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  content: {
    padding: "0px 24px",
  },
  ListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  dtxt1: {
    fontSize: 13.33,
    fontWeight: 400,
    color: theme.palette.textColor.gray,
  },
  dtxt2: {
    fontSize: 13.33,
    fontWeight: 550,
    color: theme.palette.textColor.gray8,
  },
  dtxt3: {
    fontSize: 12,
    color: theme.palette.textColor.gray8,
  },
  logoWrap: {
    position: "relative",
    width: 180,
    margin: "auto",
  },
  mainLogo: {
    marginTop: 5,
    width: 150,
  },
  pageName: {
    position: "absolute",
    color: theme.palette.textColor.white,
    fontSize: 12,
    top: -5,
    left: 56,
    textTransform: "capitalize",
    fontStyle: "italic",
  },
  cekPeluangButton: {
    justifyContent: "flex-end",
  },
  logo: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "baseline",
    margin: "0 auto",
    position: "relative",
    left: 0,
  },
  logoImg: {
    width: 100,
    // height: 50,
  },
  announceBox: {
    width: 320,
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
    padding: 16,
    margin: "20px 0",
  },
}));

export default useStyles;
