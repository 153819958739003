import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        paddingBottom: 60,
        position: 'relative',
    },
    appBody: {
        position: 'relative',
        zIndex: 1,
        width: '100%',
        margin: 'auto',
    },
    profilDialog: {
        // width: 270,
        margin: '0 auto',
        overflowX: 'hidden',
        userSelect: 'none',
        msUserSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none'
    },
    DialogTitle: {
        fontSize: 18,
        textAlign: 'center',
        backgroundColor: theme.palette.lightBlue.main,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        position: 'relative',
        paddingTop: 24,
    },
    pageName:{
        position: 'absolute',
        top: 15,
        left: 118,
        color: '#fff',
        fontSize: 12,
        fontStyle: 'italic',
    },
    pageNameAlt:{
        position: 'absolute',
        top: 15,
        left: 76,
        color: '#fff',
        fontSize: 12,
        fontStyle: 'italic',
    },
    logoImg:{
        width: 160,
        height: 'auto',
    },
    centerText: {
        textAlign: 'center',
        position: 'relative',
        zIndex: 2,
        color: '#fff',
        fontSize: 22,
        fontWeight: 'bold',
    },
    profilWrap: {
        position: 'relative',
        zIndex: 2,
    },
    profilAvatarBody: {
        width: 83,
        margin: 'auto',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    profilAvatar: {
        width: '83px',
        height: '83px',
        border: 'solid 3px #fff',
        borderRadius:  10,
    },
    profilName: {
        textAlign: 'center',
        margin: '10px auto 0',
    },
    profession: {
        color: theme.palette.textColor.gray6,
        fontSize: 12,
        fontWeight: 500,
        textAlign: 'center',
        margin: '0 auto',
    },
    description: {
        color: theme.palette.textColor.gray6,
        fontSize: 12,
        fontWeight: 500,
        textAlign: 'center',
        margin: '0 auto',
    },
    mitraRating: {
        width: 90,
        margin: '11px auto 0',
        display: 'flex !important',
    },
    priceTag: {
        border: 'solid 1px' + theme.palette.orange.main,
        padding: 5,
        color: theme.palette.orange.main,
        fontWeight: 'bold',
        textAlign: 'center',
        // display: 'block',
        width: 'fit-content',
        margin: '10px auto',
        display: 'flex',
        borderRadius: 6,
    },
    priceTag1: {
        padding: 0,
        color: theme.palette.orange.main,
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'block',
        width: 'fit-content',
        height: 20,
    },
    priceTagNb:{
        padding: 5,
        color: theme.palette.orange.main,
        fontWeight: 'bold',
        textAlign: 'center',
        // display: 'block',
        width: 'fit-content',
        margin: '10px auto',
        display: 'flex',
        borderRadius: 6,
    },
    mitraStats:{
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    statRow:{
        width: '32%',
        textAlign: 'center',
    },
    statLabel:{
        color: theme.palette.textColor.gray6,
        fontSize: 13,
        fontWeight: 600,
    },
    statValue:{
        color: theme.palette.lightBlue.main,
        fontSize: 17,
        fontWeight: 700,
    },
    dialogAction: {
        // margin: 'auto',
        padding: '10px 25px 25px',
        justifyContent: 'space-between'
    },
    professionDetail: {
        borderTop: 'solid 1px #ddd',
        paddingTop: 15,
        marginTop: 15,
    },
    detailRow: {
        color: theme.palette.textColor.gray6,
        fontSize: 13,
        fontWeight: 600,
        textAlign: 'center',
    },
    online: {
        color: theme.palette.textColor.green,
        backgroundColor: '#44b700',
        padding: 5,
        right: '12%',
        bottom: '12%',
    },
    offline: {
        color: theme.palette.textColor.gray2,
        backgroundColor: theme.palette.textColor.gray,
        padding: 5
    },
    ratingBlock: {
        position: 'relative',
    },
    rating: {
        position: 'relative',
    },
    ratingBlockInner: {
        position: 'relative',
    },
    sizeSmall: {
        fontSize: '.8em !important',
    },
    imgCoin: {
        width: 20,
        height: 20,
        marginLeft: 5
    },
    profilBackdrop: {
        position: 'absolute',
        top: -270,
        left: -65,
        width: 400,
        height: 400,
        borderRadius: '100%',
        backgroundColor: theme.palette.primary.main,
        zIndex: 1,
    },
    profilBackdropTwo: {
        position: 'absolute',
        top: -266,
        left: -55,
        width: 380,
        height: 400,
        borderRadius: '100%',
        backgroundColor: theme.palette.secondary.main,
        zIndex: 0,
    },
    profilDesc:{
        textAlign: 'center',
        margin: 0,
    },
    btnNo:{
        textTransform: 'capitalize',
        border: 'solid 1px ' + theme.palette.textColor.gray,
        backgroundColor: '#fff',
        color: theme.palette.textColor.gray,
        width: 112,
        height: 26,
        padding: 0,
        fontSize: 10,
        fontWeight: 600,
    },
    btnChatNow: {
        textTransform: 'capitalize',
        width: 112,
        height: 26,
        padding: 0,
        fontSize: 10,
        fontWeight: 600,
    }
}))

export default useStyles;