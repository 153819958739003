import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppHeader from "../../components/AppHeader";
import AppLoading from "../../components/AppLoading";
import { useFirebase } from "../../components/FirebaseProvider";
import { baseUrl } from "../../images/s3";
import results from "../tesMinatBakat/game/data/results.json";
import useStyles from "./styles";

const SuccessPayment = () => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("order_id");
  const transactionStatus = queryParams.get("transaction_status");
  const orderArray = orderId.split("-");
  const orderIdentifier = orderArray[0];
  const history = useHistory();
  const [time, setTime] = useState(30);
  const timerRef = useRef(null);
  const [mbtiDataExist, setMbtiDataExist] = useState(false);
  const [mbtiSiswa, setMbtiSiswa] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { firestore, user } = useFirebase();
  useEffect(() => {
    if (transactionStatus === "pending") {
      history.replace(`/checkout/paymentFailed?order_id=${orderId}`);
    }
    fetchData();
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      clearInterval(timerRef.current);
      if (orderIdentifier === "PakBudi") {
        history.replace(`/tesminatbakat/result/${mbtiSiswa}`);
      } else {
        history.replace(`/home`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  async function fetchData() {
    try {
      setIsLoading(true);
      const findSiswaDoc = await firestore.doc(`siswa/${user.uid}`).get();
      const siswa = findSiswaDoc.data();
      if (typeof siswa.hasil_tesMinatBakat !== "undefined") {
        setMbtiSiswa(siswa.hasil_tesMinatBakat);
        const ucMBTI = siswa.hasil_tesMinatBakat.toUpperCase();
        if (Object.keys(results).includes(ucMBTI)) {
          const result = results[ucMBTI];
          if (
            Object.keys(result).includes("tmbPlusAvailable") &&
            result.tmbPlusAvailable === "Ya"
          ) {
            setMbtiDataExist(true);
          }
        }
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerAfterPayment}>
        <AppHeader />
        {orderIdentifier === "PakBudi" ? (
          <img
            src={`${baseUrl}tmbLogo.png`}
            alt="tmbLogo"
            className={classes.logoInPayment}
          />
        ) : (
          <img
            src={`${baseUrl}logo_pakbudi_vertical.png`}
            alt="tmbLogo"
            className={classes.logoInPayment}
            style={{ top: 8 }}
          />
        )}
      </div>
      <div className={classes.messageBody}>
        <Typography variant="subtitle2">Terima kasih!</Typography>
        {orderIdentifier === "PakBudi" ? (
          <>
            {mbtiDataExist === false ? (
              <>
                <Typography variant="body2" style={{ fontSize: "0.75rem" }}>
                  Permintaan mu sedang di proses oleh tim kami.
                </Typography>
                <img
                  src={`${baseUrl}jamPasir.png`}
                  alt="jamPasir"
                  className={classes.jamPasir}
                />
                <Typography variant="body2" style={{ fontSize: "0.75rem" }}>
                  Jika hasil sudah tersedia, kami akan menghubungi mu via
                  whatsapp!
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "0.75rem", marginTop: 16 }}
                >
                  Atau hubungi Admin Instagram{" "}
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "0.75rem",
                      color: "#4bbcec",
                      display: "inline",
                    }}
                  >
                    @pakbudiapp
                  </Typography>{" "}
                  dalam 3 hari kedepan
                </Typography>
              </>
            ) : (
              <>
                <img
                  src={`${baseUrl}success.png`}
                  alt="tmbLogo"
                  className={classes.statusImage}
                />
                <Typography variant="body2" style={{ fontSize: "0.75rem" }}>
                  Hi! Pembayaranmu berhasil nih!, Kamu sekarang bisa cek
                  hasilnya di halaman TMB kamu ya. Jika ada pertanyaan, jangan
                  sungkan untuk menghubungi kami lagi ya!
                </Typography>
              </>
            )}
          </>
        ) : (
          <>
            <img
              src={`${baseUrl}success.png`}
              alt="tmbLogo"
              className={classes.statusImage}
            />
            <Typography variant="body2" style={{ fontSize: "0.75rem" }}>
              Hi! Pembayaranmu berhasil nih!, Koinmu sudah ditambahkan ke
              akunmu, Kamu sekarang bisa gunakan koin untuk menggunakan
              fitur-fitur Pakbudi lainnya. Jika ada pertanyaan, jangan sungkan
              untuk menghubungi kami lagi ya!
            </Typography>
          </>
        )}

        <Typography
          variant="body2"
          style={{ fontSize: "0.75rem", marginTop: 16 }}
        >
          Kamu akan diarahkan ke halaman tujuan dalam {time} detik.
        </Typography>
      </div>
      <Button
        className={classes.startBtn}
        variant="contained"
        color="secondary"
        component={Link}
        to={
          orderIdentifier === "PakBudi"
            ? `/tesminatbakat/result/${mbtiSiswa}`
            : `/home`
        }
      >
        {orderIdentifier === "PakBudi" ? "Lihat Hasil" : "Kembali ke awal"}
      </Button>
    </div>
  );
};

export default SuccessPayment;
