// React
import React, { useState } from "react";

// Styles
import useStyles from "./styles";

// List
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

// Divider
import Divider from "@material-ui/core/Divider";

// Icon
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

// Router
import { Redirect } from "react-router-dom";

// Component
import AlertDialogSlide from "../../components/Dialog";
import LaporMasalah from "./LaporMasalah";
import TopNav from "../../components/TopNav";

// Utils
import { Helmet } from "react-helmet";
import { useSiswa } from "../../components/SiswaProvider";
import IcoEmail from "../../images/ico-email.svg";
import IcoNotif from "../../images/ico-notif.svg";
import IcoProfile from "../../images/ico-profile.svg";
import IcoVoucher from "../../images/ico-voucher.svg";

function Settings(props) {
  //
  const { setPageEvent } = useSiswa();

  // UseStyles
  const classes = useStyles();

  // State
  const [selectedIndex, setSelectedIndex] = useState();

  // OnClick
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  setPageEvent("settings");

  // Condition
  if (selectedIndex === 0) {
    return <Redirect to="/editprofile" />;
  }
  if (selectedIndex === 1) {
    return <Redirect to="/ubahemaildanpassword" />;
  }
  if (selectedIndex === 2) {
    return <Redirect to="/vouchers" />;
  }
  if (selectedIndex === 3) {
    return <Redirect to="/notifikasisettings" />;
  }

  return (
    <div className={classes.root}>
      <TopNav />
      <Helmet>
        <title>PakBudi | Settings</title>
      </Helmet>
      {/* <div className={classes.pageHeading}>
        <Button className={classes.historyBack} onClick={handleClick}>
          <ChevronLeftIcon />
        </Button>
      </div> */}
      <div className={classes.settingBody}>
        <h2 className={classes.pageTitle}>Setelan</h2>
        <List
          className={classes.settingList}
          component="nav"
          aria-label="main mailbox folders"
        >
          <ListSubheader className={classes.subHeader}>Akun</ListSubheader>
          <ListItem
            button
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <img src={IcoProfile} alt="" />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Edit Profil"
            />
            <ListItemSecondaryAction>
              <IconButton
                className={classes.iconBtn}
                edge="end"
                disabled
                aria-label="Edit Profil"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem
            button
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <img src={IcoEmail} alt="" />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Ubah Email Dan Kata Sandi"
            />
            <ListItemSecondaryAction>
              <IconButton
                className={classes.iconBtn}
                edge="end"
                disabled
                aria-label="Ubah Email Dan Kata Sandi"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem
            button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <img src={IcoVoucher} alt="" />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Input Kode Voucher"
            />
            <ListItemSecondaryAction>
              <IconButton
                className={classes.iconBtn}
                edge="end"
                disabled
                aria-label="Input Kode Voucher"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListSubheader className={classes.subHeader}>Setelan</ListSubheader>
          <ListItem
            button
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <img src={IcoNotif} alt="" />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Notifikasi"
            />
            <ListItemSecondaryAction>
              <IconButton
                className={classes.iconBtn}
                edge="end"
                disabled
                aria-label="Notifikasi"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListSubheader className={classes.subHeader}>Tentang</ListSubheader>
          <LaporMasalah />

          <AlertDialogSlide />
        </List>
      </div>
    </div>
  );
}

export default Settings;
