// React
import React, { useEffect, useState } from "react";

// Styles
import useStyles from "./styles/editProfile";

// Component
import TopNav from "../../components/TopNav";
import UploadFoto from "./uploadFoto";

// Input
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
// Icons

// app components
import SelectJurusan from "../../components/AppSelect/jurusan";
import SelectJurusanKelas from "../../components/AppSelect/jurusanKelas";
import SelectKampus from "../../components/AppSelect/kampus";
import SelectKelas from "../../components/AppSelect/kelas";
import SelectKota from "../../components/AppSelect/kota";
import SelectProvinsi from "../../components/AppSelect/provinsi";

// React Router
import { withRouter } from "react-router-dom";

// Notification
import { useSnackbar } from "notistack";

// Validator
import trim from "lodash/trim";
import isNumber from "validator/lib/isNumeric";
// Firebase Hooks
import { useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";

// Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CopyToClipboard from "react-copy-to-clipboard";
import AppLoading from "../../components/AppLoading";

function EditProfile(props) {
  // UseStyles
  const classes = useStyles();

  // UseFirebase
  const { user, firestore, functions } = useFirebase();

  // Document Ref
  const siswaDoc = firestore.doc(`siswa/${user.uid}`);
  const { profile, pageEvent } = useSiswa();

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [form, setForm] = useState({
    nama: "",
    username: "",
    no_hp: "",
    sekolah: {
      nama: "",
      sekolah_id: "",
    },
    provinsi: {
      nama: "",
      provinsi_id: "",
    },
    kota: {
      nama: "",
      kota_id: "",
    },
    kelas: {
      nama: "",
      kelas_id: "",
    },
    kampus: {
      nama: "",
      ptn_id: "",
    },
    jurusan: {
      nama: "",
      jurusan_id: "",
    },
    jurusanKelas: {
      nama: "",
      jurusankelas_id: "",
    },
  });
  const [error, setError] = useState({
    nama: "",
    username: "",
    no_hp: "",
    sekolah: "",
    provinsi: "",
    kota: "",
    kelas: "",
    jurusanKelas: "",
    kampus: "",
    jurusan: "",
  });
  const [isSubmiting, setSubmiting] = useState(false);
  const [sekolah_custom, setSekolahCustom] = useState(false);
  // OnChange
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setError((error) => ({
      ...error,
      [name]: "",
    }));
  };

  // UseEffect
  useEffect(() => {
    if (profile) {
      setForm((form) => ({ ...form, ...profile }));
      setSekolahCustom(profile.sekolah_custom ?? false);
    }
  }, [profile]);
  useEffect(() => {}, []);

  // Validate
  const validate = async () => {
    const newError = { ...error };

    const {
      nama,
      no_hp,
      sekolah,
      provinsi,
      kota,
      jurusanKelas,
      kampus,
      jurusan,
    } = form;

    // Akhir
    if (!nama) {
      newError.nama = "Nama Wajib diisi";
    }

    if (!no_hp) {
      newError.no_hp = "No. Handphone Wajib diisi";
    } else if (no_hp.length < 6) {
      newError.no_hp = "No. Handpone Harus Berisikan 6 Digit Nomor Atau Lebih";
    } else if (no_hp.length > 15) {
      newError.no_hp = "No. Handpone Maximal Berisikan 15 Digit Nomor";
    } else if (!isNumber(trim(no_hp))) {
      newError.no_hp = "No. Handphone Harus Berupa Nomor";
    }

    if (sekolah_custom) {
      if (!sekolah.nama) {
        newError.sekolah = "Sekolah Wajib Diisi";
      }
    } else if (!sekolah.sekolah_id) {
      newError.sekolah = "Sekolah Wajib Diisi";
    }

    if (!provinsi.provinsi_id) {
      newError.provinsi = "Provinsi wajib diisi";
    }

    if (!kota.kota_id) {
      newError.kota = "Kota wajib diisi";
    }

    if (form.kelas.kelas_id === "2" || form.kelas.kelas_id === "3") {
      if (!jurusanKelas.jurusankelas_id) {
        newError.jurusanKelas = " Jurusan Kelas Wajib Diisi";
      }
    }

    if (!kampus.ptn_id) {
      newError.kampus = "Kampus Wajib diisi";
    }

    if (!jurusan.jurusan_id) {
      newError.jurusan = "Jurusan Wajib diisi";
    }

    return newError;
  };

  // OnSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const findErrors = await validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
      enqueueSnackbar(
        `Error - ${
          findErrors.nama ||
          findErrors.no_hp ||
          findErrors.username ||
          findErrors.provinsi ||
          findErrors.kota ||
          findErrors.sekolah ||
          findErrors.kelas ||
          findErrors.jurusanKelas ||
          findErrors.kampus ||
          findErrors.jurusan
        }`,
        { variant: "error" }
      );
    } else {
      try {
        setSubmiting(true);
        const date = new Date();
        let data = null;
        if (form.kelas.kelas_id === "1" || form.kelas.kelas_id === "Lulus") {
          data = {
            jurusankelas_id: "",
            nama: "",
          };
        } else {
          data = {
            jurusankelas_id: form.jurusanKelas.jurusankelas_id,
            nama: form.jurusanKelas.nama,
          };
        }

        await siswaDoc.set(
          {
            nama: form.nama,
            no_hp: trim(form.no_hp),
            // username: form.username,
            kampus: form.kampus,
            sekolah: form.sekolah,
            provinsi: form.provinsi,
            kota: form.kota,
            kelas: form.kelas,
            jurusan: form.jurusan,
            jurusanKelas: data,
            diperbarui_pada: date,
            sekolah_custom,
          },
          { merge: true }
        );
        // Show Notification
        enqueueSnackbar(`Data Telah Berhasil Diperbarui.`, {
          variant: "success",
        });
        setSubmiting(false);
        if (pageEvent === "home") {
          props.history.push("/home");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // State
  const [open, setOpen] = useState(false);

  // OnClose
  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    if (pageEvent === "settings") {
      props.history.push("/settings");
    } else if (pageEvent === "home") {
      props.history.push("/home");
    } else {
      props.history.push("/home");
    }
  };

  const handleBuatCode = async () => {
    let newError = {};
    setSubmiting(true);
    if (!form.username) {
      newError.username = "Username Wajib diisi";
    } else if (form.username !== profile.username) {
      const validateUsername = functions.httpsCallable("validateUsername");
      const result = await validateUsername({
        username: form.username,
      });
      if (!result.data.available) {
        const recommendation = result?.data?.recommendation.join(", ");
        newError.username = `Username tidak tersedia, rekomendasi: ${recommendation}`;
      }
    }

    if (profile?.count === 1) {
      newError.username = "Kode teman hanya dapat dirubah 1 kali";
    }

    if (newError.username) {
      setSubmiting(false);
      return setError(newError);
    } else {
      if (form?.username !== profile?.username) {
        await siswaDoc.set(
          {
            username: form.username,

            count: 1,
          },
          { merge: true }
        );
        // Show Notification
        enqueueSnackbar(`Kode Teman Berhasil Diperbarui.`, {
          variant: "success",
        });
      }

      setSubmiting(false);
    }
  };

  if (isSubmiting) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      <TopNav />
      <div className={classes.pageHeading}>
        <h2 className={classes.pageTitle}>Edit Profil</h2>
        <p className={classes.pageSubtitle}>
          Masukkan Data Diri Kamu Dengan Benar Dan Lengkap
        </p>
      </div>
      <div className={classes.profileBody}>
        <div className={classes.profileFormWrap}>
          <form onSubmit={handleSubmit}>
            <div className={classes.profileForm}>
              <div className={classes.profileAva}>
                <UploadFoto />
              </div>
              <div className={classes.profileTop}>
                <div className={classes.formRow}>
                  <TextField
                    id="nama"
                    name="nama"
                    label="Nama Lengkap"
                    value={form.nama}
                    autoComplete="off"
                    onChange={handleChange}
                    className={classes.textField}
                    helperText={error.nama}
                    error={!!error.nama}
                    disabled={isSubmiting}
                  />
                </div>
                <div className={classes.formRow}>
                  <TextField
                    id="no_hp"
                    name="no_hp"
                    label="Nomor Handphone"
                    className={classes.textField}
                    value={form.no_hp}
                    autoComplete="off"
                    onChange={handleChange}
                    helperText={error.no_hp}
                    error={!!error.no_hp}
                    disabled={isSubmiting}
                  />
                </div>
              </div>
              <div className={classes.friendCodeBox}>
                <h5 className={classes.fcTitle}>Kode Teman</h5>
                <p className={classes.fcDesc}>
                  Bagikan Kode Teman ini ke temanmu ketika mendaftar agar kalian
                  berdua mendapatkan coin gratis!
                </p>
                <FormControl
                  fullWidth
                  className={classes.formCtrl}
                  error={!!error.username}
                >
                  <Input
                    id="friendcode"
                    onChange={handleChange}
                    name="username"
                    type="text"
                    value={form.username}
                    disableUnderline={true}
                    disabled={isSubmiting}
                    autoComplete="off"
                    readOnly={profile?.count >= 1}
                    fullWidth={true}
                    className={classes.inputFc}
                    endAdornment={
                      <InputAdornment position="end">
                        <CopyToClipboard
                          text={form.username}
                          onCopy={() => {
                            enqueueSnackbar(`Kode teman berhasil disalin.`, {
                              variant: "success",
                            });
                          }}
                        >
                          <Button aria-label="copy" className={classes.copyBtn}>
                            Copy
                          </Button>
                        </CopyToClipboard>
                        <Button
                          aria-label="buat code"
                          variant="contained"
                          className={classes.makeCodeBtn}
                          onClick={handleBuatCode}
                          disabled={isSubmiting || profile?.count >= 1}
                        >
                          Buat Code
                        </Button>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>{error.username}</FormHelperText>
                </FormControl>
              </div>
              {/* <div className={classes.formRow}>
                <TextField
                  id="username"
                  name="username"
                  label="Username"
                  value={form.username}
                  autoComplete="off"
                  onChange={handleChange}
                  className={classes.textField}
                  helperText={error.username}
                  error={error.username ? true : false}
                  disabled={isSubmiting}
                />
              </div> */}
              <p className={classes.Select}>
                Informasi asal sekolah lengkap dengan provinsi dan kota
                /kabupaten
              </p>
              <SelectProvinsi
                className={classes.formRow}
                value={{
                  label: form.provinsi.nama,
                  value: form.provinsi.provinsi_id,
                }}
                onChange={(newValue) => {
                  let value = {
                    nama: "",
                    provinsi_id: "",
                  };

                  if (newValue) {
                    value = {
                      nama: newValue.label,
                      provinsi_id: newValue.value,
                    };
                  }

                  handleChange({
                    target: {
                      value,
                      name: "provinsi",
                    },
                  });
                  handleChange({
                    target: {
                      value: {
                        nama: "",
                        kota_id: "",
                      },
                      name: "kota",
                    },
                  });
                  handleChange({
                    target: {
                      value: {
                        nama: "",
                        sekolah_id: "",
                      },
                      name: "sekolah",
                    },
                  });
                }}
                TextFieldProps={{
                  disabled: isSubmiting,
                  helperText: error.provinsi,
                  error: !!error.provinsi,
                }}
              />
              <SelectKota
                className={classes.formRow}
                value={{ label: form.kota.nama, value: form.kota.kota_id }}
                onChange={(newValue) => {
                  let value = {
                    nama: "",
                    kota_id: "",
                  };

                  if (newValue) {
                    value = {
                      nama: newValue.label,
                      kota_id: newValue.value,
                    };
                  }

                  handleChange({
                    target: {
                      value,
                      name: "kota",
                    },
                  });
                  handleChange({
                    target: {
                      value: {
                        nama: "",
                        sekolah_id: "",
                      },
                      name: "sekolah",
                    },
                  });
                }}
                provinsi_id={form.provinsi.provinsi_id}
                TextFieldProps={{
                  disabled: isSubmiting,
                  helperText: error.kota,
                  error: !!error.kota,
                }}
              />
              {/* {sekolah_custom ? ( */}
              <TextField
                id="sekolah"
                name="sekolah"
                label="Nama SMA / SMK / Sederajat"
                style={{ marginBottom: 15 }}
                className={classes.textField}
                value={form.sekolah?.nama}
                onChange={(e) => {
                  let value = {
                    nama: e.target?.value,
                    sekolah_id: "",
                  };
                  handleChange({
                    target: {
                      value,
                      name: "sekolah",
                    },
                  });
                  // update agustus 2022. karena nama sekolah diisi manual oleh user, maka sekolah_custom === true
                  setSekolahCustom(true);
                }}
                helperText={error.sekolah}
                error={!!error.sekolah}
                disabled={isSubmiting}
              />
              {/* ) : (
                <SelectSekolah
                  className={classes.formRow}
                  value={{
                    label: form.sekolah.nama,
                    value: form.sekolah.sekolah_id,
                  }}
                  onChange={(newValue) => {
                    let value = {
                      nama: "",
                      sekolah_id: "",
                    };

                    if (newValue) {
                      value = {
                        nama: newValue.label,
                        sekolah_id: newValue.value,
                      };
                    }

                    handleChange({
                      target: {
                        value,
                        name: "sekolah",
                      },
                    });
                  }}
                  provinsi_id={form.provinsi.provinsi_id}
                  kota_id={form.kota.kota_id}
                  TextFieldProps={{
                    disabled: isSubmiting,
                    helperText: error.sekolah,
                    error: error.sekolah ? true : false,
                  }}
                />
              )}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={sekolah_custom}
                      onChange={() => {
                        setSekolahCustom((sekolah_custom) => !sekolah_custom);
                      }}
                      value="check"
                    />
                  }
                  label="Sekolah saya tidak terdaftar."
                  disabled={isSubmiting}
                />
              </FormGroup> */}
              <SelectKelas
                className={classes.formRow}
                value={{ label: form.kelas.nama, value: form.kelas.kelas_id }}
                onChange={(newValue) => {
                  let value = {
                    nama: "",
                    kelas_id: "",
                  };

                  if (newValue) {
                    value = {
                      nama: newValue.label,
                      kelas_id: newValue.value,
                    };
                  }

                  handleChange({
                    target: {
                      value,
                      name: "kelas",
                    },
                  });
                }}
                TextFieldProps={{
                  disabled: isSubmiting,
                  helperText: error.kelas,
                  error: !!error.kelas,
                }}
              />
              {form.kelas.nama === "Kelas 11" ||
              form.kelas.nama === "Kelas 12" ? (
                <SelectJurusanKelas
                  className={classes.formRow}
                  value={{
                    label: form.jurusanKelas.nama,
                    value: form.jurusanKelas.jurusankelas_id,
                  }}
                  onChange={(newValue) => {
                    let value = {
                      nama: "",
                      jurusankelas_id: "",
                    };

                    if (newValue) {
                      value = {
                        nama: newValue.label,
                        jurusankelas_id: newValue.value,
                      };
                    }

                    handleChange({
                      target: {
                        value,
                        name: "jurusanKelas",
                      },
                    });
                  }}
                  TextFieldProps={{
                    disabled: isSubmiting,
                    helperText: error.jurusanKelas,
                    error: !!error.jurusanKelas,
                  }}
                />
              ) : null}
              <p className={classes.Select}>
                Pilih Kampus dan Jurusan Impian Kamu
              </p>
              <SelectKampus
                className={classes.formRow}
                value={{ label: form.kampus.nama, value: form.kampus.ptn_id }}
                onChange={(newValue) => {
                  let value = {
                    nama: "",
                    ptn_id: "",
                  };

                  if (newValue) {
                    value = {
                      nama: newValue.label,
                      ptn_id: newValue.value,
                    };
                  }

                  handleChange({
                    target: {
                      value,
                      name: "kampus",
                    },
                  });
                  handleChange({
                    target: {
                      value: {
                        nama: "",
                        jurusan_id: "",
                      },
                      name: "jurusan",
                    },
                  });
                }}
                TextFieldProps={{
                  disabled: isSubmiting,
                  helperText: error.kampus,
                  error: !!error.kampus,
                }}
              />
              <SelectJurusan
                className={classes.formRow}
                value={{
                  label: form.jurusan.nama,
                  value: form.jurusan.jurusan_id,
                }}
                onChange={(newValue) => {
                  let value = {
                    nama: "",
                    jurusan_id: "",
                  };

                  if (newValue) {
                    value = {
                      nama: newValue.label,
                      jurusan_id: newValue.value,
                    };
                  }

                  handleChange({
                    target: {
                      value,
                      name: "jurusan",
                    },
                  });
                }}
                ptn_id={form.kampus.ptn_id}
                TextFieldProps={{
                  disabled: isSubmiting,
                  helperText: error.jurusan,
                  error: !!error.jurusan,
                }}
              />
              <div className={classes.formRowBtn}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmiting}
                  size="large"
                >
                  Perbarui
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle className={classes.DialogTitle}>
            {"Meninggalkan Halaman?"}
          </DialogTitle>
          <p className={classes.txt}>
            Kamu yakin ingin keluar dari halaman ini tanpa menyimpan perubahan ?
          </p>
          <Button onClick={handleAccept} fullWidth className={classes.btnOke}>
            Ya
          </Button>
          <Button onClick={handleClose} fullWidth className={classes.btnNo}>
            Tidak
          </Button>
        </Dialog>
      </div>
    </div>
  );
}

export default withRouter(EditProfile);
