// React
import React, { useCallback, useEffect, useState } from "react";

// Styles
import "../../components/pbTheme.css";
import "../home/styles/style.css";
import useStyles from "./styles";

// MUI
import { Tab, Tabs } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

// Router
import { useSnackbar } from "notistack";
import { functions, useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";
import { useTo } from "../../components/TryoutProvider";

// Component
import orderBy from "lodash/orderBy";
import { Helmet } from "react-helmet";
import BottomNav from "../../components/BottomNav";
import TopNav from "../../components/TopNav";
import ujian from "../../images/to-icon.png";
import DialogTo from "../home/DialogTo";
import DialogTopUp from "../home/DialogTopUp";
import DialogTransaksi from "../home/DialogTransaksiTo";
import ListTryout from "./listTryout";
function TO({ history }) {
  // UseStyles
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // Profie
  const { setPageEvent } = useSiswa();
  const { user } = useFirebase();
  const { tryout, hasiltryout } = useTo();
  const [listTo, setListTo] = useState([]);
  const [topUp, setTopUp] = useState(false);
  const [buyTo, setBuy] = useState({
    open: false,
    judul: "",
    tryout_id: "",
    coin: "",
    waktu: 0,
    isload: false,
    isExpired: false,
    jumlah: 0,
    kode_rahasia: "",
    kategori: "",
  });
  const [toDialog, setToDialog] = useState({
    open: false,
    namaTo: "",
  });
  // open to dialog automatcically
  const [check, setCheck] = useState(false);
  const handleTryout = useCallback(
    ({ judul, tryout_id, coin, waktu, isExpired, jumlah, kategori }) => {
      setBuy((buyto) => ({
        ...buyto,
        open: true,
        judul,
        tryout_id,
        coin,
        waktu,
        isExpired,
        jumlah,
        kategori,
      }));
    },
    []
  );
  const handleBeli = async (value) => {
    const { tryout_id } = buyTo;
    setBuy((buyTo) => ({
      ...buyTo,
      isload: true,
    }));
    try {
      //
      const getTryout = functions.httpsCallable("getTryout");
      await getTryout({
        tryout_id: tryout_id,
        kode_rahasia: buyTo?.kode_rahasia,
        email: user?.email,
        kode: value,
      });
      //
      setToDialog({
        open: true,
        namaTo: buyTo.judul,
      });
      //
      setCheck(false);
    } catch (e) {
      if (e.message === "koin tidak cukup") {
        setTopUp(true);
      } else {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      setCheck(false);
    }
    history.push("/listTryout");

    setBuy((buyTo) => ({
      ...buyTo,
      isload: false,
      open: false,
      kode_rahasia: "",
    }));
  };

  const [activeTab, setActiveTab] = useState("all");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (tryout) {
      const toDoc =
        hasiltryout.sort((a, b) => {
          return b.created_at.toMillis() - a.created_at.toMillis();
        }) || [];
      setListTo(
        tryout.map((doc) => {
          const to = toDoc.find((to) => to.tryout.id === doc.id);
          let orderhasiltryout = 0;
          if (doc.status === "coming soon") {
            orderhasiltryout = 1;
          } else if (to?.status === "on-progress") {
            orderhasiltryout = 2;
          } else if (to?.status === "purchased") {
            orderhasiltryout = 3;
          } else if (to?.status === "done") {
            orderhasiltryout = 5;
          } else {
            orderhasiltryout = 4;
          }
          return {
            hasiltryout: to,
            orderhasiltryout,
            ...doc,
          };
        })
      );
    }
  }, [tryout, hasiltryout]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>PakBudi | Tryout</title>
      </Helmet>
      <TopNav />
      <Grid container spacing={2} className={classes.page2}>
        {/* TRYOUT */}
        <Grid item xs={12}>
          <div style={{ overflow: "auto" }}>
            <Tabs
              value={activeTab}
              onChange={(event, tab) => handleTabChange(tab)}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab
                label="All"
                value="all"
                className={{
                  root: classes.rootTab,
                  selected: classes.selectedTab,
                }}
              />
              <Tab
                label="SNBT"
                value="snbt"
                className={{
                  root: classes.rootTab,
                  selected: classes.selectedTab,
                }}
              />
              <Tab
                label="Kompetisi Beasiswa"
                value="beasiswa"
                className={{
                  root: classes.rootTab,
                  selected: classes.selectedTab,
                }}
              />
              <Tab
                label="Olimpiade"
                value="olimpiade"
                className={{
                  root: classes.rootTab,
                  selected: classes.selectedTab,
                }}
              />
            </Tabs>
          </div>
          <div style={{ padding: 5 }}>
            {listTo.length > 0 ? (
              orderBy(
                listTo,
                ["orderhasiltryout", "order"],
                ["asc", "asc"]
              ).map((item) => {
                // Filter tryout berdasarkan kategori yang aktif
                if (activeTab !== "all" && item.category !== activeTab) {
                  return null;
                }

                return (
                  <React.Fragment key={item.id}>
                    {/* Render tryout sesuai dengan statusnya */}
                    {item.status !== "draft" ? (
                      // Jika statusnya bukan draft
                      <ListTryout
                        handleTryout={handleTryout}
                        item={item}
                        openTO={null}
                      />
                    ) : (
                      <>
                        {item.hasiltryout && (
                          // Jika statusnya draft tapi sudah ada hasil
                          <>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                              <Grid item xs>
                                <Typography
                                  align="left"
                                  color="primary"
                                  className={classes.boxTxt5}
                                >
                                  {item.judul}
                                </Typography>
                                <Typography
                                  align="left"
                                  className={classes.boxTxt6}
                                >
                                  Hasil Jawaban Benar
                                </Typography>
                                {Object.entries(item.hasiltryout.hasil).map(
                                  ([key, item]) => {
                                    return (
                                      <Typography
                                        key={key}
                                        align="left"
                                        className={classes.boxTxt7}
                                        style={{}}
                                      >
                                        {item.nama} : {item.benar}
                                      </Typography>
                                    );
                                  }
                                )}
                              </Grid>
                              <Grid item>
                                <Grid container>
                                  <Grid item>
                                    <IconButton
                                      color="secondary"
                                      size="small"
                                      disabled
                                    />
                                  </Grid>
                                  <Grid item>
                                    <IconButton
                                      color="secondary"
                                      size="small"
                                      onClick={() =>
                                        history.push(
                                          `../home/hasiltryout/${item.hasiltryout.id}`
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item>
                                    <IconButton
                                      color="secondary"
                                      size="small"
                                      disabled={item.status === "draft"}
                                      onClick={() =>
                                        handleTryout({
                                          judul: item.judul,
                                          tryout_id: item.id,
                                          coin: item.coin,
                                          waktu: item.waktu,
                                          isExpired: false,
                                          jumlah: item.jumlah_soal,
                                        })
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <img src={ujian} alt="" height="40px" />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              // Tidak ada data
              <React.Fragment>
                <Divider className={classes.divider} />
                <Typography align="center" variant="caption">
                  Belum ada ujian atau tryout tersedia
                </Typography>
              </React.Fragment>
            )}
          </div>
        </Grid>
      </Grid>

      <DialogTo
        {...toDialog}
        handleClose={() => {
          setToDialog(() => {
            return {
              ...toDialog,
              open: false,
            };
          });
        }}
      />
      <DialogTransaksi
        {...buyTo}
        setBuy={(value) => {
          setBuy((buy) => ({
            ...buy,
            kode_rahasia: value,
          }));
        }}
        hadleBuyTo={(value) => {
          handleBeli(value);
        }}
        handleChecked={() => {
          setCheck(!check);
        }}
        check={check}
        handleClose={() => {
          setBuy((buyTo) => {
            return {
              ...buyTo,
              open: false,
            };
          });
        }}
      />
      <DialogTopUp
        open={topUp}
        handleTopUp={() => {
          setPageEvent("home");
          history.push("/myCoin");
        }}
        handleNoTopUp={() => setTopUp(false)}
      />
      <BottomNav />
    </div>
  );
}

export default TO;
