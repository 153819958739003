import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    btnVerify: {
        textTransform: 'capitalize'
    },
    DialogTitle: {
        textAlign: 'center' , 
        width: '100%'
    },
    txt: {
        textAlign: 'center', 
        fontSize: '14px', 
        width: '80%', 
        margin: '0 auto'
    },
    sub: {
        color: '#f39c12'
    },
    btnOke: {
        padding: '10px', 
        marginTop: '20px', 
        borderTop: '1px solid #dedede', 
        borderBottom: '1px solid #dedede',
        textTransform: 'capitalize',
    }
}));

export default useStyles;