//
import React from "react";
//
import { makeStyles } from "@material-ui/core/styles";
//
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
//
import Slide from "@material-ui/core/Slide";
//
import update from "../../images/img-update.png";
import logo from "../../images/new-logo-c-v.png";
//
import { useSw } from "../SwProvider";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#EFEFEF",
    paddingTop: 75,
    alignItems: "center",
  },
  btnUpdate: {
    marginTop: 40,
    backgroundColor: "#2FBBEC",
    fontFamily: "Poppins",
    textTransform: "capitalize",
    width: 150,
    "&:hover": {
      backgroundColor: "#5ECBF0",
    },
  },
  title: {
    fontSize: 15,
    fontWeight: 400,
    textAlign: "center",
    color: "#4F4F4F",
    fontFamily: "Poppins",
  },
  subtitle: {
    maxWidth: 245,
    fontSize: 11.67,
    fontWeight: 400,
    textAlign: "center",
    color: "#4F4F4F",
    fontFamily: "Poppins",
  },
  logo: {
    height: 120,
  },
  img: {
    width: 250,
    margin: "36px 0px 20px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { isUpdateAvailable, updateAssets } = useSw();

  React.useEffect(() => {
    if (isUpdateAvailable) {
      setOpen(true);
    }
  }, [isUpdateAvailable]);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <div className={classes.box}>
        <img alt="logo" className={classes.logo} src={logo} />
        <img alt="img" className={classes.img} src={update} />
        <Typography className={classes.title}>Hore!</Typography>
        <Typography className={classes.subtitle}>
          PakBudi ada pembaruan nih! Klik tombol dibawah untuk memuat ulang
          halaman Pak Budi dengan update terbaru
        </Typography>
        <Button
          className={classes.btnUpdate}
          onClick={updateAssets}
          variant="contained"
          color="primary"
          size="large"
        >
          Muat Ulang
        </Button>
      </div>
    </Dialog>
  );
}
