import React from 'react';

import { Route, Switch } from 'react-router-dom';
import useStyles from './styles/index';
import HasilTest from './game/hasilTest';
import Home from './home';
import Game from './game';

function TesMinatBakat(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.appBody}>
                <Switch>
                    <Route path='/tesminatbakat/game' component={Game} />
                    <Route path="/tesminatbakat/result/:symbol" component={HasilTest} />
                    <Route component={Home} />
                </Switch>
            </div>
        </div>
    );

}

export default TesMinatBakat;