import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    tcBody:{
        backgroundColor: '#fff',
        margin: '20px auto 50px',
        padding: 10,
        borderRadius: 8,
        '@media(min-width: 320px)': {
            width: '90%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    pageContent:{
        padding: '10px 20px',
        textAlign: 'left',
    }
}))

export default useStyles;