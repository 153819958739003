// React
import React from "react";

// Router
import { Route, Switch } from "react-router-dom";

// Styles
import useStyles from "./styles";

// Components
import TopNav from "../../components/TopNav";
import BottomNav from "../../components/BottomNav";
import Bagikan from "../Bagikan";
import Notifikasi from "../notifikasi";
import MyCoin from "../myCoin";
import Berita from "../berita";
import BeritaDetail from "../berita/beritaDetail";
import IsiCoin from "../myCoin/isiCoin";
import PromoCoin from "../myCoin/promoCoin";
import Transfer from "../myCoin/transfer";
import KonfirmasiTransfer from "../myCoin/konfirmasiTransfer";
import ListTransaksi from "../myCoin/listTransaksi";
import Konsultasi from "../konsultasi";
import Misi from "../misi";
import ListMisi from "../misi/listMisi";
import KonfirmasiMisi from "../misi/konfirmasiMisi";

function LayoutD() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.blueBg}></div>
      <TopNav />
      <Switch>
        <Route path="/myCoin" component={MyCoin} />
        <Route path="/notifikasi" component={Notifikasi} />
        <Route path="/berita" component={Berita} />
        <Route path="/beritaDetail/:id" component={BeritaDetail} />
        <Route path="/isiCoin/:total/:coin" component={IsiCoin} />
        <Route path="/promoCoin/:total/:coin" component={PromoCoin} />
        <Route
          path="/rinciantransfer/:total/:method/:doc/:no"
          component={Transfer}
        />
        <Route path="/konfirmasitransfer/:id" component={KonfirmasiTransfer} />
        <Route path="/listtransaksi" component={ListTransaksi} />
        <Route path="/bagikan" component={Bagikan} />
        <Route path="/chat" component={Konsultasi} />
        <Route path="/konsultasi" component={Konsultasi} />
        <Route path="/konfirmasimisi/:id" component={KonfirmasiMisi} />
        <Route path="/misi" component={Misi} />
        <Route path="/listmisi" component={ListMisi} />
      </Switch>
      <Route
        path={[
          "/myCoin",
          "/notifikasi",
          "/berita",
          "/beritaDetail/:id",
          "/isiCoin/:total/:coin",
          "promoCoin/:total/:coin",
          "/rinciantransfer/:total/:method/:doc/:no",
          "/konfirmasitransfer/:id",
          "/listtransaksi",
          "/chat",
          "/konsultasi",
          "/misi",
          "/listmisi",
          "/konfirmasimisi/:id",
        ]}
        render={() => {
          return <BottomNav />;
        }}
      />
    </div>
  );
}

export default LayoutD;
