import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 120,
        userSelect: 'none',
        msUserSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none'
    },
    pageTitle:{
        color: theme.palette.textColor.gray,
        fontSize: 24,
        margin: 0,
    },
    pageSubtitle:{
        // width: '70%',
        margin: '0 auto',
        color: theme.palette.textColor.gray,
        fontSize: 10,
        marginTop: 0,
    },
    Select: {
        color: theme.palette.textColor.gray,
        marginTop: '25px',
        fontSize: 14,
    },
    profileBody:{
        marginBottom: 100,
        position: 'relative',
    },
    profileAva:{
        position: 'absolute',
        top: 0,
        left: -15,
        width: 109,
        height: 109,
    },
    profileTop:{
        paddingLeft: 140,
        width: '100%',
        marginTop: 25,
    },
    profileForm:{
        margin: '0 auto 10px',
        position: 'relative',
        '@media(min-width: 320px)': {
            width: '90%',
        },
        '@media(min-width: 960px)': {
            width: '50%',
        },
    },
    friendCodeBox:{
        margin: '40px 0 20px',
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 10,
    },
    fcTitle:{
        margin: 0,
        color: theme.palette.textColor.gray,
        fontSize: 13,
        fontWeight: 600,
    },
    fcDesc:{
        margin: '0 0 10px',
        color: theme.palette.textColor.gray,
        fontSize: 10,
        fontWeight: 500,
    },
    inputFc:{
        backgroundColor: theme.palette.lightBlue.secondary,
        padding: 10,
        borderRadius: 7,
        color: theme.palette.darkBlue.main,
        fontSize: 19,
        fontWeight: 700,
    },
    copyBtn:{
        color: theme.palette.lightBlue.main,
        fontSize: 13,
        fontWeight: 600,
        width: 36,
        height: 27,
        padding: 0,
    },
    makeCodeBtn:{
        backgroundColor: theme.palette.orange.main,
        color: '#fff',
        fontSize: 13,
        width: 112,
        height: 27,
        padding: 0,
        fontWeight: 600,
    },
    historyBack: {
        position: 'absolute',
        left: 0,
        top: 25,
    },
    formRow:{
        width: '100%',
        margin: '0 auto 15px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    textField:{
        width: '100%',
    },
    formRowBtn:{
        margin: '10px auto 15px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column nowrap',
    },
    DialogTitle: {
        textAlign: 'center', 
        width: '100%'
    },
    txt: {
        textAlign: 'center', 
        width: '75%', 
        margin: '0 auto'
    },
    btnOke: {
        padding: '10px', 
        marginTop: '20px', 
        borderTop: '1px solid #dedede', 
        color: '#38b1db', 
        borderBottom: '1px solid #dedede', 
        textTransform: 'capitalize',
    },
    btnNo: {
        padding: '10px', 
        textTransform: 'capitalize',
    },
}))

export default useStyles;