// React
import React from 'react';

// input
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import logo from '../../images/esport-logo.png';


import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// Styles
import useStyles from './styles';

import { firestore } from '../../components/FirebaseProvider';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import AppPageLoading from '../../components/AppLoading';
import groupBy from 'lodash/groupBy';
import { useHistory } from 'react-router-dom';
function Esport() {
    const history = useHistory();
    const esportCol = firestore.collection('esport').orderBy('diperbarui_pada', 'desc');
    const [esport, loading] = useCollectionData(esportCol);

    // UseStyles
    const classes = useStyles();

    if (loading) {
        return <AppPageLoading />
    }


    const groupEsport = groupBy(esport, item => {
        return item.kategori;
    })

    const komunitas = groupEsport['Komunitas'];
    const pendaftaran = groupEsport['Pendaftaran'];
    const info = groupEsport['Info'];
    return (
        <div className={classes.root}>
            <div className={classes.pageHeader}>
                <div className={classes.toggleBack}>
                    <Button onClick={() => {
                        history.push("/home")
                    }} className={classes.historyBack}><ChevronLeftIcon /></Button>
                </div>
                <div className={classes.logoImg}>
                    <img className={classes.mainLogo} src={logo} alt="esport logo" />
                </div>
                <div className={classes.otherNav}>

                </div>
            </div>
            <div className={classes.pageBody}>
                <div className={classes.bodySection}>
                    <h2 className={classes.pageTitle}>Komunitas E-sport</h2>
                    <p className={classes.pageSubtitle}>Tentukan lawan / kawanmu disini!</p>
                    <div className={classes.linkBlock}>
                        {komunitas && komunitas.map(item => (<div key={item.uid} className={classes.linkBox}>
                            <a href={item.redirectUrl} target="_blank" rel="noopener noreferrer"><img src={item.gambar} alt={item.judul} /></a>
                            <span>{item.judul}</span>
                        </div>))}
                    </div>
                </div>
                <div className={classes.bodySection}>
                    <h2 className={classes.pageTitle}>Pendaftaran E-sport</h2>
                    <p className={classes.pageSubtitle}>Bertanding dan dapatkan hadiahnya!</p>
                    <div className={classes.linkBlock}>
                        {pendaftaran && pendaftaran.map(item => (<div key={item.uid} className={classes.linkBoxTwo}>
                            <a href={item.redirectUrl} target="_blank" rel="noopener noreferrer"><img src={item.gambar} alt={item.judul} /></a>
                            <span>{item.judul}</span>
                        </div>))}

                    </div>
                </div>
                <div className={classes.bodySection}>
                    <h2 className={classes.pageTitle}>Info E-sport</h2>
                    <div className={classes.linkBlock}>
                        {info && info.map(item => (<div key={item.uid} className={classes.linkBoxTri}>
                            <a href={item.redirectUrl} target="_blank" rel="noopener noreferrer"><img src={item.gambar} alt={item.judul} /></a>
                            <span>{item.judul}</span>
                        </div>))}
                    </div>
                </div>


            </div>
        </div>
    );

}

export default Esport;