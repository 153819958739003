import React, { useState } from "react";
import { Divider, Typography } from "@material-ui/core";
import "./styles/style.css";

function ModalItem({ action, image, label, showDivider = true }) {
  return (
    <div onClick={action} className="pb-modal-item-wrapper">
      <div className="pb-modal-item">
        <img src={image} alt="home-action-icon" />
        <Typography>{label}</Typography>
      </div>
      {showDivider && <Divider />}
    </div>
  );
}

export default ModalItem;
