let MIDTRANS_CLIENT_KEY;
let MIDTRANS_SCRIPT_URL;
if (process.env.REACT_APP_FIREBASE_PROD === "YES") {
  MIDTRANS_CLIENT_KEY = "Mid-client-QJ8H1fNhFHibcgCP";
  MIDTRANS_SCRIPT_URL = "https://app.midtrans.com/snap/snap.js";
} else {
  MIDTRANS_CLIENT_KEY = "SB-Mid-client-5kUU5_6RRx_Ily2F";
  MIDTRANS_SCRIPT_URL = "https://app.sandbox.midtrans.com/snap/snap.js";
}
export { MIDTRANS_CLIENT_KEY, MIDTRANS_SCRIPT_URL };
