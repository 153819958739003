import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: 60,
    userSelect: "none",
    msUserSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
  },
  header: {
    backgroundColor: theme.palette.lightBlue.main,
    borderBottomLeftRadius: 13,
    borderBottomRightRadius: 13,
    padding: "0 20px 20px",
    position: "relative",
    margin: "0 auto",
    height: 230,
    "@media(min-width: 960px)": {
      width: "63%",
    },
  },
  messageBody: {
    padding: 32,
    "@media(min-width: 960px)": {
      width: "60%",
      margin: "0 auto",
    },
  },
  headerAfterPayment: {
    backgroundColor: "white",
    borderBottomLeftRadius: 13,
    borderBottomRightRadius: 13,
    padding: "0 20px 20px",
    position: "relative",
    margin: "0 auto",
    "@media(min-width: 960px)": {
      width: "63%",
    },
  },
  bodyWrapper: {
    position: "absolute",
    backgroundColor: "white",
    width: "90%",
    height: 375,
    top: 90,
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: 16,
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    padding: 16,
    "@media(min-width: 960px)": {
      width: "40%",
    },
  },
  logo: {
    width: 130,
  },
  logoInPayment: {
    width: 130,
    position: "absolute",
    top: 20,
    left: "50%",
    transform: "translateX(-50%)",
  },
  statusImage: {
    width: 100,
  },
  desc: {
    fontSize: "0.75rem",
    color: "gray",
  },
  descPoint: {
    fontSize: "0.75rem",
    color: "gray",
    textAlign: "left",
    marginTop: 4,
  },
  divider: {
    height: 260,
  },
  checkoutSection: {
    padding: 16,
    paddingTop: 0,
  },
  tmbStart: {
    backgroundColor: "#154972",
    color: "white",
    textTransform: "capitalize",
    marginTop: 24,
  },
  tmbCoin: {
    backgroundColor: theme.palette.lightBlue.main,
    color: "black",
    textTransform: "capitalize",
    marginTop: 24,
  },
  jamPasir: {
    width: 50,
    margin: "30px 0",
  },
  startBtn: {
    marginBottom: 20,
    textTransform: "capitalize",
  },
  icoCoin: {
    width: 20,
    height: 20,
    position: "relative",
    display: "inline-block",
    marginRight: 8,
  },
  imgCoin: {
    width: 20,
    height: 20,
    position: "absolute",
    left: 0,
    top: 5,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  logoWrap: {
    position: "relative",
    width: 180,
    margin: "auto",
  },
  mainLogo: {
    marginTop: 5,
    width: 150,
  },
  pageName: {
    position: "absolute",
    color: theme.palette.textColor.white,
    fontSize: 12,
    top: -5,
    left: 56,
    textTransform: "capitalize",
    fontStyle: "italic",
  },
  actionButton: {
    textTransform: "capitalize",
    fontSize: 10,
    fontWeight: 600,
    color: "#777",
  },
}));

export default useStyles;
