import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import useStyles from './styles';

import { baseURL } from '../../images/s3';

function DialogBonus({open, handleOk}) {
    const classes = useStyles();

    return (
        <Dialog
        open={open}
    >
        <DialogTitle className={classes.DialogTitle} >
            <img className={classes.logoImg} src={`${baseURL}new-logo-w.png`} alt="" />
        </DialogTitle>
        <DialogContent>
            <DialogContentText className={classes.txt}>
                Selamat Kamu Mendapatkan <b>30 Coin</b> Bonus Dari PakBudi
            </DialogContentText>
            <DialogContentText className={classes.imgCoinwrapp}>
                <img src={`${baseURL}img-coin-double.png`} className={classes.imgCoin} alt="" />
            </DialogContentText>
        </DialogContent>
        <Button
            onClick={handleOk}
            fullWidth
            className={classes.btneditt}
        >
            Ok
        </Button>
    </Dialog>
    )
}

export default DialogBonus
