import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fff',
        paddingBottom: 50,
    },
}))

export default useStyles;