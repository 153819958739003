// React
import React, { useEffect, useState } from "react";

// Styles
import useStyles from "./styles/changeEmailPassword";

// Component
import PasswordHash from "password-hash";
import VerifyHash from "password-hash/lib/password-hash";

// Input
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// Icons

// React Router
import { Link, withRouter } from "react-router-dom";

// Notification
import { useSnackbar } from "notistack";

// Validator
import isEmail from "validator/lib/isEmail";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// UseFirebase
import { useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";

import TopNav from "../../components/TopNav";

// Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogVerifikasi from "../../components/Dialog/DialogVerifikasi";

function ChangeEmailPassword(props) {
  // UseStyles
  const classes = useStyles();

  // UseFirebase
  const { user, firestore } = useFirebase();
  const { profile } = useSiswa();

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [form, setForm] = useState({
    email: "",
    password: "",
    repeat_password: "",
    oldpassword: "",
    showPassword: false,
    showPasswordd: false,
    showPassworddd: false,
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    repeat_password: "",
    oldpassword: "",
  });

  const handleClickShowPassword = () => {
    setForm({ ...form, showPassword: !form.showPassword });
  };

  const handleClickShowPasswordd = () => {
    setForm({ ...form, showPasswordd: !form.showPasswordd });
  };

  const handleClickShowPassworddd = () => {
    setForm({ ...form, showPassworddd: !form.showPassworddd });
  };

  useEffect(() => {
    if (profile) {
      setForm({
        email: profile.email,
        password: "",
        repeat_password: "",
        oldpassword: "",
      });
    }
  }, [profile, setForm]);

  const [isSubmiting, setSubmiting] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  // Document Ref
  const siswaDoc = firestore.doc(`siswa/${user.uid}`);

  // OnChange
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setError((error) => ({
      ...error,
      [name]: "",
    }));
  };

  // Validate
  const validate = () => {
    const newError = { ...error };

    const { email } = form;

    if (!email) {
      newError.email = "Email Wajib diisi";
    } else if (!isEmail(email)) {
      newError.email = "Email Tidak Valid";
    }

    if (email === user.email) {
      newError.email = "Email Tidak Boleh Sama";
    }

    return newError;
  };

  const validates = () => {
    const newErrors = { ...error };

    const { password, repeat_password } = form;
    const mypassword = VerifyHash.verify(form.oldpassword, profile.password);

    console.log(mypassword);

    if (!mypassword) {
      newErrors.oldpassword = "Kata Sandi Kamu Tidak Sama";
    }

    if (!password) {
      newErrors.password = "Kata Sandi Wajib Diisi";
    }

    if (!repeat_password) {
      newErrors.repeat_password = "Ulangi Kata Sandi Wajib Diisi";
    } else if (repeat_password !== password) {
      newErrors.repeat_password = "Ulangi Kata Sandi Tidak Sama";
    }

    return newErrors;
  };

  // OnSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmiting(true);
        await user.updateEmail(form.email);
        const date = new Date();
        await siswaDoc.set(
          {
            email: form.email,
            diperbarui_pada: date,
          },
          { merge: true }
        );
        // Show Notification
        enqueueSnackbar(`Email Telah Berhasil Diperbarui.`, {
          variant: "success",
        });
        setSubmiting(false);
      } catch (e) {
        const newError = {};

        switch (e.code) {
          case "auth/email-already-in-use":
            newError.email = "Email sudah terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Email tidak valid";
            break;
          case "auth/requires-recent-login":
            newError.email =
              "Silahkan Log Out dan masuk kembali untuk mengganti Email/Password";
            break;
          default:
            newError.email = "Terjadi kesalahan silahkan coba lagi";
            break;
        }
        setError(newError);
      }
    }
  };

  const handlePassword = async (e) => {
    e.preventDefault();

    const findErrors = validates();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmitting(true);
        await user.updatePassword(form.password);
        const date = new Date();
        await siswaDoc.set(
          {
            password: PasswordHash.generate(form.password),
            diperbarui_pada: date,
          },
          { merge: true }
        );
        // Show Notification
        enqueueSnackbar(`Password Telah Berhasil Diperbarui.`, {
          variant: "success",
        });
        setSubmitting(false);
      } catch (e) {
        const newError = {};

        switch (e.code) {
          case "auth/weak-password":
            newError.password = "Kata Sandi Terlalu Lemah";
            break;
          case "auth/requires-recent-login":
            newError.password =
              "Silahkan Log Out dan masuk kembali untuk mengganti Email/Password";
            break;
          default:
            newError.password = "Terjadi kesalahan silahkan coba lagi";
            break;
        }
        setError(newError);
      }
    }
  };

  // State
  const [open, setOpen] = useState(false);

  // OnClose
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <TopNav />
      <div className={classes.changeProfileBody}>
        <h2 className={classes.pageTitle1}>Ubah Email Dan Kata Sandi</h2>
        <form>
          <div className={classes.changeProfileForm}>
            <div className={classes.formRow}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                id="email"
                name="email"
                label="Alamat Email"
                type="email"
                value={form.email}
                onChange={handleChange}
                className={classes.textField}
                helperText={error.email}
                error={!!error.email}
              />
            </div>
            <>
              <DialogVerifikasi />
            </>
            <div className={classes.formRowBtn}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmiting}
                size="large"
                className={classes.btnPrimary}
              >
                Ubah Email
              </Button>
            </div>
          </div>
        </form>
        <form>
          <div className={classes.changeProfileForm}>
            <div className={classes.formRow}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                id="oldpassword"
                name="oldpassword"
                label="Kata Sandi Lama"
                type={form.showPassworddd ? "text" : "password"}
                className={classes.textField}
                value={form.oldpassword}
                onChange={handleChange}
                helperText={error.oldpassword}
                error={!!error.oldpassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassworddd}
                      >
                        {form.showPassworddd ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.formRow}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                id="password"
                name="password"
                label="Kata Sandi Baru"
                type={form.showPassword ? "text" : "password"}
                className={classes.textField}
                value={form.password}
                onChange={handleChange}
                helperText={error.password}
                error={!!error.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {form.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.formRow}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                id="repeat_password"
                name="repeat_password"
                label="Konfirmasi Kata Sandi"
                type={form.showPasswordd ? "text" : "password"}
                className={classes.textField}
                value={form.repeat_password}
                onChange={handleChange}
                helperText={error.repeat_password}
                error={!!error.repeat_password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordd}
                      >
                        {form.showPasswordd ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={{ marginBottom: 100 }} className={classes.formRowBtn}>
              <Button
                color="primary"
                variant="contained"
                onClick={handlePassword}
                disabled={isSubmitting}
                size="large"
                className={classes.btnPrimary}
              >
                Ubah Kata Sandi
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle className={classes.DialogTitle}>
            {"Meninggalkan Halaman?"}
          </DialogTitle>
          <p className={classes.txt}>
            Kamu yakin ingin keluar dari halaman ini tanpa menyimpan perubahan ?
          </p>
          <Button
            component={Link}
            to="/settings"
            fullWidth
            className={classes.btnOke}
          >
            Ya
          </Button>
          <Button onClick={handleClose} fullWidth className={classes.btnNo}>
            Tidak
          </Button>
        </Dialog>
      </div>
    </div>
  );
}

export default withRouter(ChangeEmailPassword);
