import React from 'react';
import PropTypes from 'prop-types';
import AppSelect from './index';
import dataJurusan from './data/jurusan.json';

function SelectJurusanKelas({ TextFieldProps = {}, ...props }) {

    return <AppSelect
        disabled={TextFieldProps.disabled}
        options={dataJurusan ? dataJurusan.map(jurusan => ({ label: jurusan.nama, value: jurusan.jurusankelas_id })) : []}
        inputId="react-select-jurusanKelas"
        placeholder="Pilih Jurusan"
        TextFieldProps={{

            label: 'Jurusan',
            InputLabelProps: {
                htmlFor: 'react-select-jurusanKelas',
                shrink: true,
            },
            ...TextFieldProps

        }}
        {...props}
    />

}

SelectJurusanKelas.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}

export default SelectJurusanKelas;
