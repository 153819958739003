import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      userSelect: "none",
      msUserSelect: "none",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
    },
    blueBg: {
      width: "100%",
      height: 380,
      backgroundColor: theme.palette.primary.main,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      position: "absolute",
      zIndex: -100,
      overflow: "auto",
      top: 0,
    },
}))

export default useStyles;