import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    pageTitle:{
        color: theme.palette.textColor.gray,
        fontSize: 24,
        marginBottom: 10,
    },
    pageSubtitle:{
        color: theme.palette.textColor.gray,
        fontSize: 14,
        marginTop: 0,
    },
    verifyInputs:{
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
    },
    verInput:{
        width: 20,
        margin: '5px',
        display: 'block'
    },
    formRowBtn:{
        margin: '10px auto 10px',
        width: '75%',
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column nowrap',
        '@media(min-width: 400px)': {
            width: '70%',
        },
        '@media(min-width: 500px)': {
            width: '65%',
        },
        '@media(min-width: 600px)': {
            width: '60%',
        },
        '@media(min-width: 700px)': {
            width: '50%',
        },
    },
    otherLinks:{
        margin: '20px auto'
    },
    regText:{
        color: theme.palette.textColor.gray,
        fontSize: 14,
        margin: 0,
    },
    linkTo:{
        color: theme.palette.primary.main,
        textDecoration: 'none'
    }
}))

export default useStyles;