import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    mainWrap: {
        background: 'url("https://tes-minat-bakat-images.s3-ap-southeast-1.amazonaws.com/pb-tmb-bg.png") no-repeat center center',
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        padding: 0,
        // paddingBottom: 120,
    },
    mainBody: {
        width: '90%',
        margin: '20px auto',
        display: 'inline-block',
        paddingBottom: 60,
        '@media(min-width: 400px)': {
            width: '70%',
        },
        '@media(min-width: 500px)': {
            width: '65%',
        },
        '@media(min-width: 600px)': {
            width: '60%',
        },
        '@media(min-width: 700px)': {
            width: '50%',
        },
    },
    pageHeader: {
        height: 130,
        margin: '0 auto',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        '@media(min-width: 320px)': {
            width: '100%',
        },
        '@media(min-width: 960px)': {
            width: '50%',
        },
    },
    historyBack: {
        width: 50,
        color: '#fff',
    },
    btnSlot: {
        width: 50,
    },
    logoWrap: {
        padding: '20px 0 0',
    },
    logo: {
        width: 164,
        height: 'auto',
    },
    heroTitle: {
        color: '#fff',
        fontSize: 70,
        fontWeight: 'bold',
        textShadow: '2px 5px 1px #ECAB10',
        margin: '0 0 5px',
    },
    subtitle: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    text: {
        color: '#fff',
        fontSize: 14,
        marginBottom: 7,
    },
    free: {
        color: '#fff',
        fontSize: 30,
        fontWeight: 'bold',
        margin: '10px 0',
    },
    btnWrap: {
        margin: '20px 0'
    },
    btnTest: {
        width: 260,
        display: 'block',
        backgroundColor: '#FBA518',
        borderRadius: 50,
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#fff',
        boxShadow: '2px 2px 1px #fff',
        fontSize: 21,
        fontWeight: 'bold',
        padding: '15px 0',
        margin: 'auto',
        "&:hover": {
            backgroundColor: '#E99104',
            boxShadow: '2px 2px 1px #fff',
        },
    },
    botSection: {
        position: 'relative',
        left: 0,
        right: 0,
        margin: 'auto',
        height: 'auto',
        width: '100%',

    },
    heroImg: {
        width: '100%',
        height: 'auto',
    }
}))