import React, { useEffect } from 'react';
import useStyles from './styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { DateToNow } from '../../utils/formatter';
import { firestore, useFirebase } from '../../components/FirebaseProvider';
import { useHistory } from 'react-router-dom';
import { useSiswa } from '../../components/SiswaProvider';
import { baseURL } from '../../images/s3';

export default function NotificationItem({ item }) {
    const classes = useStyles();
    const { user } = useFirebase();
    const { profile } = useSiswa();
    const history = useHistory();
    useEffect(() => {
        if (!item.read) {
            const changeRead = async () => {

                await firestore.doc(`notifikasi/${user.uid}/items/${item.id}`).set({ read: true }, { merge: true })
            }

            changeRead();
        }
    }, [item.read, item.id, user.uid]);

    const handleClickNotif = (item) => () => {
        history.push(item.path, { nama: profile && profile.nama })
    }

    return <React.Fragment>
        <ListItem button onClick={handleClickNotif(item)} classes={{ root: classes.listItem }}>
            <ListItemText>
                <div className={classes.notifBox}>
                    <div className={classes.notifValue}>
                        {
                            item.type && item.type === 'chat' ?
                                <span className={classes.notifTxt}>{item.title}</span>
                                :
                                <span className={classes.notifTxt}>{item.content}</span>
                        }
                        <span className={classes.timeStamp}>{DateToNow(item.createdAt)}</span>
                    </div>
                </div>
            </ListItemText>
            <ListItemAvatar classes={{ root: classes.list }}>
                <Avatar className={classes.avatar}>
                    <img className={classes.icoThumb} src={`${baseURL}${item.icon}`} alt="" />
                </Avatar>
            </ListItemAvatar>
        </ListItem>
        <Divider />
    </React.Fragment>
}