// React
import React, { useEffect, useState } from "react";

// Input
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// app components
import SelectJurusanKelas from "../../components/AppSelect/jurusanKelas";
import SelectKelas from "../../components/AppSelect/kelas";
import SelectKota from "../../components/AppSelect/kota";
import SelectProvinsi from "../../components/AppSelect/provinsi";
import Dialog from "./Dialog";

// Router
import { Redirect } from "react-router-dom";

// Firebase Hooks
import { functions, useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";

// Styles
import useStyles from "./styles";

function LengkapiData() {
  // Firebase Use
  const { user, firestore } = useFirebase();

  // Document Ref
  const { profile } = useSiswa();
  // useStyles
  const classes = useStyles();

  const [isSubmiting, setSubmiting] = useState(true);
  const [sekolah_custom, setSekolahCustom] = useState(false);
  const [open, setOpen] = useState(false);

  const [form, setForm] = useState({
    username_teman: "",
    kelas: {
      nama: "",
      kelas_id: "",
    },
    sekolah: {
      nama: "",
      sekolah_id: "",
    },
    provinsi: {
      nama: "",
      provinsi_id: "",
    },
    kota: {
      nama: "",
      kota_id: "",
    },
    jurusanKelas: {
      nama: "",
      jurusankelas_id: "",
    },
  });

  const [values, setValues] = useState({
    provinsi: "",
    kota: "",
    sekolah: "",
    kelas: "",
  });

  const [error, setError] = useState({
    provinsi: "",
    kota: "",
    sekolah: "",
    kelas: "",
    username_teman: "",
    jurusanKelas: "",
  });

  // UseEffect
  useEffect(() => {
    if (profile) {
      setValues((values) => ({ ...values, ...profile }));
    }
  }, [profile]);

  const handleChange = (e) => {
    setSubmiting(false);

    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setError((error) => ({
      ...error,
      [name]: "",
    }));
  };

  // Validate
  const validate = () => {
    const newError = { ...error };

    const { sekolah, provinsi, kota, kelas, jurusanKelas } = form;
    if (sekolah_custom) {
      if (!sekolah.nama) {
        newError.sekolah = "Sekolah Wajib Diisi";
      }
    } else if (!sekolah.sekolah_id) {
      newError.sekolah = "Sekolah Wajib Diisi";
    }

    if (!provinsi.provinsi_id) {
      newError.provinsi = "Provinsi Wajib Diisi";
    }

    if (!kota.kota_id) {
      newError.kota = "Kota wajib Diisi";
    }

    if (!kelas.kelas_id) {
      newError.kelas = "Kelas Wajib Diisi";
    }

    if (form.kelas.kelas_id === "2" || form.kelas.kelas_id === "3") {
      if (!jurusanKelas.jurusankelas_id) {
        newError.jurusanKelas = " Jurusan Kelas Wajib Diisi";
      }
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmiting(true);
        const date = new Date();
        let data = null;
        if (form.kelas.kelas_id === "1" || form.kelas.kelas_id === "Lulus") {
          data = {
            jurusankelas_id: "",
            nama: "",
          };
        } else {
          data = {
            jurusankelas_id: form.jurusanKelas.jurusankelas_id,
            nama: form.jurusanKelas.nama,
          };
        }

        if (form.username_teman !== "") {
          const bonusUsername = functions.httpsCallable("bonusUsername");

          const result = await bonusUsername({ username: form.username_teman });

          if (result.data.status === "success") {
            setOpen(true);
          }
        }

        firestore.doc(`siswa/${user.uid}`).set(
          {
            kelas: form.kelas,
            sekolah: form.sekolah,
            provinsi: form.provinsi,
            kota: form.kota,
            jurusanKelas: data,
            sekolah_custom,
            diperbarui_pada: date,
            username_teman: form.username_teman,
          },
          { merge: true }
        );

        setSubmiting(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const data = values.provinsi && values.kota && values.sekolah && values.kelas;

  if (data && open === false) {
    return <Redirect to="/harapan" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.pageHeading}>
        <h2 className={classes.pageTitle}>Lengkapi Data</h2>
        <p className={classes.pageSubtitle}>
          Pilih asal sekolah lengkap dengan provinsi dan kota /kabupaten
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classes.formWrap}>
          <div className={classes.formRow}>
            <SelectProvinsi
              value={{
                label: form.provinsi.nama,
                value: form.provinsi.provinsi_id,
              }}
              onChange={(newValue) => {
                let value = {
                  nama: "",
                  provinsi_id: "",
                };

                if (newValue) {
                  value = {
                    nama: newValue.label,
                    provinsi_id: newValue.value,
                  };
                }

                handleChange({
                  target: {
                    value,
                    name: "provinsi",
                  },
                });
                handleChange({
                  target: {
                    value: {
                      nama: "",
                      kota_id: "",
                    },
                    name: "kota",
                  },
                });
                handleChange({
                  target: {
                    value: {
                      nama: "",
                      sekolah_id: "",
                    },
                    name: "sekolah",
                  },
                });
              }}
              TextFieldProps={{
                disabled: isSubmiting,
                helperText: error.provinsi,
                error: !!error.provinsi,
              }}
            />
          </div>
          <div className={classes.formRow}>
            <SelectKota
              value={{ label: form.kota.nama, value: form.kota.kota_id }}
              onChange={(newValue) => {
                let value = {
                  nama: "",
                  kota_id: "",
                };

                if (newValue) {
                  value = {
                    nama: newValue.label,
                    kota_id: newValue.value,
                  };
                }

                handleChange({
                  target: {
                    value,
                    name: "kota",
                  },
                });
                handleChange({
                  target: {
                    value: {
                      nama: "",
                      sekolah_id: "",
                    },
                    name: "sekolah",
                  },
                });
              }}
              provinsi_id={form.provinsi.provinsi_id}
              TextFieldProps={{
                disabled: isSubmiting,
                helperText: error.kota,
                error: !!error.kota,
              }}
            />
          </div>
          {/* {sekolah_custom ? */}
          <div className={classes.formRow}>
            <TextField
              id="sekolah"
              name="sekolah"
              label="Nama Sekolah"
              className={classes.textField}
              value={form.sekolah?.nama}
              onChange={(e) => {
                let value = {
                  nama: e.target?.value,
                  sekolah_id: "",
                };
                handleChange({
                  target: {
                    value,
                    name: "sekolah",
                  },
                });
                // update agustus 2022. karena nama sekolah diisi manual oleh user, maka sekolah_custom === true
                setSekolahCustom(true);
              }}
              helperText={error.sekolah}
              error={!!error.sekolah}
              disabled={isSubmiting}
            />
          </div>
          {/* :
                    <div className={classes.formRow}>
                        <SelectSekolah
                            value={{ label: form.sekolah.nama, value: form.sekolah.sekolah_id }}
                            onChange={(newValue) => {

                                let value = {
                                    nama: '',
                                    sekolah_id: ''
                                }

                                if (newValue) {
                                    value = {
                                        nama: newValue.label,
                                        sekolah_id: newValue.value
                                    }
                                }

                                handleChange({
                                    target: {
                                        value, name: 'sekolah'
                                    }
                                })

                            }}
                            provinsi_id={form.provinsi.provinsi_id}
                            kota_id={form.kota.kota_id}
                            TextFieldProps={{
                                disabled: isSubmiting,
                                helperText: error.sekolah,
                                error: error.sekolah ? true : false
                            }}
                        />
                    </div>}
                   
                    <div className={classes.formRow}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={sekolah_custom} onChange={()=>{
                                setSekolahCustom(sekolah_custom=>!sekolah_custom)
                            }} value="check"
                            />}
                            label="Sekolah saya tidak terdaftar."
                            disabled={isSubmiting}
                        />
                    </FormGroup>
                </div> */}
          <div className={classes.formRow}>
            <SelectKelas
              value={{ label: form.kelas.nama, value: form.kelas.kelas_id }}
              onChange={(newValue) => {
                let value = {
                  nama: "",
                  kelas_id: "",
                };

                if (newValue) {
                  value = {
                    nama: newValue.label,
                    kelas_id: newValue.value,
                  };
                }

                handleChange({
                  target: {
                    value,
                    name: "kelas",
                  },
                });
              }}
              TextFieldProps={{
                disabled: isSubmiting,
                helperText: error.kelas,
                error: !!error.kelas,
              }}
            />
          </div>
          <div className={classes.formRow}>
            {form.kelas.nama === "Kelas 11" ||
            form.kelas.nama === "Kelas 12" ? (
              <SelectJurusanKelas
                value={{
                  label: form.jurusanKelas.nama,
                  value: form.jurusanKelas.jurusankelas_id,
                }}
                onChange={(newValue) => {
                  let value = {
                    nama: "",
                    jurusankelas_id: "",
                  };

                  if (newValue) {
                    value = {
                      nama: newValue.label,
                      jurusankelas_id: newValue.value,
                    };
                  }

                  handleChange({
                    target: {
                      value,
                      name: "jurusanKelas",
                    },
                  });
                }}
                TextFieldProps={{
                  disabled: isSubmiting,
                  helperText: error.jurusanKelas,
                  error: !!error.jurusanKelas,
                }}
              />
            ) : null}
          </div>

          <div className={`${classes.formRow} ${classes.formRowPrize}`}>
            <p className={classes.prizeInfo}>
              Dapatkan hadiah tambahan jika kamu mengisi username teman kamu
              dibawah ini
            </p>
            <TextField
              id="username_teman"
              name="username_teman"
              value={form.username_teman}
              label="Username teman (boleh kosong)"
              className={`${classes.textField} ${classes.textFieldRef}`}
              inputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLast,
                },
              }}
              onChange={handleChange}
            />
            {/* <IconButton aria-label="info" className={classes.btnInfo} size="small" onClick={handleClick}>
                            <InfoIcon />
                        </IconButton> */}
            {/* <Popper id={id} open={open} anchorEl={anchorEl} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <div className={classes.poperContent}><p className={classes.poperTxt}>Isi dengan username teman kamu untuk bisa mendapatkan free poin sejumlah 30</p></div>
                                </Fade>
                            )}
                        </Popper> */}
          </div>

          <div className={classes.formRowBtn}>
            <Button
              size="large"
              className={classes.kirimBtn}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmiting}
            >
              Kirim
            </Button>
          </div>
        </div>
      </form>
      <Dialog open={open} handleOk={() => setOpen(false)} />
    </div>
  );
}

export default LengkapiData;
