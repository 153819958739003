// React
import React from "react";

// Styles
import useStyles from "./styles";

// MUI
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/styles";

import { baseURL } from "../../images/s3";

export default function DialogTo({ open, handleClose, namaTo }) {
  // UseStyles
  const classes = useStyles();

  const Btn = withStyles({
    root: {
      width: 111.67,
      height: 26,
      margin: "0 auto",
      marginBottom: 20,
    },
    label: {
      fontSize: 13.33,
    },
  })(Button);

  //
  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle className={classes.DialogTitle}>
          <div className={classes.logoWrap}>
            <img
              className={classes.mainLogo}
              src={`${baseURL}new-logo-w.png`}
              alt=""
            />
            <span className={classes.pageName}>Tryout</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box
            margin="10px 0px"
            textAlign="center"
            fontSize={20}
            fontWeight={900}
            letterSpacing={4}
            color="#1461A8"
          >
            BERHASIL
          </Box>
          <Box className={classes.dtxt2} textAlign="center">
            {namaTo}
          </Box>
          <Box className={classes.dtxt1} textAlign="center">
            Sudah dibuka untukmu
          </Box>
          <Box
            margin="10px 0px"
            textAlign="center"
            fontSize={16.67}
            fontWeight={500}
            color="#4f4f4f"
          >
            Selamat Mengerjakan !
          </Box>
        </DialogContent>

        <Btn
          size="small"
          onClick={handleClose}
          variant="contained"
          color="primary"
        >
          Ok
        </Btn>
      </Dialog>
    </React.Fragment>
  );
}
