import React, { useEffect, useState } from "react";

import useStyles from "./styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import TopNav from "../../components/TopNav";
import DialogMisi from "./dialogMisi";
import DialogStatus from "./dialogStatus";

import { useHistory } from "react-router-dom";
import { useCoin } from "../../components/CoinProvider";
import {
  firestore,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";
import imgMisi from "../../images/misi-default.png";
import { baseURL } from "../../images/s3";
import misiOrder from "../../utils/misiOrder";

function Misi(props) {
  const classes = useStyles();
  const history = useHistory();
  const { misi, listMisi, misiDefault } = useCoin();
  const { profile } = useSiswa();
  const { user } = useFirebase();
  const [dialog, setDialog] = useState({
    open: false,
    data: [],
    mode: "Kerjakan",
  });
  const [dialogStatus, setDialogStatus] = useState({
    open: false,
    type: "success",
    coin: 0,
    message: "",
  });
  const [List, setList] = useState([]);
  useEffect(() => {
    if (misi) {
      const listDoc = listMisi || [];
      setList(
        misi.map((doc) => {
          const list = listDoc.find((list) => list.misi.id === doc.id);
          const ternaryList =
            list?.status === "review" ||
            list?.status === "pending" ||
            list?.status === "rejected"
              ? 2
              : 3;
          return {
            ...doc,
            sort: list === undefined ? 1 : ternaryList,
            misi: list,
          };
        })
      );
    }
  }, [listMisi, misi]);
  //
  const handleClick = (item, mode = "Kerjakan") => {
    setDialog({
      open: true,
      data: item,
      mode,
    });
  };
  //
  const handleOk = async () => {
    console.log("diaglog data", dialog.data);

    try {
      const newData = {
        siswa: {
          nama: profile?.nama,
          email: profile?.email,
        },
        misi: {
          id: dialog.data.id,
          content: dialog.data.content,
          judul: dialog.data.judul,
          url: dialog.data.url ?? "",
        },
        siswa_id: user.uid,
        tanggal: Date.now(),
        jumlah_coin: dialog.data.reward,
        photoURL: "",
        status: "pending",
      };
      let id;
      if (dialog.mode === "Coba Lagi") {
        await firestore
          .collection("misi")
          .doc(dialog.data.misi.id)
          .set(newData);
        id = dialog.data.misi.id;
      } else {
        const db = await firestore.collection("misi").add(newData);
        id = db.id;
      }
      //
      history.push(`/konfirmasimisi/${id}`);
      //
    } catch (error) {
      console.log(error.message);
    }
  };
  //

  // function claim coin
  const claimCoin = async (item) => {
    const claimMisi = functions.httpsCallable("claimMisi");

    try {
      await claimMisi({ misi_id: item.misi?.id });
      // if (result.result.status === 'success')
      setDialogStatus({ open: true, type: "success", coin: item.reward });
    } catch (e) {}
  };

  const handleMisi = () => {
    props.history.push("/listmisi");
  };

  const newList = misiOrder(misiDefault, List);

  return (
    <div className={classes.root}>
      <TopNav />
      <div className={classes.missionHead}>
        <h1 className={classes.missionTitle}>
          Ayo selesaikan misinya dan dapatkan hadiah koin gratis!
        </h1>
        <div className={classes.btnListMisiCon}>
          <Button
            variant="outlined"
            className={classes.btnListMisi}
            onClick={handleMisi}
          >
            Daftar Misi
          </Button>
        </div>
      </div>
      <div className={classes.Body}>
        {newList?.map((item, index) => {
          let Img = imgMisi;
          if (item?.status_misi === "active" && !item?.misi) {
            return (
              <div key={item.id}>
                <div className={classes.missionBox}>
                  <div className={classes.misiThumb}>
                    <img alt="img" src={`${Img}`} />
                  </div>
                  <div className={classes.misiDetail}>
                    <Typography className={classes.misiTitle}>
                      {item.judul}
                    </Typography>
                    <Typography className={classes.misiSubtitle}>
                      Dapat{" "}
                      <img
                        src={`${baseURL}icon-coin.png`}
                        className={classes.imgCoin}
                        alt=""
                      />{" "}
                      {item.reward} Coin
                    </Typography>
                    <Button
                      classes={{ label: classes.labelBtn }}
                      className={classes.goBtn}
                      fullWidth
                      onClick={() => handleClick(item)}
                    >
                      Kerjakan
                    </Button>
                  </div>
                </div>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "pending"
          ) {
            return (
              <div key={item.id}>
                <Button
                  className={classes.missionDone}
                  fullWidth
                  onClick={() =>
                    history.push(`/konfirmasimisi/${item.misi.id}`)
                  }
                >
                  <div className={classes.doneMsg}>
                    <span>Misi Belum Selesai</span>
                  </div>
                  <Button
                    classes={{ label: classes.labelBtn }}
                    className={classes.promoBtn}
                    fullWidth
                    disabled
                  >
                    <div className={classes.misiThumb}>
                      <img alt="img" src={`${Img}`} />
                    </div>
                    <div className={classes.misiDetail}>
                      <Typography className={classes.misiTitle}>
                        {item.judul}
                      </Typography>
                      <Typography className={classes.misiSubtitle}>
                        Dapat{" "}
                        <img
                          src={`${baseURL}icon-coin.png`}
                          className={classes.imgCoin}
                          alt=""
                        />{" "}
                        {item.reward} Coin
                      </Typography>
                      <Button
                        classes={{
                          label: classes.labelBtn,
                          disabled: classes.btnDisabled,
                        }}
                        className={classes.goBtn}
                        fullWidth
                        disabled
                      >
                        Kerjakan
                      </Button>
                    </div>
                  </Button>
                </Button>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "review"
          ) {
            return (
              <div key={item.id}>
                <Button
                  className={classes.missionBoxWrap}
                  fullWidth
                  onClick={() =>
                    setDialogStatus({ open: true, type: "review" })
                  }
                >
                  <div
                    className={`${classes.missionBox} ${classes.missionBoxVerify}`}
                  >
                    <div className={classes.misiThumb}>
                      <img alt="img" src={`${Img}`} />
                    </div>
                    <div className={classes.misiDetailVerify}>
                      <div className={classes.misiDetailLeft}>
                        <Typography className={classes.misiTitle}>
                          {item.judul}
                        </Typography>
                        <Typography className={classes.misiSubtitle}>
                          Dapat{" "}
                          <img
                            src={`${baseURL}icon-coin.png`}
                            className={classes.imgCoin}
                            alt=""
                          />{" "}
                          {item.reward} Coin
                        </Typography>
                      </div>
                      <div className={classes.missionStatus}>
                        <img
                          src={`${baseURL}img-hourglass.png`}
                          className={classes.imgHg}
                          alt=""
                        />
                        <span className={classes.statusVerify}>
                          Menunggu Verifikasi
                        </span>
                      </div>
                    </div>
                  </div>
                </Button>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "pending-valid"
          ) {
            return (
              <div key={item.id}>
                <div className={classes.missionBox}>
                  <div className={classes.misiThumb}>
                    <img alt="img" src={`${Img}`} />
                  </div>
                  <div className={classes.misiDetail}>
                    <Typography className={classes.misiTitle}>
                      {item.judul}
                    </Typography>
                    <Typography className={classes.misiSubtitle}>
                      Dapat{" "}
                      <img
                        src={`${baseURL}icon-coin.png`}
                        className={classes.imgCoin}
                        alt=""
                      />{" "}
                      {item.reward} Coin
                    </Typography>
                    <Button
                      classes={{ label: classes.labelBtn }}
                      className={classes.claimBtn}
                      fullWidth
                      onClick={() => claimCoin(item)}
                    >
                      Claim Coin
                    </Button>
                  </div>
                </div>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "valid"
          ) {
            return (
              <div key={item.id}>
                <Button className={classes.missionDone} fullWidth disabled>
                  <div className={classes.doneMsg}>
                    <span>Misi Sudah Dikerjakan</span>
                  </div>
                  <Button
                    classes={{ label: classes.labelBtn }}
                    className={classes.promoBtn}
                    fullWidth
                    disabled
                  >
                    <div className={classes.misiThumb}>
                      <img alt="img" src={`${Img}`} />
                    </div>
                    <div className={classes.misiDetail}>
                      <Typography className={classes.misiTitle}>
                        {item.judul}
                      </Typography>
                      <Typography className={classes.misiSubtitle}>
                        Dapat{" "}
                        <img
                          src={`${baseURL}icon-coin.png`}
                          className={classes.imgCoin}
                          alt=""
                        />{" "}
                        {item.reward} Coin
                      </Typography>
                      <Button
                        className={classes.claimBtn}
                        classes={{ disabled: classes.btnDisabled }}
                        fullWidth
                        disabled
                        onClick={() =>
                          setDialogStatus({
                            open: true,
                            type: "success",
                            coin: item.reward,
                          })
                        }
                      >
                        Claim Coin
                      </Button>
                    </div>
                  </Button>
                </Button>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "rejected"
          ) {
            return (
              <div key={item.id}>
                <div
                  className={`${classes.missionBox} ${classes.missionBoxFailed}`}
                >
                  <div className={classes.misiThumb}>
                    <img alt="img" src={`${Img}`} />
                  </div>
                  <div className={classes.misiDetail}>
                    <Typography className={classes.misiTitle}>
                      {item.judul}
                    </Typography>
                    <Typography className={classes.misiSubtitle}>
                      Dapat{" "}
                      <img
                        src={`${baseURL}icon-coin.png`}
                        className={classes.imgCoin}
                        alt=""
                      />{" "}
                      {item.reward} Coin
                    </Typography>
                  </div>
                  <Button
                    className={classes.retryBtn}
                    fullWidth
                    onClick={() => handleClick(item, "Coba Lagi")}
                  >
                    Coba Lagi
                  </Button>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
      <DialogMisi
        {...dialog}
        handleOk={handleOk}
        handleClose={() => {
          setDialog({
            open: false,
          });
        }}
      />
      <DialogStatus
        {...dialogStatus}
        handleOk={() => setDialogStatus({ open: false })}
      />
    </div>
  );
}

export default Misi;
