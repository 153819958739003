// React
import React from "react";

// Styles
import useStyles from "./styles/Home";

// Component's
import { useFirebase, functions } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";
import Typography from "@material-ui/core/Typography";
import Coin from "./Coin";
import ProfileMitra from "./profilMitra";
import TopUp from "../../components/Dialog/DialogTopUp";
import { useHistory } from "react-router-dom";
import { FixedSizeList } from "react-window";
import AppPageLoading from "../../components/AppPageLoading";
import { useSnackbar } from "notistack";

function Home({ filteredMitra, mitraItems, load, loading }) {
  const classes = useStyles();
  const { user } = useFirebase();
  const history = useHistory();
  const { profile, setPageEvent } = useSiswa();
  const { enqueueSnackbar } = useSnackbar();

  const [timeoutdialog, setTimeoutDialog] = React.useState({
    open: false,
    harga: "",
    mitraId: "",
    nama_siswa: "",
    nama_mitra: "",
    mitra_type: "",
    url: null,
    mitra_inApp: null,
    siswa_inApp: null,
    online: null,
    rating: "",
    keterangan: "",
    status: "",
    type: "",
    durasi: null,
    isLoad: false,
  });

  const [topupdialog, setTopupDialog] = React.useState({
    open: false,
  });

  const handleChat =
    (
      harga,
      uid,
      rating,
      keterangan,
      nama,
      type,
      photo,
      online,
      status,
      durasi
    ) =>
    async (e) => {
      setTimeoutDialog((timeoutdialog) => ({
        ...timeoutdialog,
        open: true,
        harga: harga,
        mitraId: uid,
        nama_siswa: profile.nama,
        nama_mitra: nama,
        mitra_type: type,
        mitra_inApp: false,
        siswa_inApp: true,
        url: photo,
        online: online,
        rating: rating,
        keterangan: keterangan,
        status: status,
        durasi,
      }));
    };
  //
  const handleOk = async (e) => {
    const { mitraId, nama_siswa, nama_mitra, mitra_inApp, siswa_inApp, type } =
      timeoutdialog;
    try {
      //
      setTimeoutDialog((timeoutdialog) => ({
        ...timeoutdialog,
        isLoad: true,
      }));
      //
      const newChatRequest = functions.httpsCallable("newChatRequest");
      //
      const result = await newChatRequest({
        siswa_id: user.uid,
        mitra_id: mitraId,
        nama_siswa: nama_siswa,
        nama_mitra: nama_mitra,
        mitra_inApp: mitra_inApp,
        siswa_inApp: siswa_inApp,
        type: type,
      });
      history.push(`/myChat/${result.data.id}`);
      //
    } catch (e) {
      if (
        e.message === "Cannot read property 'coin' of undefined" ||
        e.message === "koin tidak cukup"
      ) {
        setTimeoutDialog((timeoutdialog) => ({
          ...timeoutdialog,
          open: false,
        }));
        setTopupDialog((topupdialog) => ({
          ...topupdialog,
          open: true,
        }));
      } else {
        setTimeoutDialog((timeoutdialog) => ({
          ...timeoutdialog,
          isLoad: false,
        }));
        enqueueSnackbar(e?.message, { variant: "error" });
      }
    }
  };
  //
  const handleFree =
    (uid, rating, keterangan, nama, type, photo, online, status, durasi) =>
    async (e) => {
      setTimeoutDialog((timeoutdialog) => ({
        ...timeoutdialog,
        open: true,
        mitraId: uid,
        nama_siswa: profile.nama,
        nama_mitra: nama,
        mitra_type: type,
        mitra_inApp: false,
        siswa_inApp: true,
        url: photo,
        online: online,
        rating: rating,
        keterangan: keterangan,
        status: status,
        type: "free",
        durasi,
      }));
    };

  if (loading || load) {
    return <AppPageLoading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.konsultasiBox}>
        {filteredMitra.length === 0 && (
          <Typography className={classes.noMitra} component="p">
            Tidak ada mitra yang sedang online
          </Typography>
        )}
        <FixedSizeList
          height={200}
          itemSize={100}
          itemCount={filteredMitra.length}
        >
          {({ index, style }) => {
            const item = filteredMitra[index];
            return (
              <Coin
                key={index}
                item={item}
                itemStyle={style}
                handleChat={handleChat}
                handleFree={handleFree}
              />
            );
          }}
        </FixedSizeList>
      </div>
      <ProfileMitra
        {...timeoutdialog}
        handleClose={() => {
          setTimeoutDialog((timeoutdialog) => {
            return {
              ...timeoutdialog,
              open: false,
            };
          });
        }}
        handleOk={handleOk}
      />
      <TopUp
        {...topupdialog}
        handleNoTopUp={() => {
          setTopupDialog((topupdialog) => {
            return {
              ...topupdialog,
              open: false,
            };
          });
        }}
        handleTopUp={() => {
          setPageEvent("konsultasi");
          history.push("/myCoin");
        }}
      />
    </div>
  );
}

export default Home;
