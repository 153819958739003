// React
import React, { useEffect } from "react";
import "./App.css";

// Router
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

// material-ui themes
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

// Private Route
import PrivateRoute from "./components/PrivateRoute";

// Firebase Provider
import FirebaseProvider from "./components/FirebaseProvider";

// Components Pages
import DownloadApp from "./components/DownloadApp";
import FormPembayaran from "./pages/acara/formPembayaran";
import FormPendaftaran from "./pages/acara/formPendaftaran";
import EventIntro from "./pages/acara/intro";
import KonfirmasiPembayaran from "./pages/acara/konfirmasiPembayaran";
import EventProcess from "./pages/acara/proses";
import PublicBerita from "./pages/berita/public";
import CekPeluang from "./pages/cekPeluang";
import CekPeluangResult from "./pages/cekPeluang/cekPeluangResult";
import Chat from "./pages/chat/chatWindow";
import Esport from "./pages/esport/index";
import Home from "./pages/home";
import HasilTryout from "./pages/home/hasilTryout";
import Landing from "./pages/landing";
import LayoutA from "./pages/layoutA";
import LayoutB from "./pages/layoutB";
import LayoutC from "./pages/layoutC";
import ListTryout from "./pages/listTryout";
import Login from "./pages/login";
import LoginViaLink from "./pages/LoginViaLink";
import LoginByToken from "./pages/LoginViaLink/token";
import LupaPassword from "./pages/lupaPassword";
import NotFound from "./pages/notFound";
import Register from "./pages/register";
import Restricted from "./pages/restricted";
import Seminar from "./pages/seminar";
import TesMinatBakat from "./pages/tesMinatBakat";
import Tryout from "./pages/tryout";

// Notification
import { SnackbarProvider } from "notistack";
import queryString from "query-string";
import FailedPayment from "./pages/tmbPlus/failedPayment";
import PendingPayment from "./pages/tmbPlus/pendingPayment";
import SuccessPayment from "./pages/tmbPlus/succesPayment";
import { getData, setData } from "./utils/localforage";
// Theme
const pakBudiTheme = createTheme(theme);

function App() {
  useEffect(() => {
    if (window.location.search) {
      async function storeUTMData() {
        const utm_source = await getData("utm_source");
        if (!utm_source) {
          const qs = queryString.parse(window.location.search);

          if (qs.utm_source) {
            await setData("utm_source", qs.utm_source);
          }
        }
      }
      storeUTMData();
    }
  }, []);
  return (
    <React.Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={pakBudiTheme}>
        <DownloadApp>
          <div className="App">
            <div className="Content">
              <SnackbarProvider maxSnack={1} autoHideDuration={3000}>
                <FirebaseProvider>
                  <Router>
                    <Switch>
                      <Route path="/" exact component={Landing} />
                      <Route path="/login" component={Login} />
                      <Route path="/login-via-link" component={LoginViaLink} />
                      <Route
                        path="/login-by-token/:token"
                        component={LoginByToken}
                      />
                      <Route path="/register" component={Register} />
                      <Route path="/lupaPassword" component={LupaPassword} />
                      <Route path="/syaratKetentuan" component={LayoutA} />
                      <Route path="/privacyPolicy" component={LayoutA} />
                      <Route
                        exact
                        path="/acara/:eventId"
                        component={EventIntro}
                      />
                      <Route
                        path="/acara/:eventId/daftar"
                        component={FormPendaftaran}
                      />
                      <Route
                        path="/acara/:eventId/pembayaran/:transaksiId"
                        component={FormPembayaran}
                      />
                      <Route
                        path="/acara/:eventId/konfirmasi/:transaksiId"
                        component={KonfirmasiPembayaran}
                      />
                      <Route
                        path="/acara/:eventId/proses/:transaksiId"
                        component={EventProcess}
                      />
                      <Route
                        path="/checkout/paymentSuccess"
                        component={SuccessPayment}
                      />
                      <Route
                        path="/checkout/paymentFailed"
                        component={FailedPayment}
                      />
                      <Route
                        path="/checkout/paymentPending"
                        component={PendingPayment}
                      />
                      <PrivateRoute
                        exact
                        path="/acara/u/:eventId"
                        component={LayoutC}
                      />
                      <PrivateRoute
                        path="/acara/u/:eventId/daftar"
                        component={LayoutC}
                      />
                      <PrivateRoute
                        path="/acara/u/:eventId/pembayaran/:transaksiId"
                        component={LayoutC}
                      />
                      <PrivateRoute
                        path="/acara/u/:eventId/konfirmasi/:transaksiId"
                        component={LayoutC}
                      />
                      <PrivateRoute
                        path="/acara/u/:eventId/lengkapi-data/:pesertaId"
                        component={LayoutC}
                      />
                      <PrivateRoute
                        path="/acara/ticket/:eventId/:pesertaId"
                        component={LayoutC}
                      />
                      <PrivateRoute path="/home" component={Home} />
                      <PrivateRoute path="/notifikasi" component={LayoutB} />
                      <PrivateRoute path="/konsultasi" component={LayoutB} />
                      <PrivateRoute path="/chat" component={LayoutB} />
                      <PrivateRoute path="/myChat/:chatId" component={Chat} />
                      <PrivateRoute path="/settings" component={LayoutC} />
                      <PrivateRoute path="/vouchers" component={LayoutC} />
                      <PrivateRoute path="/editprofile" component={LayoutC} />
                      <PrivateRoute
                        path="/notifikasisettings"
                        component={LayoutC}
                      />
                      <PrivateRoute path="/myCoin" component={LayoutB} />
                      <PrivateRoute
                        path="/promoCoin/:total/:coin"
                        component={LayoutB}
                      />
                      <PrivateRoute
                        path="/isiCoin/:total/:coin"
                        component={LayoutB}
                      />
                      <PrivateRoute
                        path="/rinciantransfer/:total/:method/:doc/:no"
                        component={LayoutB}
                      />
                      <PrivateRoute
                        path="/konfirmasitransfer/:id"
                        component={LayoutB}
                      />
                      <PrivateRoute path="/listtransaksi" component={LayoutB} />
                      <PrivateRoute path="/verifikasi" component={LayoutA} />
                      <PrivateRoute path="/lengkapiData" component={LayoutA} />
                      <PrivateRoute path="/harapan" component={LayoutA} />
                      <PrivateRoute
                        path="/ubahemaildanpassword"
                        component={LayoutC}
                      />
                      <PrivateRoute path="/berita" component={LayoutB} />
                      <Route path="/warta" component={PublicBerita} />
                      <Route path="/seminar" component={Seminar} />
                      <PrivateRoute
                        path="/beritaDetail/:id"
                        component={LayoutB}
                      />
                      <PrivateRoute path="/cekpeluang" component={CekPeluang} />
                      <PrivateRoute
                        path="/cekpeluangresult"
                        component={CekPeluangResult}
                      />
                      <PrivateRoute
                        path="/tesminatbakat"
                        component={TesMinatBakat}
                      />
                      <PrivateRoute
                        path="/tesminatbakat/game"
                        component={TesMinatBakat}
                      />
                      <PrivateRoute
                        path="/tesminatbakat/result/:symbol"
                        component={TesMinatBakat}
                      />
                      <PrivateRoute path="/bagikan" component={LayoutB} />
                      <PrivateRoute
                        path="/tryout/:hasil_tryout_id"
                        component={Tryout}
                      />
                      <PrivateRoute
                        path="/hasiltryout/:to_id"
                        component={HasilTryout}
                      />
                      <PrivateRoute path="/misi" component={LayoutC} />
                      <PrivateRoute path="/checkout" component={LayoutC} />
                      <PrivateRoute path="/listmisi" component={LayoutB} />
                      <PrivateRoute
                        path="/konfirmasimisi/:id"
                        component={LayoutB}
                      />
                      <PrivateRoute
                        path="/leaderboard/:to_id"
                        component={LayoutC}
                      />
                      <PrivateRoute path="/esport" component={Esport} />
                      <Route path="/restricted" component={Restricted} />
                      <PrivateRoute path="/listTryout" component={ListTryout} />
                      <Route render={() => <NotFound />} />
                    </Switch>
                  </Router>
                </FirebaseProvider>
              </SnackbarProvider>
            </div>
          </div>
        </DownloadApp>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default App;
