import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function LoadingOverlay({ height, position, top }) {
  return (
    <div
      style={{
        width: "100%",
        height: height || "120vh",
        top: top || "-20px",
        backgroundColor: "rgba(000, 000, 000, 0.5)",
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        position: position || "fixed",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress color="secondary" />
      <p style={{ color: "#fff", fontStyle: "italic" }}>loading...</p>
    </div>
  );
}

export default LoadingOverlay;
