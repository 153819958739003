// React
import React, { useState, useEffect } from 'react';
// Styles
import useStyles from './styles';
// Components
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import TopAction from '../../components/TopAction';
import AppHeader from '../../components/AppHeader';
import ChatItem from './chatItem';
import Nochat from '../../images/Nochat.png';
// Firebase
import { useChat } from '../../components/useChatList';

function Chat() {
    // Styles
    const classes = useStyles();
    // Firebase
    const { chat } = useChat();
    // State
    const [chats, setChats] = useState([]);
    // UseEffect
    useEffect(() => {
        if (chat) {
            if (!chat.empty) {
                setChats(chat.docs.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                }).sort((a, b) => {
                    return b.updated_at.toMillis() - a.updated_at.toMillis()
                }))
            }
        }
    }, [chat])
    // 
    return (
        <div className={classes.root} >
            <div className={classes.chatBody}>
                <AppHeader />
                <div className={classes.pageHeader}>
                    <TopAction />
                </div>
                <div className={classes.chatListWrap}>
                    {chats && chats.length > 0 &&
                        <List className={classes.chatList}>
                            {chats.map((chat, index) => {
                                return <React.Fragment key={chat.id}>
                                    <ChatItem chat={chat} index={index} />
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            })}

                        </List>
                    }
                    {chats && chats.length === 0 &&
                        <div className={classes.wrapNoChat}>
                            <img alt="no-chat" className={classes.NoChat} src={Nochat} />
                            <Typography className={classes.txtTitle} component="p">Belum Ada Chat</Typography>
                            <Typography className={classes.txtSub} component="p">Kamu Belum Chat Dengan Siapapun, Ayo Coba Chat Sekarang Dan Cobalah Berbincang Dengan Orang Baru.</Typography>
                        </div>
                    }
                </div>
            </div>
        </div>
    );

}

export default Chat;