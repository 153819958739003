import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import React from "react";
import useStyles from "./styles/timeoutDialog";

export default function TimeoutDialog({
  open,
  handleClose,
  handleClickOk,
  handleClickNo,
  harga,
  isLoading,
  name,
  durasi,
}) {
  const classes = useStyles();
  if (!harga) {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className={classes.DialogTitle}>
          {"Sesi chat telah habis"}
        </DialogTitle>
        <p className={classes.txt}>
          Sesi chat ini telah berakhir dan tidak bisa diperpanjang.
        </p>

        <Button
          onClick={handleClickNo}
          fullWidth
          className={classes.btnOke}
          disabled={isLoading}
        >
          OK
        </Button>
      </Dialog>
    );
  }
  return (
    //
    // dialog muncul saat timer < 5 menit
    //
    // <Dialog
    //     disableBackdropClick
    //     disableEscapeKeyDown
    //     open={open}
    //     onClose={handleClose}
    // >
    //     {/* <DialogTitle className={classes.DialogTitle}>{"Lanjutkan Sesi"}</DialogTitle> */}
    //     <AccessAlarmIcon className={classes.warningTimer} />
    //     <p className={classes.timeoutTxt}>Waktu konsultasi kamu <strong>kurang dari 5 menit</strong> lagi, tambah durasi konsultasi?</p>
    //     {isLoading && <p className={classes.txt}>Extending...</p>}
    //     <DialogActions className={classes.dialogActionEnd}>
    //         <Button
    //         onClick={handleClickNo}
    //         className={classes.btnStop}
    //         disabled={isLoading}
    //         >
    //             Tidak
    //         </Button>
    //         <Button
    //             onClick={handleClickOk}
    //             className={classes.btnExtend}
    //             disabled={isLoading}
    //         >
    //             Tambah ({harga} Coin)
    //         </Button>
    //     </DialogActions>
    // </Dialog>
    // end timer dialog
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
    >
      {/* <DialogTitle className={classes.DialogTitle}>{"Lanjutkan Sesi"}</DialogTitle> */}
      <AccessAlarmIcon className={classes.dangerTimer} />
      <p className={classes.timeoutTxt}>
        Waktu konsultasi kamu <strong>habis</strong>, mau terhubung dengan (
        {name}) kembali?{" "}
      </p>
      <p className={classes.txt}>
        {harga} Coin/{durasi} Menit
      </p>
      {isLoading && <p className={classes.txt}>Extending...</p>}
      <DialogActions className={classes.dialogActionEnd}>
        <Button
          onClick={handleClickNo}
          className={classes.btnStop}
          disabled={isLoading}
        >
          Tidak
        </Button>
        <Button
          onClick={handleClickOk}
          className={classes.btnExtend}
          disabled={isLoading}
        >
          Tambah Sesi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
