// React
import React, { useState } from "react";

// Components
import AppLoading from "../../components/AppLoading";
import AppHeader from "../../components/AppHeader";
import LoadingOverlay from "../../components/LoadingOverlay";
import { firestore } from "../../components/FirebaseProvider";

// Styles
import useStyles from "./styles";

// MUI
import { withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";

// Icon
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import isEmail from "validator/lib/isEmail";

// React Router Dom
import { useHistory, useParams } from "react-router-dom";

// React Firebase Hooks
import { useDocumentData } from "react-firebase-hooks/firestore";

// JSON
import lokasiUjian from "./lokasi_ujian.json";

//
import { number } from "../../utils/validation";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: 4,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
      "&:hover fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
    },
  },
})(TextField);

function FormPendaftaran() {
  const classes = useStyles();

  const { eventId } = useParams();

  const history = useHistory();

  const [isSubmitting, setIsSubmtting] = useState(false);

  const eventDoc = firestore.doc(`events/${eventId}`);

  const [event, loadingEvent] = useDocumentData(eventDoc);

  const pilihan = [{ title: "Soal Gelombang 1" }, { title: "Soal Gelombang 2" }];

  const [form, setForm] = useState({
    nama_lengkap: "",
    no_telepon: "",
    no_wa: "",
    email: "",
    type: null,
    location: null,
  });

  const [error, setError] = useState({
    nama_lengkap: "",
    no_telepon: "",
    no_wa: "",
    email: "",
    type: "",
    location: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((e) => ({
      ...e,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors = { ...error };

    const { nama_lengkap, no_telepon, no_wa, email, type, location } = form;

    if (!nama_lengkap) {
      newErrors.nama_lengkap = "Nama wajib diisi!";
    }

    if (!no_telepon) {
      newErrors.no_telepon = "No. Telepon wajib diisi!";
    } else if (!number.test(no_telepon)) {
      newErrors.no_telepon = "No. Telepon tidak valid!";
    }

    if (!no_wa) {
      newErrors.no_wa = "No. Whatsapp Aktif wajib diisi!";
    } else if (!number.test(no_wa)) {
      newErrors.no_wa = "No. Whatsapp tidak valid!";
    }

    if (!email) {
      newErrors.email = "Alamat Email wajib diisi!";
    } else if (!isEmail(email)) {
      newErrors.email = "Alamat Email tidak valid!";
    }

    if (type === null) {
      newErrors.type = "Jenis Tryout wajib diisi!";
    }

    if (location === null) {
      newErrors.location = "Lokasi Ujian wajib diisi!";
    }

    return newErrors;
  };

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setIsSubmtting(true);

        const transaksiRef = firestore.collection("transaksi_event");

        const transaksiId = transaksiRef.doc().id;

        const { type, location, ...rest } = form;

        const newData = {
          ...rest,
          jenis_tryout: type?.title,
          lokasi_event: location,
          event_transaksi_id: transaksiId,
          event_id: eventId,
          status: "created",
          created_at: Date.now(),
        };

        await firestore.doc(`transaksi_event/${transaksiId}`).set(newData);

        history.push(`/acara/${eventId}/pembayaran/${transaksiId}`);

        setIsSubmtting(false);
      } catch (error) {
        console.log(error);
        setIsSubmtting(false);
      }
    }
  };

  if (loadingEvent) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      {isSubmitting && <LoadingOverlay />}
      <div className={classes.tcBody}>
        <AppHeader />
        <div className={classes.pageHeading}>
          <h1 className={classes.pageTitle}>Pendaftaran</h1>
          <h2 className={classes.pageSubtitle1}>Acara {event?.nama ?? "-"}</h2>
          <p className={classes.pageSubtitle2}>
            Tryout SNBT Offline terbaik sejak 2016 berskala NASIONAL. Soal
            100% baru, goodiebag dan bonus hadiah, ranking secara nasional,
            sesuai sistem SNBT aslinya.
          </p>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.itemTf}>
            <p className={classes.label}>Nama Lengkap</p>
            <CustomTextField
              name="nama_lengkap"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.nama_lengkap}
              disabled={isSubmitting}
            />
            {error?.nama_lengkap && (
              <p className={classes.error}>{error?.nama_lengkap}</p>
            )}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>No. Telepon</p>
            <CustomTextField
              name="no_telepon"
              placeholder="628123456789"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.no_telepon}
              disabled={isSubmitting}
            />
            {error?.no_telepon && (
              <p className={classes.error}>{error?.no_telepon}</p>
            )}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>No. Whatsapp Aktif</p>
            <CustomTextField
              name="no_wa"
              placeholder="628123456789"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.no_wa}
              disabled={isSubmitting}
            />
            {error?.no_wa && <p className={classes.error}>{error?.no_wa}</p>}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Alamat Email</p>
            <CustomTextField
              name="email"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.email}
              disabled={isSubmitting}
            />
            {error?.email && <p className={classes.error}>{error?.email}</p>}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Pilihan Jenis Tryout</p>
            <Autocomplete
              options={pilihan}
              getOptionLabel={(option) => option.title}
              popupIcon={
                <ArrowDropDown
                  fontSize="small"
                  style={{
                    color: "#fff",
                    backgroundColor: "#777",
                    borderRadius: 4,
                  }}
                />
              }
              renderInput={(params) => (
                <>
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  {error?.type && (
                    <p className={classes.error}>{error?.type}</p>
                  )}
                </>
              )}
              onChange={(e, v) => {
                setForm((f) => ({
                  ...f,
                  type: v,
                }));

                setError((e) => ({
                  ...e,
                  type: "",
                }));
              }}
              value={form?.type}
              disabled={isSubmitting}
            />
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Pilihan Lokasi Ujian</p>
            <Autocomplete
              options={lokasiUjian?.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })}
              getOptionLabel={(option) => option.name}
              popupIcon={
                <ArrowDropDown
                  fontSize="small"
                  style={{
                    color: "#fff",
                    backgroundColor: "#777",
                    borderRadius: 4,
                  }}
                />
              }
              renderInput={(params) => (
                <>
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  {error?.location && (
                    <p className={classes.error}>{error?.location}</p>
                  )}
                </>
              )}
              onChange={(e, v) => {
                setForm((f) => ({
                  ...f,
                  location: v,
                }));

                setError((e) => ({
                  ...e,
                  location: "",
                }));
              }}
              value={form?.location}
              disabled={isSubmitting}
            />
          </div>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FormPendaftaran;
