import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.gray,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    padding: "0 !important",
  },
  resultCard: {
    width: "90%",
    margin: "0 auto 10px",
    backgroundColor: theme.palette.primary.white,
    borderRadius: "0 10px 10px 10px",
    padding: "10px",
    "@media(min-width: 400px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 700px)": {
      width: "50%",
    },
  },
  topCard: {
    margin: "10px auto 10px",
    padding: "10px 10px 20px",
  },
  cardInfo: {
    display: "flex",
    justifyContent: "space-between",
  },
  bold: {
    fontWeight: 600,
    textAlign: "left",
  },
  symbol: {
    color: "#154970",
    fontWeight: 600,
    textAlign: "left",
  },
  labelInfo: {
    marginTop: "10px",
  },
  resultDetailTitle: {
    textAlign: "left",
    fontWeight: "bold",
    margin: 0,
    fontSize: "1.3rem",
    lineHeight: "1.6rem",
  },
  resultDetail: {
    textAlign: "left",
    margin: "0 0 20px",
  },
  tabNavContainer: {
    display: "flex",
    width: "90%",
    margin: "10px auto 0",
    "@media(min-width: 400px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 700px)": {
      width: "50%",
    },
  },
  tabNavButton: {
    borderRadius: "10px 10px 0 0",
    backgroundColor: theme.palette.primary.darkGray,
    color: theme.palette.primary.lightBlack,
    padding: "5px",
    minWidth: "120px",
    fontSize: "1rem",
    cursor: "pointer",
    borderBottom: "solid 2px " + theme.palette.primary.main,
  },
  tabNavActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    fontWeight: "bold",
  },
  tabPageActive: {
    display: "block",
  },
  tabPageHidden: {
    display: "none",
  },
  resultAccordion: {
    margin: "10px auto !important",
    borderRadius: "10px",
    width: "90%",
    "@media(min-width: 400px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 700px)": {
      width: "50%",
    },
    "&:before": {
      height: 0,
    },
  },
  hasilTestHead: {
    padding: "10px 0",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 0 50% 50%",
    overflow: "hidden",
    position: "relative",
  },
  logo: {
    width: 164,
    height: "auto",
  },
  resultTitle: {
    color: "#154970",
    fontSize: 40,
    margin: 0,
    lineHeight: "40px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  resultSubtitle: {
    color: "#fff",
    fontSize: 18,
    fontWeight: 500,
    margin: "10px 0",
  },
  imageContainer: {
    height: 150,
    width: 150,
    borderRadius: "50%",
    background: "linear-gradient(to bottom, #a4b357 0%,#75890c 100%)",
    overflow: "hidden",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 10,
  },
  gridItem: {
    display: "flex",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  gridItemCenter: {
    borderRight: "2px solid black",
    borderLeft: "2px solid black",
  },
  resultImgWrap: {
    width: 200,
    height: 200,
    overflow: "hidden",
    borderRadius: "100%",
    border: "solid 7px $white",
    padding: 5,
    display: "block",
    marginTop: 13,
    marginLeft: "calc(50% - 100px)",
    position: "absolute",
    zIndex: 2,
    background: "linear-gradient(to bottom, #a4b357 0%,#75890c 100%)",
    "&:before": {
      content: '""',
      position: "absolute",
      zIndex: -1,
      top: -2,
      left: -2,
      right: -2,
      bottom: -2,
      borderRadius: 100,
      border: "7px solid #fff",
    },
  },
  resultImg: {
    width: "100%",
    height: "auto",
  },
  resultCardTitle: {
    color: "#222",
    fontSize: 12,
    fontWeight: 700,
    margin: "0 0 5px",
  },
  blackText: {
    color: "#000",
    fontSize: "1.3rem",
    fontWeight: 700,
    margin: "0 0 5px",
  },
  announcePerson: {
    textAlign: "center",
    fontSize: "1.5rem",
    width: "85%",
    margin: "0 auto",
    borderRadius: 8,
    textTransform: "capitalize",
  },
  detailWrap: {
    margin: "20px 0",
  },
  heading: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
  },
  panelDetailList: {
    fontSize: 12,
    textAlign: "left",
    paddingLeft: 15,
    paddingRight: 15,
  },
  panelDetail: {
    fontSize: 12,
    textAlign: "left",
    paddingLeft: 10,
    paddingRight: 10,
    whiteSpace: "pre-line",
  },
  panelDetailCapital: {
    textTransform: "capitalize",
  },
  startBtn: {
    marginBottom: 20,
    textTransform: "capitalize",
  },
  tmbPlusBox: {
    backgroundColor: "#fdb31b",
    borderRadius: "0 10px 10px 10px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    margin: "0 auto",
    width: "90%",
    "@media(min-width: 400px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 700px)": {
      width: "50%",
    },
  },
  tmbPlusTitle: {
    marginTop: 20,
    display: "inline-block",
  },
  tmbPlusSubTitle: {
    marginTop: 10,
    color: "#cc8628",
  },
  tmbPlusContainer: {
    height: 160,
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
  },
  tmbPlusText: {
    flex: 0.6,
    padding: "14px 16px",
  },
  tmbPlusImageContainer: {
    flex: 0.4,
    paddingTop: 16,
  },
  tmbTitle: {
    fontSize: 11,
    textAlign: "left",
    fontWeight: 600,
  },
  tmbDesc: {
    fontSize: 10,
    textAlign: "left",
    marginTop: 4,
  },
  tmbPlusImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    "@media(min-width: 820px)": {
      objectFit: "contain",
    },
  },
  tmbStart: {
    backgroundColor: "#154972",
    color: "white",
    textTransform: "capitalize",
    float: "left",
    marginTop: 8,
    marginLeft: "-2px",
  },
  bannerContainer: {
    textAlign: "center",
    margin: "0 auto",
    display: "block",
    width: "90%",
    "@media(min-width: 400px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 700px)": {
      width: "50%",
    },
    "& img": {
      width: "100%",
    },
  },
  popupOverlay: {
    backgroundColor: "rgba(0,0,0,.5)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    margin: 0,
    padding: 0,
    textAlign: "center",
    zIndex: 999,
  },
  popupContainer: {
    display: "block",
    width: "90%",
    maxWidth: 400,
    margin: "120px auto 0",
    backgroundColor: theme.palette.primary.white,
    borderRadius: 10,
    textAlign: "center",
    "& > h1": {
      padding: "0 40px",
      margin: 0,
    },
    "& > p": {
      padding: "0 40px",
      margin: 0,
    },
    "& > ul": {
      padding: "0 80px",
      margin: 0,
      textAlign: "left",
    },
    "& > img": {
      width: 30,
      height: 30,
      margin: "10px auto",
    },
  },
  popupHeader: {
    borderRadius: 10,
    color: theme.palette.primary.white,
    backgroundColor: "#154972",
    padding: "20px 40px",
    textAlign: "center",
    "& > p": {
      margin: 0,
    },
  },
  popupButtonContainer: {
    display: "flex",
    padding: "20px",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
  ctaButton: {
    backgroundColor: "#154972",
    color: "white",
    textTransform: "capitalize",
  },
  ctaButtonSecondary: {
    backgroundColor: theme.palette.primary.gray,
    color: theme.palette.primary.lightBlack,
    textTransform: "capitalize",
  },
}));

export default useStyles;
