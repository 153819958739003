const font = "'Poppins', sans-serif";
export default {
  direction: "ltr",

  palette: {
    primary: {
      light: "#5ECBF0",
      main: "#30bcec",
      gray: "#f2f4f4",
      darkGray: "#ddd",
      white: "#fff",
      lightBlack: "#888",
      black: "#222",
      dark: "#139FCD",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fdb31b",
      light: "",
      dark: "",
      contrastText: "#000",
    },
    textColor: {
      gray: "#777",
      gray2: "#ddd",
      gray3: "#eee",
      gray4: "#f2f4f4",
      gray5: "#0000008a",
      gray6: "#999999",
      gray7: "#e5e5e5",
      gray8: "#4f4f4f",
      gray9: "#BBB",
      gray10: '#c4c4c4',
      gray11: '#F2F4F3',
      gray12: '#130F26',
      green: "#19b821",
      white: "#FFF",
      blue: "#2757ca",
      blue2: "#32bdea",
      green2: "#1CBA34",
      darkGray: "#222222",
      green3: '#11A030',
      yellow: '#FDED1F',
      otherBlue: '#33BDEB',
    },
    success: {
      main: "#00ff00",
    },
    info: {
      main: "#30bcec",
    },
    warning: {
      main: "#fdb31b",
    },
    danger: {
      main: "#ff0000",
    },
    lightBlue: {
      main: "#2FBBEC",
      secondary: "#A5E8FF",
    },
    darkBlue: {
      main: "#1461A8",
    },
    orange: {
      main: "#FFBB38",
    },
    red:{
      main: "#D72020",
    },
    brown:{
      main: "#C8894F",
    },
  },

  typography: {
    fontFamily: font,
    useNextVariants: true,
  },
};
