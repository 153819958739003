import { useEffect } from 'react';

import { firestore, Arr } from '../../components/FirebaseProvider';
import { useParams } from 'react-router-dom';

import { useSiswa } from '../../components/SiswaProvider';
export default function SendRaport() {
    const { profile, raport, shareRaport } = useSiswa();
    const params = useParams();

    useEffect(() => {

        if (raport) {
            const sendRaportAsync = async () => {
                const docRef = firestore.doc(`chat/${params.chatId}`);
                const chatItemsRef = docRef.collection('items');
                await docRef.set({
                    updated_at: Arr.serverTimestamp(),
                    siswa_typing: false,
                    nama_siswa: profile.nama,
                    last_chat: {
                        from_mitra: false,
                        from_siswa: true,
                        text: raport,
                        created_at: Date.now()
                    },
                    mitra_unread_count: Arr.increment(1)
                }, { merge: true });
                await chatItemsRef.add({
                    from_mitra: false,
                    from_siswa: true,
                    text: raport,
                    created_at: Date.now(),
                    read: false,
                    push: false
                })
            }
            sendRaportAsync();
            shareRaport("");
        }
    }, [raport, shareRaport, profile.nama, params.chatId])

    return null;
}