import React, { useContext } from 'react';
import { firestore, useFirebase } from './FirebaseProvider';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import AppLoading from './AppLoading';

const TryoutContext = React.createContext();

export function useTo() {
    return useContext(TryoutContext);
}

function TryoutProvider(props) {
    const { user } = useFirebase();
    // 
    const tryoutcol = firestore.collection('tryout');
    const hasiltryoutcol = firestore.collection('hasil_tryout').where("siswa_id", "==", user.uid)

    const [tryout, loading] = useCollectionData(tryoutcol, { idField: "id" });
    const [hasiltryout, loadinghasil] = useCollectionData(hasiltryoutcol, { idField: "id" });

    if (loading || loadinghasil) {
        return <AppLoading />
    }
    // 
    return (
        <TryoutContext.Provider value={{
            tryout, loading,
            hasiltryout, loadinghasil
        }}>
            {props.children}
        </TryoutContext.Provider>
    )
}

export default TryoutProvider;
