import Button from "@material-ui/core/Button";
import CircularProgres from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import styles from "./styles/index";
//
import { Helmet } from "react-helmet";
import slugify from "slugify";
import { useFirebase } from "../../components/FirebaseProvider";
import useRest from "../../hooks/useRest";
import { date } from "../../utils/formatter";
//
function Berita(props) {
  const { classes } = props;

  const { user } = useFirebase();

  const [response, loading] = useRest({
    url: "news/kategori",
    method: "get",
    params: {
      type: "kategori",
    },
  });

  const [snapshot, loadsnap] = useRest({
    url: "news/banner",
    method: "get",
    params: {
      type: "banner",
    },
  });

  const [res, load] = useRest({
    url: "news",
    method: "get",
    params: {
      type: "list",
    },
  });

  const [idName, setIdName] = useState({
    id: "",
  });

  const linkBanner = snapshot?.data?.Item?.targetUrl;
  const PhotoBanner = snapshot?.data?.Item?.photoUrl;

  useEffect(() => {
    if (response?.data?.Item?.items) {
      setIdName({
        id: response?.data?.Item?.items[0]?.id
          ? response.data.Item.items[0].id
          : "",
      });
    }
  }, [response]);

  if (loading || load || loadsnap) {
    return (
      <Container maxWidth="xs">
        <CircularProgres style={{ marginTop: "90%" }} />
      </Container>
    );
  }

  return (
    <div className={classes.newsWrap}>
      <Helmet>
        <title>PakBudi | Berita</title>
      </Helmet>
      <div className={classes.newsCategory}>
        <div className={classes.categorySlide}>
          {response?.data?.Item?.items
            ? response.data.Item.items.map((item) => {
                return (
                  <div key={item.id} className={classes.categoryLink}>
                    <Button
                      classes={{ disabled: classes.disabledBtn }}
                      color="primary"
                      className={classes.categoryBtn}
                      disabled={item.id === idName.id}
                      onClick={() => setIdName({ id: item.id })}
                    >
                      {item.title}
                    </Button>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <div className={classes.newsBody}>
        <div className={classes.newsInner}>
          {response?.data?.Item?.items
            ? response.data.Item.items.map((item) => {
                return (
                  <div key={item.id} className={classes.newsCore}>
                    {item.pinNews !== undefined && item.id === idName.id && (
                      <>
                        <Button
                          key={item.id}
                          style={{
                            background: `url(${item.pinNews.thumbnailURL}) no-repeat center center`,
                            width: "100%",
                            height: 200,
                            position: "relative",
                            overflow: "hidden",
                            backgroundSize: "cover",
                          }}
                          onClick={() => {
                            const newspath = user ? "berita" : "warta";
                            props.history.push(
                              `/${newspath}/${item.pinNews.id}/${slugify(
                                item.pinNews.title
                              )}`
                            );
                          }}
                          fullWidth
                        >
                          <div className={classes.newsCaption}>
                            <h4 className={classes.newsHeadline}>
                              {item.pinNews.title}
                            </h4>
                            <span className={classes.headlineDate}>
                              {date(item.pinNews.createdAt)}
                            </span>
                          </div>
                        </Button>
                      </>
                    )}
                  </div>
                );
              })
            : []}
          <div className={classes.newsFeed}>
            {res
              ? res.data.Items.sort((a, b) => {
                  return b.publishedAt - a.publishedAt;
                }).map((item) => {
                  return (
                    <div key={item.newsId}>
                      {item.pinNews !== true && item.kategori === idName.id ? (
                        <div className={classes.newsFeed}>
                          <List component="nav" className={classes.listNews}>
                            <ListItem
                              className={classes.listItemNews}
                              divider
                              button
                              onClick={() => {
                                const newspath = user ? "berita" : "warta";
                                props.history.push(
                                  `/${newspath}/${item.newsId}/${slugify(
                                    item.title.toLowerCase()
                                  )}`
                                );
                              }}
                            >
                              <div
                                className={classes.newsThumb}
                                style={{
                                  backgroundImage:
                                    "url('" + item.thumbnailURL + "')",
                                }}
                              ></div>
                              <ListItemText>
                                <p className={classes.newsTitle}>
                                  {item.title}
                                </p>
                                <span className={classes.dateTime}>
                                  {date(item.publishedAt)}
                                </span>
                              </ListItemText>
                            </ListItem>
                          </List>
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : []}
          </div>
        </div>
        {linkBanner !== undefined && PhotoBanner !== undefined ? (
          <div className={classes.banner}>
            <Button className={classes.btnBanner}>
              <img
                onClick={() => window.open(linkBanner, "_blank")}
                className={classes.bannerImg}
                alt=""
                src={PhotoBanner}
              />
            </Button>
          </div>
        ) : (
          <div style={{ marginBottom: 100 }}></div>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(Berita);
