import React, { useEffect, useState } from "react";

// import komponen material ui
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import Toolbar from "@material-ui/core/Toolbar";
import statements from "./data/statements.json";

import LinearProgress from "@material-ui/core/LinearProgress";
// import styles
import useStyles from "./styles/index";

import { useFirebase } from "../../../components/FirebaseProvider";
import { useSiswa } from "../../../components/SiswaProvider";
// utils for game
import calculateResult from "./calculate";

import shuffle from "lodash/shuffle";
import Img from "react-image";

import { Helmet } from "react-helmet";
import ImageLoader from "../../../components/ImageLoader";
import TopNav from "../../../components/TopNav";
import { baseUrl } from "../../../images/s3";

// buat sebuah fungsi komponen dengan nama NotFound
function Game({ history }) {
  const classes = useStyles();
  const { user, firestore, Arr } = useFirebase();
  const { profile } = useSiswa();
  const [started, setStarted] = useState(false);
  const [slide, setSlide] = useState({
    direction: "left",
    in: false,
  });
  const [index, setIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [indexes, setIndexes] = useState([]);
  const mbtiSiswa = null;

  useEffect(() => {
    const indexes = shuffle(Array.from(statements.keys()));
    setIndexes(indexes);
  }, []);
  const handleAnswer = (e) => {
    setAnswers((answers) => ({
      ...answers,
      [e.target.name]: e.target.value,
    }));

    setSlide({
      direction: "right",
      in: false,
    });
    setTimeout(() => {
      if (index >= 59) {
        setStarted(false);
        const hasil_tesMinatBakat = calculateResult(answers);
        firestore.doc(`siswa/${user.uid}`).set(
          {
            hasil_tesMinatBakat,
            diperbarui_pada: Arr.serverTimestamp(),
          },
          { merge: true }
        );
        firestore
          .doc(`notifikasi/${user.uid}`)
          .collection("items")
          .add({
            createdAt: Date.now(),
            icon: "icon-tmb.png",
            path: `/tesminatbakat/result/${hasil_tesMinatBakat.toLowerCase()}`,
            read: false,
            push: false,
            title: "Tes Minat Bakat",
            content: "Hasil Tes Minat Bakat sudah dapat di lihat",
          });
        history.push(
          `/tesminatbakat/result/${hasil_tesMinatBakat.toLowerCase()}`,
          {
            nama: profile?.nama,
          }
        );
      } else if (index !== 60) {
        setIndex((index) => index + 1);
        setSlide({
          direction: "left",
          in: true,
        });
      }
    }, 500);
  };

  if (!started) {
    return (
      <>
        <TopNav />
        <Container className={classes.container} maxWidth="xs" component="main">
          <Helmet>
            <title>PakBudi | TMB</title>
          </Helmet>
          <div
            className={classes.guide}
            style={{ height: mbtiSiswa ? 540 : 435 }}
          >
            <Typography className={classes.title} variant="h3">
              {" "}
              PERHATIAN
            </Typography>
            <ol className={classes.list}>
              <li className={classes.listItem}>
                Pilih pernyataan yang paling mewakili kepribadianmu pada salah
                satu gambar yang tersedia.
              </li>
              <li className={classes.listItem}>
                Tidak ada jawaban yang benar atau salah, maka jawablah dengan
                objektif.
              </li>
              <li className={classes.listItem}>
                {" "}
                Bila sudah siap tap tombol mulai tes dibawah ini:
              </li>
            </ol>
            <img
              className={classes.frontImg}
              src={`${baseUrl}avatar.png`}
              alt="avatar"
            />
            {mbtiSiswa ? (
              <>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 13, fontWeight: "bold" }}
                >
                  Kamu sudah pernah test minat bakat dengan hasil {mbtiSiswa},
                  untuk mengulang test minat bakat silahkan memilih opsi
                  pembayaran di bawah
                </Typography>
              </>
            ) : (
              <Button
                className={classes.startBtn}
                size="large"
                onClick={() => {
                  setStarted(true);
                  setSlide({
                    direction: "left",
                    in: true,
                  });
                }}
                variant="contained"
                color="primary"
              >
                {" "}
                Mulai Test Sekarang
              </Button>
            )}
          </div>
        </Container>
      </>
    );
  }

  const statement = statements[indexes[index]];
  return (
    <>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <img
            src={`${baseUrl}new-logo-w-tmb.png`}
            alt="logo"
            className={classes.logo}
          />
        </Toolbar>
      </AppBar>
      <Container className={classes.container} maxWidth="xs" component="main">
        <div className={classes.topMsg}>
          <Typography className={classes.topMsgText} variant="subtitle1">
            Pilih diantara 2 pilihan ini yang paling mencerminkan diri kamu
          </Typography>
        </div>
        <div className={classes.topProgress}>
          <LinearProgress
            variant="determinate"
            value={((index + 1) / 60) * 100}
          />
          <Typography className={classes.progressCount}>
            Pernyataan ke {index + 1} dari {statements.length}
          </Typography>
        </div>
        <div className={classes.certainMsg}>
          <Typography
            variant="caption"
            color="primary"
            className={classes.certainMsgText}
          >
            {index >= 0 && index <= 14 && "Ayo Cari tahu minat dan bakatmu!"}
            {index >= 15 &&
              index <= 29 &&
              "Ayo semangat! Ayo lihat cerminan dirimu!"}
            {index >= 30 && index <= 44 && "Udah setengah jalan lebih, ayo!"}
            {index >= 45 && index <= 60 && "Ayo sedikit lagi selesai!"}
          </Typography>
        </div>
        <div className={classes.statement}>
          <Slide direction={slide.direction} in={slide.in}>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="gender"
                name={statement.name}
                value={answers[statement.name] || ""}
                onChange={handleAnswer}
              >
                <FormControlLabel
                  className={classes.option}
                  value={statement.options.op1.value}
                  control={<Radio className={classes.radio} />}
                  label={
                    <>
                      <div className={classes.illustration}>
                        <Img
                          className={classes.statementImg}
                          src={statement.options.op1.illustration}
                          alt={statement.options.op1.caption}
                          loader={<ImageLoader width={280} height={160} />}
                        />
                        <span className={classes.statementCaption}>
                          {statement.options.op1.caption}
                        </span>
                      </div>
                    </>
                  }
                />
                <FormControlLabel
                  className={classes.option}
                  value={statement.options.op2.value}
                  control={<Radio className={classes.radio} />}
                  label={
                    <>
                      <div className={classes.illustration}>
                        <Img
                          className={classes.statementImg}
                          src={statement.options.op2.illustration}
                          alt={statement.options.op2.caption}
                          loader={<ImageLoader width={280} height={160} />}
                        />
                        <span className={classes.statementCaption}>
                          {statement.options.op2.caption}
                        </span>
                      </div>
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Slide>
        </div>
      </Container>
    </>
  );
}

export default Game;
