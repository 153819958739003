import React, { useContext, useEffect, useCallback, useState } from 'react';

import { useFirebase, firestore, messaging } from './FirebaseProvider';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { withRouter, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import UIfx from 'uifx';
import AppLoading from './AppLoading';
import useStyles from './style';
const SiswaContext = React.createContext();

export function useSiswa() {
    return useContext(SiswaContext);
}

function SiswaProvider(props) {
    const classes = useStyles();

    const [raport, setRaport] = useState("");
    const [pageEvent, setPageEvent] = useState("");
    const { user } = useFirebase();
    const history = useHistory();
    const profiledoc = firestore.doc(`siswa/${user.uid}`);
    const [profile, loadingprofile] = useDocumentData(profiledoc);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        if (messaging) {
            const getToken = async () => {
                try {

                    const token = await messaging.getToken();

                    if (token) {
                        await firestore.doc(`notifikasi/${user.uid}`).set({ token }, { merge: true });
                    }

                    messaging.onMessage((payload) => {
                        const ring = new UIfx(payload.data && payload.data.soundApp ? payload.data.soundApp : 'https://cdn.glitch.com/922ee5f5-2d45-4f6a-955c-10b16e7aee54%2Fringtone.mp3?v=1575722255364');
                        const messageIncome = new UIfx(payload.data && payload.data.soundMessage ? payload.data.soundMessage : 'https://cdn.glitch.com/fa950c3d-2965-41dd-b803-2aca6d06ce3c%2Fhallo.mp3?v=1578782795030');
                        const handleClick = () => {
                            return history.push(payload.notification.click_action);
                        }
                        if (payload.data && payload.data.show === 'true') {
                            if (payload.data && payload.data.inApp === 'true') {
                                messageIncome.setVolume(1.0).play()
                            } else {
                                ring.setVolume(1.0).play()
                                enqueueSnackbar(
                                    <Button
                                        onTouchStart={move => { return null }}
                                        onTouchMove={move => {
                                            if (move.touches[0].clientY > 0) { closeSnackbar() }
                                        }}
                                        onClick={handleClick}
                                        className={classes.wrap}
                                    >
                                        <div>
                                            <img className={classes.wrapImg} alt="" src={payload.notification.icon} />
                                        </div>
                                        <div className={classes.wrapContent}>
                                            <p className={classes.title}>
                                                {payload.notification.title}
                                            </p>
                                            <p className={classes.body}>
                                                {payload.notification.body}
                                            </p>
                                        </div>
                                    </Button>,
                                    { anchorOrigin: { vertical: 'top', horizontal: 'center' } },
                                    { autoHideDuration: 3000 },
                                );
                            }
                        } else {
                            if (payload.data && payload.data.inApp === 'true') {
                                messageIncome.setVolume(1.0).play()
                            } else {
                                ring.setVolume(1.0).play()
                            }
                        }
                    })

                } catch (err) {
                    console.log(err);
                }
            }

            messaging.onTokenRefresh(async () => {
                console.log('onTokenRefresh')

                await getToken();
            })

            getToken();
        }
    }, [classes.body, classes.title, classes.wrap, classes.wrapContent, classes.wrapImg, closeSnackbar, enqueueSnackbar, history, user.uid]);

    const shareRaport = useCallback((raport) => {
        setRaport(raport);
    }, [])
    if (loadingprofile) {
        return <AppLoading />
    }

    return (
        <SiswaContext.Provider value={{
            profile, loadingprofile,
            raport, shareRaport,
            pageEvent, setPageEvent
        }}>
            {props.children}
        </SiswaContext.Provider>
    )
}

export default withRouter(SiswaProvider);

