// React
import React from 'react';

// Styles
import useStyles from './styles';

// Avatar
import NoNotif from '../../images/Group 5.png';

// Component
import TopAction from '../../components/TopAction';
import AppHeader from '../../components/AppHeader';
import NotifikaiItem from './list';

// Utils
import { useNotifikasi } from '../../components/NotifikasiProvider';

function Notifikasi({ history }) {

    // UseStyles
    const classes = useStyles();
    const { notifItems } = useNotifikasi();

    return (
        <div className={classes.root}>
            <div className={classes.pageHeader}>
                <AppHeader />
                <TopAction />
            </div>
            <div className={classes.pageContent}>
                <div className={classes.notifikasiBox}>
                    {
                        notifItems && notifItems.length > 0 ?
                            notifItems.map((item) => 
                                <NotifikaiItem item={item} key={item.id} />
                            )
                            :
                            <div className={classes.NoNotif}>
                                <img className={classes.imgNoNotif} alt="" src={NoNotif} />
                                <p className={classes.title}>Tidak Ada Apapun !</p>
                                <p className={classes.subtitle}>Kamu Belum Mendapatkan Notifikasi Apapun</p>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Notifikasi;