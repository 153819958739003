// React
import React, { useCallback, useEffect, useState } from "react";
// DropZone
import { useDropzone } from "react-dropzone";
// Axios
import axios from "axios";
// Firebase
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { firestore, functions } from "../../components/FirebaseProvider";
// Notification
import { useSnackbar } from "notistack";
// Styles
import useStyles from "./styles";
// Component
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import ImageIcon from "@material-ui/icons/Image";
import AppHeader from "../../components/AppHeader";
import { baseURL } from "../../images/s3";
//
function KonfirmasiTransfer(props) {
  // Styles
  const classes = useStyles();
  // Router
  const params = useParams();
  // State
  const [error, setError] = useState("");
  //
  const [Loading, setLoading] = useState(false);
  //
  const [Data, setData] = useState();
  //
  const [form, setForm] = useState({
    photoURL: "",
  });
  // State File
  const accept = ["image/png", "image/jpeg"];
  // MaxSize
  const maxSize = "20971520";
  // Query
  const [snapshot, loading] = useDocument(firestore.doc(`misi/${params.id}`));
  // UseEffect
  useEffect(() => {
    if (snapshot) {
      setData(snapshot.data());
    }
  }, [snapshot]);
  // Snackbar
  const { enqueueSnackbar } = useSnackbar();
  // On Accepted
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setError("");
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onabort = () => {
        setError("Pembacaan file dibatalkan");
      };
      reader.onerror = () => {
        setError("Pembacaan file gagal");
      };
      reader.onload = async () => {
        setLoading(true);
        try {
          const generateUploadUrl =
            functions.httpsCallable("generateUploadUrl");

          const result = await generateUploadUrl({
            filename: file.name,
            directory: `images/buktiMisi/`,
            contentType: file.type,
            id: params.id,
          });

          await axios.put(result.data.uploadUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          setForm({
            photoURL: result.data.photoUrl,
          });

          // Show Notification
          enqueueSnackbar("Photo Transaksi Berhasil Diupload.", {
            variant: "success",
          });
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      reader.readAsDataURL(file);
    },
    [enqueueSnackbar, params.id]
  );
  // On Rejected
  const onDropRejected = useCallback(
    (rejected) => {
      if (!accept.includes(rejected[0].type)) {
        setError(`Tipe file tidak didukung : ${rejected[0].type} `);
      } else if (rejected[0].size >= maxSize) {
        setError(`Ukuran file terlalu besar > 20Mb`);
      }
    },
    [accept, maxSize]
  );
  // UseDropzone
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxSize,
    disabled: Loading,
  });
  // State
  const [open, setOpen] = React.useState(false);
  // FUnctions
  const handleClickOpen = async () => {
    try {
      await firestore.doc(`misi/${params.id}`).set(
        {
          photoURL: form.photoURL,
          status: "review",
        },
        { merge: true }
      );
    } catch (err) {
      console.log(err.message);
    }
    setOpen(true);
  };
  //
  const handleClose = () => {
    setOpen(false);
    props.history.push("/listmisi");
  };
  //
  const handleDelete = async () => {
    setForm({
      photoURL: "",
    });
  };
  // Condition Loading
  if (loading) {
    return (
      <Container maxWidth="xs" className={classes.wrapper}>
        <CircularProgress />
      </Container>
    );
  }
  //
  return (
    <div className={classes.root}>
      <div className={classes.listMissionHead}>
        <AppHeader />
      </div>
      <div className={classes.confirmBody}>
        <div className={classes.pageTop}>
          <h2 className={classes.confirmTitle}>Selesaikan Misi!</h2>
          <p className={classes.confirmSubtitle}>"{Data?.misi?.judul}"</p>
          <Button
            className={classes.btnGogo}
            href={Data?.misi?.url ?? "#"}
            target="_blank"
          >
            GO !
          </Button>
          {/* <div className={classes.konfirmasiWrap}>
                        <p className={classes.pageSubtitle2} dangerouslySetInnerHTML={{ __html: Data && Data.misi && Data.misi.content }} />
                    </div> */}
        </div>
        <p
          className={classes.sub1}
          dangerouslySetInnerHTML={{
            __html: Data?.misi?.content,
          }}
        />
        <div className={classes.uploadForm}>
          {form.photoURL !== "" ? (
            <Grid className={classes.thumbPLayout}>
              <Button
                onClick={handleDelete}
                classes={{ root: classes.btnroot }}
                className={classes.btnClose}
              >
                <CloseIcon className={classes.iconClose} color="error" />
              </Button>
              <div className={classes.layer} />
              <Avatar
                variant="square"
                className={classes.thumbPreview}
                src={form.photoURL}
              />
            </Grid>
          ) : (
            <Grid {...getRootProps()} className={classes.thumbPLayout}>
              <input {...getInputProps()} />
              {Loading && <CircularProgress className={classes.gridCaption} />}
              {!Loading && (
                <Typography
                  variant="caption"
                  color="primary"
                  className={classes.gridCaption}
                >
                  <ImageIcon className={classes.imageIcon} /> Upload Bukti Misi
                  Kamu
                </Typography>
              )}
            </Grid>
          )}
          {error && <Typography color="error">{error}</Typography>}
          <img src={`${baseURL}klik.png`} className={classes.imgKlik} alt="" />
        </div>
        <Button
          className={classes.btnOke}
          variant="contained"
          onClick={handleClickOpen}
          disabled={form.photoURL === ""}
          color="primary"
          size="large"
        >
          Kirim Bukti Misi
        </Button>

        <div className={classes.dialogWrap}>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle className={classes.DialogTitle}>
              <span className={classes.pageName}>Misi</span>
              <img
                className={classes.logoImg}
                src={`${baseURL}new-logo-w.png`}
                alt=""
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.dialogTitleConfirm}
                style={{
                  color: "#4f4f4f",
                  textAlign: "center",
                  fontSize: ".85rem",
                  marginTop: 20,
                }}
              >
                Terima kasih konfirmasi misi sudah kami terima, dan akan
                dikonfirmasi dalam waktu{" "}
                <strong className={classes.blueBold}>2 x 24 Jam</strong>.
              </DialogContentText>
            </DialogContent>
            <Button
              className={classes.btnDialogConfirm}
              onClick={handleClose}
              color="primary"
            >
              Ok
            </Button>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default KonfirmasiTransfer;
