import { useState } from "react";

const Notification = window.Notification || {};

function UseNotifikasi() {
  const [permission, setPermission] = useState(Notification.permission);

  const showPrompt = async () => {
    if ("Notification" in window) {
      const permission = await Notification.requestPermission();

      setPermission(permission);
    } else {
      console.log("No notification");
    }
  };

  return { permission, showPrompt };
}

export default UseNotifikasi;
