// React
import React from "react";

// Styles
import useStyles from "./styles";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ShareIcon from "@material-ui/icons/Share";
import { Link, Route, withRouter } from "react-router-dom";
import logo from "../../images/new-logo-w.png";
import { baseURL } from "../../images/s3";
import { deleteData, getData } from "../../utils/localforage";
import html2canvas from "html2canvas";
import { useSnackbar } from "notistack";
import { dataURItoFile } from "../../utils/converter";
import share from "../../utils/share";

function AppHeader(props) {
  const classes = useStyles();

  const handleBack = async () => {
    let eventId = props?.match?.params?.eventId ?? "";

    const prevPath = await getData(`prevPath-${eventId}`);

    if (prevPath !== undefined) {
      deleteData(eventId);

      props.history.push(prevPath);
    } else {
      if (props?.match?.path?.includes("/acara/u")) {
        deleteData(eventId);

        props.history.push("/");
      } else {
        props.history.goBack();
      }
    }
  };

  const handleBackChat = () => {
    props.history.push("/konsultasi/semua");
  };

  const handleBackNotif = () => {
    props.history.push("/settings");
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleShare = async (e) => {
    // props.history.push("/bagikan");
    try {
      const canvas = await html2canvas(document.body, {
        allowTaint: false,
        useCORS: true,
        windowHeight: 900,
        windowWidth: 450,
      });
      // console.log(canvas.toDataURL())
      const file = dataURItoFile(canvas.toDataURL());
      //console.log(file);
      const shareData = {
        files: [file],
        title: "PakBudi.id",
        text: "Ini hasil Tes Minat Bakatku\nkamu mana?\nTes 5 menit aja gratis klik:\nhttps://tesminatbakat.pakbudi.id",
      };
      // const response = await share(shareData);
      await share(shareData);
      //  console.log(response)
    } catch (e) {
      enqueueSnackbar(e.message);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.toggleBack}>
          <div data-html2canvas-ignore>
            {/* Back Button White */}
            <Route
              path={[
                "/acara/:eventId/pembayaran/:transaksiId",
                "/acara/:eventId/konfirmasi/:transaksiId",
                "/acara/u/:eventId/pembayaran/:transaksiId",
                "/acara/u/:eventId/konfirmasi/:transaksiId",
                "/bagikan",
                "/berita/:id/:slug",
                "/hasiltryout/:to_id",
                "/myCoin",
                "/isiCoin/:total/:coin",
                "/listtransaksi",
                "/konfirmasitransfer/:id",
                "/rinciantransfer/:total/:method/:doc/:no",
                "/promoCoin/:total/:coin",
                "/misi",
                "/listmisi",
                "/konfirmasimisi/:id",
                "/tryout/:to_id",
                "/leaderboard/:to_id",
                "/konsultasi/:type",
                "/tesminatbakat",
                "/checkout",
              ]}
              render={() => {
                return (
                  <IconButton
                    size="small"
                    color="primary"
                    className={classes.historyBackWhite2}
                    onClick={handleBack}
                  >
                    <ChevronLeftIcon color="primary" fontSize="small" />
                  </IconButton>
                );
              }}
            />

            {/* Back Button Color */}
            <Route
              path={[
                "/syaratKetentuan",
                "/privacyPolicy",
                "/acara/:eventId/daftar",
                "/acara/:eventId/proses/:transaksiId",
                "/acara/u/:eventId/daftar",
                "/acara/u/:eventId/lengkapi-data/:pesertaId",
              ]}
              render={() => {
                return (
                  <IconButton
                    size="small"
                    color="primary"
                    className={classes.historyBackColor}
                    onClick={handleBack}
                  >
                    <ChevronLeftIcon fontSize="small" />
                  </IconButton>
                );
              }}
            />

            {/* Back Button White (Back to "/konsultasi") */}
            <Route
              path="/chat"
              render={() => {
                return (
                  <IconButton
                    size="small"
                    color="primary"
                    className={classes.historyBackWhite2}
                    onClick={handleBackChat}
                  >
                    <ChevronLeftIcon color="primary" fontSize="small" />
                  </IconButton>
                );
              }}
            />

            {/* Back Button White (Back to "/settings") */}
            <Route
              path="/notifikasisettings"
              render={() => {
                return (
                  <IconButton
                    size="small"
                    color="primary"
                    className={classes.historyBackColor}
                    onClick={handleBackNotif}
                  >
                    <ChevronLeftIcon fontSize="small" />
                  </IconButton>
                );
              }}
            />
          </div>
        </div>

        {/* Logo */}
        <Button component={Link} to="/" className={classes.logo}>
          <Route
            path="/chat"
            render={() => {
              return <span className={classes.pageName}>Chat</span>;
            }}
          />
          <Route
            path={["/berita", "/beritaDetail/:id"]}
            render={() => {
              return <span className={classes.pageName}>Berita</span>;
            }}
          />
          <Route
            path="/chat"
            render={() => {
              return <span className={classes.pageName}>Chat</span>;
            }}
          />
          <Route
            path={[
              "/acara/u/:eventId/pembayaran/:transaksiId",
              "/acara/u/:eventId/konfirmasi/:transaksiId",
              "/acara/:eventId/pembayaran/:transaksiId",
              "/acara/:eventId/konfirmasi/:transaksiId",
            ]}
            render={() => {
              return <span className={classes.pageName}>Acara</span>;
            }}
          />
          <Route
            exact
            path={[
              "/acara/:eventId",
              "/acara/u/:eventId",
              "/acara/ticket/:eventId/:transaksiId",
            ]}
            render={() => {
              return <span className={classes.pageName}>Acara</span>;
            }}
          />
          <Route
            path={["/bagikan", "/hasiltryout/:to_id"]}
            render={() => {
              return <span className={classes.pageName}>Rapor</span>;
            }}
          />
          <Route
            path="/notifikasi"
            render={() => {
              return <span className={classes.pageName}>Notifikasi</span>;
            }}
          />
          {/* <Route
            path="/konsultasi/:type"
            render={() => {
              return (
                <div className={classes.spanWrap}>
                  <span className={classes.pageNameTwo}>Konsultasi</span>
                  <span className={classes.betaTag}>BETA</span>
                </div>
              );
            }}
          /> */}
          <Route
            path="/konsultasi/:type"
            render={() => {
              return <span className={classes.pageName}>Konsultasi</span>;
            }}
          />
          <Route
            path="/tesminatbakat"
            render={() => {
              return <span className={classes.pageName}>Tes Minat Bakat</span>;
            }}
          />
          <Route
            path={[
              "/myCoin",
              "/isiCoin/:total/:coin",
              "/listtransaksi",
              "/konfirmasitransfer/:id",
              "/rinciantransfer/:total/:method/:doc/:no",
              "/promoCoin/:total/:coin",
            ]}
            render={() => {
              return <span className={classes.pageName}>Coin</span>;
            }}
          />
          <Route
            path={[
              "/tryout/:hasil_tryout_id",
              "/tryout/:to_id",
              "/leaderboard/:to_id",
            ]}
            render={() => {
              return <span className={classes.pageName}>Tryout</span>;
            }}
          />
          <Route
            path={["/misi", "/listmisi", "/konfirmasimisi/:id"]}
            render={() => {
              return <span className={classes.pageName}>Misi</span>;
            }}
          />

          {/* Logo Putih */}
          <Route
            path={[
              "/konsultasi/:type",
              "/notifikasi",
              "/chat",
              "/berita",
              "/hasiltryout/:to_id",
              "/acara/u/:eventId/pembayaran/:transaksiId",
              "/acara/u/:eventId/konfirmasi/:transaksiId",
              "/acara/:eventId/pembayaran/:transaksiId",
              "/acara/:eventId/konfirmasi/:transaksiId",
              "/myCoin",
              "/isiCoin/:total/:coin",
              "/listtransaksi",
              "/konfirmasitransfer/:id",
              "/rinciantransfer/:total/:method/:doc/:no",
              "/promoCoin/:total/:coin",
              "/tryout/:hasil_tryout_id",
              "/misi",
              "/listmisi",
              "/konfirmasimisi/:id",
              "/tryout/:to_id",
              "/leaderboard/:to_id",
              "/tesminatbakat",
              "/checkout",
            ]}
            render={() => {
              return (
                <img
                  draggable={false}
                  className={classes.mainLogo1}
                  src={`${baseURL}new-logo-w.png`}
                  alt=""
                  width="150px"
                />
              );
            }}
          />

          {/* Logo Putih (Exact) */}
          <Route
            exact
            path={[
              "/acara/:eventId",
              "/acara/u/:eventId",
              "/acara/ticket/:eventId/:transaksiId",
            ]}
            render={() => {
              return (
                <img
                  draggable={false}
                  className={classes.mainLogo1}
                  src={`${baseURL}new-logo-w.png`}
                  alt=""
                  width="150px"
                />
              );
            }}
          />

          {/* Logo Putih (Local) */}
          <Route
            exact
            path="/bagikan"
            render={() => {
              return (
                <img
                  draggable={false}
                  className={classes.mainLogo1}
                  src={logo}
                  alt=""
                  width="150px"
                />
              );
            }}
          />

          {/* Logo Warna */}
          <Route
            exact
            path={[
              "/login",
              "/lupapassword",
              "/register",
              "/ubahemaildanpassword",
              "/syaratKetentuan",
              "/privacyPolicy",
              "/verifikasi",
              "/lengkapiData",
              "/harapan",
              "/editprofile",
              "/notifikasisettings",
              "/vouchers",
              "/acara/u/:eventId/daftar",
              "/acara/:eventId/daftar",
              "/acara/:eventId/proses/:transaksiId",
            ]}
            render={() => {
              return (
                <img
                  draggable={false}
                  className={classes.mainLogo1}
                  src={`${baseURL}new-logo-c.png`}
                  alt=""
                  width="150px"
                />
              );
            }}
          />

          {/* Logo Warna (Exact) */}
          <Route
            exact
            path={["/acara/u/:eventId/lengkapi-data/:pesertaId"]}
            render={() => {
              return (
                <img
                  draggable={false}
                  className={classes.mainLogo1}
                  src={`${baseURL}new-logo-c.png`}
                  alt=""
                  width="150px"
                />
              );
            }}
          />
        </Button>

        {/* Share Button */}
        <div className={classes.toggleBack}>
          <Route
            data-html2canvas-ignore
            path={[
              "/notifikasi",
              "/konsultasi",
              "/chat",
              "/vouchers",
              "/promoCoin/:total/:coin",
              "/isiCoin/:total/:coin",
              "/rinciantransfer/:total/:method/:doc/:no",
              "/konfirmasitransfer/:id",
              "/listtransaksi",
              "/verifikasi",
              "/lengkapiData",
              "/berita",
              "/tesminatbakat",
              "/tesminatbakat/game",
              "/tryout/:hasil_tryout_id",
              "/misi",
              "/listmisi",
              "/konfirmasimisi/:id",
              "/leaderboard/:to_id",
              "/tesminatbakat",
              "/checkout",
            ]}
            render={() => {
              return (
                <IconButton
                  size="small"
                  className={classes.shareWhite}
                  onClick={handleShare}
                >
                  <ShareIcon fontSize="small" />
                </IconButton>
              );
            }}
          />

          {/* Exact */}
          <Route
            exact
            data-html2canvas-ignore
            path={[
              "/acara/u/:eventId",
              "/acara/u/:eventId/pembayaran/:transaksiId",
              "/acara/u/:eventId/konfirmasi/:transaksiId",
              "/acara/u/:eventId/lengkapi-data/:pesertaId",
              "/acara/ticket/:eventId/:pesertaId",
            ]}
            render={() => {
              return (
                <IconButton
                  size="small"
                  className={classes.shareWhite}
                  onClick={handleShare}
                >
                  <ShareIcon fontSize="small" />
                </IconButton>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(AppHeader);
