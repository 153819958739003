/* eslint-disable react-hooks/exhaustive-deps */
// React
import React, { useEffect } from "react";

// Styles
import useStyles from "./styles";

// Input

// Router
import { Link, Route, useLocation } from "react-router-dom";

// Icons
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import ChatIcon from "@material-ui/icons/Chat";
import ShareIcon from "@material-ui/icons/Share";

import { useSiswa } from "../../components/SiswaProvider";
import { useChat } from "../../components/useChatList";

function TopAction() {
  const classes = useStyles();

  const { setPageEvent } = useSiswa();

  const { unread } = useChat();

  const location = useLocation();

  let from = null;

  if (location.pathname === "/home") {
    from = "home";
  } else if (location.pathname === "/chat") {
    from = "chat";
  } else if (location.pathname === "/notifikasi") {
    from = "notifikasi";
  } else if (
    location.pathname === "/konsultasi/semua" ||
    location.pathname === "/konsultasi/mahasiswa" ||
    location.pathname === "/konsultasi/gurubk" ||
    location.pathname === "/konsultasi/motivator"
  ) {
    from = "konsultasi";
  }

  useEffect(() => {
    setPageEvent(from);
  }, [from]);

  const chat = (
    <Badge
      badgeContent={unread?.length}
      classes={{ badge: classes.online }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <ChatIcon />
    </Badge>
  );

  return (
    <div className={classes.root}>
      <div className={classes.topAction}>
        <div className={classes.otherAction}>
          {/* <Route path="/home" render={() => {
                        return <IconButton onClick={captureBody} className={classes.actionBtn}><PhotoCameraIcon /></IconButton>
                    }} /> */}
          <Route
            path="/konsultasi"
            render={() => {
              return (
                <IconButton
                  className={classes.actionBtn}
                  component={Link}
                  to="/chat"
                >
                  {chat}
                </IconButton>
              );
            }}
          />
          <Route
            path="/home"
            render={() => {
              return (
                <IconButton
                  className={classes.actionBtn}
                  style={{ padding: 8 }}
                  component={Link}
                  to="/bagikan"
                >
                  <ShareIcon />
                </IconButton>
              );
            }}
          />
          <Route
            path="/notifikasi"
            render={() => {
              return <div className={classes.spacing}></div>;
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TopAction;
