import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import useStyles from "./styles/timeoutDialog";

export default function CameraDialog({
  open,
  handleClose,
  previewSrc,
  sendImage,
}) {
  const classes = useStyles();
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
      {/* <DialogTitle className={classes.DialogTitle}>{"Mengakhiri Sesi"}</DialogTitle> */}
      <DialogContent>
        <img src={previewSrc} style={{ width: "100%" }} alt="preview" />
      </DialogContent>
      <DialogActions className={classes.dialogActionEnd}>
        <Button onClick={handleClose} fullWidth className={classes.btnDontEnd}>
          Tidak
        </Button>
        <Button fullWidth onClick={sendImage} className={classes.btnEndChat}>
          Kirim
        </Button>
      </DialogActions>
    </Dialog>
  );
}
