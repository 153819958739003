// React
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// Styles
import useStyles from "../home/styles";

// MUI
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";
import UseNotifikasi from "../../components/UseNotifikasi";

// Component
import React, { useEffect, useState } from "react";
import BottomNav from "../../components/BottomNav";
import PbAccordionCard from "../../components/PbAccordionCard";
import TopAction from "../../components/TopAction";
import { client } from "../../hooks/useFetchCoralis";
import Logo from "../../images/new-logo-w.png";
import noPic from "../../images/no-pic.svg";
function CekPeluangResult({ history }) {
  // UseStyles
  const classes = useStyles();

  // Profie
  const { profile, setPageEvent } = useSiswa();
  const { user } = useFirebase();
  const { permission, showPrompt } = UseNotifikasi();

  const [advice, setAdvice] = useState("");
  const [advice2, setAdvice2] = useState("");
  const [chanceText, setChanceText] = useState("Rendah");
  const [percentage, setPercentage] = useState(0);

  const handleLink = () => {
    setPageEvent("home");
    history.push("/editprofile");
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    try {
      const response = await client.get(`cek_peluang?uid=${user.uid}`);
      const { data } = response.data;
      setAdvice(data.advice_text);
      setAdvice2(data.advice_text_2);
      setChanceText(data.result_text);
      setPercentage(data.weight_percent);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>PakBudi | Cek Peluang</title>
      </Helmet>
      <div className={classes.profileBg}>
        <Grid container className={classes.page}>
          {/* LOGO */}
          <Grid item xs={12}>
            <img src={Logo} width="120px" alt="" />
          </Grid>

          {/* COIN, NOTIFICATION & SHARE RAPOR */}
          <Grid item xs={12}>
            <TopAction />
            {permission !== "granted" && (
              <Fab
                size="small"
                color="secondary"
                onClick={showPrompt}
                className={classes.fab}
              >
                <NotificationsActiveIcon style={{ color: "#fff" }} />
              </Fab>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* FOTO PROFIL */}
              <Grid item>
                <Avatar
                  alt=""
                  src={user?.photoURL ? user?.photoURL : noPic}
                  className={classes.profileImg}
                />
              </Grid>

              {/* DATA PROFIL */}
              <Grid item xs>
                <div className={classes.profileBar}>
                  <p className={classes.username}>{profile?.nama}</p>
                  <h5 className={classes.profile}>{profile?.sekolah?.nama}</h5>
                  <h5 className={classes.profile}>{profile?.kampus?.nama}</h5>
                  <h5 className={classes.profile}>{profile?.jurusan?.nama}</h5>
                  {/* EDIT PROFILE */}
                  <div className={classes.editBtnCon}>
                    <Button
                      size="small"
                      variant="contained"
                      className={classes.editBtn}
                      onClick={handleLink}
                    >
                      Edit Profile
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.pbCard + " first medium"}>
        <p className={classes.pbHeroIntro}>Hasil Cek Peluang SNBT Kamu</p>
        <h1 className={classes.pbHeroTitle}>{chanceText}</h1>
        <h2 className={classes.pbHeroNumber}>{percentage}%</h2>
      </div>
      <PbAccordionCard title="Saran Daya Saing">
        <p dangerouslySetInnerHTML={{ __html: advice }}></p>
      </PbAccordionCard>
      <PbAccordionCard title="Saran Pengembangan Nilai UTBK">
        <p dangerouslySetInnerHTML={{ __html: advice2 }}></p>
      </PbAccordionCard>
      <div className={classes.pbDisclaimerCard}>
        <h4>Disclaimer:</h4>
        <p>
          Program ini hanya sebagai prediksi untuk membantu cek peluang di SNBT
          2023 ya. Bukan merupakan acuan utama atau hasil resmi SNBT.
        </p>
      </div>
      <Link
        to="/cekpeluang?retry=1"
        className={classes.pbBackLink}
        style={{ marginTop: 15, display: "inline-block" }}
      >
        Coba Lagi
      </Link>
      <BottomNav />
    </div>
  );
}

export default CekPeluangResult;
