import React from "react";
import { withRouter, Link } from "react-router-dom";
// Icons
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
// Stylesheet
import "../pbTheme.css";
import "./style.css";

function BottomNav(props) {
  const { location } = props;
  return (
    <>
      <div className="pb-bottom-navbar-wrapper">
        <Link to="/home">
          <button className={`pb-bottom-navbar-button ${location.pathname === "/home" ? "active" : ""}`}>
            <HomeOutlinedIcon className="pb-bottom-navbar-icon" />
            <label>Home</label>
          </button>
        </Link>
        <Link to="/berita">
          <button className={`pb-bottom-navbar-button ${location.pathname === "/berita" ? "active" : ""}`}>
            <LibraryBooksOutlinedIcon className="pb-bottom-navbar-icon" />
            <label>Berita</label>
          </button>
        </Link>
        <Link to="/tesminatbakat">
          <button className={`pb-bottom-navbar-button ${location.pathname === "/tesminatbakat" ? "active" : ""}`}>
            <ColorLensOutlinedIcon className="pb-bottom-navbar-icon" />
            <label>TMB</label>
          </button>
        </Link>
        <Link to="/listTryout/listTryout">
          <button className={`pb-bottom-navbar-button ${location.pathname === "/listTryout/listTryout" ? "active" : ""}`}>
            <BorderColorOutlinedIcon className="pb-bottom-navbar-icon" />
            <label>Tryout</label>
          </button>
        </Link>
        <Link to="/misi">
          <button className={`pb-bottom-navbar-button ${location.pathname === "/misi" ? "active" : ""}`}>
            <span className="pb-bottom-navbar-notification-counter">!</span>
            <BeenhereOutlinedIcon className="pb-bottom-navbar-icon" />
            <label>Misi</label>
          </button>
        </Link>
        <Link to="/konsultasi/semua">
          <button className={`pb-bottom-navbar-button ${location.pathname === "/konsultasi/semua" ? "active" : ""}`}>
            <ForumOutlinedIcon className="pb-bottom-navbar-icon" />
            <label>Konsultasi</label>
          </button>
        </Link>
      </div>
    </>
  );
}

export default withRouter(BottomNav);