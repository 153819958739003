import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import useStyles from './styles/timeoutDialog';
import { useTo } from '../../components/TryoutProvider';
import { useHistory } from 'react-router-dom';

export default function ToListDialog({ open, handleClose, sendTO }) {
    const classes = useStyles();
    const history = useHistory();
    const { tryout, hasiltryout } = useTo();
    const [listTo, setListTo] = useState([]);
    useEffect(() => {
        if (tryout) {
            const toDoc =
                hasiltryout.sort((a, b) => {
                    return b.created_at.toMillis() - a.created_at.toMillis();
                }) || [];
            setListTo(
                tryout.map?.((doc) => {
                    const to = toDoc.find((to) => to.tryout.id === doc.id);
                    return {
                        hasiltryout: to,
                        ...doc,
                    };
                })?.sort?.((a, b) => a.order - b.order)
            );
        }
    }, [tryout, hasiltryout])
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
        >
            <DialogTitle className={classes.toListDialogTitle}>{"Daftar TRYOUT"}</DialogTitle>
            <DialogContent>
                <div className={classes.toList}>

                    {
                        listTo.map?.((item) => {

                            if (item?.hasiltryout?.status === "done") {

                                return (<div key={item.id} className={classes.toListRow}>
                                    <h3 className={classes.toName}> {item.judul}</h3>
                                    <div className={classes.toListDesc}>
                                        <p className={classes.toListCaption}>Hasil Jawaban Benar</p>
                                        <div className={classes.toBoxDesc}>
                                            <div className={classes.toBoxDescCol}>
                                                {Object.entries(item.hasiltryout?.hasil ?? {}).map(([key, item]) => {
                                                    return (
                                                        <div className={classes.toBoxDescRow} key={key}>
                                                            <span className={classes.descLabel}> {item.nama}</span>
                                                            <span className={classes.descValue}>: {item.benar}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className={classes.shareToWrap}>
                                                <Button className={classes.shareToBtn} onClick={() => sendTO({
                                                    judul: item.judul,
                                                    hasil: item.hasiltryout?.hasil
                                                })}>Bagikan</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            }

                            if (item.status === "draft") {
                                return null;
                            }

                            return <div className={classes.toListRow} key={item.id}>
                                <h3 className={classes.toNameAlt}>{item.judul}</h3>
                                <div className={classes.toListDesc}>
                                    <div className={classes.toBoxDesc}>
                                        <p className={classes.toListCaption}>Belum Dikerjakan</p>
                                        <div className={classes.shareToWrap}>
                                            <Button className={classes.goToBtn} onClick={() => {
                                                history.push(`/home?open_to=${item.id}`)
                                            }}>Kerjakan</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        })
                    }
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActionEnd}>
                <Button
                    onClick={handleClose}
                    fullWidth
                    className={classes.btnBack}
                >
                    Kembali
                </Button>

            </DialogActions>
        </Dialog>
    );
}