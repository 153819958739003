import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import AppHeader from "../../components/AppHeader";
import AppLoading from "../../components/AppLoading";
import { functions, useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";
import { baseURL, baseUrl } from "../../images/s3";
import { MIDTRANS_CLIENT_KEY, MIDTRANS_SCRIPT_URL } from "../../utils/midtrans";
import useStyles from "./styles";

const TmbPlusCheckout = () => {
  const classes = useStyles();
  const history = useHistory();
  const { firestore, user } = useFirebase();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaidTmbPlus, setIsPaidTmbPlus] = useState(false);
  const [mbtiSiswa, setMbtiSiswa] = useState(null);
  const [openConfirmPay, setOpenConfirmPay] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { profile } = useSiswa();

  useEffect(() => {
    const midtransScriptUrl = MIDTRANS_SCRIPT_URL;
    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;
    const myMidtransClientKey = MIDTRANS_CLIENT_KEY;
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);
    document.body.appendChild(scriptTag);
    fetchData();
    return () => {
      document.body.removeChild(scriptTag);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleCheckout() {
    try {
      setIsLoading(true);
      const initiatePayment = functions.httpsCallable("initiatePayment");
      const response = await initiatePayment();
      window.snap.pay(`${response.data.transactionToken}`);
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchData() {
    try {
      setIsLoading(true);
      const findSiswaDoc = await firestore.doc(`siswa/${user.uid}`).get();
      const siswa = findSiswaDoc.data();
      if (typeof siswa?.hasil_tesMinatBakat !== "undefined") {
        const ucMBTI = siswa.hasil_tesMinatBakat.toUpperCase();
        setMbtiSiswa(ucMBTI);
      } else {
        history.replace("/tesminatbakat");
      }

      if (
        typeof siswa.is_tmb_plus !== "undefined" &&
        siswa.is_tmb_plus === true
      ) {
        setIsPaidTmbPlus(true);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function payWithCoin() {
    try {
      setIsLoading(true);
      const payTMBPlusWithCoin = functions.httpsCallable("payTMBPlusWithCoin");
      const response = await payTMBPlusWithCoin();
      const { order_id } = response.data;
      history.replace(
        `/checkout/paymentSuccess?order_id=${order_id}&transaction_status=settlement`
      );
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <>
      <div className={classes.root}>
        <Helmet>
          <title>PakBudi | TMB+</title>
        </Helmet>
        <div
          className={classes.header}
          style={{ height: isPaidTmbPlus ? 100 : 230 }}
        >
          <AppHeader />
        </div>
        {isPaidTmbPlus === false ? (
          <>
            <div className={classes.bodyWrapper}>
              <img
                src={`${baseUrl}tmbLogo.png`}
                alt="tmbLogo"
                className={classes.logo}
              />
              <Typography
                variant="body2"
                style={{ marginTop: 16 }}
                className={classes.desc}
              >
                Tes Minat Bakat Plus adalah fitur super eksklusif untuk kamu
                yang ingin mengetahui minat dan bakat mu lebih dalam.
              </Typography>
              <Typography
                variant="body2"
                style={{ textAlign: "left", marginTop: 16 }}
                className={classes.desc}
              >
                Disini kamu akan mendapatkan penjelasan mendalam tentang:
              </Typography>
              <Typography variant="body2" className={classes.descPoint}>
                1. Apa itu {mbtiSiswa}
              </Typography>
              <Typography variant="body2" className={classes.descPoint}>
                2. Personal Growth Motivation and Development
              </Typography>
              <Typography variant="body2" className={classes.descPoint}>
                3. Bagaimana baiknya {mbtiSiswa} belajar
              </Typography>
              <Typography variant="body2" className={classes.descPoint}>
                4. Pembahasan kuliah dan pekerjaan
              </Typography>
              <Typography variant="body2" className={classes.descPoint}>
                5. Professional development
              </Typography>
              <Typography variant="body2" className={classes.descPoint}>
                6. Pekerjaan alternatif
              </Typography>
              <Typography variant="body2" className={classes.descPoint}>
                7. Tipe kepribadian lain yang cocok dengan {mbtiSiswa}
              </Typography>
            </div>
            <div className={classes.divider}></div>
            <div className={classes.checkoutSection}>
              <Typography variant="subtitle2" style={{ fontSize: "0.75rem" }}>
                Hanya dengan{" "}
                <Typography
                  variant="subtitle2"
                  style={{
                    textDecoration: "line-through",
                    display: "inline-block",
                    fontSize: "0.75rem",
                  }}
                >
                  Rp. 50.000
                </Typography>{" "}
                <Typography
                  variant="subtitle2"
                  style={{
                    display: "inline-block",
                    fontSize: "0.75rem",
                    color: "red",
                  }}
                >
                  Rp. 19.000
                </Typography>{" "}
                atau 110{" "}
                <span className={classes.icoCoin}>
                  <img
                    className={classes.imgCoin}
                    src={`${baseURL}icon-coin.png`}
                    alt="coin"
                  />
                </span>
                kamu akan mendapatkan penjelasan mendetail tentang diri kamu!
                Curi start lebih awal agar masa depan mu lebih tertata!
              </Typography>
              <Box className={classes.row}>
                <Button
                  className={classes.tmbStart}
                  variant="contained"
                  onClick={handleCheckout}
                >
                  Beli Sekarang!
                </Button>
                <Button
                  className={classes.tmbCoin}
                  variant="contained"
                  onClick={() => setOpenConfirmPay(true)}
                >
                  Gunakan 110{" "}
                  <span className={classes.icoCoin}>
                    <img
                      className={classes.imgCoin}
                      src={`${baseURL}icon-coin.png`}
                      alt="coin"
                      style={{ top: -1, left: 3 }}
                    />
                  </span>
                </Button>
              </Box>
            </div>
          </>
        ) : (
          <div className={classes.messageBody}>
            <img
              src={`${baseUrl}tmbLogo.png`}
              alt="tmbLogo"
              className={classes.logo}
            />
            <Typography
              variant="subtitle2"
              style={{ fontSize: "0.75rem", marginTop: 16 }}
            >
              Horee! Kamu sudah membeli TMB+. Terima kasih atas kepercayaannya!
              Klik tombol di bawah untuk melihat hasil TMB+ kamu:
            </Typography>
            <Link to={"tesminatbakat/result/" + profile.hasil_tesMinatBakat}>
              <h4
                style={{
                  color: "#222",
                  textDecoration: "none",
                  display: "inline-block",
                  borderBottom: "solid 2px #55aa55",
                }}
              >
                Lihat
              </h4>
            </Link>
            <Typography
              variant="subtitle2"
              style={{ fontSize: "0.75rem", marginTop: 16 }}
            >
              Jangan lupa follow{" "}
              <a
                href="https://instagram.com/pakbudiapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                @pakbudiapp
              </a>{" "}
              untuk info-info menarik lainnya!
            </Typography>
          </div>
        )}
      </div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openConfirmPay}
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
        onClose={(_event, reason) => {
          if (reason !== "backdropClick" || !isLoading) {
            setOpenConfirmPay(false);
          }
        }}
      >
        <DialogTitle
          className={classes.DialogTitle}
          style={{ backgroundColor: "#4bbcec" }}
        >
          <div className={classes.logoWrap}>
            <img
              className={classes.mainLogo}
              src={`${baseURL}new-logo-w.png`}
              alt=""
            />
            <span className={classes.pageName}>TMB+</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">
            Apakah kamu yakin ingin membeli TMB+ dengan 110 koin? Bayar sekarang
            dan siapkan masa depanmu dari sekarang!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.actionButton}
            style={{ color: "red" }}
            variant="outlined"
            fullWidth
            onClick={() => setOpenConfirmPay(false)}
          >
            Kembali
          </Button>
          <Button
            className={classes.actionButton}
            style={{ color: "white" }}
            variant="contained"
            fullWidth
            color="primary"
            onClick={payWithCoin}
          >
            Beli
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TmbPlusCheckout;
