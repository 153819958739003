import React from "react";

// import komponen material ui
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

// import komponen React Router DOM
import { Link } from "react-router-dom";

// import styles
import useStyles from "./styles";

import BottomNav from "../../../components/BottomNav";
import TopNav from "../../../components/TopNav";
import { baseUrl } from "../../../images/s3";

// buat sebuah fungsi komponen dengan nama NotFound
function Home(props) {
  const classes = useStyles();
  return (
    <div>
      <TopNav />
      <Container maxWidth="lg" component="main" className={classes.mainWrap}>
        <div className={classes.mainBody}>
          <Typography className={classes.heroTitle} variant="h1">
            87%
          </Typography>
          <Typography className={classes.subtitle} variant="h3">
            Orang Salah Pilih Jurusan Kuliah !!
          </Typography>
          <Typography className={classes.text} variant="subtitle1">
            Ikuti Tes Minat Bakat Online
          </Typography>
          <Typography className={classes.free} variant="h4">
            GRATIS!!
          </Typography>
          <Typography className={classes.text} variant="subtitle1">
            Agar kamu bisa tau jurusan kuliah dan jenis pekerjaan yang sesuai
            dengan bakatmu:
          </Typography>
          <div className={classes.btnWrap}>
            <Button
              className={classes.btnTest}
              size="large"
              component={Link}
              to="/tesminatbakat/game"
              variant="contained"
            >
              {" "}
              ISI TES SEKARANG
            </Button>
          </div>
          <div className={classes.botSection}>
            <img
              className={classes.heroImg}
              src={`${baseUrl}chars-all.png`}
              alt="logo"
            />
          </div>
        </div>
      </Container>
      <BottomNav />
    </div>
  );
}

export default Home;
