// React
import React, { useEffect, useState } from "react";
import {
  firestore,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";

// Styles
import useStyles from "../home/styles";

// MUI
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

// Router
import { Link, useLocation } from "react-router-dom";

import { useSiswa } from "../../components/SiswaProvider";

// Component
import { TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import AppLoading from "../../components/AppLoading";
import BottomNav from "../../components/BottomNav";
import PbNumberInput from "../../components/PbNumberInput";
import TopNav from "../../components/TopNav";
import useDebounce from "../../hooks/useDebounce";
import { client } from "../../hooks/useFetchCoralis";
import noPic from "../../images/no-pic.svg";
import { baseURL } from "../../images/s3";

function CekPeluang({ history }) {
  // UseStyles
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const retry = queryParams.get("retry");

  // Profie
  const { profile, setPageEvent } = useSiswa();
  const { user } = useFirebase();

  const { enqueueSnackbar } = useSnackbar();

  const [formValue, setFormValue] = useState({
    tps1: 0,
    tps2: 0,
    tps3: 0,
    tps4: 0,
    tl1: 0,
    tl2: 0,
    tl3: 0,
    irt: 0,
    ptnId: null,
  });
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);

  useEffect(() => {
    checkAlreadyTest();
  });

  async function checkAlreadyTest() {
    try {
      if (retry === null) {
        const response = await client.get(
          `check_user_already_take?uid=${user.uid}`
        );
        const { data } = response.data;
        if (data.length > 0) {
          history.push("/cekpeluangresult");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function onSearch() {
    try {
      if (searchValue !== "") {
        setShowResult(true);
        const response = await client.get(`search_ptn?keyword=${searchValue}`);
        const { data } = response.data;
        setSearchResults(data);
        searchUniversity();
      } else {
        setSearchValue("");
        setSearchResults([]);
        setShowResult(false);
        setFormValue((prevState) => ({ ...prevState, ptnId: null }));
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleInputBlur = () => {
    if (searchValue !== "") {
      // Panggil fungsi lain jika handleSelectPTN tidak terpanggil
      searchUniversity();
    }
  };

  async function searchUniversity() {
    try {
      const universitiesRef = firestore.collection("universitas");
      const querySnapshot = await universitiesRef
        .where("nama", "==", searchValue)
        .get();

      if (!querySnapshot.empty) {
        const university = querySnapshot.docs[0].data();
        const universityId = university.ptn_id;
        setSelectedUniversity({ id: universityId, nama: searchValue });
        setShowResult(true);
        setSearchResults([{ ptn_id: universityId, ptn_nama: searchValue }]);
        setFormValue((prevValue) => ({
          ...prevValue,
          ptnId: universityId,
        }));
      } else {
        enqueueSnackbar(
          "Nama Jurusan dan Universitas yang anda masukkan tidak ada!",
          { variant: "error" }
        );
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const debounceOnChange = useDebounce(onSearch);

  const handleLink = () => {
    setPageEvent("home");
    history.push("/editprofile");
  };

  async function handleSubmitForm(event) {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      const validate = formValidation();
      if (validate.valid === false) {
        enqueueSnackbar(validate.message, { variant: "error" });
        return;
      }
      const payCekPeluang = functions.httpsCallable("payCekPeluang");
      await payCekPeluang({
        ...formValue,
        uid: user.uid,
      });
      history.push("/cekpeluangresult");
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleSelectPTN(id, nama) {
    setShowResult(false);
    setFormValue((prevState) => ({ ...prevState, ptnId: id }));
    setSearchValue(nama);
  }

  function formValidation() {
    const response = { valid: true, message: "All is good" };
    const updatedForm = sanitizeNaN(formValue);
    setFormValue(updatedForm);
    if (formValue.ptnId === null) {
      response.valid = false;
      response.message =
        "Kamu harus memilih Jurusan dan Universitas terlebih dahulu";
      return response;
    }
    return response;
  }

  function sanitizeNaN(obj) {
    for (const key in obj) {
      if (isNaN(obj[key]) && obj[key] !== null) {
        obj[key] = 0;
      }
    }
    return obj;
  }

  if (isSubmitting) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>PakBudi | Cek Peluang</title>
      </Helmet>
      <TopNav />
      <div className={classes.profileBg}>
        <Grid container className={classes.page}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* FOTO PROFIL */}
              <Grid item>
                <Avatar
                  alt=""
                  src={user?.photoURL ? user?.photoURL : noPic}
                  className={classes.profileImg}
                />
              </Grid>

              {/* DATA PROFIL */}
              <Grid item xs>
                <div className={classes.profileBar}>
                  <p className={classes.username}>{profile?.nama}</p>
                  <h5 className={classes.profile}>{profile?.sekolah?.nama}</h5>
                  <h5 className={classes.profile}>{profile?.kampus?.nama}</h5>
                  <h5 className={classes.profile}>{profile?.jurusan?.nama}</h5>
                  {/* EDIT PROFILE */}
                  <div className={classes.editBtnCon}>
                    <Button
                      size="small"
                      variant="contained"
                      className={classes.editBtn}
                      onClick={handleLink}
                    >
                      Edit Profile
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <form onSubmit={handleSubmitForm}>
        <div className={classes.pbCard + " first medium"}>
          <h2>Prediksi UTBK SNBT</h2>
          <p>Lengkapi input dibawah ini!</p>
          <div className={classes.pbCardTitle}>
            Jumlah Jawab Benar Soal 7 Subtes
          </div>
          <PbNumberInput
            label="Kemampuan penalaran umum"
            inputName="tps1"
            value={formValue.tps1}
            setValue={setFormValue}
            max="30"
          ></PbNumberInput>
          <PbNumberInput
            label="Pengetahuan dan pemahaman umum"
            inputName="tps2"
            value={formValue.tps2}
            setValue={setFormValue}
            max="20"
          ></PbNumberInput>
          <PbNumberInput
            label="Kemampuan memahami bacaan & menulis"
            inputName="tps3"
            value={formValue.tps3}
            setValue={setFormValue}
            max="20"
          ></PbNumberInput>
          <PbNumberInput
            label="Pengetahuan kuantitatif"
            inputName="tps4"
            value={formValue.tps4}
            setValue={setFormValue}
            max="15"
          ></PbNumberInput>
          <PbNumberInput
            label="Literasi bahasa Indonesia"
            inputName="tl1"
            value={formValue.tl1}
            setValue={setFormValue}
            max="30"
          ></PbNumberInput>
          <PbNumberInput
            label="Literasi bahasa Inggris"
            inputName="tl2"
            value={formValue.tl2}
            setValue={setFormValue}
            max="20"
          ></PbNumberInput>
          <PbNumberInput
            label="Penalaran matematika"
            inputName="tl3"
            value={formValue.tl3}
            setValue={setFormValue}
            max="20"
          ></PbNumberInput>
        </div>
        <div className={classes.pbCard + " medium"}>
          <div className={classes.pbCardTitle}>Nilai IRT UTBK SNBT</div>
          <p>
            Kosongkan jika tidak memiliki nilai IRT UTBK SNBT. Jika IRT kosong,
            peluang akan dihitung berdasarkan subtes saja.
          </p>
          <PbNumberInput
            label="Nilai Total IRT"
            inputName="irt"
            value={formValue.irt}
            setValue={setFormValue}
            max="1500"
          ></PbNumberInput>
        </div>
        <div className={classes.pbCard + " medium"}>
          <div className={classes.pbCardTitle}>Jurusan dan Universitas</div>
          <p>
            Cari Jurusan dan Universitas impian kamu dengan format [JURUSAN] -
            [UNIVERSITAS]
          </p>
          <TextField
            id="search-bar"
            onChange={(e) => {
              debounceOnChange();
              setSearchValue(e.target.value);
            }}
            label="Jurusan - Universitas"
            variant="outlined"
            placeholder="Cari..."
            size="small"
            style={{ position: "relative", width: 300 }}
            value={searchValue}
          />
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              width: 300,
            }}
          >
            {showResult &&
              searchResults.map((result) => (
                <div
                  className="text"
                  onClick={() =>
                    handleSelectPTN(
                      result.ptn_id,
                      `${result.ptn_fakultas} - ${result.ptn_nama}`
                    )
                  }
                  style={{
                    fontSize: 12,
                    padding: "10px 0",
                    backgroundColor: "white",
                    borderBottom: "1px solid",
                  }}
                  key={result.ptn_id}
                >
                  {result.ptn_fakultas} - {result.ptn_nama}
                </div>
              ))}
            {!showResult && searchValue && (
              <div>
                <input
                  style={{ display: "none" }}
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onBlur={() => {
                    if (!searchResults.length) {
                      // Panggil fungsi lain jika handleSelectPTN tidak terpanggil
                      handleInputBlur();
                    }
                  }}
                />
              </div>
            )}
          </div>
          {selectedUniversity && (
            <div>Universitas yang dicari: {selectedUniversity.nama}</div>
          )}
        </div>
        <button type="submit" className={classes.pbButton}>
          <p>Cek Peluang Sekarang</p>
          <div className={classes.pbCoinGroup}>
            <span>70</span>
            <img
              className={classes.imgCoin}
              src={`${baseURL}icon-coin.png`}
              alt="pakbudi-coin"
            />
          </div>
        </button>
        <Link to="/home" className={classes.pbBackLink}>
          Kembali
        </Link>
      </form>
      <BottomNav />
    </div>
  );
}

export default CekPeluang;
