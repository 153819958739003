function getOrientation(file, callback) {
  const reader = new FileReader();
  reader.onload = function (e) {
    const view = new DataView(e.target.result);
    if (view.getUint16(0, false) !== 0xffd8) {
      return callback(-2);
    }
    let length = view.byteLength,
      offset = 2;
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
      let marker = view.getUint16(offset, false);
      offset += 2;
      if (marker === 0xffe1) {
        let newTwoOffset = (offset += 2);
        if (view.getUint32(newTwoOffset, false) !== 0x45786966) {
          return callback(-1);
        }
        let newSixOffset = (offset += 6);
        let little = view.getUint16(newSixOffset, false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        let tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            return callback(view.getUint16(offset + i * 12 + 8, little));
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    return callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

export default function getOrientationAsync(file) {
  return new Promise((resolve, reject) => {
    getOrientation(file, (orientation) => {
      resolve(orientation);
    });
  });
}
