// React
import React, { useEffect, useState } from "react";

// Styles
import useStyles from "./styles/hasilTest";

// Component
import TopNav from "../../../components/TopNav";

import Container from "@material-ui/core/Container";
// Images

import noticeLogo from "../../../images/notice.png";
import { baseUrl } from "../../../images/s3";

// Expansion
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Typography
import Typography from "@material-ui/core/Typography";
import results from "./data/results.json";

import { Button } from "@material-ui/core";
import { Link, Redirect, useHistory } from "react-router-dom";

// Firebase
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Helmet } from "react-helmet";
import { useFirebase } from "../../../components/FirebaseProvider";
import { useSiswa } from "../../../components/SiswaProvider";

function HasilTest(props) {
  const classes = useStyles();
  const { match, location } = props;
  const { symbol } = match.params;
  const history = useHistory();
  const { profile } = useSiswa();

  const { firestore, user } = useFirebase();
  const [isPaidTmbPlus, setIsPaidTmbPlus] = useState(false);
  const [mbtiDataExist, setMbtiDataExist] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [popupVisible, setPopupVisible] = useState(true);
  let counter = 0;

  const [banner] = useDocumentData(firestore.doc(`events/banner`));

  useEffect(() => {
    fetchData();
    let intervalId;
    if (isPaidTmbPlus === false) {
      intervalId = setInterval(runPopupTimer, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaidTmbPlus]);

  function runPopupTimer() {
    counter = counter + 1;
    if (counter >= 30) {
      setPopupVisible(true);
      counter = 0;
    }
  }

  async function fetchData() {
    try {
      const findSiswaDoc = await firestore.doc(`siswa/${user.uid}`).get();
      const siswa = findSiswaDoc.data();
      if (
        typeof siswa.is_tmb_plus !== "undefined" &&
        siswa.is_tmb_plus === true
      ) {
        setIsPaidTmbPlus(true);
        setPopupVisible(false);
        const ucMBTI = siswa.hasil_tesMinatBakat.toUpperCase();
        if (symbol.toUpperCase !== ucMBTI) {
          history.replace(`/tesminatbakat/result/${ucMBTI.toLowerCase()}`);
        }
        if (Object.keys(results).includes(ucMBTI)) {
          const result = results[ucMBTI];
          if (
            Object.keys(result).includes("tmbPlusAvailable") &&
            result.tmbPlusAvailable === "Ya"
          ) {
            setMbtiDataExist(true);
          }
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  if (!symbol || !Object.keys(results).includes(symbol.toUpperCase())) {
    return <Redirect to="/" />;
  }
  const ucSymbol = symbol.toUpperCase();
  const result = results[ucSymbol];
  const username = profile?.nama ? profile?.nama : "anak Pak Budi";
  const nama = location?.state?.nama ? location.state.nama : username;

  return (
    <Container className={classes.container} maxWidth="lg" component="main">
      <Helmet>
        <title>PakBudi | TMB</title>
      </Helmet>
      <TopNav />
      <div className={classes.root}>
        <div className={classes.hasilTestHead}>
          <img
            src={`${baseUrl}new-logo-w-tmb.png`}
            alt=""
            className={classes.logo}
          />
        </div>
        <div className={classes.resultCard + ` ` + classes.topCard}>
          <div className={classes.cardInfo}>
            <div className={classes.info}>
              <Typography>Nama Asli :</Typography>
              <Typography className={classes.bold}>{nama}</Typography>
              <Typography className={classes.labelInfo}>
                Nama Super Hero :
              </Typography>
              <Typography className={classes.bold}>{result.tokoh}</Typography>
              <Typography className={classes.labelInfo}>Kekuatan :</Typography>
              <Typography variant="h5" className={classes.symbol}>
                {result.symbol}
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={`${baseUrl}${symbol}.png`}
                alt=""
                className={classes.resultImg}
              />
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <Typography>{result.skill[0]}</Typography>
            </div>
            <div className={classes.gridItem + ` ` + classes.gridItemCenter}>
              <Typography>{result.skill[1]}</Typography>
            </div>
            <div className={classes.gridItem}>
              <Typography>{result.skill[2]}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.tabNavContainer}>
          <div
            className={
              classes.tabNavButton +
              ` ` +
              (currentTab === 1 ? classes.tabNavActive : "")
            }
            onClick={() => {
              setCurrentTab(1);
            }}
          >
            Super Power
          </div>
          <div
            className={
              classes.tabNavButton +
              ` ` +
              (currentTab === 2 ? classes.tabNavActive : "")
            }
            onClick={() => {
              setCurrentTab(2);
            }}
          >
            Super Power PLUS
          </div>
        </div>
        <div
          className={
            classes.resultCard +
            ` ` +
            (currentTab === 1 ? classes.tabPageActive : classes.tabPageHidden)
          }
        >
          <h2 className={classes.resultDetailTitle}>Kamu adalah calon:</h2>
          <p className={classes.resultDetail}>{result.profession}</p>
          <h2 className={classes.resultDetailTitle}>
            Tokoh sukses yang mirip kepribadian kamu:
          </h2>
          <p className={classes.resultDetail}>{result.tokoh}</p>
        </div>
        <Accordion
          className={
            classes.resultAccordion +
            ` ` +
            (currentTab === 1 ? classes.tabPageActive : classes.tabPageHidden)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Penjelasan Karakter Kamu
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              component="ol"
              className={classes.panelDetailList}
              dangerouslySetInnerHTML={{ __html: result.description }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={
            classes.resultAccordion +
            ` ` +
            (currentTab === 1 ? classes.tabPageActive : classes.tabPageHidden)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Saran Pengembangan
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.panelDetail}>
              {result.improvement}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={
            classes.resultAccordion +
            ` ` +
            (currentTab === 1 ? classes.tabPageActive : classes.tabPageHidden)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Profesi yang Cocok Buat Kamu
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              className={`${classes.panelDetail} ${classes.panelDetailCapital}`}
            >
              {result.profession}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={
            classes.resultAccordion +
            ` ` +
            (currentTab === 1 ? classes.tabPageActive : classes.tabPageHidden)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Jurusan Kuliah yang Cocok Buat Kamu
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.panelDetail}>
              {result.jurusan}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={
            classes.resultAccordion +
            ` ` +
            (currentTab === 1 ? classes.tabPageActive : classes.tabPageHidden)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Tipe Kepribadian Orang Lain yang Cocok Denganmu
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.panelDetail}>
              {result.partner}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={
            classes.resultAccordion +
            ` ` +
            (currentTab === 1 ? classes.tabPageActive : classes.tabPageHidden)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Contoh Orang Sukses Dengan Kepribadian yang Sama
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.panelDetail}>
              {result.tokoh}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {isPaidTmbPlus === false && (
          <div
            className={
              classes.tmbPlusBox +
              ` ` +
              (currentTab === 2 ? classes.tabPageActive : classes.tabPageHidden)
            }
          >
            <h2 className={classes.tmbPlusTitle}>Hasil Tes Minat Bakat+</h2>
            <h4 className={classes.tmbPlusSubTitle}>Fitur belum dibuka</h4>
            <div className={classes.tmbPlusContainer}>
              <div className={classes.tmbPlusText}>
                <Typography variant="subtitle2" className={classes.tmbTitle}>
                  Yuk Cari Tahu Lebih Dalam
                </Typography>
                <Typography variant="body2" className={classes.tmbDesc}>
                  apa itu {result.symbol}, gimana baiknya kamu belajar, pilihan
                  kuliah dan karir, pekerjaan alternatif, dan tipe minat bakat
                  lain yang cocok sama kamu
                </Typography>
                <Button
                  className={classes.tmbStart}
                  variant="contained"
                  component={Link}
                  to="/checkout"
                >
                  Mulai
                </Button>
              </div>
              <div className={classes.tmbPlusImageContainer}>
                <img
                  src={`${baseUrl}tmbplus.png`}
                  alt="tmbplus"
                  className={classes.tmbPlusImage}
                />
              </div>
            </div>
          </div>
        )}
        {isPaidTmbPlus && (
          <>
            <div
              className={
                classes.resultCard +
                ` ` +
                (currentTab === 2
                  ? classes.tabPageActive
                  : classes.tabPageHidden)
              }
            >
              <h2 className={classes.tmbPlusTitle}>Hasil Tes Minat Bakat+</h2>
              <p className={classes.resultDetail}>
                Kenali dirimu lebih baik melalui hasil tes minat bakat plus
                Pakbudi:
              </p>
            </div>
            {mbtiDataExist ? (
              <>
                <Accordion
                  className={
                    classes.resultAccordion +
                    ` ` +
                    (currentTab === 2
                      ? classes.tabPageActive
                      : classes.tabPageHidden)
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Apa itu {ucSymbol}?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.panelDetail}>
                      {result.whatIs}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={
                    classes.resultAccordion +
                    ` ` +
                    (currentTab === 2
                      ? classes.tabPageActive
                      : classes.tabPageHidden)
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Personal Growth
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.panelDetail}>
                      {result.personalGrowth}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={
                    classes.resultAccordion +
                    ` ` +
                    (currentTab === 2
                      ? classes.tabPageActive
                      : classes.tabPageHidden)
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Bagaimana {ucSymbol} Belajar?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.panelDetail}>
                      {result.learning}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={
                    classes.resultAccordion +
                    ` ` +
                    (currentTab === 2
                      ? classes.tabPageActive
                      : classes.tabPageHidden)
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Pekerjaan atau Kuliah?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.panelDetail}>
                      {result.workOrCollege}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={
                    classes.resultAccordion +
                    ` ` +
                    (currentTab === 2
                      ? classes.tabPageActive
                      : classes.tabPageHidden)
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Professional Development
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.panelDetail}>
                      {result.professionalDevelopment}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={
                    classes.resultAccordion +
                    ` ` +
                    (currentTab === 2
                      ? classes.tabPageActive
                      : classes.tabPageHidden)
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Pekerjaan Alternatif
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.panelDetail}>
                      {result.pekerjaanAlternatif}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={
                    classes.resultAccordion +
                    ` ` +
                    (currentTab === 2
                      ? classes.tabPageActive
                      : classes.tabPageHidden)
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Kecocokan
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.panelDetail}>
                      {result.kecocokan}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            ) : (
              <div
                className={
                  classes.tmbPlusContainer +
                  ` ` +
                  classes.resultCard +
                  ` ` +
                  (currentTab === 2
                    ? classes.tabPageActive
                    : classes.tabPageHidden)
                }
              >
                <div className={classes.tmbPlusText}>
                  <Typography
                    variant="body2"
                    style={{ fontSize: "0.75rem", textAlign: "left" }}
                  >
                    Halo! Data kamu sedang kami proses oleh admin. Mohon tunggu
                    sebentar ya! Kami akan segera memberitahumu jika data sudah
                    siap. Terima kasih ya!
                  </Typography>
                </div>
                <div className={classes.tmbPlusImageContainer}>
                  <img
                    src={`${baseUrl}tmbplus.png`}
                    alt="tmbplus"
                    className={classes.tmbPlusImage}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {banner && banner?.target_event?.tipe === "offline" && (
          <Link
            to={`/acara/u/${banner?.target_event?.id}`}
            className={classes.bannerContainer}
          >
            <img
              src={banner?.photoUrl}
              alt=""
              crossOrigin="anonymous"
              width="300px"
            />
          </Link>
        )}
        {banner && banner?.target_event?.tipe === "online" && (
          <Link
            to={{ pathname: banner?.target_event?.lokasi_online }}
            target="_blank"
            className={classes.bannerContainer}
          >
            <img
              src={banner?.photoUrl}
              alt=""
              crossOrigin="anonymous"
              width="300px"
            />
          </Link>
        )}
      </div>
      {isPaidTmbPlus && popupVisible && (
        <div className={classes.popupOverlay}>
          <div className={classes.popupContainer}>
            <div className={classes.popupHeader}>
              <p>Kamu sedang melihat hasil Tes Minat Bakat FREE</p>
            </div>
            <img src={noticeLogo} alt="notice-logo"></img>
            <h1>
              UPGRADE ke<br></br>Tes Minat Bakat PLUS
            </h1>
            <p>Untuk melihat hasil lebih lengkap</p>
            <ul>
              <li>Kecocokan pasangan</li>
              <li>Cara belajar</li>
              <li>Kuliah / Kerja</li>
              <li>Pekerjaan alternatif</li>
            </ul>
            <div className={classes.popupButtonContainer}>
              <Button
                className={classes.ctaButtonSecondary}
                onClick={() => {
                  setPopupVisible(false);
                }}
              >
                Lihat Free
              </Button>
              <Button
                className={classes.ctaButton}
                variant="contained"
                component={Link}
                to="/checkout"
              >
                Upgrade
              </Button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}

export default HasilTest;
