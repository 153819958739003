import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/index';
import { Switch, Route, Link } from 'react-router-dom';
import ListBerita from './list';
import BeritaDetail from './beritaDetail';
import headerLogo from "../../images/new-logo-w.png";
function PublicBerita(props) {
    const { classes } = props;
    return (
        <div className={classes.rootMain}>
            <div className={classes.blueBg}></div>
            <Link to="/home" className={classes.publicHeaderLogo}><img src={headerLogo} alt="logo"></img></Link>
            <div className={classes.appBody}>
                <div className={classes.root}>
                    <Switch>
                        <Route path="/warta/:id/:slug" component={BeritaDetail} />
                        <Route component={ListBerita} />
                    </Switch>
                </div>
            </div>
        </div>
    );

}

export default withStyles(styles)(PublicBerita);