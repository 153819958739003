import React from 'react';
import PropTypes from 'prop-types';
import AppSelect from './index';
import dataKelas from './data/kelas.json';

function SelectKelas({ TextFieldProps = {}, ...props }) {

    return <AppSelect
        disabled={TextFieldProps.disabled}
        options={dataKelas ? dataKelas.map(kelas => ({ label: kelas.nama, value: kelas.kelas_id })) : []}
        inputId="react-select-kelas"
        placeholder="Pilih Kelas"
        TextFieldProps={{

            label: 'Kelas',
            InputLabelProps: {
                htmlFor: 'react-select-kelas',
                shrink: true,
            },
            ...TextFieldProps

        }}
        {...props}
    />

}

SelectKelas.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}

export default SelectKelas;
