import Button from "@material-ui/core/Button";
import React from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
// Styles
import { Grid } from "@material-ui/core";
import uniq from "lodash/uniq";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AppHeader from "../../components/AppHeader";
import { firestore } from "../../components/FirebaseProvider";
import useStyles from "./styles/index";
function PembahasanSoalItem({ soal, jawaban, no }) {
  const kunciJawabanRef = firestore.doc(`kunci_jawaban/${soal?.id}`);
  const [kunci] = useDocumentDataOnce(kunciJawabanRef);
  const classes = useStyles();
  return (
    <div className={classes.trSoal}>
      <div className={classes.soalNum}>Soal {no}</div>
      <div
        className={classes.soalText}
        dangerouslySetInnerHTML={{ __html: soal?.pertanyaan ?? "" }}
      />
      <div
        className={`${classes.myAnswer} ${
          jawaban?.jawaban === kunci?.jawaban
            ? classes.myAnswer1
            : classes.myAnswer0
        }`}
      >
        Jawaban kamu : {jawaban?.jawaban?.toUpperCase?.() ?? "-"}
      </div>
      <div className={classes.trSoalReview}>
        <div>Pembahasan</div>
        <p
          className={classes.explanation}
          dangerouslySetInnerHTML={{ __html: kunci?.penjelasan ?? "" }}
        />
        <p className={classes.trueAnswer}>
          Jawaban yang Benar : {kunci?.jawaban?.toUpperCase?.() ?? "-"}
        </p>
      </div>
    </div>
  );
}
export default function DoneTO({ to, hasilTo, soal, jawaban }) {
  const classes = useStyles();
  const history = useHistory();
  if (to.hide_ranking === "false") {
    to.hide_ranking = false;
  } else if (to.hide_ranking === "true") {
    to.hide_ranking = true;
  }
  console.log(Boolean(to.hide_ranking));
  const orderIds = jawaban?.find?.((j) => j.id === "order") ?? [];
  const mapOrderIdsToKategori = orderIds.orderIds?.map?.((soalId) => {
    const currSoal = soal?.find((s) => s.id === soalId);
    return currSoal?.kategori_soal?.id;
  });
  const sortedGrupSoal = uniq(mapOrderIdsToKategori);
  return (
    <div className={classes.resultPage}>
      <Helmet>
        <title>PakBudi | Tryout</title>
      </Helmet>
      <div className={classes.resultHead}>
        <AppHeader />
      </div>
      {Boolean(to.hide_ranking) === false ? (
        <>
          <div className={classes.contentWrap} style={{ marginTop: 120 }}>
            <div className={classes.contentWrapInner}>
              <h1 className={classes.endMsg}>{to.judul} </h1>
              <h3 className={classes.resultTitle}>Hasil Jawaban</h3>
              <div className={classes.resultTable}>
                <div className={classes.resultTableHead}>
                  <div className={classes.toTypeCell}>Tipe</div>
                  <div className={classes.toTrueCell}>Benar</div>
                  <div className={classes.toFalseCell}>Salah</div>
                  <div className={classes.toEmptyCell}>Kosong</div>
                </div>
                {sortedGrupSoal?.map((kategoriId, index) => {
                  const hasil = hasilTo.hasil[kategoriId];
                  const perkategori =
                    to.jumlah_soal_perkategori?.find?.(
                      (pk) => pk.kategori === kategoriId
                    ) ?? to.jumlah_soal_perkategori?.[kategoriId];

                  const jawaban_kosong =
                    perkategori?.total -
                    ((hasil?.benar ?? 0) + (hasil?.salah ?? 0));

                  return (
                    <div className={classes.resultRow} key={kategoriId}>
                      <div className={classes.toTypeCell}>
                        {perkategori?.nama ?? ""}
                      </div>
                      <div className={classes.toTrueCell}>
                        {hasil?.benar ?? "0"}
                      </div>
                      <div className={classes.toFalseCell}>
                        {hasil?.salah ?? "0"}
                      </div>
                      <div className={classes.toEmptyCell}>
                        {jawaban_kosong}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={classes.title}>
            <div className={classes.trHead}>
              <h2>HASIL UJIAN</h2>
              <p>{to.judul}</p>
            </div>
          </div>
          <div style={{ marginBottom: 100 }}>
            {sortedGrupSoal?.map((kategoriId) => {
              const perkategori =
                to.jumlah_soal_perkategori?.find?.(
                  (pk) => pk.kategori === kategoriId
                ) ?? to.jumlah_soal_perkategori?.[kategoriId];

              return (
                <div key={kategoriId} className={classes.testResult}>
                  <div className={classes.trDesc}>
                    <span>Tipe: {perkategori?.nama}</span>
                    <span>Jumlah Soal : {perkategori?.total}</span>
                  </div>

                  {orderIds?.orderIds?.map?.((soalId, index) => {
                    console.log(orderIds);
                    const currJawaban = jawaban?.find(
                      (j) => j.soal_id === soalId
                    );
                    const currSoal = soal?.find((s) => s.id === soalId);
                    if (currSoal?.kategori_soal?.id === kategoriId) {
                      return (
                        <PembahasanSoalItem
                          key={soalId}
                          jawaban={currJawaban}
                          soal={currSoal}
                          no={index + 1}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              );
            })}
          </div>
          <div className={classes.backtoHomeBtnWrap}>
            <Grid container style={{ maxWidth: 400, margin: "auto" }}>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    history.push(`/leaderboard/${to.id}`);
                  }}
                  className={classes.backtoHomeBtn}
                  color="secondary"
                  variant="contained"
                >
                  Lihat Ranking
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    history.push("/home");
                  }}
                  className={classes.backtoHomeBtn}
                  color="primary"
                  variant="contained"
                >
                  Kembali ke Home
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div className={classes.hideRank}>
            <p>
              Yuhuu, hasil ranking tryout kamu lagi diproses dengan penuh
              semangat sama tim kita nih! Gak lama lagi, kamu bisa cek hasil
              ranking kamu langsung dari sini. Jangan lupa untuk selalu
              pantengin update terbarunya ya!
            </p>
          </div>
        </>
      )}
    </div>
  );
}
