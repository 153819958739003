// React
import React from "react";

// Styles
import useStyles from "./styles";

// Component
import AppHeader from "../../components/AppHeader";

// Icons
import Ovo from "../../images/img-payment-ovo.png";
import Gopay from "../../images/img-payment-gopay.png";
// import Bca from '../../images/img-payment-bca.png';
import Bsi from "../../images/img-payment-bsi.png";
import Mandiri from "../../images/img-payment-mandiri.png";
import Bri from "../../images/img-payment-bri.png";
import Bni from "../../images/img-payment-bni.png";
import Button from "@material-ui/core/Button";
import { currency } from "../../utils/formatter-rupiah";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import FileCopyIcon from "@material-ui/icons/FileCopy";

// Router
import { useParams } from "react-router-dom";
//
function IsiCoin(props) {
  const { enqueueSnackbar } = useSnackbar();
  // Styles
  const classes = useStyles();
  // Router
  const params = useParams();
  // Get Params
  const Pay = params.total;
  const Method = params.method;
  const DocId = params.doc;
  const Transaksi = params.no;
  // Functions
  const handleClick = () => {
    props.history.push(`/konfirmasitransfer/${DocId}`);
  };
  // Data
  let IMG = "";
  let No = "";
  let Nama = "";
  let items = [];
  // Check Method
  if (Method === "GPY") {
    IMG = Gopay;
    No = "08112255747";
    Nama = "pakbudiapp";
    items = [
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Ovo,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1000002212",
        img: Bsi,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1800096965555",
        img: Mandiri,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "137701000427567",
        img: Bri,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1600000104",
        img: Bni,
      },
    ];
  }
  //
  if (Method === "OVO") {
    IMG = Ovo;
    No = "08112255747";
    Nama = "pakbudiapp";
    items = [
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Gopay,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1000002212",
        img: Bsi,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1800096965555",
        img: Mandiri,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "137701000427567",
        img: Bri,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1600000104",
        img: Bni,
      },
    ];
  }
  //
  if (Method === "BSI") {
    IMG = Bsi;
    No = "1000002212";
    Nama = "PT AKU ORANG INDONESIA";
    items = [
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Gopay,
      },
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Ovo,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1800096965555",
        img: Mandiri,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "137701000427567",
        img: Bri,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1600000104",
        img: Bni,
      },
    ];
  }
  //
  if (Method === "MDR") {
    IMG = Mandiri;
    No = "1800096965555";
    Nama = "PT AKU ORANG INDONESIA";
    items = [
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Gopay,
      },
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Ovo,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1000002212",
        img: Bsi,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "137701000427567",
        img: Bri,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1600000104",
        img: Bni,
      },
    ];
  }
  //
  if (Method === "BRI") {
    IMG = Bri;
    No = "137701000427567";
    Nama = "PT AKU ORANG INDONESIA";
    items = [
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Gopay,
      },
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Ovo,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1000002212",
        img: Bsi,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1800096965555",
        img: Mandiri,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1600000104",
        img: Bni,
      },
    ];
  }
  //
  if (Method === "BNI") {
    IMG = Bni;
    No = "1600000104";
    Nama = "PT AKU ORANG INDONESIA";
    items = [
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Gopay,
      },
      {
        nama: "pakbudiapp",
        no: "08112255747",
        img: Ovo,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1000002212",
        img: Bsi,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "1800096965555",
        img: Mandiri,
      },
      {
        nama: "PT AKU ORANG INDONESIA",
        no: "137701000427567",
        img: Bri,
      },
    ];
  }
  //

  const copyMsg = "Nomor Rekening / Akun telah disalin";
  const noNew = No.split(" ").join("");
  // console.log(noNew);
  const copyClick = () => {
    enqueueSnackbar(copyMsg, {
      variant: "success",
    });
  };

  return (
    <div className={classes.root}>
      <AppHeader />
      <div className={classes.coinBodyAlt}>
        <div className={classes.pageHeading}>
          <h2 className={classes.pageTitleAlt}>Rincian Transfer</h2>
        </div>
        <p className={classes.pageSubtitle5}>
          NOMOR TRANSAKSI <span className={classes.noTrx}>{Transaksi}</span>
        </p>
        <p className={classes.pageSubtitle6}>
          Simpan baik-baik halaman ini! <br />
          Silahkan transfer ke akun/rekening dibawah ini
        </p>
        <img alt="" className={classes.choiceImg1} src={IMG} />
        <p className={classes.noRek}>
          <span className={classes.btnCopyLabel}>{No}</span>
          <CopyToClipboard text={noNew}>
            <Button
              color="primary"
              variant="contained"
              className={classes.btnCopy}
              onClick={copyClick}
            >
              <FileCopyIcon className={classes.iconCopy} /> Copy
            </Button>
          </CopyToClipboard>
        </p>
        <p className={classes.sub1}>
          Atas Nama <span style={{ fontWeight: "bolder" }}>{Nama}</span>
        </p>
        <div className={classes.priceBox}>
          <p className={classes.priceRow}>
            <span className={classes.labelPrice}>Harga Coin</span>{" "}
            <span className={classes.CoinPrice}>{currency(Pay)}</span>
          </p>
          <p className={classes.priceRow}>
            <span className={classes.labelPrice}>Biaya Penanganan</span>
            <span className={classes.convPrice}>GRATIS</span>
          </p>
          <hr />
          <p className={classes.priceRow}>
            <strong className={classes.priceRow}>Total</strong>
            <span className={classes.CoinPrice}>{currency(Pay)}</span>
          </p>
        </div>
        <p className={classes.sub4}>Transfer Sejumlah :</p>
        <p className={classes.total}>{currency(Pay)}</p>
        <p className={classes.confirmMsg}>
          Setelah Transfer silahkan lakukan
          <br /> "Konfirmasi Transfer"{" "}
          <Button className={classes.Link} onClick={handleClick}>
            disini
          </Button>
        </p>
        <p className={classes.sub5}>
          Jika kamu mendapatkan kesulitan atau masalah dengan pilihan transfer
          mu diatas, kamu dapat mengganti dengan akun/rekening dibawah ini :
        </p>
        <div style={{ marginBottom: 100 }} className={classes.transferList}>
          {items.map((item) => {
            return (
              <div key={item.img} className={classes.anotherPayment}>
                <img className={classes.imgPayment} alt="" src={item.img} />
                <div>
                  <p className={classes.nohp}>{item.no}</p>
                  <p className={classes.pemilik}>
                    Atas Nama <span className={classes.nama}>{item.nama}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default IsiCoin;
