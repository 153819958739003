import axios from "axios";
import { useState } from "react";
export const client = axios.create({
  baseURL: "https://pakbudi.coralisstudio.com/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-KEY": "coralisxpakbudi2023RestAPI",
  },
});

const useFetchCoralis = async (url) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  try {
    setLoading(true);
    const result = await client.get(url);
    const { data } = result.data;
    setResponse(data);
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }

  return { loading, response, error };
};

export default useFetchCoralis;
