import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#1B3D80',
        overflowY: 'auto'
    },
    pageHeader:{
        backgroundColor: '#1D2746',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 130,
        '@media(min-width: 320px)': {
            width: '100%',
        },
        '@media(min-width: 960px)': {
            width: '60%',
        },
        margin: '0 auto',
        marginBottom: 10,
        borderRadius: '0 0 20px 20px',
    },
    logoImg:{
        position: 'relative',
        paddingTop: 13,
    },
    mainLogo:{
        width: 180,
        height: 'auto',
    },
    toggleBack:{
        width: 65,
    },
    historyBack:{
        color: '#fff !important',
    },
    otherNav:{
        marginTop: -20,
        width: 135,
        marginLeft: -55
    },
    pageBody:{
        width: '90%',
        margin: '0 auto 60px',
        '@media(min-width: 960px)': {
            width: '60%',
        },
    },
    bodySection:{
        textAlign: 'left',
        marginBottom: 10,
        paddingTop: '10px'
    },
    pageTitle:{
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 3,
    },
    pageSubtitle:{
        color: '#9fa6b5',
        fontSize: 14,
        marginTop:0,
    },
    linkBlock:{
        width: '100%',
        overflowX: 'auto',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        marginTop: 10,
    },
    linkBox:{
        width: 60,
        margin: '0 15px',
        textAlign: 'center',
        '& > span':{
            color: '#fff',
            fontWeight: 'bold'
        },
        '&:first-child':{
            marginLeft:0
        }
    },
    linkBoxTwo:{
        width: 180,
        margin: '0 10px',
        textAlign: 'center',
        '& > span':{
            color: '#fff',
            fontSize: 12,
            fontWeight: 'bold'
        },
        '&:first-child':{
            marginLeft:0
        }
    },
    linkBoxTri:{
        width: 100,
        margin: '0 10px',
        textAlign: 'center',
        '& > span':{
            color: '#fff',
            fontSize: 12,
            fontWeight: 'bold'
        },
        '&:first-child':{
            marginLeft:0
        }
    }
}))

export default useStyles;