// React
import React, { useEffect, useState } from "react";

// Styles
import useStyles from "./styles";

// Component
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AppHeader from "../../components/AppHeader";
import { useCoin } from "../../components/CoinProvider";
import { baseURL } from "../../images/s3";

import { currency, delimiters } from "../../utils/formatter-rupiah";
// import voucherIcon from '../../images/voucher-icon.png';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Helmet } from "react-helmet";
import AppLoading from "../../components/AppLoading";
import { functions } from "../../components/FirebaseProvider";
import singleCoin from "../../images/icon-coin.png";
import { MIDTRANS_CLIENT_KEY, MIDTRANS_SCRIPT_URL } from "../../utils/midtrans";

function MyCoin(props) {
  // Styles
  const classes = useStyles();
  // Firebase
  const { Coin, promo } = useCoin();
  // State
  const [count, setCount] = useState(0);
  const [submit, setSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [bilangan, setBilangan] = useState(0);

  useEffect(() => {
    const midtransScriptUrl = MIDTRANS_SCRIPT_URL;
    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;
    const myMidtransClientKey = MIDTRANS_CLIENT_KEY;
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);
    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FuncTions
  const increment = () => {
    const prevCount = count;
    const newCount = prevCount + 100;
    setCount(newCount);
    let coinMultiplier;
    if (newCount < 200) {
      coinMultiplier = 200;
    } else {
      coinMultiplier = 150;
    }
    const bilanganValue = newCount * coinMultiplier;
    setBilangan(bilanganValue);
    setSubmit(false);
  };
  const decrement = () => {
    const prevCount = count;
    const newCount = prevCount - 100;
    setCount(newCount);
    let coinMultiplier;
    if (newCount < 200) {
      coinMultiplier = 200;
    } else {
      coinMultiplier = 150;
    }
    const bilanganValue = newCount * coinMultiplier;
    setBilangan(bilanganValue);
    if (count <= 100) {
      setCount(0);
      setSubmit(true);
    }
  };
  // Check
  const koin = Coin && Coin.coin ? Coin.coin : 0;
  // Check

  const handleTransaksi = () => {
    props.history.push("/listtransaksi");
  };

  async function handleBuyCoin(totalCoin, totalMoney) {
    try {
      setIsLoading(true);
      const coinPayment = functions.httpsCallable("coinPayment");
      const response = await coinPayment({
        jumlah_koin: totalCoin,
        jumlah_rupiah: totalMoney,
      });
      window.snap.pay(`${response.data.transactionToken}`);
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>PakBudi | Coin</title>
      </Helmet>
      <AppHeader />
      <div className={classes.coinContainer}>
        <div className={classes.btnListTransaksiCon}>
          <Button
            variant="contained"
            className={classes.btnListTransaksi}
            onClick={handleTransaksi}
          >
            Daftar Transaksi
          </Button>
        </div>
        <div className={classes.coinBody2}>
          <div className={classes.pageHeading}>
            <p className={classes.pageSubtitle}>
              Maksimalkan fitur-fitur PakBudi dengan menggunakan coin agar kamu
              menjadi juara di kelas dan di ujian apapun!
            </p>
          </div>
          <div className={classes.coinImgWrap}></div>
          <div className={classes.coinStats}>
            <h3 className={classes.statsTitle}>Jumlah Koin kamu :</h3>
            <div className={classes.coinCountWrap}>
              <img
                src={`${baseURL}img-coin-double.png`}
                className={classes.imgCoin}
                alt=""
              />
              <p className={classes.coinCount}>{delimiters(koin)}</p>
            </div>
          </div>
          <div className={classes.addCoin}>
            <h4 className={classes.addCoinLabel}>Tambah Koin</h4>
            <div className={classes.valueCounter}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={`${classes.counterBtn} ${classes.counterBtnRight}`}
                        onClick={increment}
                      >
                        <AddIcon className={classes.counterItem} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        className={`${classes.counterBtn} ${classes.counterBtnLeft}`}
                        onClick={decrement}
                      >
                        <RemoveIcon className={classes.counterItem} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                  className: classes.InputProps,
                }}
                inputProps={{
                  className: classes.inputProps,
                }}
                value={delimiters(count)}
                disabled={true}
                className={classes.txtField}
              />
            </div>
            <p className={classes.moneyValue}>Senilai {currency(bilangan)} </p>
            <Button
              color="primary"
              disabled={submit}
              onClick={() => {
                handleBuyCoin(count, bilangan);
              }}
              variant="contained"
              size="large"
            >
              Proses
            </Button>
            <div className={classes.dividerpromo}></div>
            <div className={classes.promoWrap}>
              <h3 className={classes.promoWrapTitle}>Promo</h3>
              {promo &&
                promo.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      {item.status === true ? (
                        <div className={classes.promoRow}>
                          <div>
                            <Typography className={classes.promoTitle}>
                              <img src={singleCoin} alt="" />
                              <span>{delimiters(item.nilai_koin)} Coin</span>
                            </Typography>
                            <Typography className={classes.boldText}>
                              {currency(item.nilai_rupiah)}
                            </Typography>
                          </div>
                          <Button
                            classes={{ label: classes.labelBtn }}
                            className={classes.promoBtn}
                            onClick={() => {
                              handleBuyCoin(item.nilai_koin, item.nilai_rupiah);
                            }}
                            variant="contained"
                          >
                            {/* <span className={classes.promoTitle}>Promo {delimiters(item.nilai_koin)} Coin &nbsp;</span> <span className={classes.boldText}>Cuma {currency(item.nilai_rupiah)}</span> */}
                            <Typography className={classes.boldText}>
                              Mau
                            </Typography>
                          </Button>
                        </div>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCoin;
