import React, { useState } from 'react';
import useStyles from "../pages/home/styles";

function PbAccordionCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }

  return (
    <div className={classes.pbAccordionCard}>
      <h3 onClick={toggleExpanded} className={expanded ? "expanded" : ""}>{props.title}</h3>
      <div className={classes.pbAccordionBody + " " + (expanded ? "expanded" : "")}>
        {props.children}
      </div>
    </div>
  );
}

export default PbAccordionCard;