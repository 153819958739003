import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/index';
import CircularProgress from '@material-ui/core/CircularProgress';

const AppPageLoading = (props) => {

    const { classes } = props;
    
    return (
        <div className={classes.root}>
            <div className={classes.loadingBody}>
                    <CircularProgress className={classes.progress} color="primary" />
                    <p className={classes.loadingText}>loading...</p>
            </div>
        </div>
    )
}


export default withStyles(styles)(AppPageLoading);