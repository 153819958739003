import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DoneIcon from '@material-ui/icons/Done';
import { useFirebase, firestore } from '../../components/FirebaseProvider';
import { useNotifikasi } from '../../components/NotifikasiProvider';
import UIfx from 'uifx';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  subHeader: {
    backgroundColor: theme.palette.textColor.gray4
  },
  list: {
    paddingTop: 0
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let ring = [
  {
    id: 0,
    ring: 'https://cdn.glitch.com/8bc34ca3-db97-40d5-84e6-fa932e9bfe2c%2Fsilence.mp3?v=1579161528664',
    nama: 'Tidak Ada'
  },
  {
    id: 1,
    ring: 'https://cdn.glitch.com/922ee5f5-2d45-4f6a-955c-10b16e7aee54%2Fringtone.mp3?v=1575722255364',
    nama: 'Sony'
  },
  {
    id: 2,
    ring: 'https://cdn.glitch.com/fa950c3d-2965-41dd-b803-2aca6d06ce3c%2Fpop.mp3?v=1578783001098',
    nama: 'Pop'
  },
  {
    id: 3,
    ring: 'https://cdn.glitch.com/fa950c3d-2965-41dd-b803-2aca6d06ce3c%2Fhallo.mp3?v=1578782795030',
    nama: 'Halo'
  },
  {
    id: 4,
    ring: 'https://cdn.glitch.com/fa950c3d-2965-41dd-b803-2aca6d06ce3c%2Fmessage.mp3?v=1578782869644',
    nama: 'Message'
  }
]

export function Ring({ open, set }) {

  const { user } = useFirebase();

  const { notif } = useNotifikasi();

  const classes = useStyles();

  const [ringtone, setRingtone] = useState({
    id: notif && notif.ringmessage ? notif.ringmessage.id : null,
    icon: notif && notif.ringmessage ? notif.ringmessage.icon : false,
    ring: notif && notif.ringmessage ? notif.ringmessage.ring : '',
    nama: notif && notif.ringmessage ? notif.ringmessage.nama : ''
  });

  const handleClick = (event, id, ring, nama) => {
    setRingtone({
      id: id,
      icon: true,
      ring: ring,
      nama: nama
    })
    const Ring = new UIfx(ring);
    Ring.setVolume(1.0).play();
  }

  const handleClose = () => {
    set({ open: false })
    setRingtone({
      id: notif && notif.ringmessage ? notif.ringmessage.id : null,
      icon: notif && notif.ringmessage ? notif.ringmessage.icon : false,
      ring: notif && notif.ringmessage ? notif.ringmessage.ring : '',
      nama: notif && notif.ringmessage ? notif.ringmessage.nama : ''
    })
  }

  const handleSimpan = async (e) => {
    e.preventDefault();
    await firestore.doc(`/notifikasi/${user.uid}`).set({
      ringmessage: {
        nama: ringtone.nama,
        ring: ringtone.ring,
        id: ringtone.id,
        icon: ringtone.icon
      }
    }, { merge: true })
    set({
      open: false
    })
  }

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Bunyi Pesan
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSimpan}>
              Simpan
            </Button>
          </Toolbar>
        </AppBar>
        <List classes={{padding: classes.list}}>
          <ListSubheader component="div" className={classes.subHeader}>
            Nada Peringatan
          </ListSubheader>
          {
            ring.map(Ring => {
              const id = Ring.id;
              const ring = Ring.ring;
              const nama = Ring.nama;

              return (
                <React.Fragment key={id}>
                  <ListItem button disableRipple onClick={event => handleClick(event, id, ring, nama)}>
                    <ListItemText primary={`${nama}`} />
                    {
                      ringtone.icon === true && ringtone.id === id &&
                      <DoneIcon />
                    }
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            })
          }
        </List>
      </Dialog>
    </div>
  );
}

export default function FullScreenDialog({ open, set }) {

  const { user, firestore } = useFirebase();

  const { notif } = useNotifikasi();

  const classes = useStyles();

  const [ringtone, setRingtone] = useState({
    id: notif && notif.ringApp ? notif.ringApp.id : null,
    icon: notif && notif.ringApp ? notif.ringApp.icon : false,
    ring: notif && notif.ringApp ? notif.ringApp.ring : '',
    nama: notif && notif.ringApp ? notif.ringApp.nama : ''
  });

  const handleClick = (event, id, ring, nama) => {
    setRingtone({
      id: id,
      icon: true,
      ring: ring,
      nama: nama
    })
    const Ring = new UIfx(ring);
    Ring.setVolume(1.0).play();
  }

  const handleClose = () => {
    set({ open: false })
    setRingtone({
      id: notif && notif.ringApp ? notif.ringApp.id : null,
      icon: notif && notif.ringApp ? notif.ringApp.icon : false,
      ring: notif && notif.ringApp ? notif.ringApp.ring : '',
      nama: notif && notif.ringApp ? notif.ringApp.nama : ''
    })
  }

  const handleSimpan = async (e) => {
    e.preventDefault();
    await firestore.doc(`/notifikasi/${user.uid}`).set({
      ringApp: {
        nama: ringtone.nama,
        ring: ringtone.ring,
        id: ringtone.id,
        icon: ringtone.icon
      }
    }, { merge: true })
    set({
      open: false
    })
  }

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Bunyi
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSimpan}>
              Simpan
            </Button>
          </Toolbar>
        </AppBar>
        <List classes={{padding: classes.list}}>
          <ListSubheader component="div" className={classes.subHeader}>
            Nada Peringatan
          </ListSubheader>
          {
            ring.map(Ring => {
              const id = Ring.id;
              const ring = Ring.ring;
              const nama = Ring.nama;

              return (
                <React.Fragment key={id}>
                  <ListItem button disableRipple onClick={event => handleClick(event, id, ring, nama)}>
                    <ListItemText primary={`${nama}`} />
                    {
                      ringtone.icon === true && ringtone.id === id &&
                      <DoneIcon />
                    }
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            })
          }
        </List>
      </Dialog>
    </div>
  );
}