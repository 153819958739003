import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root:{
        position: 'relative',
        height: 40,
    },
    topAction:{
        width: '100%',
        height: 40,
        margin: 'auto',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    coinBtn:{
        backgroundColor: '#fff',
        padding: 0,
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 100,
    },
    btnGrp:{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRight: 'solid 1px' + theme.palette.textColor.gray3,
        padding: '4px 9px'
    },
    totalCoin:{
        fontWeight: 600,
        fontSize: 12,
    },
    icoCoin:{
        width: 15,
        height: 18,
        marginLeft: 3,
        marginRight: 3
    },
    imgCoin:{
        width: 15,
        height: 15,
    },
    icoPlusWrap:{
        display: 'flex',
        alignItems: 'center',
        margin: "0 13px 0px 5px"
    },
    icoPlus:{
        width: 20,
        height: 24,
    },
    actionBtn:{
        color: '#fff',
    },
    spacing:{
        width: 48,
        height: 48,
    },
    online: {
        color: '#ffff',
        fontWeight: 'bold',
        backgroundColor: theme.palette.textColor.green
    },
    misiBtn: {
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: 16
    }
}))

export default useStyles;