// React
import React, { useContext } from 'react';

// Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/messaging';

// Firebase Config
import firebaseConfig, { publicVapidKey } from '../config/firebase';


// Firebase Hooks
import { useAuthState } from 'react-firebase-hooks/auth';

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const facebookAuth = new firebase.auth.FacebookAuthProvider();
export const googleAuth = new firebase.auth.GoogleAuthProvider();
export const functions = firebase.functions();
export const Arr = firebase.firestore.FieldValue;
let messagingSupported = null;

if (firebase.messaging.isSupported()) {
    messagingSupported = firebase.messaging();

    messagingSupported.usePublicVapidKey(publicVapidKey);
}

export const messaging = messagingSupported

// React-Context
const FirebaseContext = React.createContext();

export function useFirebase() {
    return useContext(FirebaseContext);
}

function FirebaseProvider(props) {


    // messaging.usePublicVapidKey(publicVapidKey);

    const [user, loading, error] = useAuthState(auth);

    return (
        <FirebaseContext.Provider value={{
            facebookAuth,
            googleAuth,
            firebase,
            messaging,
            Arr,
            auth,
            firestore,
            functions,
            user,
            loading,
            error
        }}>
            {props.children}
        </FirebaseContext.Provider>
    );
}

export default FirebaseProvider;