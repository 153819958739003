import React from 'react';

// material-ui components
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useStyles from './styles/hasilTo';

import AppHeader from '../../components/AppHeader';

// Profile Hooks
import { useSiswa } from '../../components/SiswaProvider';
import { useFirebase } from '../../components/FirebaseProvider';
import { useTo } from '../../components/TryoutProvider';

//utils
import { useHistory, useParams } from 'react-router-dom';

function HasilTryout() {
    // 
    const history = useHistory();
    // 
    const params = useParams();
    // 
    const { hasiltryout, tryout } = useTo();
    // 
    const hasilTo = hasiltryout.find(to => to.id === params.to_id);
    const link = tryout.find(to => to.id === hasilTo.tryout.id);
    // 
    const classes = useStyles();
    // 
    const { profile } = useSiswa();
    const { user } = useFirebase();
    // 
    return (
        <div className={classes.root}>
            <AppHeader />
            <Grid style={{ marginBottom: 70 }} container spacing={2} direction="column" justify="center" alignItems="center">
                <Grid item xs={10} md={3} lg={3} container justify="center" alignItems="center" direction="column" className={classes.whiteBox}>
                    {user.photoURL && <Avatar alt={profile && profile.nama} src={profile && profile.photoURL} className={classes.avatarPhoto}
                        imgProps={{ crossOrigin: "anonymous" }}
                    />}
                    {!user.photoURL && <Avatar className={classes.avatarText}>{profile && profile.nama ? profile.nama.charAt(0) : 'U'}</Avatar>}
                    <Typography variant="body1" className={classes.textBlack}>{profile && profile.nama}</Typography>
                    <Typography variant="caption" className={classes.textBlack}>{profile && profile.sekolah && profile.sekolah.nama}</Typography>
                </Grid>
                <Grid item xs={10} md={3} lg={3} container justify="center" alignItems="flex-start" direction="column" className={classes.whiteBox}>
                    <Typography variant="h6" color="secondary">{hasilTo.tryout.nama}</Typography>
                    <Typography className={classes.textBlack}><b>Hasil Jawaban Benar</b></Typography>
                    {
                        Object.entries(hasilTo.hasil).map(([key, values]) => {
                            return (
                                <Typography key={key} className={classes.textBlack}>{values.nama}: {values.benar}</Typography>
                            )
                        })
                    }
                </Grid>
                {
                    link.url && link.url !== '' &&
                    <Button
                        color="secondary"
                        variant="contained"
                        className={classes.backHome}
                        onClick={() => window.open(link.url)}
                    >
                        Cek Hasil & Ranking
                    </Button>
                }
                <Button
                    color="secondary"
                    variant="contained"
                    className={classes.backHome}
                    onClick={() => history.goBack()}
                >
                    Kembali Ke Home
                </Button>
            </Grid>
        </div>
    )
}

export default HasilTryout;