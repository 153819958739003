import React from "react";

// Material-UI
import { Dialog, IconButton, styled } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// Components
import { Close, ZoomIn, ZoomOut } from "@material-ui/icons";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function DialogImage({ open, handleClose, img }) {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.bg }} onClose={handleClose} fullScreen>
      <Container>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          size="small"
        >
          <Close style={{ color: "white" }} />
        </IconButton>
      </Container>

      <Wrapper>
        <TransformWrapper
          initialScale={1}
          initialPositionX={1}
          initialPositionY={1}
        >
          {({ zoomIn, zoomOut }) => (
            <>
              <HeadWrapper>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => zoomOut()}
                  aria-label="close"
                  size="small"
                >
                  <ZoomOut style={{ color: "white" }} />
                </IconButton>
                <div style={{ width: 16 }} />
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => zoomIn()}
                  aria-label="close"
                  size="small"
                >
                  <ZoomIn style={{ color: "white" }} />
                </IconButton>
              </HeadWrapper>
              <TransformComponent>
                <div
                  style={{
                    width: "100vw",
                    textAlign: "center",
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                >
                  <img
                    src={img}
                    alt={img}
                    style={{
                      objectFit: "contain",
                      width: "40vw",
                      height: "100vh",
                      margin: "0 auto",
                    }}
                  />
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Wrapper>
    </Dialog>
  );
}

// Style
const useStyles = makeStyles(() => ({
  bg: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
}));

// Styled Component
const Container = styled("div")(() => ({
  position: "fixed",
  zIndex: 10,
  top: 0,
  left: 0,
  right: 0,
  padding: "16px 16px 16px 16px",
  backgroundColor: "rgba(0,0,0,0.7)",
}));

const Wrapper = styled("div")(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
}));

const HeadWrapper = styled("div")(() => ({
  position: "fixed",
  display: "flex",
  flexDirection: "row",
  zIndex: 11,
  top: 16,
  right: 16,
}));

export default DialogImage;
