// React
import React from "react";

// Styles
import useStyles from "./styles";
import { withStyles } from "@material-ui/styles";

// MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { baseURL } from "../../images/s3";

function TopUp({ open, handleNoTopUp, handleTopUp }) {
  // UseStyles
  const classes = useStyles();

  // Yes
  const Btn1 = withStyles({
    label: {
      textTransform: "capitalize",
      fontSize: 10,
      fontWeight: 600,
    },
    root: {
      height: 35,
      padding: "6px 9px"
    },
  })(Button);

  // No
  const Btn0 = withStyles({
    label: {
      textTransform: "capitalize",
      fontSize: 10,
      fontWeight: 600,
      color: "#777",
    },
    root: {
      border: "1.5px solid #777",
      height: 35,
      padding: "6px 9px"
    },
  })(Button);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      PaperProps={{
        style: {
          borderRadius: 10,
        },
      }}
    >
      <DialogTitle className={classes.DialogTitle}>
        <img width="150px" src={`${baseURL}new-logo-w.png`} alt="" />
      </DialogTitle>
      <DialogContent style={{ marginTop: 10 }}>
        <DialogContentText align="center"
          style={{ marginBottom: 5, fontWeight: "bolder" }}
        >
          Yah.. Coin Kamu Gak Cukup
        </DialogContentText>
        <DialogContentText align="center" style={{ fontSize: 13 }}>
          Top Up Dulu Yuk, Supaya Kamu Bisa Mengerjakan TryOut !!!
        </DialogContentText>
        <DialogContentText align="center">
          <img src={`${baseURL}img-coin-double.png`} alt="" />
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ margin: 8 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Btn0
              onClick={handleNoTopUp}
              variant="outlined"
              size="small"
              fullWidth
            >
              Nanti
            </Btn0>
          </Grid>
          <Grid item xs={6}>
            <Btn1
              onClick={handleTopUp}
              variant="contained"
              color="primary"
              size="small"
              fullWidth
            >
              Top Up
            </Btn1>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default TopUp;
