import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useFirebase } from './FirebaseProvider';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import AppLoading from './AppLoading';
import SiswaProvider from './SiswaProvider';
function SiswaRoute({ component: Component, ...restProps }) {
    const { user, firestore } = useFirebase();
    const roledoc = firestore.doc(`roles/${user.uid}`);
    const [role, loadingrole] = useDocumentData(roledoc);
    return <Route
        {...restProps}
        render={props => {

            if (loadingrole) {
                return <AppLoading />
            }


            return (role && role.akses === 'mitra') || (role && role.akses === 'admin') || (role && role.akses === 'bk') || (role && role.akses === 'cs') ?
                <Redirect to={{
                    pathname: "/restricted",
                }} />
                :
                <SiswaProvider> <Component {...props} /></SiswaProvider>
        }}
    />
}

export default SiswaRoute;