// React
import React, {useState} from 'react';

// Input
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Styles
import useStyles from './styles/index';

// Router
import {Redirect} from 'react-router-dom';

// Components
import AppHeader from '../../components/AppHeader';
import AppLoading from '../../components/AppLoading';

// Firebase
import {useFirebase} from '../../components/FirebaseProvider';
import {siswa} from '../../config/url'; 

function LoginViaLink({location}) {
    // Firebase
    const {auth, user, loading} = useFirebase(); 

    // State
    const [email, setEmail] = useState('') ;
    const [error, setError] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);

    // Class
    const classes = useStyles();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            return setError('Email wajib diisi');
        }
        if (auth.isSignInWithEmailLink(window.location.href)) {
            try{
                setSubmitting(true);
                await auth.signInWithEmailLink(email, window.location.href)
            }catch(err) {
                setError(err.message);
                setSubmitting(false);
            }
            }else {
                setError('Login link tidak valid, Kami akan mengirimkan link baru, silahkan cek email anda.');
                const actionCodeSettings = {
                    url: `${siswa}login-via-link`,
                    handleCodeInApp: true,
                }
            try{
                await auth.sendSignInLinkToEmail(email, actionCodeSettings);
            }catch(err) {
                setError(err.message);
            }
        }
    }

    // Condition Loading
    if (loading) {
        return <AppLoading />
    }

    // conditions when there are users for SignIn with form
    if (user) {
        const redirectTo = location.state && location.state.from ? location.state.from.pathname : '/lengkapiData' ;
        return <Redirect to={redirectTo} />
    }

    return(
        <div className={classes.root}>
            <AppHeader />
            <div className={classes.pageHeading}>
                <h2 className={classes.pageTitle}>Masuk Aplikasi</h2>
                <p className={classes.pageSubtitle}>Masuk dengan link</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={classes.formRow}>
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        className={classes.textField}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        helperText={error}
                        error={error ? true : false}
                        disabled={isSubmitting}
                    />
                </div>
                <div className={classes.formRow}>
                    <Button
                        className={classes.loginBtn}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Masuk
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default LoginViaLink;