import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formRow:{
        margin: '0 auto 10px',
        width: '75%',
        display: 'flex',
        justifyContent: 'space-between',
        '@media(min-width: 400px)': {
            width: '70%',
        },
        '@media(min-width: 500px)': {
            width: '65%',
        },
        '@media(min-width: 600px)': {
            width: '60%',
        },
        '@media(min-width: 700px)': {
            width: '50%',
        },
    },
    formRowBtn:{
        margin: '10px auto 10px',
        width: '75%',
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column nowrap',
        '@media(min-width: 400px)': {
            width: '70%',
        },
        '@media(min-width: 500px)': {
            width: '65%',
        },
        '@media(min-width: 600px)': {
            width: '60%',
        },
        '@media(min-width: 700px)': {
            width: '50%',
        },
    },
    pageTitle:{
        color: theme.palette.textColor.gray,
        fontSize: 24,
        marginBottom: 10,
    },
    pageSubtitle:{
        color: theme.palette.textColor.gray,
        fontSize: 14,
        marginTop: 0,
    },
    textField:{
        width: '100%',
    },
    loginBtn:{
        borderRadius: 4,
        textTransform: 'Capitalize',
        fontWeight: 'bold',
        padding: '5px 25px',
        width: '100%',
        borderBottom: '-10px'
    },
}))

export default useStyles;