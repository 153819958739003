import React from 'react';

// Route
import { Route, Redirect } from 'react-router-dom';

import NotifikasiProvider from './NotifikasiProvider';
import CoinProvider from './CoinProvider';
import ChatProvider from './useChatList';
import SiswaRoute from './SiswaRoute';
import TryoutProvider from './TryoutProvider';
// Firebase
import { useFirebase } from './FirebaseProvider';

function PrivateRouter({ component: Component, ...restProps }) {
    const { user } = useFirebase();
    return (
        <Route
            {...restProps}
            render={props => {
                return user ?
                    <TryoutProvider><ChatProvider><CoinProvider><NotifikasiProvider><SiswaRoute component={Component}  {...restProps} /></NotifikasiProvider></CoinProvider></ChatProvider></TryoutProvider>
                    :
                    <Redirect to={{
                        pathname: '/login',
                        state: {
                            from: props.location
                        }
                    }}
                    />
            }}
        />
    );
}

export default PrivateRouter;