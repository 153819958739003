import React, { useEffect, useState } from 'react';
import { useTo } from '../../components/TryoutProvider';
import { useParams, useHistory } from 'react-router-dom';

import StartTO from './start';
import SoalTO from './soal';
import { useDownloadApp } from '../../components/DownloadApp';
import { useCollectionDataOnce, useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from '../../components/FirebaseProvider';
import AppLoading from '../../components/AppLoading';
import DoneTO from './done';

export default function TryOut() {

    const params = useParams();
    const history = useHistory();

    const { hasiltryout, tryout } = useTo();

    const [activeTO, setActiveTO] = useState({});
    const [activeHasilTO, setActiveHasilTO] = useState({})
    const { hideDownloadApp } = useDownloadApp();

    const soalColRef = firestore.collection("soal").where("tryout.id", "==", activeTO.id || "");
    const [soal, loadingsoal] = useCollectionDataOnce(soalColRef, { idField: "id" });
    const jawabanColRef = firestore.collection(`hasil_tryout/${params.hasil_tryout_id}/jawaban_siswa`);
    const [jawaban, loadingjawaban] = useCollectionData(jawabanColRef, { idField: "id" });
    useEffect(() => {

        hideDownloadApp();
    }, [hideDownloadApp])

    useEffect(() => {

        const findHasilTO = hasiltryout.find(item => item.id === params.hasil_tryout_id);

        if (findHasilTO) {

            setActiveHasilTO(findHasilTO);

            const findTO = tryout.find(item => item.id === findHasilTO.tryout.id)

            if (findTO) {
                setActiveTO(findTO);
            } else {
                history.push("/home")
            }
        } else {
            history.push("/home")
        }


    }, [
        params.hasil_tryout_id,
        tryout,
        history,
        hasiltryout
    ])


    if (loadingsoal || loadingjawaban) {
        return <AppLoading />
    }

    if (activeHasilTO.status === "done") {
        return <DoneTO to={activeTO} hasilTo={activeHasilTO} soal={soal} jawaban={jawaban} />
    }

    if (activeHasilTO.status === "purchased") {
        return <StartTO tryout={activeTO} />
    }


    return <SoalTO to={activeTO} hasilTo={activeHasilTO} soal={soal} jawaban={jawaban} />

}