import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rankHead: {
    backgroundColor: theme.palette.lightBlue.main,
    padding: "30px 15px 0",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: "relative",
  },
  goBack: {
    position: "absolute",
    top: 5,
    left: 0,
    color: "#fff",
  },
  rankTitle: {
    "& > h2": {
      color: "#fff",
      margin: "5px 0 0",
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: "1.500rem",
      fontWeight: 700,
    },
    "& > p": {
      color: theme.palette.darkBlue.main,
      margin: "0 0 15px",
      textAlign: "center",
      fontSize: "0.750rem",
      fontWeight: 600,
      textTransform: "capitalize",
    },
  },
  hideRank: {
    "& > h2": {
      color: "#fff",
      margin: "5px 0 0",
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: "1.250rem",
      fontWeight: 700,
    },
    "& > p": {
      color: theme.palette.darkBlue.main,
      margin: "100px auto",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: 600,
      maxWidth: "500px",
      padding: "10px",
    },
  },
  rankTopThree: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "space-between",
    margin: "auto",
    "@media(min-width: 480px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 768px)": {
      width: 400,
    },
  },
  rankBox: {
    width: "28%",
    backgroundColor: "#fff",
    borderRadius: 20,
    position: "relative",
  },
  rank1: {
    width: "35%",
  },
  rankValue: {
    position: "absolute",
    top: -7,
    right: -10,
    width: 24,
    height: 24,
    color: "#fff",
    fontSize: "1.063rem",
    fontWeight: 700,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rankValue2: {
    backgroundColor: theme.palette.textColor.gray8,
  },
  rankValue1: {
    backgroundColor: theme.palette.orange.main,
    width: 30,
    height: 30,
    fontSize: "1.250rem",
  },
  rankValue3: {
    backgroundColor: theme.palette.brown.main,
  },
  rankUserImg: {
    width: 48,
    height: 48,
    margin: "10px auto 5px",
    borderRadius: "50%",
    border: "solid 1px " + theme.palette.textColor.gray2,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding: 5,
    "& > img": {
      width: "100%",
      height: "auto",
    },
  },
  rankUserImg1: {
    width: 60,
    height: 60,
    "& > img": {
      width: "100%",
      height: "auto",
    },
  },
  rankUserName: {
    color: theme.palette.textColor.darkGray,
    fontSize: "0.625rem",
    fontWeight: 600,
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "90%",
    height: "fit-content",
    margin: "auto",
    whiteSpace: "nowrap",
  },
  rankUserName1: {
    fontSize: "0.750rem",
  },
  rankUserSchool: {
    color: theme.palette.textColor.gray,
    fontSize: "0.500rem",
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "90%",
    height: "fit-content",
    margin: "auto",
    whiteSpace: "nowrap",
  },
  rankUserSchool1: {
    fontSize: "0.625rem",
  },
  rankUserScore: {
    color: theme.palette.textColor.gray8,
    fontSize: "1.063rem",
    fontWeight: 700,
    marginBottom: 5,
  },
  lbTabs: {
    position: "relative",
    width: "94%",
    margin: "auto",
    "@media(min-width: 480px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 768px)": {
      width: 400,
    },
  },
  tabLabel: {
    fontSize: "0.625rem",
  },
  regColor: {
    color: "#000000",
    fontSize: "0.625rem",
    textTransform: "capitalize",
  },
  TabSelected: {
    color: "#fff",
  },
  lastUpdate: {
    color: theme.palette.textColor.yellow,
    fontSize: "0.438rem",
    position: "absolute",
    top: 15,
    right: 0,
    width: 80,
    "& > span": {
      display: "block",
      textAlign: "right",
    },
  },
  rankTable: {
    borderTop: "solid 1px " + theme.palette.textColor.gray7,
    padding: "8px 0",
    margin: 0,
  },
  rankTableHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    fontSize: "0.625rem",
    fontWeight: 600,
    textAlign: "left",
    width: "94%",
    margin: "auto",
    "@media(min-width: 480px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 768px)": {
      width: 400,
    },
  },
  rankCell: {
    width: "13%",
  },
  userCell: {
    width: "37%",
    textAlign: "center",
  },
  schoolCell: {
    width: "37%",
  },
  gradeCell: {
    width: "13%",
    textAlign: "center",
  },
  rankTableBody: {
    margin: "15px auto",
    width: "94%",
    position: "relative",
    "@media(min-width: 480px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 768px)": {
      width: 400,
    },
  },
  rankWrap: {
    display: "flex",
  },
  rankRow: {
    backgroundColor: "#fff",
    borderRadius: 7,
    padding: 7,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5,
    position: "relative",
    zIndex: 1,
    width: "100%",
  },
  myRankWrap: {
    top: "0px !important",
    zIndex: 3,
    position: "sticky !important",
  },
  myRank: {
    backgroundColor: theme.palette.lightBlue.secondary,
    bottom: "78px !important",
    zIndex: 3,
    position: "fixed !important",
    maxWidth: "400px",
  },
  myRankAlt: {
    backgroundColor: theme.palette.lightBlue.secondary,
    position: "relative",
    marginTop: 24,
  },
  rankMarker: {
    backgroundColor: theme.palette.lightBlue.main,
    color: "#fff",
    fontSize: "0.438rem",
    fontWeight: 700,
    position: "absolute",
    width: 54,
    padding: "3px 0",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    textAlign: "center",
    top: -16,
    left: 48,
    zIndex: 2,
  },
  rowRankCell: {
    width: "13%",
    color: theme.palette.darkBlue.main,
    fontSize: "0.813rem",
    fontWeight: 600,
    textAlign: "center",
  },
  rowUserCell: {
    width: "37%",
    color: theme.palette.textColor.gray,
    fontSize: "0.625rem",
    fontWeight: 600,
    textAlign: "left",
    wordBreak: "break-word",
    paddingRight: 5,
  },
  rowSchoolCell: {
    width: "37%",
    color: theme.palette.textColor.gray,
    fontSize: "0.625rem",
    fontWeight: 500,
    textAlign: "left",
    wordBreak: "break-word",
  },
  rowGradeCell: {
    width: "13%",
    color: theme.palette.textColor.gray,
    fontSize: "0.813rem",
    fontWeight: 600,
    textAlign: "center",
  },
  participantCount: {
    fontSize: "0.625rem",
    textAlign: "left",
    color: "#FFF",
    fontWeight: "bold",
    maxWidth: "400px",
    margin: "0 auto",
  },
}));

export default useStyles;
