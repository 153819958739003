// React
import React from "react";

// Router
import { Route } from "react-router-dom";

// Styles
import useStyles from "./styles";

// Component's
import AppHeader from "../../components/AppHeader";
import Harapan from "../harapan";
import LengkapiData from "../lengkapiData";
import SyaratKetentuan from "../syaratKetentuan";
import PrivacyPolicy from "../syaratKetentuan/privacyPolicy";
import Verifikasi from "../verifikasi";

function LayoutA() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppHeader />
      <div className={classes.appBody}>
        <Route
          path="/lengkapiData"
          render={(matchProps) => <LengkapiData {...matchProps} />}
        />
        <Route
          path="/verifikasi"
          render={(matchProps) => <Verifikasi {...matchProps} />}
        />
        <Route
          path="/harapan"
          render={(matchProps) => <Harapan {...matchProps} />}
        />
        <Route path="/syaratKetentuan" component={SyaratKetentuan} />
        <Route path="/privacyPolicy" component={PrivacyPolicy} />
      </div>
    </div>
  );
}

export default LayoutA;
