// React
import React from "react";
import AppHeader from "../../components/AppHeader";
import EventBusyOutlinedIcon from "@material-ui/icons/EventBusyOutlined";

// Styles
import useStyles from "./styles";

// MUI
import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

// Firebase
import { firestore } from "../../components/FirebaseProvider";
import { useDocumentData } from "react-firebase-hooks/firestore";

// React Router Dom
import { useHistory, useParams } from "react-router-dom";
import AppLoading from "../../components/AppLoading";

const CustomButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1461A8",
    textTransform: "Capitalize",
    fontWeight: "bold",
    margin: "20px 0px 50px",
    "&:hover": {
      backgroundColor: "#166DBD",
    },
  },
}))(Button);

function EventIntro() {
  const classes = useStyles();

  const { eventId } = useParams();

  const history = useHistory();

  const eventDoc = firestore.doc(`events/${eventId}`);

  const [event, loadingEvent] = useDocumentData(eventDoc);

  const handleClick = () => {
    history.push(`/acara/${eventId}/daftar`);
  };

  if (loadingEvent) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AppHeader />
      </div>
      {event !== undefined ? (
        <div className={classes.tcBody}>
          <div className={classes.pageHeading}>
            <h2 className={classes.pageTitle2}>
              Selamat datang di halaman pendaftaran acara {event?.nama ?? "-"}
            </h2>
            <p
              className={classes.pageSubtitle2}
              dangerouslySetInnerHTML={{ __html: event?.deskripsi ?? <p></p> }}
            />
          </div>
          <Divider className={classes.hr} />
          <div className={classes.pageContent}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ul className={classes.ul}>
                  <li>
                    Soal dibuat baru, bukan hasil ambil dari internet / buku
                  </li>
                  <li>Kualitas soal 99% prediktif</li>
                  <li>
                    Sistem pengerjaan dan penilaian mirip SNBT aslinya untuk
                    pengalaman serupa
                  </li>
                  <li>Terbesar Serentak SeIndonesia</li>
                  <li>Panitia yang ramah</li>
                  <li>Pembahasan mendetail</li>
                </ul>
              </Grid>
              <Grid item xs={6}>
                <ul className={classes.ul}>
                  <li>Update materi dan sistem SNBT terbaru 2023</li>
                  <li>Ranking secara nasional melawan puluhan ribu peserta</li>
                  <li>Goodiebag, bonus, hadiah, dan masih banyak lagi</li>
                  <li>Kisi-kisi soal Gelombang 1 dan 2</li>
                  <li>Asuransi Covid-19</li>
                </ul>
              </Grid>
            </Grid>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              Daftar Sekarang
            </CustomButton>
            <p className={classes.note}>
              Ikuti terus info dan perkembangan acara kami di Instagram{" "}
              <span style={{ color: "#30bcec" }}>@masukkeptn</span> dan kolom
              berita aplikasi PakBudi
            </p>
          </div>
        </div>
      ) : (
        <div className={classes.tcBody} style={{ marginTop: 80 }}>
          <EventBusyOutlinedIcon style={{ fontSize: 100, color: "#0e4970" }} />
          <h3 style={{ color: "#4F4F4F" }}>Belum Ada Acara</h3>
          <Button
            color="secondary"
            variant="text"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>
        </div>
      )}
    </div>
  );
}

export default EventIntro;
